import { computed, makeObservable, observable } from 'mobx';
import {
  AiOutlineAlert,
  AiOutlineBug,
  AiOutlineClose,
  AiOutlineCustomerService,
  AiOutlineDelete,
  AiOutlineDesktop,
  AiOutlineEnvironment,
  AiOutlineEuro,
  AiOutlineFileAdd,
  AiOutlineFileExcel,
  AiOutlineFork,
  AiOutlineHome,
  AiOutlineMail,
  AiOutlineMessage,
  AiOutlineMobile,
  AiOutlinePhone,
  AiOutlineNodeIndex,
  AiOutlinePrinter,
  AiOutlineSave,
  AiOutlineSearch,
  AiOutlineDatabase,
  AiOutlineSync,
  AiOutlineHistory,
  AiFillDatabase,
} from 'react-icons/ai';
import Swal from 'sweetalert2';
import { RiPriceTag3Fill } from 'react-icons/all';
import { MenuModel, ToolModel } from '../models';
import {
  ModalStore,
  PublicStore,
  RouterStore,
} from '.';
import { ConfirmWarning } from '../utils/confirm';
import { ConfirmType, ConfirmTypeName } from '../constants';
import {
  FunctionRepository,
} from '../repositories';

interface IToolStore {
  tools: ToolModel[];
}

export default class ToolStore implements IToolStore {
  @observable tools: ToolModel[];

  readonly publicStore: PublicStore;

  readonly routerStore: RouterStore;

  readonly modalStore: ModalStore;

  menuEmail = new MenuModel('email', 'AiOutlineMail', '이메일', '/email');

  menuGps = new MenuModel('gps', 'AiOutlineEnvironment', 'GPS', '/gps');

  menuGosi = new MenuModel('gosi', 'AiOutlineFork', '정보센터', '/gosi');

  constructor(publicStore: PublicStore, routerStore: RouterStore, modalStore: ModalStore) {
    this.publicStore = publicStore;
    this.routerStore = routerStore;
    this.modalStore = modalStore;
    this.tools = [
      new ToolModel('RETRIEVE', AiOutlineSearch, '조회', () => this.onRetrieveHandler()),
      new ToolModel('NEW', AiOutlineFileAdd, '신규', () => this.onNewHandler()),
      new ToolModel('SAVE', AiOutlineSave, '저장', () => this.onSaveHandler()),
      new ToolModel('DELETE', AiOutlineDelete, '삭제', () => this.onDeleteHandler()),
      new ToolModel('ROLLBACK', AiOutlineHistory, '롤백', () => this.onRollbackHandler()),
      new ToolModel('HISTORY', AiOutlineDatabase, '기록', () => this.onReproduceHandler()),
      new ToolModel('HOME', AiOutlineHome, '홈', () => this.onHomeHandler()),
      new ToolModel('ALERT', AiOutlineAlert, '알림', () => this.onAlertHandler()),
      new ToolModel('PRINT', AiOutlinePrinter, '인쇄', () => this.onPrintHandler()),
      new ToolModel('EXCEL', AiOutlineFileExcel, '엑셀', () => this.onExcelHandler()),
      new ToolModel('FAX', AiOutlinePhone, '팩스', () => this.onFaxHandler()),
      new ToolModel('WAIT', AiOutlineNodeIndex, '대기열', () => this.onWaitHandler()),
      new ToolModel('SMS', AiOutlineMobile, '문자', () => this.onSMSHandler()),
      new ToolModel('KAKAOTALK', AiOutlineMessage, '카톡', () => this.onKakaoHandler()),
      new ToolModel('MAIL', AiOutlineMail, '메일', () => this.onEmailHandler()),
      new ToolModel('GPS', AiOutlineEnvironment, 'GPS', () => this.onGpsHandler()),
      new ToolModel('BANK', AiOutlineEuro, '은행내역', () => this.onBankHandler()),
      new ToolModel('GOSI', AiOutlineFork, '정보센터', () => this.onGosiHandler()),
      new ToolModel('QA', AiOutlineBug, '문의', () => this.onBugHandler()),
      new ToolModel('MATERIAL', AiFillDatabase, '자재관리', () => this.onMaterialHandler()),
      new ToolModel('REMOTE', AiOutlineCustomerService, '원격', () => this.onCSHandler()),
      new ToolModel('KS', RiPriceTag3Fill, 'KS산업', () => this.onKSHandler()),
      new ToolModel('TUTORIAL', AiOutlineDesktop, '교육영상', () => this.onTutorialHandler()),
      new ToolModel('CACHE', AiOutlineSync, '캐시리셋', () => this.onClearCacheHandler()),
      new ToolModel('CLOSE', AiOutlineClose, '닫기', () => this.onCloseHandler()),
    ];

    makeObservable(this);
  }

  @computed
  get headerTools(): Array<ToolModel> {
    const { user, isDev } = this.publicStore;
    if (
      (user.custcd === 'weberp' && user.perid === '00456')
      || user.userid === 'ROOT'
      || user.userid === 'ELMAN'
      || isDev
    ) {
      return this.tools;
    }

    return this.tools.filter((x) => x.id !== 'HISTORY' && x.id !== 'CACHE');
  }

  setBadgeText(key: string, text: string) {
    const filtered = this.tools.filter((x) => x.id === key);
    if (filtered.length) {
      const badge = !text || text === '0' ? '' : text;
      filtered[0].setBadgeText(badge);
    }
  }

  /**
   * 조회
   * @category 상단 툴바
   */
  onRetrieveHandler() {
    const { currentPage, setStateChanged } = this.publicStore;
    if (currentPage?.onRetrieveEvent) {
      currentPage.onRetrieveEvent();
      setStateChanged(false);
    } else {
      ConfirmWarning.show('기능 없음', '이 화면은 조회 기능이 없습니다.');
    }
  }

  /**
   * 신규
   * @category 상단 툴바
   */
  onNewHandler() {
    const { currentPage } = this.publicStore;
    if (currentPage?.onNewEvent) {
      currentPage.onNewEvent();
    } else {
      ConfirmWarning.show(
        '기능 없음',
        '이 화면은 무언가를 추가하는 기능이 없습니다.',
      );
    }
  }

  /**
   * 저장
   * @category 상단 툴바
   */
  onSaveHandler() {
    const { currentPage, setStateChanged } = this.publicStore;
    if (currentPage?.onSaveEvent) {
      currentPage.onSaveEvent();
      setStateChanged(false);
    } else {
      ConfirmWarning.show('기능 없음', '이 화면에서는 저장할 수 없습니다.');
    }
  }

  /**
   * 삭제
   * @category 상단 툴바
   */
  onDeleteHandler() {
    const { currentPage } = this.publicStore;
    if (currentPage?.onDeleteEvent) {
      currentPage.onDeleteEvent();
    } else {
      ConfirmWarning.show('기능 없음', '이 화면에서는 삭제할 수 없습니다.');
    }
  }

  /**
   * 롤백
   * @category 상단 툴바
   */
  onRollbackHandler() {
    const { currentPage } = this.publicStore;
    if (currentPage?.onSaveEvent) {
      this.modalStore.openRollbackSelector();
    } else {
      ConfirmWarning.show('기능 없음', '이 화면은 롤백할 수 없습니다.');
    }
  }

  /**
   * 기록
   * @category 상단 툴바
   */
  onReproduceHandler() {
    const { currentPage } = this.publicStore;
    if (currentPage?.onRetrieveEvent) {
      this.modalStore.openReproduceSelector();
    } else {
      ConfirmWarning.show('기능 없음', '이 화면은 기록된 내역이 없습니다.');
    }
  }

  /**
   * 홈
   * @category 상단 툴바
   */
  onHomeHandler() {
    const { routerStore, publicStore } = this;
    publicStore!.currentMenu.setEmpty();
    routerStore.replace('/');
  }

  /**
   * 알람
   * @category 상단 툴바
   */
  async onAlertHandler() {
    this.modalStore.openIndividualSelector();
  }

  /**
   * 프린트
   * @category 상단 툴바
   */
  onPrintHandler() {
    const { currentPage, isStateChanged } = this.publicStore;
    if (currentPage?.onPrintEvent && isStateChanged) {
      ConfirmWarning.show('저장 필요', '저장되지 않은 데이터가 있습니다.\n저장 후 다시 시도해주세요.');
    } else if (currentPage?.onPrintEvent) {
      currentPage.onPrintEvent();
    } else {
      ConfirmWarning.show('기능 없음', '이 화면은 인쇄할 수 없습니다.');
    }
  }

  /**
   * 엑셀
   * @category 상단 툴바
   */
  onExcelHandler() {
    const { currentPage, isStateChanged } = this.publicStore;
    if (currentPage?.onExcelEvent && isStateChanged) {
      ConfirmWarning.show('저장 필요', '저장되지 않은 데이터가 있습니다.\n저장 후 다시 시도해주세요.');
    } else if (currentPage?.onExcelEvent) {
      currentPage.onExcelEvent();
    } else {
      ConfirmWarning.show('기능 없음', '이 화면은 엑셀 변환 기능이 없습니다.');
    }
  }

  /**
   * 팩스
   * @category 상단 툴바
   */
  onFaxHandler() {
    this.modalStore.openFax();
  }

  /**
   * WAIT
   * @category 상단 툴바
   */
  onWaitHandler() {
    this.modalStore.openWaitQueue();
  }

  /**
   * SMS
   * @category 상단 툴바
   */
  onSMSHandler() {
    this.modalStore.openSMS();
  }

  /**
   * 카톡
   * @category 상단 툴바
   */
  onKakaoHandler() {
    this.modalStore.openKakao();
  }

  /**
   * 메일
   * @category 상단 툴바
   */
  onEmailHandler() {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-success',
      },
      buttonsStyling: false,
    });

    // @ts-ignore
    swalWithBootstrapButtons.fire({
      title: '선택',
      html: '이메일을 보내시겠습니까?<br/>아니면 메일함을 확인하시겠습니까?',
      icon: ConfirmTypeName[ConfirmType.QUESTION],
      showCancelButton: true,
      confirmButtonText: '메일보내기',
      cancelButtonText: '메일함(메일확인)',
      reverseButtons: true,
    // @ts-ignore
    }).then((result: { value: any; dismiss: Swal.DismissReason; }) => {
      if (result.value) {
        this.modalStore.openEmail();
      } else if (
        result.dismiss === Swal.DismissReason.backdrop
      ) {
        ConfirmWarning.show('취소', '취소하였습니다.');
      } else {
        const { publicStore } = this;
        let url = '';
        if (publicStore.user.emailuseyn === '0') {
          ConfirmWarning.show('오류', '사업자정보등록에서 이메일 사용설정을 해주세요.');
          return;
        }
        if (publicStore.user.emailuseyn === '1') {
          url = 'https://accounts.kakao.com/login?continue=https%3A%2F%2Flogins.daum.net%2Faccounts%2Fksso.do%3Frescue%3Dtrue%26url%3Dhttps%253A%252F%252Fwww.daum.net';
        }
        if (publicStore.user.emailuseyn === '2') {
          url = 'https://accounts.kakao.com/login?continue=https%3A%2F%2Fmail.kakao.com%2F';
        }
        if (publicStore.user.emailuseyn === '3') {
          url = 'https://nid.naver.com/nidlogin.login?url=http%3A%2F%2Fmail.naver.com%2F';
        }
        if (publicStore.user.emailuseyn === '4') {
          url = 'https://accounts.google.com/AccountChooser/signinchooser?service=mail&continue=https%3A%2F%2Fmail.google.com%2Fmail%2F&flowName=GlifWebSignIn&flowEntry=AccountChooser';
        }
        if (publicStore.user.emailuseyn === '5') {
          url = 'http://home.mail.nate.com/login/login.html';
        }
        window.open(url);
      }
    });
  }

  /**
   * GPS
   * @category 상단 툴바
   */
  onGpsHandler() {
    const { user } = this.publicStore;
    const url = `https://api.elmansoft.com/webgps/?key=3721762463189cb&cust=${user.custcd}`;
    window.open(url);
  }

  /**
   * 은행내역
   * @category 상단 툴바
   */
  onBankHandler() {
    this.publicStore.withManager(() => {
      this.publicStore.publish({
        command: 'bank',
      });
    });
  }

  /**
   * 자재관리
   * @category 상단 툴바
   */
  async onMaterialHandler() {
    const { data } = await FunctionRepository.exec(
      'general',
      'open',
      await this.publicStore.makeParamsWithWindowName('w_xusers', {
        sub: 'w_popup_jaego',
      }),
    );

    if (data?.jaego_yn === '1') {
      window.open('https://api.elmansoft.com/emm');
    } else {
      ConfirmWarning.show('권한없음', '바코드 재고관리 프로그램은 부가서비스입니다\n해당 기능은 바코드프린터기, 바코드인식기로 재고관리하는 기능입니다\n엘맨소프트에 문의해주세요');
    }
  }

  /**
   * CS
   * @category 상단 툴바
   */
  onCSHandler() {
    window.open('https://113366.com/elmansoft');
  }

  /**
   * CS
   * @category 상단 툴바
   */
  onKSHandler() {
    this.modalStore.openKS();
  }

  /**
   * 정보센터
   * @category 상단 툴바
   */
  onGosiHandler() {
    this.modalStore.openGosiAsk();
  }

  /**
   * 문의
   * @category 상단 툴바
   */
  onBugHandler() {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-success',
      },
      buttonsStyling: false,
    });

    // @ts-ignore
    swalWithBootstrapButtons.fire({
      title: '선택',
      html: '문의내용을 확인하시겠습니까?<br/>아니면 새로운 문의(버그제보)를 보내시겠습니까?',
      icon: ConfirmTypeName[ConfirmType.QUESTION],
      showCancelButton: true,
      confirmButtonText: '문의하기',
      cancelButtonText: '나의 문의 확인',
      reverseButtons: true,
      // @ts-ignore
    }).then((result: { value: any; dismiss: Swal.DismissReason; }) => {
      if (result.value) {
        this.publicStore.withManager(() => {
          this.publicStore.publish({
            command: 'cs',
          });
        });
      } else if (
        result.dismiss === Swal.DismissReason.backdrop
      ) {
        ConfirmWarning.show('취소', '취소하였습니다.');
      } else {
        this.modalStore.openQnASelector();
      }
    });
  }

  /**
   * 교육영상
   * @category 상단 툴바
   */
  onTutorialHandler() {
    window.open('http://elmansoft.com/tutorial');
  }

  /**
   * 윈도우 캐시 비우기
   * @category 상단 툴바
   */
  onClearCacheHandler() {
    const { publicStore } = this;
    publicStore.clearCache();
  }

  /**
   * 닫기
   * @category 상단 툴바
   */
  onCloseHandler() {
    const { headerTabStore } = this.publicStore;
    const tab = headerTabStore.active;

    if (tab) {
      let i = 0;
      for (; i < headerTabStore.tabs.length; i += 1) {
        if (headerTabStore.tabs[i].id === tab.id) break;
      }

      headerTabStore.removeTab(tab, i);
    }
  }
}
