import * as React from 'react';
import {
  AiFillMinusSquare,
  AiFillPlusSquare,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import { TextBox } from '../../../../components/forms/TextBox';
import { DateTextBox } from '../../../../components/forms/DateTextBox';
import { FlexLayout } from '../../../../components/layout/FlexLayout';
import { SearchLayout } from '../../../../components/layout/SearchLayout';
import { Global, TemplateProps } from '../../../../constants';
import { Date6Calculator, Date8 } from '../../../../utils/time';
import { SelfCheck } from './SelfCheck';
import { SearchBinding } from '../../../../models/common';
import {
  Button,
  ComboBox,
  ComboBoxModel,
  GridLayout,
  GridLayoutAdditionalHeader,
  IFrame,
  LayoutTitle,
  ModalView,
} from '../../../../components';
import { JournalButtonClickedTypes } from '../../../electronic/preparation/Journal/Journal';
import { Sum } from '../../../../utils/array';
import { Format } from '../../../../utils/string';


/*
 * 화면
 * @window w_tb_e033w
 * @category 자체점검현황
*/
const states = [
  { value: '1', remark: '미처리' },
  { value: '2', remark: '처리' },
  { value: '3', remark: '원격' },
];

const contgs = [
  { value: '01', remark: '신규' },
  { value: '02', remark: '연장' },
  { value: '03', remark: '재계약' },
  { value: '04', remark: '해지' },
  { value: '05', remark: '회수' },
  { value: '06', remark: '타사' },
];


export const SelfCheckTemplate: React.FC<TemplateProps<SelfCheck>> = ({
  scope,
}) => (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout isVertical={true}>
          <SearchLayout
            size={Global.LAYOUT_SEARCH_HEIGHT_1}
          >
            <FlexLayout size={250}>
              <span>기간</span>
              <DateTextBox
                value={scope.state.stdate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => scope.setState({ stdate: value })}
                onEnter={() => scope.onRetrieveEvent()}
                head={(
                  <button
                    onClick={() => {
                      const date = new Date(Date8.withDash(scope.state.stdate));
                      date.setDate(date.getDate() - 1);
                      scope.setState({ stdate: Date8.make(date) },
                        () => scope.onRetrieveEvent());
                    }}
                  >
                    <MdKeyboardArrowLeft size={24} />
                  </button>
                )}
                trail={(
                  <button
                    onClick={() => {
                      const date = new Date(Date8.withDash(scope.state.stdate));
                      date.setDate(date.getDate() + 1);
                      scope.setState({ stdate: Date8.make(date) },
                        () => scope.onRetrieveEvent());
                    }}
                  >
                    <MdKeyboardArrowRight size={24} />
                  </button>
                )}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>
            <FlexLayout size={250}>
              <span style={{ marginLeft: 8 }}>~</span>
              <DateTextBox
                value={scope.state.enddate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => scope.setState({ enddate: value })}
                onEnter={() => scope.onRetrieveEvent()}
                head={(
                  <button
                    onClick={() => {
                      const date = new Date(Date8.withDash(scope.state.enddate));
                      date.setDate(date.getDate() - 1);
                      scope.setState({ enddate: Date8.make(date) },
                        () => scope.onRetrieveEvent());
                    }}
                  >
                    <MdKeyboardArrowLeft size={24} />
                  </button>
                )}
                trail={(
                  <button
                    onClick={() => {
                      const date = new Date(Date8.withDash(scope.state.enddate));
                      date.setDate(date.getDate() + 1);
                      scope.setState({ enddate: Date8.make(date) },
                        () => scope.onRetrieveEvent());
                    }}
                  >
                    <MdKeyboardArrowRight size={24} />
                  </button>
                )}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>
            <FlexLayout size={326}>
              <span style={{ color: 'var(--color-red)' }}>부서</span>
              <TextBox
                value={scope.state.divicd}
                textAlign="center"
                onChange={(value) => scope.setState({ divicd: value })}
                onEnter={() => scope.onRetrieveEvent()}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JC002',
                  {},
                  true,
                  (item) => {
                    scope.setState({ divicd: item.cd, divinm: item.cdnm });
                  },
                )}
                isDisabledTrackingStateChange={true}
              />
              <TextBox
                value={scope.state.divinm}
                textAlign="center"
                isDisabledTrackingStateChange={true}
                readonly={true}
              />
            </FlexLayout>
            <FlexLayout size={350}>
              <span style={{ color: 'var(--color-red)', marginLeft: 78 }}>담당</span>
              <TextBox
                value={scope.state.perid}
                textAlign="center"
                onChange={(value) => scope.setState({ perid: value })}
                onEnter={() => scope.onRetrieveEvent()}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    scope.setState({
                      perid: item.cd,
                      pernm: item.cdnm,
                    });
                  },
                )}
                isDisabledTrackingStateChange={true}
              />
              <TextBox
                value={scope.state.pernm}
                textAlign="center"
                isDisabledTrackingStateChange={true}
                readonly={true}
              />
            </FlexLayout>
            <FlexLayout>
              <span style={{ color: 'var(--color-red)' }}>회사구분</span>
              <TextBox
                value={scope.state.wkactcd}
                textAlign="center"
                onChange={(value) => scope.setState({ wkactcd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_E018_1',
                  {},
                  true,
                  (item) => {
                    scope.setState({
                      wkactcd: item.cd,
                      wkactnm: item.cdnm,
                    });
                    scope.onRetrieveEvent();
                  },
                )}
              />
              <TextBox
                value={scope.state.wkactnm}
                readonly={true}
              />
            </FlexLayout>
          </SearchLayout>
          <SearchLayout
            size={Global.LAYOUT_SEARCH_HEIGHT_1}
            isSub={true}
          >
            <FlexLayout size={476}>
          <span style={{ color: 'var(--color-red)' }}>
            현장
          </span>
              <TextBox
                style={{ marginLeft: 24 }}
                value={scope.state.actcd}
                textAlign="center"
                onChange={(value) => scope.setState({ actcd: value })}
                onEnter={() => scope.onRetrieveEvent()}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_E601_1',
                  {},
                  true,
                  (item) => {
                    scope.setState({
                      actcd: item.cd,
                      actnm: item.cdnm,
                    });
                    scope.onRetrieveEvent();
                  },
                )}
                isDisabledTrackingStateChange={true}
              />
              <TextBox
                weight={1.63}
                value={scope.state.actnm}
                textAlign="center"
                isDisabledTrackingStateChange={true}
                readonly={true}
              />
            </FlexLayout>
            <FlexLayout>
              <span style={{ color: 'var(--color-red)', marginLeft: 26 }}>호기명</span>
              <TextBox
                textAlign="center"
                value={scope.state.equpcd}
                onChange={(value) => scope.setState({ equpcd: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_E611',
                  { uv_arg1: scope.state.actcd },
                  true,
                  (item) => {
                    scope.setState({
                      equpcd: item.cd,
                      equpnm: item.cdnm,
                    }, () => scope.onRetrieveEvent());
                  },
                )}
                isDisabledTrackingStateChange={true}
              />

              <TextBox
                value={scope.state.equpnm}
                readonly={true}
              />
            </FlexLayout>
            <FlexLayout>
              <span>결과/계약구분</span>
              <ComboBox
                value={scope.state.gubun}
                data={[
                  { value: '%', remark: '전체' },
                  { value: '1', remark: '미처리' },
                  { value: '2', remark: '처리' },
                ].map((x) => new ComboBoxModel(x.value, x.remark))}
                onSelect={(option) => scope.setState({ gubun: option.value })}
                isDisabledTrackingStateChange={true}
              />
              <ComboBox
                value={scope.state.contg}
                data={[
                  { value: '%', remark: '전체' },
                  { value: '1', remark: '계약중' },
                  { value: '2', remark: '해지' },
                  { value: '3', remark: '미계약' },
                ].map((x) => new ComboBoxModel(x.value, x.remark))}
                onSelect={(option) => scope.setState({ contg: option.value })}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>
            <FlexLayout>
              <span>전송구분</span>
              <ComboBox
                value={scope.state.kcflag}
                data={[
                  { value: '%', remark: '전체' },
                  { value: '1', remark: '전송' },
                  { value: '0', remark: '미전송' },
                ].map((x) => new ComboBoxModel(x.value, x.remark))}
                onSelect={(option) => scope.setState({ kcflag: option.value })}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>
              <Button
                onClick={() => scope.openChargeModal()}
              >
                <div style={{ width: 60 }}>담당별합계</div>
              </Button>
          </SearchLayout>
      </FlexLayout>
      </FlexLayout>
      <FlexLayout isVertical={true}>
        <FlexLayout weight={3}>
          <GridLayout
            ref={scope.grid}
            header={[
              {
                id: 'state',
                text: '결과',
                width: 7,
                render: (x) => (
                  <FlexLayout
                    weight={2}
                    align='center'
                    justify='center'
                    style={{ color: x.state === '2' ? 'var(--color-blue)' : x.state === '3' ? 'var(--color-violet)' : 'var(--color-red)' }}>
                    {states.filter((y) => y.value === x.state)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'plandate',
                text: '계획일자',
                width: 12,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    계획 {scope.state.cnt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.plandate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'rptdate',
                text: '점검일자',
                width: 12,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.rptdate)}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-blue)' }}
                  >
                    점검 {scope.state.success_tot}
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 30,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.equpnm}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-red)' }}
                  >
                   미처리: {scope.state.plan_not_tot}
                  </FlexLayout>
                ),
              },
              {
                id: 'wkactcd',
                text: '회사구분',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.wkactcds.filter((y) => y.wkactcd === x.wkactcd)[0]?.wkactnm || '-'}
                  </FlexLayout>
                ),
              },
              {
                id: 'contg',
                text: '계약',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={x.contg === '04' ? { color: 'var(--color-red)' } : {}}
                  >
                    {contgs.filter((y) => y.value === x.contg)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'plantime',
                text: '시간',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.plantime.substr(0, 2)}:{x.plantime.substr(2, 2)}
                  </FlexLayout>
                ),
              },
              {
                id: 'pernm',
                text: '담당자',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'pernm2',
                text: '보조담당자',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.pernm2}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: 'var(--color-pink)' }}
                  >
                    원격: {scope.state.remote_tot}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcpernm',
                text: '점검자',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.kcpernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'kcpernm2',
                text: '보조점검자',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.kcpernm2}
                  </FlexLayout>
                ),
              },
              {
                id: 'remark',
                text: '비고',
                width: 50,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.remark}
                  </FlexLayout>
                ),
              },

            ]}
            infinityHandler={scope.infinity}
            data={scope.state.SelfCheckList}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onRowClick={(item) => scope.onRowFocusEvent(item)}
          />
          <ModalView
            isVisible={scope.state.isChargeModalVisible}
            onClose={() => scope.closeChargeModal()}
            zIndex={-1}
            width={1600}
            height={1200}
          >
            <FlexLayout isVertical={true} style={{ padding: 8 }}>
              <FlexLayout
                size={Global.LAYOUT_SEARCH_HEIGHT_1}
                style={{ paddingBottom: 2 }}
              >
                <LayoutTitle weight={1}>
                  담당별합계
                </LayoutTitle>
              </FlexLayout>

              <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
                <SearchLayout size={300}>
                  <FlexLayout size={200}>
                    <span>월</span>
                    <DateTextBox
                      value={scope.state.popupStmon}
                      textAlign="center"
                      format="#### - ##"
                      onChange={(value) => { scope.setState({ popupStmon: value }); }}
                      onEnter={() => scope.modalChargeRetrieve()}
                      head={(<button
                        onClick={() => {
                          scope.setState({ popupStmon: new Date6Calculator(scope.state.popupStmon).add(-1).toString() },
                            () => scope.modalChargeRetrieve());
                        }}
                      >
                        <MdKeyboardArrowLeft size={24}/>
                      </button>)}
                      trail={(<button
                        onClick={() => {
                          scope.setState({ popupStmon: new Date6Calculator(scope.state.popupStmon).add().toString() },
                            () => scope.modalChargeRetrieve());
                        }}
                      >
                        <MdKeyboardArrowRight size={24}/>
                      </button>)}
                      isDisabledTrackingStateChange={true}
                    />
                  </FlexLayout>
                </SearchLayout>
                <FlexLayout
                  justify='right'
                  align='center'
                >
                  <Button
                    style={{ marginTop: '10px', height: '25px', width: '60px' }}
                    onClick={() => scope.modalChargeRetrieve()}
                  >
                    조회
                  </Button>
                  <Button
                    style={{ marginTop: '10px', height: '25px', width: '60px' }}
                    onClick={() => scope.modalChargePrint()}
                  >
                    출력
                  </Button>
                  <Button
                    style={{ marginTop: '10px', height: '25px', width: '60px' }}
                    onClick={() => scope.closeChargeModal()}
                  >
                    확인
                  </Button>
                </FlexLayout>
              </FlexLayout>

              <GridLayoutAdditionalHeader
                ref={scope.additionalTitle}
                height={Global.LAYOUT_TITLE_HEIGHT_1}
                contentWidth={1772}
              >
                <FlexLayout size={202}/>
                <FlexLayout
                  size={281}
                  justify="center"
                  align="center"
                >
                  관리 대수
                </FlexLayout>
                <FlexLayout
                  size={154}
                  justify="center"
                  align="center"
                >
                  03월 점검
                </FlexLayout>
                <FlexLayout
                  size={102}
                  justify="center"
                  align="center"
                >
                  1주
                </FlexLayout>
                <FlexLayout
                  size={102}
                  justify="center"
                  align="center"
                >
                  2주
                </FlexLayout>
                <FlexLayout
                  size={102}
                  justify="center"
                  align="center"
                >
                  3주
                </FlexLayout>
                <FlexLayout
                  size={102}
                  justify="center"
                  align="center"
                >
                  4주
                </FlexLayout>
                <FlexLayout
                  size={102}
                  justify="center"
                  align="center"
                >
                  5주
                </FlexLayout>
                <FlexLayout
                  size={595}
                  justify="center"
                  align="center"
                >
                </FlexLayout>
              </GridLayoutAdditionalHeader>

              <GridLayout
                ref={scope.popupChargeGrid}
                header={[
                  {
                    id: 'divinm',
                    text: '부서명',
                    width: 125,
                    trail: () => (
                      <FlexLayout
                        style={{ color: 'var(--color-pink)' }}
                        justify="center"
                        align="center"
                      >
                        합계
                      </FlexLayout>
                    ),
                    render: (x) => (
                      <FlexLayout>
                        {(x.chk === '0' && x.open === '0')
                        && <Button
                            onClick={() => scope.onButtonClicked(JournalButtonClickedTypes.ADD, x)}
                            isIcon={true}
                        >
                            <AiFillPlusSquare size={17}/>
                        </Button>
                        }
                        {
                          (x.chk === '0' && x.open === '1')
                          && <Button
                              onClick={() => scope.onButtonClicked(JournalButtonClickedTypes.MINUS, x)}
                              isIcon={true}
                          >
                              <AiFillMinusSquare size={17}/>
                          </Button>
                        }
                        <FlexLayout
                          justify="left"
                          align="center"
                          style={{ fontWeight: x.chk === '0' && 'bold' }}
                        >
                          {x.divinm}
                        </FlexLayout>
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'pernm',
                    text: '담당자명',
                    width: 75,
                    trail: (data) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        {`${data.length} 명`}
                      </FlexLayout>
                    ),
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                        style={{ fontWeight: x.chk === '0' && 'bold' }}
                      >
                        {x.pernm}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'endqty2',
                    text: '평균대수',
                    width: 75,
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                        style={{ fontWeight: x.chk === '0' && 'bold' }}
                      >
                        {x.endqty2 !== '0' && x.endqty2}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'totqty',
                    text: '현재대수',
                    width: 75,
                    trail: (data) => {
                      const sumTotqty = Sum.all(data, 'totqty');
                      return (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          {sumTotqty !== 0 && Format.number(sumTotqty)}
                        </FlexLayout>
                      );
                    },
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                        style={{ fontWeight: x.chk === '0' && 'bold' }}
                      >
                        {x.totqty !== '0' && x.totqty}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'chaqty',
                    text: '변경대수',
                    width: 75,
                    trail: (data) => {
                      const sumChaqty = Sum.all(data, 'chaqty');
                      return (
                        <FlexLayout
                          style={{ color: 'var(--color-blue)' }}
                          justify="center"
                          align="center"
                        >
                          {sumChaqty !== 0 && Format.number(sumChaqty)}
                        </FlexLayout>
                      );
                    },
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                        style={{ fontWeight: x.chk === '0' && 'bold' }}
                      >
                        {x.chaqty !== '0' && x.chaqty}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'notkccnt',
                    text: '미검사',
                    width: 50,
                    trail: (data) => {
                      const sumNotkccnt = Sum.all(data, 'notkccnt');
                      return (
                        <FlexLayout
                          style={{ color: 'var(--color-violet)' }}
                          justify="center"
                          align="center"
                        >
                          {Format.number(sumNotkccnt)}
                        </FlexLayout>
                      );
                    },
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                        style={{
                          color: 'var(--color-violet)',
                          fontWeight: x.chk === '0' && 'bold',
                        }}
                      >
                        {x.notkccnt}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'miscnt',
                    text: '미계획',
                    width: 50,
                    trail: (data) => {
                      const sumMiscnt = Sum.all(data, 'miscnt');
                      return (
                        <FlexLayout
                          style={{ color: 'var(--color-red)' }}
                          justify="center"
                          align="center"
                        >
                          {sumMiscnt !== 0 && Format.number(sumMiscnt)}
                        </FlexLayout>
                      );
                    },
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                        style={{
                          color: 'var(--color-red)',
                          fontWeight: x.chk === '0' && 'bold',
                        }}
                      >
                        {x.miscnt !== '0' && x.miscnt}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'cnt',
                    text: '계획',
                    width: 50,
                    trail: (data) => {
                      const sumCnt = Sum.all(data, 'cnt');
                      return (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          {sumCnt !== 0 && Format.number(sumCnt)}
                        </FlexLayout>
                      );
                    },
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                        style={{ fontWeight: x.chk === '0' && 'bold' }}
                      >
                        {x.cnt !== '0' && x.cnt}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'compcnt',
                    text: '점검',
                    width: 50,
                    trail: (data) => {
                      const sumCompcnt = Sum.all(data, 'compcnt');
                      return (
                        <FlexLayout
                          style={{ color: 'var(--color-blue)' }}
                          justify="center"
                          align="center"
                        >
                          {sumCompcnt !== 0 && Format.number(sumCompcnt)}
                        </FlexLayout>
                      );
                    },
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                        style={{
                          color: 'var(--color-blue)',
                          fontWeight: x.chk === '0' && 'bold',
                        }}
                      >
                        {x.compcnt !== '0' && x.compcnt}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'weekcnt1',
                    text: '계획',
                    width: 50,
                    trail: (data) => {
                      const sumWeekcnt1 = Sum.all(data, 'weekcnt1');
                      return (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          {sumWeekcnt1 !== 0 && Format.number(sumWeekcnt1)}
                        </FlexLayout>
                      );
                    },
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                        style={{ fontWeight: x.chk === '0' && 'bold' }}
                      >
                        {x.weekcnt1 !== '0' && x.weekcnt1}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'weekcompcnt1',
                    text: '점검',
                    width: 50,
                    trail: (data) => {
                      const sumWeekcompcnt1 = Sum.all(data, 'weekcompcnt1');
                      return (
                        <FlexLayout
                          style={{ color: 'var(--color-blue)' }}
                          justify="center"
                          align="center"
                        >
                          {sumWeekcompcnt1 !== 0 && Format.number(sumWeekcompcnt1)}
                        </FlexLayout>
                      );
                    },
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                        style={{
                          color: 'var(--color-blue)',
                          fontWeight: x.chk === '0' && 'bold',
                        }}
                      >
                        {x.weekcompcnt1 !== '0' && x.weekcompcnt1}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'weekcnt2',
                    text: '계획',
                    width: 50,
                    trail: (data) => {
                      const sumWeekcnt2 = Sum.all(data, 'weekcnt2');
                      return (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          {sumWeekcnt2 !== 0 && Format.number(sumWeekcnt2)}
                        </FlexLayout>
                      );
                    },
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                        style={{ fontWeight: x.chk === '0' && 'bold' }}
                      >
                        {x.weekcnt2 !== '0' && x.weekcnt2}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'weekcompcnt2',
                    text: '점검',
                    width: 50,
                    trail: (data) => {
                      const sumWeekcompcnt2 = Sum.all(data, 'weekcompcnt2');
                      return (
                        <FlexLayout
                          style={{ color: 'var(--color-blue)' }}
                          justify="center"
                          align="center"
                        >
                          {sumWeekcompcnt2 !== 0 && Format.number(sumWeekcompcnt2)}
                        </FlexLayout>
                      );
                    },
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                        style={{
                          color: 'var(--color-blue)',
                          fontWeight: x.chk === '0' && 'bold',
                        }}
                      >
                        {x.weekcompcnt2 !== '0' && x.weekcompcnt2}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'weekcnt3',
                    text: '계획',
                    width: 50,
                    trail: (data) => {
                      const sumWeekcnt3 = Sum.all(data, 'weekcnt3');
                      return (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          {sumWeekcnt3 !== 0 && Format.number(sumWeekcnt3)}
                        </FlexLayout>
                      );
                    },
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                        style={{ fontWeight: x.chk === '0' && 'bold' }}
                      >
                        {x.weekcnt3 !== '0' && x.weekcnt3}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'weekcompcnt3',
                    text: '점검',
                    width: 50,
                    trail: (data) => {
                      const sumWeekcompcnt3 = Sum.all(data, 'weekcompcnt3');
                      return (
                        <FlexLayout
                          style={{ color: 'var(--color-blue)' }}
                          justify="center"
                          align="center"
                        >
                          {sumWeekcompcnt3 !== 0 && Format.number(sumWeekcompcnt3)}
                        </FlexLayout>
                      );
                    },
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                        style={{
                          color: 'var(--color-blue)',
                          fontWeight: x.chk === '0' && 'bold',
                        }}
                      >
                        {x.weekcompcnt3 !== '0' && x.weekcompcnt3}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'weekcnt4',
                    text: '계획',
                    width: 50,
                    trail: (data) => {
                      const sumWeekcnt4 = Sum.all(data, 'weekcnt4');
                      return (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          {sumWeekcnt4 !== 0 && Format.number(sumWeekcnt4)}
                        </FlexLayout>
                      );
                    },
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                        style={{ fontWeight: x.chk === '0' && 'bold' }}
                      >
                        {x.weekcnt4 !== '0' && x.weekcnt4}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'weekcompcnt4',
                    text: '점검',
                    width: 50,
                    trail: (data) => {
                      const sumWeekcompcnt4 = Sum.all(data, 'weekcompcnt4');
                      return (
                        <FlexLayout
                          style={{ color: 'var(--color-blue)' }}
                          justify="center"
                          align="center"
                        >
                          {sumWeekcompcnt4 !== 0 && Format.number(sumWeekcompcnt4)}
                        </FlexLayout>
                      );
                    },
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                        style={{
                          color: 'var(--color-blue)',
                          fontWeight: x.chk === '0' && 'bold',
                        }}
                      >
                        {x.weekcompcnt4 !== '0' && x.weekcompcnt4}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'weekcnt5',
                    text: '계획',
                    width: 50,
                    trail: (data) => {
                      const sumWeekcnt5 = Sum.all(data, 'weekcnt5');
                      return (
                        <FlexLayout
                          justify="center"
                          align="center"
                        >
                          {sumWeekcnt5 !== 0 && Format.number(sumWeekcnt5)}
                        </FlexLayout>
                      );
                    },
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                        style={{ fontWeight: x.chk === '0' && 'bold' }}
                      >
                        {x.weekcnt5 !== '0' && x.weekcnt5}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'weekcompcnt5',
                    text: '점검',
                    width: 50,
                    trail: (data) => {
                      const sumWeekcompcnt5 = Sum.all(data, 'weekcompcnt5');
                      return (
                        <FlexLayout
                          style={{ color: 'var(--color-blue)' }}
                          justify="center"
                          align="center"
                        >
                          {sumWeekcompcnt5 !== 0 && Format.number(sumWeekcompcnt5)}
                        </FlexLayout>
                      );
                    },
                    render: (x) => (
                      <FlexLayout
                        style={{
                          fontWeight: x.chk === '0' && 'bold',
                          color: 'var(--color-blue)',
                        }}
                        justify="center"
                        align="center"
                      >
                        {x.weekcompcnt5 !== '0' && x.weekcompcnt5}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'jrececnt',
                    text: '지원받음',
                    width: 100,
                    trail: (data) => {
                      const sumJrececnt = Sum.all(data, 'jrececnt');
                      return (
                        <FlexLayout
                          style={{ color: 'var(--color-green)' }}
                          justify="center"
                          align="center"
                        >
                          {sumJrececnt !== 0 && Format.number(sumJrececnt)}
                        </FlexLayout>
                      );
                    },
                    render: (x) => (
                      <FlexLayout
                        style={{
                          color: 'var(--color-green)',
                          fontWeight: x.chk === '0' && 'bold',
                        }}
                        justify="center"
                        align="center"
                      >
                        {x.jrececnt !== '0' && x.jrececnt}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'jmiscnt',
                    text: '미점검',
                    width: 100,
                    trail: (data) => {
                      const sumJmiscnt = Sum.all(data, 'jmiscnt');
                      return (
                        <FlexLayout
                          style={{ color: 'var(--color-red)' }}
                          justify="center"
                          align="center"
                        >
                          {sumJmiscnt !== 0 && Format.number(sumJmiscnt)}
                        </FlexLayout>
                      );
                    },
                    render: (x) => (
                      <FlexLayout
                        style={{
                          fontWeight: x.chk === '0' && 'bold',
                          color: 'var(--color-red)',
                        }}
                        justify="center"
                        align="center"
                      >
                        {x.jmiscnt !== '0' && x.jmiscnt}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'jcompcnt',
                    text: '지원점검',
                    width: 100,
                    trail: (data) => {
                      const sumJcompcnt = Sum.all(data, 'jcompcnt');
                      return (
                        <FlexLayout
                          style={{ color: 'var(--color-green)' }}
                          justify="center"
                          align="center"
                        >
                          {sumJcompcnt !== 0 && Format.number(sumJcompcnt)}
                        </FlexLayout>
                      );
                    },
                    render: (x) => (
                      <FlexLayout
                        style={{
                          fontWeight: x.chk === '0' && 'bold',
                          color: 'var(--color-green)',
                        }}
                        justify="center"
                        align="center"
                      >
                        {x.jcompcnt !== '0' && x.jcompcnt}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'totcnt',
                    text: '담당점검',
                    width: 100,
                    trail: (data) => {
                      const sumTotcnt = Sum.all(data, 'totcnt');
                      return (
                        <FlexLayout
                          style={{ color: 'var(--color-blue)' }}
                          justify="center"
                          align="center"
                        >
                          {sumTotcnt !== 0 && Format.number(sumTotcnt)}
                        </FlexLayout>
                      );
                    },
                    render: (x) => (
                      <FlexLayout
                        style={{
                          fontWeight: x.chk === '0' && 'bold',
                          color: 'var(--color-blue)',
                        }}
                        justify="center"
                        align="center"
                      >
                        {x.totcnt !== '0' && x.totcnt}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'totyul',
                    text: '진행률',
                    width: 60,
                    trail: (data) => {
                      // const sumPlanyul = Sum.all(data, 'planyul');
                      const sumTodayplan = Sum.all(data, 'todayplan');
                      const sumPlancomp = Sum.all(data, 'plancomp');
                      return (
                        <FlexLayout
                          style={{ color: 'var(--color-violet)' }}
                          justify="right"
                          align="center"
                        >
                          {sumTodayplan !== 0 && sumPlancomp !== 0
                          && `${Format.number(((sumPlancomp / sumTodayplan) * 100).toFixed(0))} %`
                          }
                        </FlexLayout>
                      );
                    },
                    render: (x) => (
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{
                          color: 'var(--color-violet)',
                          fontWeight: x.chk === '0' && 'bold',
                        }}
                      >
                        {x.totyul !== 0 && x.totyul}%
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'send5cnt',
                    text: '5일이상전송초과',
                    width: 125,
                    trail: (data) => {
                      const sumSend5cnt = Sum.all(data, 'send5cnt');
                      return (
                        <FlexLayout
                          style={{ color: 'var(--color-violet)' }}
                          justify="right"
                          align="center"
                        >
                          {sumSend5cnt !== 0 && Format.number(sumSend5cnt)}
                        </FlexLayout>
                      );
                    },
                    render: (x) => (
                      <FlexLayout
                        style={{
                          color: 'var(--color-red)',
                          fontWeight: x.chk === '0' && 'bold',
                        }}
                        justify="right"
                        align="center"
                      >
                        {x.send5cnt !== '0' && x.send5cnt}
                      </FlexLayout>
                    ),
                  },
                ]}
                data={scope.state.popupChargeList}
                rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                onRowClick={(item) => scope.onChargeRowFocusEvent(item)}
                onHorizontalScroll={(x) => { scope.additionalTitle.current?.scrollTo(x); }}
              />

              <FlexLayout>
                {scope.state.isGraphVisible
                && <IFrame src={scope.graph}/>
                }
              </FlexLayout>
            </FlexLayout>
          </ModalView>
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
);
