import { FiFolderPlus } from 'react-icons/fi';
import { GridLayoutHeader } from '../../../../../constants';
import { FlexLayout } from '../../../../../components/layout/FlexLayout';
import { UnitModel } from '../models';
import { RepairCurrent } from '../RepairCurrent';
import { Format } from '../../../../../utils/string';
import { Date8 } from '../../../../../utils/time';
import { ConfirmWarning } from '../../../../../utils/confirm';
import {
  Button,
  BUTTON_THEMES,
} from '../../../../../components/forms/Button/Button';

// 호기현황
const Unit: GridLayoutHeader<UnitModel, RepairCurrent>[] = [
  {
    id: 'spjangnm',
    text: '사업장',
    width: 130,
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        총계
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.spjangnm}
      </FlexLayout>
    ),
  },
  {
    id: 'contgubun',
    text: '계약구분',
    width: 80,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.total} 대
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {scope?.state.contgs?.filter((y) => y.code === x.contg)[0]?.cnam || '-'}
      </FlexLayout>
    ),
  },
  {
    id: 'actnm',
    text: '현장명',
    width: 200,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.actnm}
      </FlexLayout>
    ),
  },
  {
    id: 'equpnm',
    text: '호기명',
    width: 90,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.equpnm}
      </FlexLayout>
    ),
  },
  {
    id: 'elno',
    text: '승강기번호',
    width: 100,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.elno?.substr(0, 4)}-{x.elno?.substr(4, 3)}
      </FlexLayout>
    ),
  },
  {
    id: 'equpamt',
    text: '대당금액',
    width: 120,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.equpamt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {Format.number(Math.round(Number(x.equpamt)))}
      </FlexLayout>
    ),
  },
  {
    id: 'bildyd',
    text: '건물용도',
    width: 130,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.averamt_tot}
      </FlexLayout>
    ),
    render: (x, scope) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {scope?.state.bildyds?.filter((y) => y.buildcd === x.bildyd)[0]?.buildnm || '-'}
      </FlexLayout>
    ),
  },
  {
    id: 'number',
    text: '운행층수',
    width: 70,
  },
  {
    id: 'evnm',
    text: '점검기종',
    width: 130,
    render: (x: any) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.evnm}
      </FlexLayout>
    ),
  },
  // {
  //   id: 'classnm',
  //   text: '분류',
  //   width: 100,
  //   render: (x: any) => (
  //     <FlexLayout
  //       justify="left"
  //       align="center"
  //     >
  //       {x.classnm}
  //     </FlexLayout>
  //   ),
  // },
  {
    id: 'class2',
    text: '인화물구분',
    width: 70,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.class2 === 'F' ? '화물용' : '인승용'}
      </FlexLayout>
    ),
  },
  {
    id: 'plc',
    text: 'PLC',
    width: 150,
    render: (x: any, scope) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        <FlexLayout justify="left">
          {x.plc === '1' ? '사용' : '미사용'}
        </FlexLayout>
        <FlexLayout
          style={x.plc === '1' ? { color: 'var(--color-blue)' } : { color: 'var(--color-red)' }}
          justify="right"
        >
          {x.plc === '1' ? '파일있음' : '파일없음'}
        </FlexLayout>
        <Button
          onClick={() => {
            if (x.plc !== '1') {
              ConfirmWarning.show('오류', '먼저 사용여부를 사용으로 설정하세요');
              return;
            }

            scope?.openPLC();
          }}
          isIcon={true}
          theme={BUTTON_THEMES.BLUE}
        >
          <FiFolderPlus size={25}/>
        </Button>
      </FlexLayout>
    ),
  },
  {
    id: 'cctv',
    text: 'cctv',
    width: 60,
    render: (x: any) => (
      <FlexLayout
        justify="center"
        align="center"
        style={{ color: x.cctv === '1' ? 'var(--color-blue)' : 'var(--color-red)' }}
      >
        {x.cctv === '1' ? '있음' : '없음'}
      </FlexLayout>
    ),
  },
  {
    id: 'jejak',
    text: '제작',
    width: 120,
    render: (x: any) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.jejak}
      </FlexLayout>
    ),
  },
  {
    id: 'mdnm',
    text: '모델',
    width: 120,
    render: (x: any) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.mdnm}
      </FlexLayout>
    ),
  },
  {
    id: 'spped',
    text: '속도',
    width: 60,
    render: (x: any) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.spped}
      </FlexLayout>
    ),
  },
  {
    id: 'elignm',
    text: '구분',
    width: 110,
    render: (x: any) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.elignm}
      </FlexLayout>
    ),
  },
  {
    id: 'elifnm',
    text: '형식',
    width: 110,
    render: (x: any) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.elifnm}
      </FlexLayout>
    ),
  },
  {
    id: 'sizecd',
    text: '사양',
    width: 110,
    render: (x: any) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.sizecd}
      </FlexLayout>
    ),
  },
  {
    id: 'nperson',
    text: '정원',
    width: 90,
    render: (x: any) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.nperson}
      </FlexLayout>
    ),
  },
  {
    id: 'yyyymm',
    text: '제작년월',
    width: 110,
    render: (x: any) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {Date8.withDash(x.yyyymm)}
      </FlexLayout>
    ),
  },
  {
    id: 'height',
    text: '무게',
    width: 110,
    render: (x: any) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.height}
      </FlexLayout>
    ),
  },
  {
    id: 'jugi',
    text: '점검주기',
    width: 110,
    render: (x: any) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.jugi}
      </FlexLayout>
    ),
  },
  {
    id: 'divinm',
    text: '담당부서',
    width: 90,
    render: (x) => (
        <FlexLayout
          justify="left"
          align="center"
        >
          {x.divinm}
        </FlexLayout>
    ),
  },
  {
    id: 'pernm',
    text: '담당자',
    width: 90,
    render: (x) => (
        <FlexLayout
            justify="left"
            align="center"
        >
          {x.pernm}
        </FlexLayout>
    ),
  },
  {
    id: 'tel',
    text: '현장연락처',
    width: 120,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.tel}
      </FlexLayout>
    ),
  },
  {
    id: 'areanm',
    text: '지역명',
    width: 90,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.areanm}
      </FlexLayout>
    ),
  },
  {
    id: 'zipcode',
    text: '우편번호 ',
    width: 90,
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.zipcode}
      </FlexLayout>
    ),
  },
  {
    id: 'address',
    text: '주소 ',
    width: 260,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.address}
      </FlexLayout>
    ),
  },
  {
    id: 'address2',
    text: '상세주소',
    width: 260,
    render: (x) => (
      <FlexLayout
        justify="left"
        align="center"
      >
        {x.address2}
      </FlexLayout>
    ),
  },
];

export default Unit;
