import * as React from 'react';
import {
  FiArrowRightCircle,
  IoIosMail,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import {
  FlexLayout,
  SearchLayout,
  DateTextBox,
  TextBox,
  FormatTextBox,
  GridLayout,
  Button,
  LabelBox,
  TableLayout,
  LayoutTitle,
  ComboBox,
  ComboBoxModel,
  ModalView,
  SubHeader,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import {
  WorkReport,
  WorkReportItemChangeTypes,
} from './WorkReport';
import {
  Date6Calculator, Date8,
  Today,
} from '../../../../utils/time';
import { SearchBinding } from '../../../../models/common';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import { ConfirmWarning } from '../../../../utils/confirm';

/**
 * 화면
 * @window w_tb_e512
 * @category 당직근무보고서
 */

const appgubuns = [
  { value: '101', remark: '결재' },
  { value: '121', remark: '참조' },
  { value: '001', remark: '대기' },
  { value: '131', remark: '반려' },
  { value: '111', remark: '결재중' },
  { value: '301', remark: '전결' },
  { value: '401', remark: '대결' },
];

export const WorkReportTemplate: React.FC<TemplateProps<WorkReport>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  const model = scope.state.data;
  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout size={200}>
          <span>월</span>
          <DateTextBox
            value={scope.state.stmon}
            textAlign="center"
            format="####-##"
            onChange={
              (value) => {
                scope.setState({ stmon: value },
                  () => scope.onRetrieveEvent());
              }
            }
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                scope.setState({
                  stmon: new Date6Calculator(scope.state.stmon).add(-1).toString(),
                },
                () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                scope.setState({
                  stmon: new Date6Calculator(scope.state.stmon).add().toString(),
                },
                () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout/>
        <Button onClick={() => scope.onReport()}>
          <div style={{ width: 80 }}> {scope.state.isReported ? '상신취소' : '결재상신'}</div>
        </Button>
      </SearchLayout>

      <FlexLayout>
        <GridLayout
          ref={scope.grid}
          header={[
            {
              id: 'rptdate',
              text: '일자',
              width: 30,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.rptdate)}
                </FlexLayout>
              ),
            },
            {
              id: 'telpernm',
              text: '통신담당(책임)',
              width: 30,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.telpernm}
                </FlexLayout>
              ),
            },
            {
              id: 'telrspnm',
              text: '직위',
              width: 15,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.telrspnm}
                </FlexLayout>
              ),
            },
            {
              id: 'teldivinm',
              text: '부서명',
              width: 30,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.teldivinm}
                </FlexLayout>
              ),
            },
            {
              id: 'appgubun',
              text: '상태',
              width: 15,
              render: (x) => (
                <FlexLayout>
                  <FlexLayout
                    weight={2}
                    align='center'
                    justify='center'
                    onDoubleClick={
                      () => (x.appremark?.length > 0 ? scope.appgubunDetailPopup() : scope.appgubunPopup())
                    }
                    style={{
                      marginLeft: x.appremark?.length > 0 ? 27 : 0,
                      color: x.appgubun === '101'
                        ? 'var(--color-blue)'
                        : x.appgubun === '131'
                          ? 'var(--color-red)'
                          : '',
                    }}
                  >
                    {appgubuns.filter((y) => y.value === x.appgubun)[0]?.remark}
                  </FlexLayout>
                  { x.appremark.length > 0
                    ? <FlexLayout
                      align='center'
                      justify='right'
                      style={{ color: 'var(--color-red)' }}
                    >
                      <IoIosMail size={25}/>
                    </FlexLayout>
                    : ''
                  }
                </FlexLayout>
              ),
            },
          ]}
          data={scope.state.workReportList}
          infinityHandler={scope.infinity}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onRowClick={(item, index) => scope.onRowFocusEvent(item, index)}
        />
        <FlexLayout
          weight={1.5}
          isVertical={true}
          disabled={!model?.custcd}
          disabledText="신규로 추가하거나 당직근무보고서를 선택해주세요"
        >
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="작성일자"
              isNeed={true}
            >
              <DateTextBox
                value={model?.rptdate}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => setData({ rptdate: value },
                  () => scope.itemChanged(WorkReportItemChangeTypes.RPTDATE, value))}
                readonly={model?.new === '0'}
              />
            </LabelBox>
            <LabelBox title="문서번호">
              <FormatTextBox
                format="######-###-####"
                textAlign="center"
                value={model?.appnum}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="작성자">
              <TextBox
                textAlign="center"
                value={model?.perid}
                readonly={true}
              />
              <TextBox
                textAlign="center"
                value={model?.pernm}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="직위/부서">
              <TextBox
                textAlign="center"
                value={model?.rspnm}
                readonly={true}
              />
              <TextBox
                textAlign="center"
                value={model?.divinm}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="통신담당(책임자)"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                value={model?.telperid}
                textAlign="center"
                onChange={(value) => setData({ telperid: value })}
                onEnter={() => scope.onRetrieveEvent()}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    setData({
                      telperid: item.cd,
                      telpernm: item.cdnm,
                    }, () => scope.itemChanged(WorkReportItemChangeTypes.TELPERID, model?.rptdate || `${Today.yearMon()}${Today.date()}`));
                  },
                )}
              />
              <TextBox
                value={model?.telpernm}
                textAlign="center"
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="직위/부서">
              <TextBox
                textAlign="center"
                value={model?.telrspnm}
                readonly={true}
              />
              <TextBox
                textAlign="center"
                value={model?.teldivinm}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="당직근무"
              footer={
                <FlexLayout>
                  <Button
                    onClick={() => scope.openWorkerModal(true)}
                    isIcon={true}
                  >
                    <FiArrowRightCircle size={17} />
                  </Button>
                </FlexLayout>
              }
            >
              <TextBox
                textAlign="left"
                value={model?.dperid}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="설치작업">
              <TextBox
                textAlign="left"
                value={model?.sremark}
                onChange={ (value) => setData({ sremark: value })}
              />
            </LabelBox>
            <LabelBox title="공사작업">
              <TextBox
                textAlign="left"
                value={model?.gremark}
                onChange={ (value) => setData({ gremark: value })}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={200}>
            <LabelBox title="안전지시사항" >
              <TextBox
                isMultiline={true}
                value={model?.saferemark}
                onChange={(value) => setData({ saferemark: value })}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={160}>
            <LabelBox title="특이사항(팀장지시사항)" >
              <TextBox
                isMultiline={true}
                value={model?.remark}
                onChange={(value) => setData({ remark: value })}
              />
            </LabelBox>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
      <FlexLayout size={Global.LAYOUT_BUTTON_HEIGHT_1}>
        <LayoutTitle size={230}>고장내용</LayoutTitle>
        <FlexLayout justify='right'>
          <Button
            style={{ height: '25px', width: '60px' }}
            onClick={() => scope.onNewEvent2()}
          >
            추가
          </Button>
          <Button
            style={{ height: '25px', width: '60px' }}
            theme={BUTTON_THEMES.RED}
            onClick={() => scope.onDeleteEvent2()}
          >
            삭제
          </Button>
        </FlexLayout>
      </FlexLayout>

      <FlexLayout
        disabled={!model?.custcd}
        disabledText="신규로 추가하거나 당직근무보고서를 선택해주세요"
      >
        <TableLayout
          ref={scope.table}
          header={[
            {
              id: 'seq',
              text: '순서',
              width: 50,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.seq}
                  onChange={(value) => rowUpdate({ seq: value })}
                  textAlign="center"
                />
              ),
            },
            {
              id: 'receflag',
              text: '구분',
              width: 75,
              render: (x, rowUpdate, _) => (
                <ComboBox
                  style={{ color: x.receflag === '1' ? 'var(--color-red)' : 'var(--color-blue)' }}
                  value={x.receflag}
                  data={[
                    { value: '1', remark: '접수' },
                    { value: '2', remark: '처리' },
                  ].map((y) => new ComboBoxModel(y.value, y.remark))}
                  onSelect={(option) => rowUpdate({ receflag: option.value })}
                />
              ),
            },
            {
              id: 'actcd',
              text: '현장코드',
              width: 100,
              color: 'var(--color-red)',
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.actcd}
                  textAlign="center"
                  onChange={(value) => rowUpdate({ actcd: value })}
                  bindSearchModal={new SearchBinding(
                    scope.props.modalStore,
                    'TB_E601_1',
                    {},
                    true,
                    (item) => {
                      rowUpdate({
                        actcd: item.cd,
                        actnm: item.cdnm,
                      });
                      // scope.itemChanged(OrderItemChangeTypes.ACTCD, item.cd);
                    },
                  )}
                />
              ),
            },
            {
              id: 'actnm',
              text: '현장명',
              width: 250,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.actnm}
                </FlexLayout>
              ),
            },
            {
              id: 'equpcd',
              text: '호기코드',
              width: 100,
              color: 'var(--color-red)',
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.equpcd}
                  textAlign="center"
                  onChange={(value) => rowUpdate({ equpcd: value })}
                  bindSearchModal={new SearchBinding(
                    scope.props.modalStore,
                    'TB_E611',
                    { uv_arg1: x.actcd },
                    true,
                    (item) => {
                      rowUpdate({
                        equpcd: item.cd,
                        equpnm: item.cdnm,
                      });
                    },
                  )}
                />
              ),
            },
            {
              id: 'equpnm',
              text: '동호기',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.equpnm}
                </FlexLayout>
              ),
            },
            {
              id: 'contcd',
              text: '고장내용',
              width: 100,
              render: (x, rowUpdate, _) => (
                <ComboBox
                  value={x.contcd}
                  data={scope.state.contcds?.map((y) => new ComboBoxModel(y.contcd, y.contnm))}
                  onSelect={(option) => {
                    rowUpdate({ contcd: option.value });
                    scope.detailItemChanged(WorkReportItemChangeTypes.CONTCD, option.value);
                  }
                  }
                />
              ),
            },
            {
              id: 'contents',
              text: '고장상세내용',
              width: 200,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.contents}
                  textAlign="left"
                  onChange={(value) => rowUpdate({ contents: value })}
                />
              ),
            },
            {
              id: 'recetime',
              text: '접수시간',
              width: 100,
              render: (x, rowUpdate, ref) => (
                <FormatTextBox
                  ref={ref}
                  textAlign="center"
                  format="##:##"
                  value={x.recetime}
                  onChange={(value) => rowUpdate({ recetime: value })}
                />
              ),
            },
            {
              id: 'recedate',
              text: '접수일자',
              width: 150,
              render: (x, rowUpdate, ref) => (
                <DateTextBox
                  ref={ref}
                  format="####-##-##"
                  value={(!x.recedate) ? `${Today.date()}` : x.recedate }
                  textAlign="center"
                  onChange={(value) => rowUpdate({ recedate: value })}
                />
              ),
            },
            {
              id: 'comptime',
              text: '처리시간',
              width: 100,
              render: (x, rowUpdate, ref) => (
                <FormatTextBox
                  ref={ref}
                  textAlign="center"
                  format="##:##"
                  value={x.comptime}
                  onChange={(value) => rowUpdate({ comptime: value })}
                />
              ),
            },
            {
              id: 'compdate',
              text: '처리일자',
              width: 150,
              render: (x, rowUpdate, ref) => (
                <DateTextBox
                  ref={ref}
                  format="####-##-##"
                  value={(!x.compdate) ? `${Today.date()}` : x.compdate }
                  textAlign="center"
                  onChange={(value) => rowUpdate({ compdate: value })}
                />
              ),
            },
            {
              id: 'perid',
              text: '처리자코드',
              width: 100,
              color: 'var(--color-red)',
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.perid}
                  textAlign="center"
                  onChange={(value) => rowUpdate({ perid: value })}
                  bindSearchModal={new SearchBinding(
                    scope.props.modalStore,
                    'TB_JA001',
                    {},
                    true,
                    (item) => {
                      rowUpdate({
                        perid: item.cd,
                        pernm: item.cdnm,
                      });
                    },
                  )}
                />
              ),
            },
            {
              id: 'pernm',
              text: '처리자명',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.pernm}
                </FlexLayout>
              ),
            },
            {
              id: 'resucd',
              text: '처리내용',
              width: 100,
              render: (x, rowUpdate, _) => (
                <ComboBox
                  value={x.resucd}
                  data={scope.state.resucds?.map((y) => new ComboBoxModel(y.resucd, y.resunm))}
                  onSelect={(option) => rowUpdate({ resucd: option.value })}
                />
              ),
            },
            {
              id: 'resuremark',
              text: '처리상세내용',
              width: 200,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.resuremark}
                  textAlign="center"
                  onChange={(value) => rowUpdate({ resuremark: value })}
                />
              ),
            },
            {
              id: 'gregicd',
              text: '고장부위',
              width: 100,
              render: (x, rowUpdate, _) => (
                <ComboBox
                  value={x.gregicd}
                  data={scope.state.gregicds?.map((y) => new ComboBoxModel(y.gregicd, y.greginm))}
                  onSelect={(option) => rowUpdate({ gregicd: option.value })}
                />
              ),
            },
            {
              id: 'regicd',
              text: '고장부위상세',
              width: 150,
              render: (x, rowUpdate, _) => (
                <ComboBox
                  value={x.regicd}
                  data={scope.state.regicds?.map((y) => new ComboBoxModel(y.regicd, y.reginm))}
                  onSelect={(option) => rowUpdate({ regicd: option.value })}
                />
              ),
            },
            {
              id: 'remocd',
              text: '고장요인',
              width: 100,
              render: (x, rowUpdate, _) => (
                <ComboBox
                  value={x.remocd}
                  data={scope.state.remocds?.map((y) => new ComboBoxModel(y.remocd, y.remonm))}
                  onSelect={(option) => rowUpdate({ remocd: option.value })}
                />
              ),
            },
            {
              id: 'faccd',
              text: '고장원인',
              width: 100,
              render: (x, rowUpdate, _) => (
                <ComboBox
                  value={x.faccd}
                  data={scope.state.faccds?.map((y) => new ComboBoxModel(y.faccd, y.facnm))}
                  onSelect={(option) => rowUpdate({ faccd: option.value })}
                />
              ),
            },
            {
              id: 'resultcd',
              text: '처리결과',
              width: 100,
              render: (x, rowUpdate, _) => (
                <ComboBox
                  value={x.resultcd}
                  data={scope.state.resultcds?.map((y) => new ComboBoxModel(y.resultcd, y.resultnm))}
                  onSelect={(option) => rowUpdate({ resultcd: option.value })}
                />
              ),
            },
          ]}
          infinityHandler={scope.infinity2}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          data={scope.state.workReportDetailList}
          onChange={(rows, updatedRows) => {
            scope.onUpdatedRows(rows, updatedRows);
          }}
          onRowFocusEvent={(item) => scope.onRowFocusEvent2(item)}
        />
      </FlexLayout>

      <ModalView
        isVisible={scope.state.workerModal}
        onClose={() => scope.openWorkerModal(false)}
        width={500}
        height={600}
        zIndex={-1}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>당직 등록</LayoutTitle>

          <FlexLayout
            size={Global.LAYOUT_BUTTON_HEIGHT_1}
            justify='right'
          >
            <FlexLayout
              style={{ color: 'var(--color-blue)' }}
              align='center'
              justify='left'
            >
              <span>*담당팀장을 입력하면 팀원이 들어갑니다.</span>
            </FlexLayout>
            <Button
              style={{ height: '25px', width: '60px' }}
              theme={BUTTON_THEMES.BLUE}
              onClick={() => scope.modalNew()}
            >
              추 가
            </Button>
            <Button
              style={{ height: '25px', width: '60px' }}
              theme={BUTTON_THEMES.RED}
              onClick={() => scope.modalDelete()}
            >
              삭 제
            </Button>
          </FlexLayout>
          <FlexLayout>
            <TableLayout
              ref={scope.popupTable}
              header={[
                {
                  id: 'rptdate',
                  text: '일자',
                  width: 100,
                  render: (x) => (
                    <FormatTextBox
                      textAlign="center"
                      format="####-##-##"
                      value={x.rptdate}
                    />
                  ),
                },
                {
                  id: 'perid',
                  text: '사원코드',
                  width: 100,
                  color: 'var(--color-red)',
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.perid}
                      textAlign="center"
                      onChange={(value) => rowUpdate({ perid: value })}
                      bindSearchModal={new SearchBinding(
                        scope.props.modalStore,
                        'TB_JA001',
                        {},
                        true,
                        (item) => {
                          rowUpdate({
                            perid: item.cd,
                            pernm: item.cdnm,
                          });
                          scope.modalItemChanged(WorkReportItemChangeTypes.PERID, {
                            ...x,
                            perid: item.cd,
                          });
                        },
                      )}
                    />
                  ),
                },
                {
                  id: 'pernm',
                  text: '사원명',
                  width: 100,
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.pernm}
                      textAlign="center"
                      onChange={(value) => rowUpdate({ pernm: value })}
                    />
                  ),
                },
                {
                  id: 'flag',
                  text: '구분',
                  width: 100,
                  render: (x, rowUpdate, _) => (
                    <ComboBox
                      value={x.flag}
                      data={scope.state.flags?.map((y) => new ComboBoxModel(y.com_code, y.com_cnam))}
                      onSelect={(option) => {
                        if (option.value === '2') {
                          ConfirmWarning.show('경고', '당직선택에서는 통신관련된 구분을 선택할 수 없습니다!');
                          return;
                        }
                        rowUpdate({ flag: option.value });
                      }}
                    />
                  ),
                },
              ]}
              data={scope.state.workReportPopupList}
              infinityHandler={scope.infinity3}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              onChange={(rows2, updatedRows2) => {
                scope.onUpdatedRows2(rows2, updatedRows2);
              }}
              onRowFocusEvent={(item) => scope.onRowFocusEvent3(item)}
            />
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.modalSave()}>저장</Button>
              <Button onClick={() => scope.openWorkerModal(false)}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
