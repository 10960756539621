import { observable, action, makeObservable } from 'mobx';
import { dropByCacheKey } from 'react-router-cache-route';
import { MenuModel, TabModel } from '../models';
import { HeaderTabDataModel } from '../components/common/HeaderTab/HeaderTabDataModel';
import { RouterStore, PublicStore } from '.';
import { AuthRepository } from '../repositories';
import { ConfirmWarning } from '../utils/confirm';

interface IHeaderTabStore {
  publicStore?: PublicStore;
  routerStore: RouterStore;
  tabs: TabModel[];
}

export default class HeaderTabStore implements IHeaderTabStore {
  publicStore?: PublicStore;

  readonly routerStore: RouterStore;

  @observable tabs: TabModel[];

  constructor(routerStore: RouterStore) {
    this.routerStore = routerStore;
    this.tabs = [];

    makeObservable(this);
  }

  @action
  async toggle(menu: MenuModel, params: any = {}) {
    const { publicStore, routerStore } = this;

    if (menu.children?.length) {
      menu.parent?.children?.forEach((x) => {
        // eslint-disable-next-line no-param-reassign
        if (menu.id !== x.id) x.isEnabled = false;
      });
      menu.enable(!menu.isEnabled);
    } else if (menu.path) {
      const existTabs = this.tabs.filter((x) => x.id === menu.id);
      if (existTabs.length) {
        publicStore!.pageParams = params;
        this.showTab(existTabs[0]);
      } else {
        try {
          const response = await AuthRepository.check({
            token: publicStore?.user.token,
            window: menu.path?.substr(1),
          });

          if (response.status !== 200) {
            ConfirmWarning.show('오류', '권한이 없습니다');
            return;
          }

          publicStore!.pageParams = params;

          // Save current page state
          this.saveTabState();

          // Open new page
          publicStore?.modalStore?.closeCalendar();
          publicStore?.modalStore?.closeComboBox();
          publicStore?.currentMenu.setActive(menu);
          this.append(menu);
          routerStore.replace(menu.path);
        } catch {
          ConfirmWarning.show('오류', '로그인이 해제되었습니다');
          publicStore?.logout();
        }
      }
    }
  }

  setPublicStore(publicStore: PublicStore) {
    this.publicStore = publicStore;
  }

  get active(): TabModel | undefined {
    for (let i = 0; i < this.tabs.length; i += 1) {
      if (this.tabs[i].isEnabled) return this.tabs[i];
    }
    return undefined;
  }

  /**
   * 상단 탭을 통한 페이지 전환
   *
   * 현재 화면을 임시저장한다.
   * @param tab
   */
  @action
  showTab(tab: TabModel) {
    const { publicStore, routerStore } = this;
    this.saveTabState();

    /**
     * 클릭한 탭 오픈
     */
    publicStore!.currentMenu.setActive(tab.data.menu);
    publicStore!.modalStore?.closeCalendar();
    publicStore!.modalStore?.closeComboBox();
    routerStore.replace(tab.data.menu.path);
  }

  saveTabState() {
    const { publicStore } = this;
    const current = this.tabs.filter(
      (x) => publicStore!.currentMenu.active.id === x.id,
    );

    /**
     * 현재 화면 임시 저장
     */
    if (current.length) {
      const newState = JSON.stringify(publicStore?.currentPage?.state || {});
      current[0].data = new HeaderTabDataModel(
        current[0].id,
        publicStore!.currentMenu.active,
        newState,
      );
    }
  }

  /**
   * 탭 닫기
   * @param tab
   * @param index
   */
  @action
  removeTab(tab: TabModel, index: number) {
    const { publicStore, routerStore } = this;
    if (publicStore!.currentMenu.active.id === tab.id) {
      if (this.tabs.length > 1 && this.tabs.length > index + 1) {
        this.replaceTab(this.tabs.filter((x) => tab.id !== x.id)[index].data);
      } else if (this.tabs.length > 1) {
        this.replaceTab(
          this.tabs.filter((x) => tab.id !== x.id)[0].data,
        );
      } else {
        publicStore?.modalStore?.closeCalendar();
        publicStore?.modalStore?.closeComboBox();
        routerStore.replace('/');
      }
    }

    this.tabs = this.tabs.filter((x) => tab.id !== x.id);
    dropByCacheKey(tab.data.menu.path);
    // eslint-disable-next-line no-param-reassign
    delete tab.data;
  }

  /**
   * 화면 전환
   * @param model
   */
  @action
  replaceTab(model: HeaderTabDataModel) {
    const { publicStore, routerStore } = this;
    publicStore!.currentMenu.setActive(model.menu);

    publicStore?.modalStore?.closeCalendar();
    publicStore?.modalStore?.closeComboBox();
    if (model.state) {
      routerStore.replace(model.menu.path || '/', JSON.parse(model.state));
    } else {
      routerStore.replace(model.menu.path || '/');
    }
  }

  /**
   * 탭 추가
   * @param menu
   */
  @action
  append(menu: MenuModel) {
    if (!this.tabs.filter((x) => x.id === menu.id).length) {
      this.tabs.push(
        new TabModel(
          menu.id,
          menu.icon,
          menu.text!,
          () => false,
          true,
          new HeaderTabDataModel(menu.id, menu),
        ),
      );
    }
  }
}
