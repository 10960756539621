import * as React from 'react';
import { observer } from 'mobx-react';
import style from '../AuthorizedScreen.module.scss';
import { ConfirmWarning } from '../../utils/confirm';
import { ModalView } from '../../components/layout/ModalView';
import { ModalStore } from '../../stores';
import { FlexLayout } from '../../components/layout/FlexLayout';
import { Global } from '../../constants';
import { SearchLayout } from '../../components/layout/SearchLayout';
import { TextBox } from '../../components/forms/TextBox';
import { GridLayout } from '../../components/layout/GridLayout';
import { Button } from '../../components/forms/Button';
import { ComboBox, ComboBoxModel } from '../../components/forms/ComboBox';
import { Format } from '../../utils/string';
import { ImageView } from '../../components/forms/ImageView';
import { SearchBinding } from '../../models';

interface ModalProductProps {
  modalStore: ModalStore;
}

const wkactcds = [
  { value: '001', remark: '자사' },
  { value: '002', remark: '현대' },
  { value: '003', remark: '오티스' },
  { value: '004', remark: '티센' },
  { value: '005', remark: '미쓰비시' },
];

const cgubuns = [
  { value: '', remark: '' },
  { value: '001', remark: '형식승인부품' },
  { value: '002', remark: 'KS부품' },
  { value: '003', remark: '안전인증부품' },
];

const picflags = [
  { value: '0', remark: 'X' },
  { value: '1', remark: 'O' },
];

@observer
export class ModalProduct extends React.Component<ModalProductProps> {
  render() {
    const { modalStore } = this.props;
    const { isContPermitted } = modalStore.publicStore;

    return (
      <ModalView
        width={1500}
        isVisible={modalStore.isVisibleProductSelector}
        onClose={() => modalStore.closeProductSelector()}
      >
        <FlexLayout isVertical={true} style={{ padding: 8 }}>
          <SearchLayout
            size={Global.LAYOUT_SEARCH_HEIGHT_1}
          >
            <FlexLayout size={180}>
              <span style={{ width: 60 }}>회사구분</span>
              <ComboBox
                value={modalStore.productSelectorSearchWkactcd}
                data={[
                  { value: '%', remark: '전체' },
                  { value: '001', remark: '자사' },
                  { value: '002', remark: '현대' },
                  { value: '003', remark: '오티스' },
                  { value: '004', remark: '티센' },
                  { value: '005', remark: '미쓰비시' },
                ].map((x) => new ComboBoxModel(x.value, x.remark))}
                onSelect={(option) => {
                  modalStore.productSelectorSearchWkactcd = option.value;
                  modalStore.productSelectorRetrieve();
                }}
              />
            </FlexLayout>

            <FlexLayout size={300}>
              <span>검색어(품명)</span>
              <TextBox
                value={modalStore.productSelectorSearchQuery}
                onChange={(value) => { modalStore.productSelectorSearchQuery = value; }}
                onEnter={() => modalStore.productSelectorRetrieve()}
              />
            </FlexLayout>

            <FlexLayout size={240}>
              <span>규격</span>
              <TextBox
                value={modalStore.productSelectorSearchQuery2}
                onChange={(value) => { modalStore.productSelectorSearchQuery2 = value; }}
                onEnter={() => modalStore.productSelectorRetrieve()}
              />
            </FlexLayout>

            <FlexLayout size={260}>
              <span style={{ color: 'var(--color-red)' }}>주거래처</span>

              <TextBox
                textAlign="center"
                value={modalStore.productSelectorSearchCltcd}
                onChange={(value) => {
                  modalStore.productSelectorSearchCltcd = value;
                }}
                bindSearchModal={new SearchBinding(
                  modalStore,
                  'TB_XCLIENT',
                  {},
                  true,
                  (item) => {
                    modalStore.productSelectorSearchCltcd = item.cd;
                    modalStore.productSelectorSearchCltnm = item.cdnm;
                    modalStore.productSelectorRetrieve();
                  },
                )}
              />

              <TextBox
                weight={1.3}
                textAlign="left"
                value={modalStore.productSelectorSearchCltnm}
                onChange={(value) => { modalStore.productSelectorSearchCltnm = value; }}
                readonly={true}
              />
            </FlexLayout>

            <FlexLayout size={180}>
              <span style={{ marginLeft: 2 }}>재고관리</span>
              <ComboBox
                style={{
                  padding: '0 0 0 6px', fontSize: 12,
                }}
                value={modalStore.productSelectorSearchMtyn}
                data={[
                  new ComboBoxModel('%', '전체제품'),
                  new ComboBoxModel('1', '설치제품'),
                ]}
                onSelect={(item) => {
                  modalStore.productSelectorSearchMtyn = item.value;
                  modalStore.productSelectorRetrieve();
                }}
              />
            </FlexLayout>

            <FlexLayout size={180}>
              <span>설치제품</span>
              <ComboBox
                value={modalStore.productSelectorSearchQuery3}
                data={[
                  new ComboBoxModel('%', '전체제품'),
                  new ComboBoxModel('1', '유'),
                  new ComboBoxModel('0', '무'),
                ]}
                onSelect={(item) => {
                  modalStore.productSelectorSearchQuery3 = item.value;
                  modalStore.productSelectorRetrieve();
                }}
              />
            </FlexLayout>
          </SearchLayout>

          <SearchLayout
            isSub={true}
            size={Global.LAYOUT_SEARCH_HEIGHT_1}
          >
            <FlexLayout>
              <span>선택</span>
              <ComboBox
                value={modalStore.productSelectorSearchChoice}
                style={{
                  padding: '0 0 0 6px', fontSize: 12,
                }}
                data={modalStore.productSelectorChoices
                  ?.map((y) => new ComboBoxModel(y.chcd, y.chnm))}
                onSelect={(option) => {
                  modalStore.productSelectorSearchChoice = option.value;
                  modalStore.productSelectorSearchPhm_mode = option.value === '%' ? '%' : option.value.substr(0, 3);
                  modalStore.productSelectorSearchAgrb = option.value === '%' ? '%' : option.value.substr(3, 2);
                  modalStore.productSelectorSearchBgrb = option.value === '%' ? '%' : option.value.substr(5, 2);
                  modalStore.productSelectorSearchCgrb = option.value === '%' ? '%' : option.value.substr(7, 2);
                  modalStore.productOptionInit();
                }}
              />
            </FlexLayout>

            <FlexLayout>
              <span>품목분류</span>
              <ComboBox
                value={modalStore.productSelectorSearchPhm_mode}
                style={{
                  padding: '0 0 0 6px',
                  fontSize: 12,
                }}
                data={modalStore.productSelectorPhm_modes
                  ?.map((y) => new ComboBoxModel(y.phm_mode, y.phm_modenm))}
                onSelect={(option) => {
                  modalStore.productSelectorSearchPhm_mode = option.value;
                  modalStore.productOptionInit();
                }}
              />
            </FlexLayout>

            <FlexLayout
              onClick={() => {
                if (modalStore.productSelectorSearchPhm_mode === '%') {
                  ConfirmWarning.show('경고', '먼저 품목분류를 선택하세요!');
                }
              }}>
              <span>대분류</span>
              <ComboBox
                value={modalStore.productSelectorSearchAgrb}
                style={{
                  padding: '0 0 0 6px',
                  fontSize: 12,
                }}
                data={modalStore.productSelectorAgrbs
                  ?.map((y) => new ComboBoxModel(y.pgr_hcod, y.pgr_hnam))}
                onSelect={(option) => {
                  modalStore.productSelectorSearchAgrb = option.value;
                  modalStore.productOptionInit();
                }}
              />
            </FlexLayout>

            <FlexLayout onClick={() => {
              if (modalStore.productSelectorSearchAgrb === '%') {
                ConfirmWarning.show('경고', '먼저 대분류를 선택하세요!');
              }
            }}>
              <span>중분류</span>
              <ComboBox
                value={modalStore.productSelectorSearchBgrb}
                style={{
                  padding: '0 0 0 6px',
                  fontSize: 12,
                }}
                data={modalStore.productSelectorBgrbs
                  ?.map((y) => new ComboBoxModel(y.bgroup, y.bgrpnm))}
                onSelect={(option) => {
                  modalStore.productSelectorSearchBgrb = option.value;
                  modalStore.productOptionInit();
                }}
              />
            </FlexLayout>

            <FlexLayout size={230} onClick={() => {
              if (modalStore.productSelectorSearchBgrb === '%') {
                ConfirmWarning.show('경고', '먼저 중분류를 선택하세요!');
              }
            }}>
              <span>소분류</span>
              <ComboBox
                value={modalStore.productSelectorSearchCgrb}
                style={{
                  padding: '0 0 0 6px',
                  fontSize: 12,
                }}
                data={modalStore.productSelectorCgrbs
                  ?.map((y) => new ComboBoxModel(y.cgroup, y.cgrpnm))}
                onSelect={(option) => {
                  modalStore.productSelectorSearchCgrb = option.value;
                  modalStore.productOptionInit();
                }}
              />
            </FlexLayout>
          </SearchLayout>

          <FlexLayout>
            <FlexLayout>
              <GridLayout
                ref={modalStore.productSelectorGrid}
                header={[
                  {
                    id: 'wkactcd',
                    text: '회사구분',
                    width: 80,
                    render: (x) => (
                      <FlexLayout
                        align='center'
                        justify='center'
                      >
                        {wkactcds.filter((y) => y.value === x.wkactcd)[0]?.remark}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'picflag',
                    text: '사진',
                    width: 50,
                    render: (x) => (
                      <FlexLayout
                        align='center'
                        justify='center'
                        style={{ color: x.picflag === '1' ? 'var(--color-blue)' : 'var(--color-red)' }}
                      >
                        {picflags.filter((y) => y.value === x.picflag)[0]?.remark}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'phm_pcod',
                    text: '제품코드',
                    width: 80,
                  },
                  {
                    id: 'phm_pnam',
                    text: '품목명',
                    width: 250,
                    render: (x) => (
                      <FlexLayout align="center" justify="left">
                        {x.phm_pnam}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'phm_knam',
                    text: '품목명(한글명)',
                    width: 160,
                    render: (x) => (
                      <FlexLayout align="center" justify="left">
                        {x.phm_knam}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'phm_size',
                    text: '규격',
                    width: 230,
                    render: (x) => (
                      <FlexLayout align="center" justify="left">
                        {x.phm_size}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'psize',
                    text: '크기/치수',
                    width: 80,
                  },
                  {
                    id: 'phm_unit',
                    text: '단위',
                    width: 60,
                  },
                  {
                    id: 'point',
                    text: '주요',
                    width: 60,
                    render: (x) => (
                      <FlexLayout align="center" justify="center">
                        {x.point === '1' ? '유' : '무'}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'pmodel',
                    text: '기종',
                    width: 60,
                  },
                  {
                    id: 'phm_mamt',
                    text: '매입단가',
                    width: 100,
                    render: (x) => (
                      <FlexLayout align="center" justify="right">
                        {isContPermitted ? Format.number(x.phm_mamt) : ''}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'phm_samt',
                    text: '판매단가',
                    width: 100,
                    render: (x) => (
                      <FlexLayout align="center" justify="right">
                        {Format.number(x.phm_samt)}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'phm_mode',
                    text: '품목분류',
                    width: 80,
                    render: (x) => (
                      <FlexLayout
                        align='center'
                        justify='center'
                      >
                        {modalStore.productSelectorPhm_modes?.filter((y) => y.phm_mode === x.phm_mode)[0]?.phm_modenm}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'phm_sgrbnm',
                    text: '대분류',
                    width: 80,
                  },
                  {
                    id: 'bgroupnm',
                    text: '중분류',
                    width: 80,
                  },
                  {
                    id: 'cgroupnm',
                    text: '소분류',
                    width: 80,
                    render: (x) => (
                      <FlexLayout align="center" justify="left">
                        {x.cgroupnm}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'phm_pgun',
                    text: '품목구분',
                    width: 60,
                  },
                  {
                    id: 'type',
                    text: '품질검사유형',
                    width: 80,
                  },
                  {
                    id: 'phm_jqty',
                    text: '적정재고',
                    width: 60,
                  },
                  {
                    id: 'jqty',
                    text: '현 재고',
                    width: 60,
                  },
                  {
                    id: 'age',
                    text: '부품수명',
                    width: 60,
                  },
                  {
                    id: 'store',
                    text: '보관창고',
                    width: 100,
                    render: (x) => (
                      <FlexLayout
                        align='center'
                        justify='center'
                      >
                        {x.storenm}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'cgubun',
                    text: '안전검사구분',
                    width: 100,
                    render: (x) => (
                      <FlexLayout
                        align='center'
                        justify='center'
                      >
                        {cgubuns.filter((y) => y.value === x.cgubun)[0]?.remark}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'jugi',
                    text: '실사주기',
                    width: 60,
                  },
                  {
                    id: 'remarks',
                    text: '특이사항',
                    width: 300,
                  },
                ]}
                data={modalStore?.productSelectorData || []}
                rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                infinityHandler={modalStore.productSelectorInfinity}
                onRowClick={(item) => modalStore.onProductRowFocusEvent(item)}
                onRowDoubleClick={(item) => {
                  modalStore.onProductRowFocusEvent(item);
                  modalStore.closeProductSelector(true);
                }}
              />
            </FlexLayout>

            <FlexLayout
              size={220}
              isVertical={true}
            >
              <ImageView
                src={modalStore.productSelectorImageA}
              />
              <ImageView
                src={modalStore.productSelectorImageB}
              />
            </FlexLayout>
          </FlexLayout>

          <FlexLayout className={style.buttons} size={38}>
            <Button
              className={style.btnDanger}
              isFull={true}
              onClick={() => modalStore.closeProductSelector()}
            >
              취소
            </Button>
            <Button
              className={style.btnSuccess}
              isFull={true}
              onClick={() => {
                if (modalStore.productSelected) {
                  modalStore.closeProductSelector(true);
                } else {
                  ConfirmWarning.show('오류', '항목을 먼저 선택해주세요.');
                }
              }}
            >
              확인
            </Button>
          </FlexLayout>
        </FlexLayout>
      </ModalView>
    );
  }
}
