import { computed } from 'mobx';

/**
 * 모델
 * 승강기제원정보
 * @window w_tb_e611_type
 * @category 승강기제원정보[이력카드]
 */
export class InfoDetailModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 발령코드
   *
   * **data type** varchar(2)
   */
  ordercd: string;

  /**
   * 발령명
   *
   * **data type** varchar(20)
   */
  ordernm: string;

  /**
   * 발령명
   *
   * **data type** varchar(1)
   */
  useyn: string;

  /**
   * 호기
   *
   * **data type** varchar(3)
   */
  equpcd: string;

  /**
   * 호기명
   *
   * **data type** varchar(100)
   */
  equpnm: string;

  /**
   * 계약
   *
   * **data type** varchar(1)
   */
  contyn: string;

  /**
   * 승강기번호
   *
   * **data type** varchar(50)
   */
  elno: string;

  /**
   * 구분
   *
   * **data type** char(1)
   */
  gubun: string;

  /**
   * 종류
   *
   * **data type** char(3)
   */
  kind: string;

  /**
   * 형식
   *
   * **data type** char(3)
   */
  form: string;

  /**
   * 도어방식
   *
   * **data type** char(3)
   */
  inver: string;

  /**
   * 점검기종
   *
   * **data type** char(10)
   */
  evcd_new: string;

  /**
   * 점검기종
   *
   * **data type** char(10)
   */
  evcd: string;

  /**
   * 제어반
   *
   * **data type** varchar(50)
   */
  jeoban: string;

  /**
   * 인화물구분
   *
   * **data type** varchar(모름)
   */
  class2: string;

  /**
   * 모델명
   *
   * **data type** char(3)
   */
  model: string;

  /**
   * 모델명2
   *
   * **data type** varchar(20)
   */
  mdnm: string;

  /**
   * 제조업체
   *
   * **data type** varchar(5)
   */
  manucd: string;

  /**
   * 제조업체2
   *
   * **data type** varchar(200)
   */
  manunm: string;

  /**
   * 층수
   *
   * **data type** char(20)
   */
  number: string;

  /**
   * 지상
   *
   * **data type** char(20)
   */
  number_gr: string;

  /**
   * 지하
   *
   * **data type** char(20)
   */
  number_ungr: string;

  /**
   * 최대정원
   *
   * **data type** float
   */
  nperson: string;

  /**
   * 적재하중
   *
   * **data type** float
   */
  height: string;

  /**
   * 정격속도
   *
   * **data type** varchar(3)
   */
  spped: string;

  /**
   * 정격속도2
   *
   * **data type** 모름
   */
  speednm: string;

  /**
   * 사양
   *
   * **data type** varchar(100)
   */
  sizecd: string;

  /**
   * 점검대상
   *
   * **data type** varchar(1)
   */
  kcflag: string;

  /**
   * 원격
   *
   * **data type** varchar(1)
   */
  remflag: string;

  /**
   * 점검일
   *
   * **data type** varchar(8)
   */
  kcdate: string;

  /**
   * plc
   *
   * **data type** 모름
   */
  plc: string;

  /**
   * tac
   *
   * **data type** 모름
   */
  attcnt: string;

  /**
   * 도면
   *
   * **data type** 모름
   */
  dpattcnt: string;

  /**
   * 최초설치일자
   *
   * **data type** datetime
   */
  yyyymm: string;

  /**
   * 설치일자
   *
   * **data type** datetime
   */
  yyyymm2: string;

  /**
   * 운행유효기간-시작일
   *
   * **data type** datetime
   */
  kcstdate: string;

  /**
   * 종료일
   *
   * **data type** datetime
   */
  kcenddate: string;

  /**
   * 최종검사일
   *
   * **data type** datetime
   */
  totkcdate: string;

  /**
   * 최종검사종류
   *
   * **data type** 모름
   */
  kcgubun: string;

  /**
   * 결과
   *
   * **data type** 모름
   */
  kcresult: string;

  actcd: string;

  /**
   * 제원01
   *
   * **data type** varchar(255)
   */
  type01: string;

  /**
   * 제원제목01
   *
   * **data type** varchar(255)
   */
  typename01: string;

  /**
   * 제원02
   *
   * **data type** varchar(255)
   */
  type02: string;

  /**
   * 제원제목02
   *
   * **data type** varchar(255)
   */
  typename02: string;

  /**
   * 제원03
   *
   * **data type** varchar(255)
   */
  type03: string;

  /**
   * 제원제목03
   *
   * **data type** varchar(255)
   */
  typename03: string;

  /**
   * 제원04
   *
   * **data type** varchar(255)
   */
  type04: string;

  /**
   * 제원제목04
   *
   * **data type** varchar(255)
   */
  typename04: string;

  /**
   * 제원05
   *
   * **data type** varchar(255)
   */
  type05: string;

  /**
   * 제원제목05
   *
   * **data type** varchar(255)
   */
  typename05: string;

  /**
   * 제원06
   *
   * **data type** varchar(255)
   */
  type06: string;

  /**
   * 제원제목06
   *
   * **data type** varchar(255)
   */
  typename06: string;

  /**
   * 제원07
   *
   * **data type** varchar(255)
   */
  type07: string;

  /**
   * 제원제목07
   *
   * **data type** varchar(255)
   */
  typename07: string;

  /**
   * 제원08
   *
   * **data type** varchar(255)
   */
  type08: string;

  /**
   * 제원제목08
   *
   * **data type** varchar(255)
   */
  typename08: string;

  /**
   * 제원09
   *
   * **data type** varchar(255)
   */
  type09: string;

  /**
   * 제원제목09
   *
   * **data type** varchar(255)
   */
  typename09: string;

  /**
   * 제원10
   *
   * **data type** varchar(255)
   */
  type10: string;

  /**
   * 제원제목10
   *
   * **data type** varchar(255)
   */
  typename10: string;

  /**
   * 제원11
   *
   * **data type** varchar(255)
   */
  type11: string;

  /**
   * 제원제목11
   *
   * **data type** varchar(255)
   */
  typename11: string;

  /**
   * 제원12
   *
   * **data type** varchar(255)
   */
  type12: string;

  /**
   * 제원제목12
   *
   * **data type** varchar(255)
   */
  typename12: string;

  /**
   * 제원13
   *
   * **data type** varchar(255)
   */
  type13: string;

  /**
   * 제원제목13
   *
   * **data type** varchar(255)
   */
  typename13: string;

  /**
   * 제원14
   *
   * **data type** varchar(255)
   */
  type14: string;

  /**
   * 제원제목14
   *
   * **data type** varchar(255)
   */
  typename14: string;

  /**
   * 제원15
   *
   * **data type** varchar(255)
   */
  type15: string;

  /**
   * 제원제목15
   *
   * **data type** varchar(255)
   */
  typename15: string;

  /**
   * 제원16
   *
   * **data type** varchar(255)
   */
  type16: string;

  /**
   * 제원제목16
   *
   * **data type** varchar(255)
   */
  typename16: string;

  /**
   * 제원17
   *
   * **data type** varchar(255)
   */
  type17: string;

  /**
   * 제원제목17
   *
   * **data type** varchar(255)
   */
  typename17: string;

  /**
   * 제원18
   *
   * **data type** varchar(255)
   */
  type18: string;

  /**
   * 제원제목18
   *
   * **data type** varchar(255)
   */
  typename18: string;

  /**
   * 제원19
   *
   * **data type** varchar(255)
   */
  type19: string;

  /**
   * 제원제목19
   *
   * **data type** varchar(255)
   */
  typename19: string;

  /**
   * 제원20
   *
   * **data type** varchar(255)
   */
  type20: string;

  /**
   * 제원제목20
   *
   * **data type** varchar(255)
   */
  typename20: string;

  /**
   * 제원21
   *
   * **data type** varchar(255)
   */
  type21: string;

  /**
   * 제원제목21
   *
   * **data type** varchar(255)
   */
  typename21: string;

  /**
   * 제원22
   *
   * **data type** varchar(255)
   */
  type22: string;

  /**
   * 제원제목22
   *
   * **data type** varchar(255)
   */
  typename22: string;

  /**
   * 제원23
   *
   * **data type** varchar(255)
   */
  type23: string;

  /**
   * 제원제목23
   *
   * **data type** varchar(255)
   */
  typename23: string;

  /**
   * 제원24
   *
   * **data type** varchar(255)
   */
  type24: string;

  /**
   * 제원제목24
   *
   * **data type** varchar(255)
   */
  typename24: string;

  /**
   * 제원25
   *
   * **data type** varchar(255)
   */
  type25: string;

  /**
   * 제원제목25
   *
   * **data type** varchar(255)
   */
  typename25: string;

  /**
   * 제원26
   *
   * **data type** varchar(255)
   */
  type26: string;

  /**
   * 제원제목26
   *
   * **data type** varchar(255)
   */
  typename26: string;

  /**
   * 제원27
   *
   * **data type** varchar(255)
   */
  type27: string;

  /**
   * 제원제목27
   *
   * **data type** varchar(255)
   */
  typename27: string;

  /**
   * 제원28
   *
   * **data type** varchar(255)
   */
  type28: string;

  /**
   * 제원제목28
   *
   * **data type** varchar(255)
   */
  typename28: string;

  /**
   * 제원29
   *
   * **data type** varchar(255)
   */
  type29: string;

  /**
   * 제원제목29
   *
   * **data type** varchar(255)
   */
  typename29: string;

  /**
   * 제원30
   *
   * **data type** varchar(255)
   */
  type30: string;

  /**
   * 제원제목30
   *
   * **data type** varchar(255)
   */
  typename30: string;

  /**
   * 제원31
   *
   * **data type** varchar(255)
   */
  type31: string;

  /**
   * 제원제목31
   *
   * **data type** varchar(255)
   */
  typename31: string;

  /**
   * 제원32
   *
   * **data type** varchar(255)
   */
  type32: string;

  /**
   * 제원제목32
   *
   * **data type** varchar(255)
   */
  typename32: string;

  /**
   * 제원33
   *
   * **data type** varchar(255)
   */
  type33: string;

  /**
   * 제원제목33
   *
   * **data type** varchar(255)
   */
  typename33: string;

  /**
   * 제원34
   *
   * **data type** varchar(255)
   */
  type34: string;

  /**
   * 제원제목34
   *
   * **data type** varchar(255)
   */
  typename34: string;

  /**
   * 제원35
   *
   * **data type** varchar(255)
   */
  type35: string;

  /**
   * 제원제목35
   *
   * **data type** varchar(255)
   */
  typename35: string;

  /**
   * 제원36
   *
   * **data type** varchar(255)
   */
  type36: string;

  /**
   * 제원제목36
   *
   * **data type** varchar(255)
   */
  typename36: string;

  /**
   * 제원37
   *
   * **data type** varchar(255)
   */
  type37: string;

  /**
   * 제원제목37
   *
   * **data type** varchar(255)
   */
  typename37: string;

  /**
   * 제원38
   *
   * **data type** varchar(255)
   */
  type38: string;

  /**
   * 제원제목38
   *
   * **data type** varchar(255)
   */
  typename38: string;

  /**
   * 제원39
   *
   * **data type** varchar(255)
   */
  type39: string;

  /**
   * 제원제목39
   *
   * **data type** varchar(255)
   */
  typename39: string;

  /**
   * 제원40
   *
   * **data type** varchar(255)
   */
  type40: string;

  /**
   * 제원제목40
   *
   * **data type** varchar(255)
   */
  typename40: string;

  /**
   * 제원41
   *
   * **data type** varchar(255)
   */
  type41: string;

  /**
   * 제원제목41
   *
   * **data type** varchar(255)
   */
  typename41: string;

  /**
   * 제원42
   *
   * **data type** varchar(255)
   */
  type42: string;

  /**
   * 제원제목42
   *
   * **data type** varchar(255)
   */
  typename42: string;

  /**
   * 제원43
   *
   * **data type** varchar(255)
   */
  type43: string;

  /**
   * 제원제목43
   *
   * **data type** varchar(255)
   */
  typename43: string;

  /**
   * 제원44
   *
   * **data type** varchar(255)
   */
  type44: string;

  /**
   * 제원제목44
   *
   * **data type** varchar(255)
   */
  typename44: string;

  /**
   * 제원45
   *
   * **data type** varchar(255)
   */
  type45: string;

  /**
   * 제원제목45
   *
   * **data type** varchar(255)
   */
  typename45: string;

  /**
   * 제원46
   *
   * **data type** varchar(255)
   */
  type46: string;

  /**
   * 제원제목46
   *
   * **data type** varchar(255)
   */
  typename46: string;

  /**
   * 제원47
   *
   * **data type** varchar(255)
   */
  type47: string;

  /**
   * 제원제목47
   *
   * **data type** varchar(255)
   */
  typename47: string;

  /**
   * 제원48
   *
   * **data type** varchar(255)
   */
  type48: string;

  /**
   * 제원제목48
   *
   * **data type** varchar(255)
   */
  typename48: string;

  /**
   * 제원49
   *
   * **data type** varchar(255)
   */
  type49: string;

  /**
   * 제원제목49
   *
   * **data type** varchar(255)
   */
  typename49: string;

  /**
   * 제원50
   *
   * **data type** varchar(255)
   */
  type50: string;

  /**
   * 제원제목50
   *
   * **data type** varchar(255)
   */
  typename50: string;

  /**
   * 제원51
   *
   * **data type** varchar(255)
   */
  type51: string;

  /**
   * 제원제목51
   *
   * **data type** varchar(255)
   */
  typename51: string;

  /**
   * 제원52
   *
   * **data type** varchar(255)
   */
  type52: string;

  /**
   * 제원제목52
   *
   * **data type** varchar(255)
   */
  typename52: string;

  /**
   * 제원53
   *
   * **data type** varchar(255)
   */
  type53: string;

  /**
   * 제원제목53
   *
   * **data type** varchar(255)
   */
  typename53: string;

  /**
   * 제원54
   *
   * **data type** varchar(255)
   */
  type54: string;

  /**
   * 제원제목54
   *
   * **data type** varchar(255)
   */
  typename54: string;

  /**
   * 제원55
   *
   * **data type** varchar(255)
   */
  type55: string;

  /**
   * 제원제목55
   *
   * **data type** varchar(255)
   */
  typename55: string;

  /**
   * 제원56
   *
   * **data type** varchar(255)
   */
  type56: string;

  /**
   * 제원제목56
   *
   * **data type** varchar(255)
   */
  typename56: string;

  /**
   * 제원57
   *
   * **data type** varchar(255)
   */
  type57: string;

  /**
   * 제원제목57
   *
   * **data type** varchar(255)
   */
  typename57: string;

  /**
   * 제원58
   *
   * **data type** varchar(255)
   */
  type58: string;

  /**
   * 제원제목58
   *
   * **data type** varchar(255)
   */
  typename58: string;

  /**
   * 제원59
   *
   * **data type** varchar(255)
   */
  type59: string;

  /**
   * 제원제목59
   *
   * **data type** varchar(255)
   */
  typename59: string;

  /**
   * 제원60
   *
   * **data type** varchar(255)
   */
  type60: string;

  /**
   * 제원제목60
   *
   * **data type** varchar(255)
   */
  typename60: string;

  /**
   * 제원61
   *
   * **data type** varchar(255)
   */
  type61: string;

  /**
   * 제원제목61
   *
   * **data type** varchar(255)
   */
  typename61: string;

  /**
   * 제원62
   *
   * **data type** varchar(255)
   */
  type62: string;

  /**
   * 제원제목62
   *
   * **data type** varchar(255)
   */
  typename62: string;

  /**
   * 제원63
   *
   * **data type** varchar(255)
   */
  type63: string;

  /**
   * 제원제목63
   *
   * **data type** varchar(255)
   */
  typename63: string;

  /**
   * 제원64
   *
   * **data type** varchar(255)
   */
  type64: string;

  /**
   * 제원제목64
   *
   * **data type** varchar(255)
   */
  typename64: string;

  /**
   * 제원65
   *
   * **data type** varchar(255)
   */
  type65: string;

  /**
   * 제원제목65
   *
   * **data type** varchar(255)
   */
  typename65: string;

  /**
   * 제원66
   *
   * **data type** varchar(255)
   */
  type66: string;

  /**
   * 제원제목66
   *
   * **data type** varchar(255)
   */
  typename66: string;

  /**
   * 제원67
   *
   * **data type** varchar(255)
   */
  type67: string;

  /**
   * 제원제목67
   *
   * **data type** varchar(255)
   */
  typename67: string;

  /**
   * 제원68
   *
   * **data type** varchar(255)
   */
  type68: string;

  /**
   * 제원제목68
   *
   * **data type** varchar(255)
   */
  typename68: string;

  /**
   * 제원69
   *
   * **data type** varchar(255)
   */
  type69: string;

  /**
   * 제원제목69
   *
   * **data type** varchar(255)
   */
  typename69: string;

  /**
   * 제원70
   *
   * **data type** varchar(255)
   */
  type70: string;

  /**
   * 제원제목70
   *
   * **data type** varchar(255)
   */
  typename70: string;

  /**
   * 제원71
   *
   * **data type** varchar(255)
   */
  type71: string;

  /**
   * 제원제목71
   *
   * **data type** varchar(255)
   */
  typename71: string;

  /**
   * 제원72
   *
   * **data type** varchar(255)
   */
  type72: string;

  /**
   * 제원제목72
   *
   * **data type** varchar(255)
   */
  typename72: string;

  /**
   * 제원73
   *
   * **data type** varchar(255)
   */
  type73: string;

  /**
   * 제원제목73
   *
   * **data type** varchar(255)
   */
  typename73: string;

  /**
   * 제원74
   *
   * **data type** varchar(255)
   */
  type74: string;

  /**
   * 제원제목74
   *
   * **data type** varchar(255)
   */
  typename74: string;

  /**
   * 제원75
   *
   * **data type** varchar(255)
   */
  type75: string;

  /**
   * 제원제목75
   *
   * **data type** varchar(255)
   */
  typename75: string;

  /**
   * 제원76
   *
   * **data type** varchar(255)
   */
  type76: string;

  /**
   * 제원제목76
   *
   * **data type** varchar(255)
   */
  typename76: string;

  /**
   * 제원77
   *
   * **data type** varchar(255)
   */
  type77: string;

  /**
   * 제원제목77
   *
   * **data type** varchar(255)
   */
  typename77: string;

  /**
   * 제원78
   *
   * **data type** varchar(255)
   */
  type78: string;

  /**
   * 제원제목78
   *
   * **data type** varchar(255)
   */
  typename78: string;

  /**
   * 제원79
   *
   * **data type** varchar(255)
   */
  type79: string;

  /**
   * 제원제목79
   *
   * **data type** varchar(255)
   */
  typename79: string;

  /**
   * 제원80
   *
   * **data type** varchar(255)
   */
  type80: string;

  /**
   * 제원제목80
   *
   * **data type** varchar(255)
   */
  typename80: string;

  /**
   * 제원81
   *
   * **data type** varchar(255)
   */
  type81: string;

  /**
   * 제원제목81
   *
   * **data type** varchar(255)
   */
  typename81: string;

  /**
   * 제원82
   *
   * **data type** varchar(255)
   */
  type82: string;

  /**
   * 제원제목82
   *
   * **data type** varchar(255)
   */
  typename82: string;

  /**
   * 제원83
   *
   * **data type** varchar(255)
   */
  type83: string;

  /**
   * 제원제목83
   *
   * **data type** varchar(255)
   */
  typename83: string;

  /**
   * 제원84
   *
   * **data type** varchar(255)
   */
  type84: string;

  /**
   * 제원제목84
   *
   * **data type** varchar(255)
   */
  typename84: string;

  /**
   * 제원85
   *
   * **data type** varchar(255)
   */
  type85: string;

  /**
   * 제원제목85
   *
   * **data type** varchar(255)
   */
  typename85: string;

  /**
   * 제원86
   *
   * **data type** varchar(255)
   */
  type86: string;

  /**
   * 제원제목86
   *
   * **data type** varchar(255)
   */
  typename86: string;

  /**
   * 제원87
   *
   * **data type** varchar(255)
   */
  type87: string;

  /**
   * 제원제목87
   *
   * **data type** varchar(255)
   */
  typename87: string;

  /**
   * 제원88
   *
   * **data type** varchar(255)
   */
  type88: string;

  /**
   * 제원제목88
   *
   * **data type** varchar(255)
   */
  typename88: string;

  /**
   * 제원89
   *
   * **data type** varchar(255)
   */
  type89: string;

  /**
   * 제원제목89
   *
   * **data type** varchar(255)
   */
  typename89: string;

  /**
   * 제원90
   *
   * **data type** varchar(255)
   */
  type90: string;

  /**
   * 제원제목90
   *
   * **data type** varchar(255)
   */
  typename90: string;

  /**
   * 제원91
   *
   * **data type** varchar(255)
   */
  type91: string;

  /**
   * 제원제목91
   *
   * **data type** varchar(255)
   */
  typename91: string;

  /**
   * 제원92
   *
   * **data type** varchar(255)
   */
  type92: string;

  /**
   * 제원제목92
   *
   * **data type** varchar(255)
   */
  typename92: string;

  /**
   * 제원93
   *
   * **data type** varchar(255)
   */
  type93: string;

  /**
   * 제원제목93
   *
   * **data type** varchar(255)
   */
  typename93: string;

  /**
   * 제원94
   *
   * **data type** varchar(255)
   */
  type94: string;

  /**
   * 제원제목94
   *
   * **data type** varchar(255)
   */
  typename94: string;

  /**
   * 제원95
   *
   * **data type** varchar(255)
   */
  type95: string;

  /**
   * 제원제목95
   *
   * **data type** varchar(255)
   */
  typename95: string;

  /**
   * 제원96
   *
   * **data type** varchar(255)
   */
  type96: string;

  /**
   * 제원제목96
   *
   * **data type** varchar(255)
   */
  typename96: string;

  /**
   * 제원97
   *
   * **data type** varchar(255)
   */
  type97: string;

  /**
   * 제원제목97
   *
   * **data type** varchar(255)
   */
  typename97: string;

  /**
   * 제원98
   *
   * **data type** varchar(255)
   */
  type98: string;

  /**
   * 제원제목98
   *
   * **data type** varchar(255)
   */
  typename98: string;

  /**
   * 제원99
   *
   * **data type** varchar(255)
   */
  type99: string;

  /**
   * 제원제목99
   *
   * **data type** varchar(255)
   */
  typename99: string;

  /**
   * 제원100
   *
   * **data type** varchar(255)
   */
  type100: string;

  /**
   * 제원제목100
   *
   * **data type** varchar(255)
   */
  typename100: string;

  /**
   * 제원101
   *
   * **data type** varchar(255)
   */
  type101: string;

  /**
   * 제원제목101
   *
   * **data type** varchar(255)
   */
  typename101: string;

  /**
   * 제원102
   *
   * **data type** varchar(255)
   */
  type102: string;

  /**
   * 제원제목102
   *
   * **data type** varchar(255)
   */
  typename102: string;

  /**
   * 제원103
   *
   * **data type** varchar(255)
   */
  type103: string;

  /**
   * 제원제목103
   *
   * **data type** varchar(255)
   */
  typename103: string;

  /**
   * 제원104
   *
   * **data type** varchar(255)
   */
  type104: string;

  /**
   * 제원제목104
   *
   * **data type** varchar(255)
   */
  typename104: string;

  /**
   * 제원105
   *
   * **data type** varchar(255)
   */
  type105: string;

  /**
   * 제원제목105
   *
   * **data type** varchar(255)
   */
  typename105: string;

  /**
   * 제원106
   *
   * **data type** varchar(255)
   */
  type106: string;

  /**
   * 제원제목106
   *
   * **data type** varchar(255)
   */
  typename106: string;

  /**
   * 제원107
   *
   * **data type** varchar(255)
   */
  type107: string;

  /**
   * 제원제목107
   *
   * **data type** varchar(255)
   */
  typename107: string;

  /**
   * 제원108
   *
   * **data type** varchar(255)
   */
  type108: string;

  /**
   * 제원제목108
   *
   * **data type** varchar(255)
   */
  typename108: string;

  /**
   * 제원109
   *
   * **data type** varchar(255)
   */
  type109: string;

  /**
   * 제원제목109
   *
   * **data type** varchar(255)
   */
  typename109: string;

  /**
   * 제원110
   *
   * **data type** varchar(255)
   */
  type110: string;

  /**
   * 제원제목110
   *
   * **data type** varchar(255)
   */
  typename110: string;

  /**
   * 보험사명
   *
   * **data type** varchar(100)
   */
  company_nm: string;

  /**
   * 보험종류
   *
   * **data type** varchar(100)
   */
  insurance_kind: string;

  /**
   * 계약기간-시작일
   *
   * **data type** varchar(8)
   */
  cont_st_de: string;

  /**
   * 종료일
   *
   * **data type** varchar(8)
   */
  cont_en_de: string;

  /**
   * 고장인센티브
   *
   * **data type** varchar(8)
   */
  incentive: string;

  cltnm: string;

  /**
   * 신규인지
   *
   * **data type** varchar(1)
   */
  readonly new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.cltnm = data.cltnm || '';
    this.ordercd = data.ordercd || '';
    this.ordernm = data.ordernm || '';
    this.useyn = data.useyn || '';
    this.equpcd = data.equpcd || '';
    this.equpnm = data.equpnm || '';
    this.contyn = data.contyn || '';
    this.actcd = data.actcd || '';
    this.elno = data.elno || '';
    this.gubun = data.gubun || '';
    this.kind = data.kind || '';
    this.form = data.form || '';
    this.inver = data.inver || '';
    this.evcd_new = data.evcd_new || '';
    this.evcd = data.evcd || '';
    this.jeoban = data.jeoban || '';
    this.class2 = data.class2 || '';
    this.model = data.model || '';
    this.mdnm = data.mdnm || '';
    this.manucd = data.manucd || '';
    this.manunm = data.manunm || '';
    this.number = data.number || '';
    this.number_gr = data.number_gr || '';
    this.number_ungr = data.number_ungr || '';
    this.nperson = data.nperson || '';
    this.height = data.height || '';
    this.spped = data.spped || '';
    this.speednm = data.speednm || '';
    this.sizecd = data.sizecd || '';
    this.kcflag = data.kcflag || '';
    this.remflag = data.remflag || 'N';
    this.remflag = this.remflag === '0' ? 'N' : this.remflag;
    this.kcdate = data.kcdate || '';
    this.dpattcnt = data.dpattcnt || '';
    this.plc = data.plc || '';
    this.attcnt = data.attcnt || '';
    this.yyyymm = data.yyyymm || '';
    this.yyyymm2 = data.yyyymm2 || '';
    this.kcstdate = data.kcstdate || '';
    this.kcenddate = data.kcenddate || '';
    this.totkcdate = data.totkcdate || '';
    this.kcgubun = data.kcgubun || '';
    this.kcresult = data.kcresult || '';
    this.type01 = data.type01 || '';
    this.typename01 = data.typename01 || '';
    this.type02 = data.type02 || '';
    this.typename02 = data.typename02 || '';
    this.type03 = data.type03 || '';
    this.typename03 = data.typename03 || '';
    this.type04 = data.type04 || '';
    this.typename04 = data.typename04 || '';
    this.type05 = data.type05 || '';
    this.typename05 = data.typename05 || '';
    this.type06 = data.type06 || '';
    this.typename06 = data.typename06 || '';
    this.type07 = data.type07 || '';
    this.typename07 = data.typename07 || '';
    this.type08 = data.type08 || '';
    this.typename08 = data.typename08 || '';
    this.type09 = data.type09 || '';
    this.typename09 = data.typename09 || '';
    this.type10 = data.type10 || '';
    this.typename10 = data.typename10 || '';
    this.type11 = data.type11 || '';
    this.typename11 = data.typename11 || '';
    this.type12 = data.type12 || '';
    this.typename12 = data.typename12 || '';
    this.type13 = data.type13 || '';
    this.typename13 = data.typename13 || '';
    this.type14 = data.type14 || '';
    this.typename14 = data.typename14 || '';
    this.type15 = data.type15 || '';
    this.typename15 = data.typename15 || '';
    this.type16 = data.type16 || '';
    this.typename16 = data.typename16 || '';
    this.type17 = data.type17 || '';
    this.typename17 = data.typename17 || '';
    this.type18 = data.type18 || '';
    this.typename18 = data.typename18 || '';
    this.type19 = data.type19 || '';
    this.typename19 = data.typename19 || '';
    this.type20 = data.type20 || '';
    this.typename20 = data.typename20 || '';
    this.type21 = data.type21 || '';
    this.typename21 = data.typename21 || '';
    this.type22 = data.type22 || '';
    this.typename22 = data.typename22 || '';
    this.type23 = data.type23 || '';
    this.typename23 = data.typename23 || '';
    this.type24 = data.type24 || '';
    this.typename24 = data.typename24 || '';
    this.type25 = data.type25 || '';
    this.typename25 = data.typename25 || '';
    this.type26 = data.type26 || '';
    this.typename26 = data.typename26 || '';
    this.type27 = data.type27 || '';
    this.typename27 = data.typename27 || '';
    this.type28 = data.type28 || '';
    this.typename28 = data.typename28 || '';
    this.type29 = data.type29 || '';
    this.typename29 = data.typename29 || '';
    this.type30 = data.type30 || '';
    this.typename30 = data.typename30 || '';
    this.type31 = data.type31 || '';
    this.typename31 = data.typename31 || '';
    this.type32 = data.type32 || '';
    this.typename32 = data.typename32 || '';
    this.type33 = data.type33 || '';
    this.typename33 = data.typename33 || '';
    this.type34 = data.type34 || '';
    this.typename34 = data.typename34 || '';
    this.type35 = data.type35 || '';
    this.typename35 = data.typename35 || '';
    this.type36 = data.type36 || '';
    this.typename36 = data.typename36 || '';
    this.type37 = data.type37 || '';
    this.typename37 = data.typename37 || '';
    this.type38 = data.type38 || '';
    this.typename38 = data.typename38 || '';
    this.type39 = data.type39 || '';
    this.typename39 = data.typename39 || '';
    this.type40 = data.type40 || '';
    this.typename40 = data.typename40 || '';
    this.type41 = data.type41 || '';
    this.typename41 = data.typename41 || '';
    this.type42 = data.type42 || '';
    this.typename42 = data.typename42 || '';
    this.type43 = data.type43 || '';
    this.typename43 = data.typename43 || '';
    this.type44 = data.type44 || '';
    this.typename44 = data.typename44 || '';
    this.type45 = data.type45 || '';
    this.typename45 = data.typename45 || '';
    this.type46 = data.type46 || '';
    this.typename46 = data.typename46 || '';
    this.type47 = data.type47 || '';
    this.typename47 = data.typename47 || '';
    this.type48 = data.type48 || '';
    this.typename48 = data.typename48 || '';
    this.type49 = data.type49 || '';
    this.typename49 = data.typename49 || '';
    this.type50 = data.type50 || '';
    this.typename50 = data.typename50 || '';
    this.type51 = data.type51 || '';
    this.typename51 = data.typename51 || '';
    this.type52 = data.type52 || '';
    this.typename52 = data.typename52 || '';
    this.type53 = data.type53 || '';
    this.typename53 = data.typename53 || '';
    this.type54 = data.type54 || '';
    this.typename54 = data.typename54 || '';
    this.type55 = data.type55 || '';
    this.typename55 = data.typename55 || '';
    this.type56 = data.type56 || '';
    this.typename56 = data.typename56 || '';
    this.type57 = data.type57 || '';
    this.typename57 = data.typename57 || '';
    this.type58 = data.type58 || '';
    this.typename58 = data.typename58 || '';
    this.type59 = data.type59 || '';
    this.typename59 = data.typename59 || '';
    this.type60 = data.type60 || '';
    this.typename60 = data.typename60 || '';
    this.type61 = data.type61 || '';
    this.typename61 = data.typename61 || '';
    this.type62 = data.type62 || '';
    this.typename62 = data.typename62 || '';
    this.type63 = data.type63 || '';
    this.typename63 = data.typename63 || '';
    this.type64 = data.type64 || '';
    this.typename64 = data.typename64 || '';
    this.type65 = data.type65 || '';
    this.typename65 = data.typename65 || '';
    this.type66 = data.type66 || '';
    this.typename66 = data.typename66 || '';
    this.type67 = data.type67 || '';
    this.typename67 = data.typename67 || '';
    this.type68 = data.type68 || '';
    this.typename68 = data.typename68 || '';
    this.type69 = data.type69 || '';
    this.typename69 = data.typename69 || '';
    this.type70 = data.type70 || '';
    this.typename70 = data.typename70 || '';
    this.type71 = data.type71 || '';
    this.typename71 = data.typename71 || '';
    this.type72 = data.type72 || '';
    this.typename72 = data.typename72 || '';
    this.type73 = data.type73 || '';
    this.typename73 = data.typename73 || '';
    this.type74 = data.type74 || '';
    this.typename74 = data.typename74 || '';
    this.type75 = data.type75 || '';
    this.typename75 = data.typename75 || '';
    this.type76 = data.type76 || '';
    this.typename76 = data.typename76 || '';
    this.type77 = data.type77 || '';
    this.typename77 = data.typename77 || '';
    this.type78 = data.type78 || '';
    this.typename78 = data.typename78 || '';
    this.type79 = data.type79 || '';
    this.typename79 = data.typename79 || '';
    this.type80 = data.type80 || '';
    this.typename80 = data.typename80 || '';
    this.type81 = data.type81 || '';
    this.typename81 = data.typename81 || '';
    this.type82 = data.type82 || '';
    this.typename82 = data.typename82 || '';
    this.type83 = data.type83 || '';
    this.typename83 = data.typename83 || '';
    this.type84 = data.type84 || '';
    this.typename84 = data.typename84 || '';
    this.type85 = data.type85 || '';
    this.typename85 = data.typename85 || '';
    this.type86 = data.type86 || '';
    this.typename86 = data.typename86 || '';
    this.type87 = data.type87 || '';
    this.typename87 = data.typename87 || '';
    this.type88 = data.type88 || '';
    this.typename88 = data.typename88 || '';
    this.type89 = data.type89 || '';
    this.typename89 = data.typename89 || '';
    this.type90 = data.type90 || '';
    this.typename90 = data.typename90 || '';
    this.type91 = data.type91 || '';
    this.typename91 = data.typename91 || '';
    this.type92 = data.type92 || '';
    this.typename92 = data.typename92 || '';
    this.type93 = data.type93 || '';
    this.typename93 = data.typename93 || '';
    this.type94 = data.type94 || '';
    this.typename94 = data.typename94 || '';
    this.type95 = data.type95 || '';
    this.typename95 = data.typename95 || '';
    this.type96 = data.type96 || '';
    this.typename96 = data.typename96 || '';
    this.type97 = data.type97 || '';
    this.typename97 = data.typename97 || '';
    this.type98 = data.type98 || '';
    this.typename98 = data.typename98 || '';
    this.type99 = data.type99 || '';
    this.typename99 = data.typename99 || '';
    this.type100 = data.type100 || '';
    this.typename100 = data.typename100 || '';
    this.type101 = data.type101 || '';
    this.typename101 = data.typename101 || '';
    this.type102 = data.type102 || '';
    this.typename102 = data.typename102 || '';
    this.type103 = data.type103 || '';
    this.typename103 = data.typename103 || '';
    this.type104 = data.type104 || '';
    this.typename104 = data.typename104 || '';
    this.type105 = data.type105 || '';
    this.typename105 = data.typename105 || '';
    this.type106 = data.type106 || '';
    this.typename106 = data.typename106 || '';
    this.type107 = data.type107 || '';
    this.typename107 = data.typename107 || '';
    this.type108 = data.type108 || '';
    this.typename108 = data.typename108 || '';
    this.type109 = data.type109 || '';
    this.typename109 = data.typename109 || '';
    this.type110 = data.type110 || '';
    this.typename110 = data.typename110 || '';
    this.company_nm = data.company_nm || '';
    this.insurance_kind = data.insurance_kind || '';
    this.cont_st_de = data.cont_st_de || '';
    this.cont_en_de = data.cont_en_de || '';
    this.incentive = data.incentive || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
