import * as React from 'react';
import {
  Button, ComboBox, ComboBoxModel,
  FlexLayout,
  OptionBox,
  SearchLayout,
  TableLayout,
  TelnumTextBox,
  TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import { Work } from './Work';
import { SearchBinding } from '../../../../models';


/**
 * 화면
 * @window w_tb_e601_sulchi_step
 * @category 설치업무담당등록
 */
export const WorkTemplate: React.FC<TemplateProps<Work>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  return (
    <FlexLayout isVertical={true}>
      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
        style={{ width: 600 }}
      >
        <FlexLayout>
          <span>단계</span>
          <div>
            <OptionBox
              value={scope.state.searchStep}
              data={[
                { value: '%', remark: '전체' },
                { value: '1', remark: '1단계' },
                { value: '2', remark: '2단계' },
                { value: '3', remark: '3단계' },
                { value: '4', remark: '4단계' },
                { value: '5', remark: '5단계' },
              ]}
              onChange={(item) => {
                setData({ searchStep: item.value });
                scope.setState({}, () => scope.onRetrieveEvent());
              }}
              isDisabledTrackingStateChange={true}
            />
          </div>
        </FlexLayout>
      </SearchLayout>
      <FlexLayout>
        <TableLayout
          ref={scope.table}
          header={[
            {
              id: 'step',
              text: '단계',
              width: 20,
              render: (x, rowUpdate) => (
                <ComboBox
                  value={x.step}
                  data={[
                    { value: '1', remark: '1단계' },
                    { value: '2', remark: '2단계' },
                    { value: '3', remark: '3단계' },
                    { value: '4', remark: '4단계' },
                    { value: '5', remark: '5단계' },
                  ].map((y) => new ComboBoxModel(y.value, y.remark))}
                  onSelect={(option) => rowUpdate({ step: option.value })}
                  textAlign='center'
                />
              ),
            },
            {
              id: 'cd',
              text: '업무코드',
              width: 20,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  textAlign="center"
                  ref={ref}
                  value={x.cd}
                  onChange={(value) => rowUpdate({ cd: value })}
                />
              ),
            },
            {
              id: 'nm',
              text: '업무명',
              width: 60,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  textAlign="left"
                  ref={ref}
                  value={x.nm}
                  onChange={(value) => rowUpdate({ nm: value })}
                />
              ),
            },
          ]}
          data={scope.state.data}
          infinityHandler={scope.infinity}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onChange={(rows, updatedRows) => {
            scope.onUpdatedRows(rows, updatedRows);
          }}
          onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
        />

        <FlexLayout isVertical={true}>
          <FlexLayout size={Global.LAYOUT_GRID_HEIGHT_1}>
            <FlexLayout justify="right" weight={3}>
              <Button style={{ height: '30px', width: '60px' }} onClick={() => scope.onNewEvent2()}>추가</Button>
              <Button
                style={{ height: '30px', width: '60px' }}
                theme={BUTTON_THEMES.RED}
                onClick={() => scope.onDeleteEvent2()}
              >삭제</Button>
            </FlexLayout>
          </FlexLayout>

          <FlexLayout>
            <TableLayout
              ref={scope.tableDetail}
              header={[
                {
                  id: 'seq',
                  text: '순서',
                  width: 20,
                },
                {
                  id: 'perid',
                  text: '담당자코드',
                  width: 30,
                  color: 'var(--color-red)',
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      ref={ref}
                      textAlign="center"
                      value={x.perid}
                      onChange={(value) => rowUpdate({ perid: value })}
                      bindSearchModal={new SearchBinding(
                        scope.props.modalStore,
                        'TB_JA001',
                        {},
                        true,
                        (item) => {
                          rowUpdate({
                            perid: item.cd,
                            pernm: item.cdnm,
                          });
                        },
                      )}
                    />
                  ),
                },
                {
                  id: 'pernm',
                  text: '담당자명',
                  width: 30,
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      textAlign="center"
                      ref={ref}
                      value={x.pernm}
                      onChange={(value) => rowUpdate({ pernm: value })}
                    />
                  ),
                },
                {
                  id: 'hpnum',
                  text: '핸드폰번호',
                  width: 50,
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      textAlign="center"
                      ref={ref}
                      value={x.hpnum}
                      onChange={(value) => rowUpdate({ hpnum: value })}
                    />
                  ),
                },
                {
                  id: 'remark',
                  text: '비고',
                  width: 50,
                  render: (x, rowUpdate, ref) => (
                    <TelnumTextBox
                      textAlign="left"
                      ref={ref}
                      value={x.remark}
                      onChange={(value) => rowUpdate({ remark: value })}
                    />
                  ),
                },
              ]}
              data={scope.state.workhumandata}
              infinityHandler={scope.infinity2}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              onChange={(rows2, updatedRows2) => {
                scope.onUpdatedRows2(rows2, updatedRows2);
              }}
              onRowFocusEvent={(item) => scope.onRowFocusEvent2(item)}
            />
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};
