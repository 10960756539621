import * as React from 'react';
import { FiTrash, FiUpload } from 'react-icons/fi';
import {
  Button,
  ComboBox,
  ComboBoxModel,
  FlexLayout,
  FormatNumberTextBox,
  FormatTextBox,
  ImageView,
  LabelBox,
  LayoutTitle,
  LoaderButton,
  OptionBox,
  SearchLayout,
  GridLayout,
  TextBox, CheckBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { ConfirmWarning } from '../../../../utils/confirm';
import { ProductCode, ProductCodeImages, ProductCodeItemChangeTypes } from './ProductCode';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import { SearchBinding } from '../../../../models/common';
import { Format } from '../../../../utils/string';

// const wkactcds = [
//   { value: '001', remark: '자사' },
//   { value: '002', remark: '현대' },
//   { value: '003', remark: '오티스' },
//   { value: '004', remark: '티센' },
//   { value: '005', remark: '미쓰비시' },
// ];

const phm_modes = [
  { value: '001', remark: '엘리베이터' },
  { value: '002', remark: '에스컬레이터' },
  { value: '003', remark: '주차기' },
  { value: '004', remark: '카리프트' },
  { value: '005', remark: '덤웨이터' },
  { value: '006', remark: '화물용' },
  { value: '007', remark: '소모품' },
];

const cgubuns = [
  { value: '', remark: '' },
  { value: '001', remark: '형식승인부품' },
  { value: '002', remark: 'KS부품' },
  { value: '003', remark: '안전인증부품' },
];

const picflags = [
  { value: '0', remark: 'X' },
  { value: '1', remark: 'O' },
];

/**
 * 화면
 * @window w_tb_ca501_01
 * @category 제품코드등록
 */
export const ProductCodeTemplate: React.FC<TemplateProps<ProductCode>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  const model = scope.state.data;
  return (
  <FlexLayout isVertical={true}>
    <SearchLayout
      size={Global.LAYOUT_SEARCH_HEIGHT_1}
    >
      <FlexLayout size={180}>
        <span style={{ width: 60 }}>회사구분</span>
        <ComboBox
          value={scope.state.searchWkactcd}
          data={[
            { value: '%', remark: '전체' },
            { value: '001', remark: '자사' },
            { value: '002', remark: '현대' },
            { value: '003', remark: '오티스' },
            { value: '004', remark: '티센' },
            { value: '005', remark: '미쓰비시' },
          ].map((x) => new ComboBoxModel(x.value, x.remark))}
          onSelect={(option) => scope.setState({ searchWkactcd: option.value },
            () => scope.onRetrieveEvent())}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      <FlexLayout size={430}>
        <span>검색어(품명)</span>
        <TextBox
          placeholderColor="#CACACA"
          placeholderAlign="left"
          placeholder="(검색어품명) 품명, 품명(한글명), 특이사항으로 검색하세요"
          value={scope.state.searchQuery}
          onChange={(value) => {
            scope.initFocusIndex();
            scope.setState({ searchQuery: value });
          }}
          onEnter={() => scope.onRetrieveEvent()}
        />
      </FlexLayout>

      <FlexLayout size={360}>
        <span style={{ marginLeft: 44 }}>규격</span>
        <TextBox
          placeholderColor="#CACACA"
          placeholderAlign="left"
          placeholder="(규격) 규격으로 검색하세요"
          value={scope.state.searchQuery2}
          onChange={(value) => scope.setState({ searchQuery2: value })}
          onEnter={() => scope.onRetrieveEvent()}
        />
      </FlexLayout>

      <FlexLayout size={180}>
        <span style={{ marginLeft: 2 }}>재고관리</span>
        <ComboBox
          style={{
            padding: '0 0 0 6px',
            fontSize: 12,
          }}
          value={scope.state.mtyn}
          data={[
            { value: '%', remark: '전체' },
            { value: '1', remark: '유' },
            { value: '0', remark: '무' },
          ].map((x) => new ComboBoxModel(x.value, x.remark))}
          onSelect={(option) => scope.setState({ mtyn: option.value })}
        />
      </FlexLayout>

      <FlexLayout size={460}>
        <span style={{ color: 'var(--color-red)' }}>주거래처</span>
        <TextBox
          textAlign="center"
          value={scope.state.cltcd}
          onChange={(value) => scope.setState({ cltcd: value })}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_XCLIENT',
            {},
            true,
            (item) => {
              scope.setState({ cltcd: item.cd, cltnm: item.cdnm }, () => scope.onRetrieveEvent());
            },
          )}
        />
        <TextBox
          weight={1.5}
          textAlign="left"
          value={scope.state.cltnm}
          onChange={(value) => scope.SS({ cltnm: value })}
          readonly={true}
        />
      </FlexLayout>
    </SearchLayout>

    <SearchLayout
      size={Global.LAYOUT_SEARCH_HEIGHT_1}
      isSub={true}
    >
      <FlexLayout size={610}>
        <span style={{ marginLeft: 48 }}>선택</span>
        <ComboBox
          value={scope.state.searchChoice}
          style={{
            padding: '0 0 0 6px',
            fontSize: 12,
          }}
          data={scope.state.choices?.map((y) => new ComboBoxModel(y.chcd, y.chnm))}
          onSelect={(option) => scope.setState({
            searchChoice: option.value,
            searchPhm_mode: option.value === '%' ? '%' : option.value.substr(0, 3),
            searchAgrb: option.value === '%' ? '%' : option.value.substr(3, 2),
            searchBgrb: option.value === '%' ? '%' : option.value.substr(5, 2),
            searchCgrb: option.value === '%' ? '%' : option.value.substr(7, 2),
          },
          () => scope.onFirstOpenEvent())}
        />
      </FlexLayout>

      <FlexLayout size={180}>
        <span>품목분류</span>
        <ComboBox
          value={scope.state.searchPhm_mode}
          style={{
            padding: '0 0 0 6px',
            fontSize: 12,
          }}
          data={scope.state.phm_modes?.map((y) => new ComboBoxModel(y.phm_mode, y.phm_modenm))}
          onSelect={(option) => scope.setState({ searchPhm_mode: option.value },
            () => scope.onFirstOpenEvent())}
        />
      </FlexLayout>

      <FlexLayout size={180}
        onClick={() => {
          if (scope.state.searchPhm_mode === '%') {
            ConfirmWarning.show('경고', '먼저 품목분류를 선택하세요!');
          }
        }}>
        <span>대분류</span>
        <ComboBox
          value={scope.state.searchAgrb}
          style={{
            padding: '0 0 0 6px',
            fontSize: 12,
          }}
          data={scope.state.agrbs?.map((y) => new ComboBoxModel(y.pgr_hcod, y.pgr_hnam))}
          onSelect={(option) => scope.setState({ searchAgrb: option.value },
            () => scope.onFirstOpenEvent())}
        />
      </FlexLayout>

      <FlexLayout size={180} onClick={() => {
        if (scope.state.searchAgrb === '%') {
          ConfirmWarning.show('경고', '먼저 대분류를 선택하세요!');
        }
      }}>
        <span>중분류</span>
        <ComboBox
          value={scope.state.searchBgrb}
          style={{
            padding: '0 0 0 6px',
            fontSize: 12,
          }}
          data={scope.state.bgrbs?.map((y) => new ComboBoxModel(y.bgroup, y.bgrpnm))}
          onSelect={(option) => scope.setState({ searchBgrb: option.value },
            () => scope.onFirstOpenEvent())}
        />
      </FlexLayout>

      <FlexLayout size={220} onClick={() => {
        if (scope.state.searchBgrb === '%') {
          ConfirmWarning.show('경고', '먼저 중분류를 선택하세요!');
        }
      }}>
        <span style={{ marginLeft: 30 }}>소분류</span>
        <ComboBox
          value={scope.state.searchCgrb}
          style={{
            padding: '0 0 0 6px',
            fontSize: 12,
          }}
          data={scope.state.cgrbs?.map((y) => new ComboBoxModel(y.cgroup, y.cgrpnm))}
          onSelect={(option) => scope.setState({ searchCgrb: option.value },
            () => scope.onFirstOpenEvent())}
        />
      </FlexLayout>
      <FlexLayout size={240}>
        <span>설치제품</span>
        <ComboBox
            value={scope.state.sulchi}
            data={[
              { value: '%', remark: '전체제품' },
              { value: '1', remark: '설치제품' },
            ].map((x) => new ComboBoxModel(x.value, x.remark))}
            onSelect={(option) => scope.setState({ sulchi: option.value },
              () => scope.onRetrieveEvent())}
            isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
    </SearchLayout>

    <GridLayout
      ref={scope.table}
      header={[
        {
          id: 'wkactcd',
          text: '회사구분',
          width: 80,
          render: (x) => (
            <FlexLayout
              align='center'
              justify='center'
            >
              {scope.state.wkactcds?.filter((y) => y.wkactcd === x.wkactcd)[0]?.wkactnm}
            </FlexLayout>
          ),
        },
        {
          id: 'picflag',
          text: '사진',
          width: 50,
          render: (x) => (
            <FlexLayout
              align='center'
              justify='center'
              style={{ color: x.picflag === '1' ? 'var(--color-blue)' : 'var(--color-red)' }}
            >
              {picflags.filter((y) => y.value === x.picflag)[0]?.remark}
            </FlexLayout>
          ),
        },
        {
          id: 'phm_pcod',
          text: '제품코드',
          width: 80,
        },
        {
          id: 'phm_pnam',
          text: '품목명',
          width: 250,
          render: (x) => (
            <FlexLayout align="center" justify="left">
              {x.phm_pnam}
            </FlexLayout>
          ),
        },
        {
          id: 'phm_knam',
          text: '품목명(한글명)',
          width: 160,
          render: (x) => (
            <FlexLayout align="center" justify="left">
              {x.phm_knam}
            </FlexLayout>
          ),
        },
        {
          id: 'phm_size',
          text: '규격',
          width: 230,
          render: (x) => (
            <FlexLayout align="center" justify="left">
              {x.phm_size}
            </FlexLayout>
          ),
        },
        {
          id: 'psize',
          text: '크기/치수',
          width: 80,
        },
        {
          id: 'phm_unit',
          text: '단위',
          width: 60,
        },
        {
          id: 'point',
          text: '주요',
          width: 60,
          render: (x) => (
            <FlexLayout align="center" justify="center">
              {x.point === '1' ? '유' : '무'}
            </FlexLayout>
          ),
        },
        {
          id: 'pmodel',
          text: '기종',
          width: 60,
        },
        {
          id: 'phm_mamt',
          text: '구매단가',
          width: 100,
          render: (x) => (
            <FlexLayout align="center" justify="right">
              {Format.number(x.phm_mamt)}
            </FlexLayout>
          ),
        },
        {
          id: 'phm_samt',
          text: '출고단가',
          width: 100,
          render: (x) => (
            <FlexLayout align="center" justify="right">
              {Format.number(x.phm_samt)}
            </FlexLayout>
          ),
        },
        {
          id: 'phm_jqty',
          text: '적정재고',
          width: 60,
        },
        {
          id: 'jqty',
          text: '현 재고',
          width: 60,
        },
        {
          id: 'phm_mode',
          text: '품목분류',
          width: 80,
          render: (x) => (
            <FlexLayout
              align='center'
              justify='center'
            >
              {phm_modes.filter((y) => y.value === x.phm_mode)[0]?.remark}
            </FlexLayout>
          ),
        },
        {
          id: 'phm_sgrbnm',
          text: '대분류',
          width: 80,
        },
        {
          id: 'bgroupnm',
          text: '중분류',
          width: 80,
        },
        {
          id: 'cgroupnm',
          text: '소분류',
          width: 80,
          render: (x) => (
            <FlexLayout align="center" justify="left">
              {x.cgroupnm}
            </FlexLayout>
          ),
        },
        {
          id: 'phm_pgun',
          text: '품목구분',
          width: 60,
        },
        {
          id: 'type',
          text: '품질검사유형',
          width: 80,
        },
        {
          id: 'age',
          text: '부품수명',
          width: 60,
        },
        {
          id: 'store',
          text: '보관창고',
          width: 100,
          render: (x) => (
            <FlexLayout
              align='center'
              justify='center'
            >
              {scope.state.store?.filter((y) => y.store === x.store)[0]?.storenm}
            </FlexLayout>
          ),
        },
        {
          id: 'cgubun',
          text: '안전검사구분',
          width: 100,
          render: (x) => (
            <FlexLayout
              align='center'
              justify='center'
            >
              {cgubuns.filter((y) => y.value === x.cgubun)[0]?.remark}
            </FlexLayout>
          ),
        },
        {
          id: 'jugi',
          text: '실사주기',
          width: 60,
        },
        {
          id: 'quality',
          text: '특이사항',
          width: 300,
        },
      ]}
      data={scope.state.productCodeList}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      infinityHandler={scope.infinity}
      onRowClick={(item, index) => scope.onRowFocusEvent(item, index)}
    />

    <FlexLayout>
      <FlexLayout isVertical={true} weight={3}>
        <FlexLayout size={Global.LAYOUT_GRID_HEIGHT_1}>
          <SearchLayout
            style={{ width: 1000 }}
          >
            <FlexLayout size={800} onClick={() => {
              if (!scope.state.data.isNew) {
                ConfirmWarning.show('경고', '신규를 누르고 선택하세요!');
              }
            }}>
              <span style={{ width: 60 }}>선택</span>
              <ComboBox
                textAlign="left"
                value={model?.choice2}
                style={{
                  padding: '0 0 0 6px',
                  fontSize: 12,
                }}
                data={scope.state.choices2?.map((y) => new ComboBoxModel(y.chcd, y.chnm))}
                onSelect={(option) => setData({
                  choice2: option.value,
                },
                () => scope.itemChanged(option.value, ProductCodeItemChangeTypes.CHOICE))}
                readonly={(model?.isNew && model.wkactcd !== '001') || (model?.wkactcd !== '001')
                || (scope.state.open && model?.wkactcd !== '001')
                || (scope.state.firstOpen)}
              />
              <span style={{ width: 230, color: 'var(--color-red)', paddingTop: 13 }}>
                &nbsp;자사 신규입력시 여기를 먼저 선택하세요!
              </span>
             </FlexLayout>
          </SearchLayout>
        </FlexLayout>

        <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
          <LabelBox title="회사구분" isNeed={true}>
            <ComboBox
              value={model.wkactcd}
              data={scope.state.wkactcds?.map((x) => new ComboBoxModel(x.wkactcd, x.wkactnm))}
              onSelect={(option) => setData({ wkactcd: option.value })}
              readonly={model.new !== '1'}
            />
          </LabelBox>

          <LabelBox title="품목코드" isNeed={true}>
            <TextBox
              value={model.phm_pcod}
              textAlign="center"
              onChange={(value) => setData({ phm_pcod: value },
                () => scope.itemChanged(value, ProductCodeItemChangeTypes.PHM_PCOD))}
              readonly={model.new !== '1'}
            />
          </LabelBox>

          <LabelBox title="품목명" weight={2} isNeed={true}>
            <TextBox
              value={model.phm_pnam}
              onChange={(value) => setData({ phm_pnam: value })}
            />
          </LabelBox>

          <LabelBox title="품목명(한글)" weight={2}>
            <TextBox
              value={model.phm_knam}
              onChange={(value) => setData({ phm_knam: value })}
            />
          </LabelBox>
        </FlexLayout>

        <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
          <LabelBox title="기종(모델)">
            <TextBox
              textAlign="center"
              value={model.pmodel}
              onChange={(value) => setData({ pmodel: value })}
            />
          </LabelBox>

          <LabelBox title="단위">
            <TextBox
              textAlign="center"
              value={model.phm_unit}
              onChange={(value) => setData({ phm_unit: value })}
            />
          </LabelBox>

          <LabelBox title="규격" weight={2}>
            <TextBox
              value={model.phm_size}
              onChange={(value) => setData({ phm_size: value })}
            />
          </LabelBox>

          <LabelBox title="크기/치수" weight={2}>
            <TextBox
              value={model.psize}
              onChange={(value) => setData({ psize: value })}
            />
          </LabelBox>
        </FlexLayout>

        <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
          <LabelBox title="보관창고" style={{ color: 'var(--color-red)' }}>
            <TextBox
              textAlign="center"
              value={model.store}
              onChange={(value) => setData({ store: value })}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_CA504',
                {},
                true,
                (item) => {
                  setData({
                    store: item.cd,
                    storenm: item.cdnm,
                  });
                },
              )}
            />

            <TextBox
              value={model.storenm}
              textAlign="center"
              readonly={true}
              onChange={(value) => setData({ storenm: value })}
            />
          </LabelBox>

          <LabelBox title="제작사">
            <ComboBox
              value={model.manucd}
              data={scope.state.manucds?.map((x) => new ComboBoxModel(x.manucd, x.manunm))}
              onSelect={(option) => setData({
                manucd: option.value,
                manunm: option.value,
              })}
            />
          </LabelBox>

          <LabelBox title="품목구분">
            <ComboBox
              value={model.phm_pgun}
              data={[
                { value: 'M', remark: '원자재' },
                { value: 'L', remark: '제품' },
              ].map((x) => new ComboBoxModel(x.value, x.remark))}
              onSelect={(option) => setData({ phm_pgun: option.value })}
            />
          </LabelBox>

          <LabelBox title="적정재고" weight={0.5}>
            <TextBox
              value={model.phm_jqty}
              textAlign="center"
              onChange={(value) => setData({ phm_jqty: value })}
            />
          </LabelBox>

          <LabelBox title="현 재고" weight={0.5}>
            <TextBox
              value={model.jqty}
              readonly={true}
              textAlign="center"
              onChange={(value) => setData({ jqty: value })}
            />
          </LabelBox>

          <LabelBox title="주요자재">
            <OptionBox
              value={model.point}
              data={[
                { value: '1', remark: '주소요자재' },
                { value: '0', remark: '비소요자재' },
              ]}
              onChange={(item) => setData({ point: item.value })}
            />
          </LabelBox>

          <LabelBox title="재고관리">
            <OptionBox
              value={model.mtyn}
              data={[
                { value: '1', remark: '유' },
                { value: '0', remark: '무' },
              ]}
              onChange={(item) => setData({ mtyn: item.value })}
            />
          </LabelBox>
        </FlexLayout>

        <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
          <LabelBox title="물픔분류">
            <ComboBox
              value={model.phm_mode}
              style={{
                padding: '0 0 0 6px',
                fontSize: 12,
              }}
              data={scope.state.phm_modes?.map((y) => new ComboBoxModel(y.phm_mode, y.phm_modenm))}
              onSelect={(option) => setData({ phm_mode: option.value })}
            />
          </LabelBox>

          <LabelBox title="대분류">
            <TextBox
              value={model.phm_sgrbnm}
              textAlign="center"
              readonly={true}
              onChange={(value) => setData({ phm_sgrbnm: value })}
            />
          </LabelBox>

          <LabelBox title="중분류">
            <TextBox
              value={model.bgroupnm}
              textAlign="center"
              readonly={true}
              onChange={(value) => setData({ bgroupnm: value })}
            />
          </LabelBox>

          <LabelBox title="소분류">
            <TextBox
              value={model.cgroupnm}
              textAlign="center"
              readonly={true}
              onChange={(value) => setData({ cgroupnm: value })}
            />
          </LabelBox>

          <LabelBox
            title="부품수명"
            footer={<FlexLayout>
              {model.age}일
            </FlexLayout>}
          >
            <FormatTextBox
              format="###년"
              weight={0.7}
              textAlign="right"
              value={model.age_year}
              onChange={(value) => setData({ age_year: value, itemCheck: '4' },
                () => scope.itemChanged(value, ProductCodeItemChangeTypes.AGE_YEAR))}
            />
            <FormatTextBox
              format="##개월"
              weight={0.7}
              textAlign="right"
              value={model.age_month}
              onChange={(value) => setData({ age_month: value },
                () => scope.itemChanged(value, ProductCodeItemChangeTypes.AGE_MONTH))}
            />
          </LabelBox>

          <LabelBox title="비고(도번/바코드출력비고)">
            <TextBox
              value={model.quality}
              onChange={(value) => setData({ quality: value })}
            />
          </LabelBox>
        </FlexLayout>

        <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
          <LabelBox title="주구매처" style={{ color: 'var(--color-red)' }}>
            <TextBox
              textAlign="center"
              value={model.cltcd}
              onChange={(value) => setData({ cltcd: value })}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_XCLIENT',
                {},
                true,
                (item) => {
                  setData({
                    cltcd: item.cd,
                    cltnm: item.cdnm,
                  });
                },
              )}
            />
            <TextBox
              value={model.cltnm}
              readonly={true}
              onChange={(value) => setData({ cltnm: value })}
            />
          </LabelBox>

          <LabelBox title="매입단가">
            <FormatNumberTextBox
              char={','}
              charAt={3}
              textAlign="right"
              // value={scope.props.publicStore.isContPermitted ? '' : model.phm_mamt}
              value={model.phm_mamt}
              onChange={(value) => setData({ phm_mamt: value })}
              readonly={!scope.props.publicStore.isContPermitted}
            />
          </LabelBox>

          <LabelBox title="판매단가">
            <FormatNumberTextBox
              char={','}
              charAt={3}
              textAlign="right"
              value={model.phm_samt}
              onChange={(value) => setData({ phm_samt: value })}
            />
          </LabelBox>

          <LabelBox title="설치관련제품">
            <CheckBox
              value={model?.sulchi === '1'}
              onChange={(value) => {
                setData({ sulchi: value ? '1' : '0' });
              }}
            />
          </LabelBox>

          <LabelBox title="일련번호">
            <TextBox
              value={model.remarks}
              onChange={(value) => setData({ remarks: value })}
            />
          </LabelBox>

          <LabelBox title="사용유무">
            <OptionBox
              value={model.useyn}
              data={[
                { value: '1', remark: '사용' },
                { value: '0', remark: '미사용' },
              ]}
              onChange={(item) => setData({ useyn: item.value })}
            />
          </LabelBox>
        </FlexLayout>
      </FlexLayout>

      <FlexLayout isVertical={true}>
        <LayoutTitle size={Global.LAYOUT_GRID_HEIGHT_1}>품목사진1</LayoutTitle>
        <FlexLayout onDoubleClick={() => scope.onPrintEventImg(0)}>
          <ImageView
            src={scope.state.img_cust}
            footer={<React.Fragment>
              <LoaderButton
                isFull={true}
                onClick={() => scope.imageSelect(ProductCodeImages.CUST)}
                percent={scope.state.percentImageCust}
              >
                <FiUpload />
                <span>등록</span>
              </LoaderButton>

              <Button
                isFull={true}
                theme={BUTTON_THEMES.RED}
                onClick={() => scope.imageDelete(ProductCodeImages.CUST)}
              >
                <FiTrash/>
                <span>삭제</span>
              </Button>
            </React.Fragment>}
          />
        </FlexLayout>
      </FlexLayout>
      <FlexLayout isVertical={true}>
        <LayoutTitle size={Global.LAYOUT_GRID_HEIGHT_1}>품목사진2</LayoutTitle>
        <FlexLayout onDoubleClick={() => scope.onPrintEventImg(1)}>
          <ImageView
            src={scope.state.img_seal}
            footer={<React.Fragment>
              <LoaderButton
                isFull={true}
                onClick={() => scope.imageSelect(ProductCodeImages.SEAL)}
                percent={scope.state.percentImageSeal}
              >
                <FiUpload />
                <span>등록</span>
              </LoaderButton>

              <Button
                isFull={true}
                theme={BUTTON_THEMES.RED}
                onClick={() => scope.imageDelete(ProductCodeImages.SEAL)}
              >
                <FiTrash/>
                <span>삭제</span>
              </Button>
            </React.Fragment>}
          />
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  </FlexLayout>
  );
};
