import * as React from 'react';

import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import {
  DateTextBox,
  FlexLayout,
  GridLayout,
  SearchLayout,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { MonthlyMaterial } from './MonthlyMaterial';
import {
  Date8,
} from '../../../../utils/time';
import { Format } from '../../../../utils/string';

/**
 * 화면
 * @window w_tb_da034w_02
 * @category 월별자재마감
 */

export const MonthlyMaterialTemplate: React.FC<TemplateProps<MonthlyMaterial>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <FlexLayout size={200}>
        <span>기간</span>
        <DateTextBox
          value={scope.state.stdate}
          textAlign="center"
          format="####-##-##"
          onChange={(value) => scope.setState({ stdate: value })}
          onEnter={() => scope.onRetrieveEvent()}
          head={(<button
            onClick={() => {
              const date = new Date(Date8.withDash(scope.state.stdate));
              date.setDate(date.getDate() - 1);
              scope.SS({ stdate: Date8.make(date) });
            }}
          >
            <MdKeyboardArrowLeft size={24}/>
          </button>)}
          trail={(<button
            onClick={() => {
              const date = new Date(Date8.withDash(scope.state.stdate));
              date.setDate(date.getDate() + 1);
              scope.SS({ stdate: Date8.make(date) });
            }}
          >
            <MdKeyboardArrowRight size={24}/>
          </button>)}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>

      <FlexLayout size={180}>
        <span style={{ marginLeft: 8 }}>~</span>
        <DateTextBox
          value={scope.state.enddate}
          textAlign="center"
          format="####-##-##"
          onChange={(value) => scope.setState({ enddate: value })}
          onEnter={() => scope.onRetrieveEvent()}
          head={(<button
            onClick={() => {
              const date = new Date(Date8.withDash(scope.state.enddate));
              date.setDate(date.getDate() - 1);
              scope.SS({ enddate: Date8.make(date) });
            }}
          >
            <MdKeyboardArrowLeft size={24}/>
          </button>)}
          trail={(<button
            onClick={() => {
              const date = new Date(Date8.withDash(scope.state.enddate));
              date.setDate(date.getDate() + 1);
              scope.SS({ enddate: Date8.make(date) });
            }}
          >
            <MdKeyboardArrowRight size={24}/>
          </button>)}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
    </SearchLayout>

    <FlexLayout>
      <FlexLayout weight={1.6}>
        <GridLayout
          ref={scope.grid}
          data={scope.state.leftData}
          header={[
            {
              id: 'spjangcdnm',
              text: '사업장명',
              width: 100,
              render: (x) => (
                <FlexLayout
                  align="center"
                  justify="left"
                >
                  {x.spjangcdnm}
                </FlexLayout>
              ),
            },
            {
              id: 'samt_u',
              text: '불출-유상',
              width: 100,
              render: (x) => (
                <FlexLayout
                  align="center"
                  justify="right"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {Format.number(x.samt_u)}
                </FlexLayout>
              ),
            },
            {
              id: 'iamt',
              text: '입고',
              width: 100,
              render: (x) => (
                <FlexLayout
                  align="center"
                  justify="right"
                  style={{ color: 'var(--color-red)' }}
                >
                  {Format.number(x.iamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'samt_fm',
              text: '불출-FM',
              width: 100,
              render: (x) => (
                <FlexLayout
                  align="center"
                  justify="right"
                >
                  {Format.number(x.samt_fm)}
                </FlexLayout>
              ),
            },
            {
              id: 'samt_m',
              text: '불출-무상',
              width: 100,
              render: (x) => (
                <FlexLayout
                  align="center"
                  justify="right"
                >
                  {Format.number(x.samt_m)}
                </FlexLayout>
              ),
            },
            {
              id: 'iamt_m',
              text: '무상입고',
              width: 100,
              render: (x) => (
                <FlexLayout
                  align="center"
                  justify="right"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {Format.number(x.iamt_m)}
                </FlexLayout>
              ),
            },
            {
              id: 'totamt',
              text: '이익금(수익)',
              width: 100,
              render: (x) => (
                <FlexLayout
                  align="center"
                  justify="right"
                  style={{ color: 'var(--color-violet)' }}
                >
                  {Format.number(x.totamt)}
                </FlexLayout>
              ),
            },
          ]}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        >
        </GridLayout>
      </FlexLayout>

      <FlexLayout>
        <GridLayout
          ref={scope.grid2}
          data={scope.state.rightData}
          header={[
            {
              id: 'seq',
              text: '순번',
              width: 100,
              render: (x) => (
                <FlexLayout
                  align="center"
                  justify="center"
                >
                  {x.seq}
                </FlexLayout>
              ),
            },
            {
              id: 'gubunnm',
              text: '구분',
              width: 100,
              render: (x) => (
                <FlexLayout
                  align="center"
                  justify="center"
                >
                  {x.gubunnm}
                </FlexLayout>
              ),
            },
            {
              id: 'amt_be',
              text: `전월 (${Date8.withDashMon(scope.state.rightData[0]?.bemonnm)})`,
              width: 100,
              render: (x) => (
                <FlexLayout
                  align="center"
                  justify="right"
                  style={{ color: 'var(--color-green)' }}
                >
                  {Format.number(x.amt_be)}
                </FlexLayout>
              ),
            },
            {
              id: 'amt',
              text: `당월 (${Date8.withDashMon(scope.state.rightData[0]?.monnm)})`,
              width: 100,
              render: (x) => (
                <FlexLayout
                  align="center"
                  justify="right"
                >
                  {Format.number(x.amt)}
                </FlexLayout>
              ),
            },
            {
              id: 'yul',
              text: '증감사항',
              width: 100,
              render: (x) => (
                <FlexLayout
                  align="center"
                  justify="right"
                  style={Number(x.yul) > 0 ? { color: 'var(--color-blue)' } : { color: 'var(--color-red)' }}
                >
                  {x.yul} %
                </FlexLayout>
              ),
            },
          ]}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        >
        </GridLayout>
      </FlexLayout>
    </FlexLayout>
  </FlexLayout>
);
