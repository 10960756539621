import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import {
  DateTextBox,
  FlexLayout,
  GridLayout,
  SearchLayout,
  TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { JournalStatement } from './JournalStatement';
import { SearchBinding } from '../../../../models/common';
import { Date6Calculator } from '../../../../utils/time';
import { Range } from '../../../../utils/array';

/**
 * 화면
 * @window w_tb_e037w
 * @category 업무일지작성현황
 */

export const JournalStatementTemplate: React.FC<TemplateProps<JournalStatement>> = ({
  scope,
}) => (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout size={200}>
          <span>기간</span>
          <DateTextBox
            value={scope.state.stmon}
            textAlign="center"
            format="####-##"
            onChange={
              (value) => {
                scope.setState({ stmon: value },
                  () => scope.onRetrieveEvent());
              }
            }
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                scope.setState({
                  stmon: new Date6Calculator(scope.state.stmon).add(-1).toString(),
                },
                () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                scope.setState({
                  stmon: new Date6Calculator(scope.state.stmon).add().toString(),
                },
                () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={250}>
          <span style={{ color: 'var(--color-red)' }}>부서</span>
          <TextBox
            value={scope.state.divicd}
            textAlign="center"
            onChange={(value) => scope.setState({ divicd: value })}
            onEnter={() => scope.onRetrieveEvent()}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JC002',
              {},
              true,
              (item) => {
                scope.setState({
                  divicd: item.cd,
                  divinm: item.cdnm,
                }, () => scope.onRetrieveEvent());
              },
            )}
            isDisabledTrackingStateChange={true}
          />
          <TextBox
            value={scope.state.divinm}
            weight={2}
            textAlign="center"
            isDisabledTrackingStateChange={true}
            readonly={true}
          />
        </FlexLayout>
        <FlexLayout size={250}>
          <span style={{ color: 'var(--color-red)' }}>사용자</span>
          <TextBox
            value={scope.state.perid}
            textAlign="center"
            onChange={(value) => scope.setState({ perid: value })}
            onEnter={() => scope.onRetrieveEvent()}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JA001',
              {},
              true,
              (item) => {
                scope.setState({
                  perid: item.cd,
                  pernm: item.cdnm,
                }, () => scope.onRetrieveEvent());
              },
            )}
            isDisabledTrackingStateChange={true}
          />
          <TextBox
            value={scope.state.pernm}
            weight={1.5}
            textAlign="center"
            isDisabledTrackingStateChange={true}
            readonly={true}
          />
        </FlexLayout>
      </SearchLayout>

      <FlexLayout isVertical={true}>
        <GridLayout
          ref={scope.grid}
          headerHeight={Global.LAYOUT_SEARCH_HEIGHT_1}
          isScroll={true}
          header={[
            {
              id: 'seq',
              text: 'No',
              width: 40,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.seq}
                </FlexLayout>
              ),
            },
            {
              id: 'divinm',
              text: '부서명',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  합계
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.divinm}
                </FlexLayout>
              ),
            },
            {
              id: 'pernm',
              text: '사원명',
              width: 80,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.pernm}
                </FlexLayout>
              ),
            },
            {
              id: 'rspnm',
              text: '직위',
              width: 80,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.rspnm}
                </FlexLayout>
              ),
            },
            {
              id: 'totkm',
              text: '이동거리(km)',
              width: 80,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.totkm}
                </FlexLayout>
              ),
            },
            {
              id: 'deday',
              text: '기준일',
              width: 80,
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {scope.state.deday_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.deday === '0' ? '' : x.deday}
                </FlexLayout>
              ),
            },
            {
              id: 'totday',
              text: '작성일',
              width: 80,
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {scope.state.today_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-blue)' }}
                >
                  {x.totday === '0' ? '' : x.totday}
                </FlexLayout>
              ),
            },
            {
              id: 'totnotday',
              text: '미작성일',
              width: 80,
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {scope.state.totnotday_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: 'var(--color-red)' }}
                >
                  {x.totnotday === '0' ? '' : x.totnotday}
                </FlexLayout>
              ),
            },
            // 1~31일
            ...Range.make(31).map((key) => (
              {
                id: `date${key + 1}`,
                text:
                  <FlexLayout
                    style={{
                      // @ts-ignore
                      color: scope.state.journalStatementList[0]?.[`weekday${key + 1}`] === '토'
                        // @ts-ignore
                        ? 'var(--color-blue)' : scope.state.journalStatementList[0]?.[`weekday${key + 1}`] === '일'
                          ? 'var(--color-red)' : 'var(--color-black)',
                    }}
                    isVertical={true}
                  >
                    <FlexLayout>
                      {key + 1}
                    </FlexLayout>
                    <FlexLayout style={{ marginTop: -20 }}>
                      {/* @ts-ignore */}
                      {scope.state.journalStatementList[0]?.[`weekday${key + 1}`]}
                    </FlexLayout>
                  </FlexLayout>,
                width: 34,
                render: (x: any) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    isVertical={true}
                  >
                    <FlexLayout style={{
                      color:
                      // @ts-ignore
                        scope.state.journalStatementList[x.seq - 1]?.[`date${key + 1}`] === '0' ? 'var(--color-blue)'
                          // @ts-ignore
                          : scope.state.journalStatementList[x.seq - 1]?.[`date${key + 1}`] === 'X' ? 'var(--color-red)'
                            // @ts-ignore
                            // eslint-disable-next-line max-len
                            : scope.state.journalStatementList[x.seq - 1]?.[`date${key + 1}`] === '휴일' ? 'var(--color-orange)'
                              : 'var(--color-olive)',
                    }}>
                      {/* @ts-ignore */}
                      {scope.state.journalStatementList[x.seq - 1]?.[`date${key + 1}`]}
                    </FlexLayout>
                    {/* @ts-ignore */}
                    <FlexLayout>{scope.state.journalStatementList[x.seq - 1]?.[`km${key + 1}`]}</FlexLayout>
                  </FlexLayout>
                ),
              }
            )),
          ]}
          data={scope.state.journalStatementList}
          infinityHandler={scope.infinity}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onRowClick={(item) => scope.onRowFocusEvent(item)}
          onHorizontalScroll={(x) => scope.additionalTitle.current?.scrollTo(x)}
        />
      </FlexLayout>
    </FlexLayout>
);
