import * as React from 'react';
import { FaBell } from 'react-icons/fa';
import { FiArrowRightCircle, FiRefreshCcw } from 'react-icons/fi';

import {
  Button,
  CheckBox,
  ComboBox,
  ComboBoxModel,
  DefaultLayout,
  FlexLayout,
  FormatNumberTextBox,
  FormatTextBox,
  GridLayout,
  LabelBox,
  LayoutTitle,
  ModalView,
  OptionBox,
  SearchLayout,
  SubHeader,
  TableLayout,
  TelnumTextBox,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { AddressButton } from '../../../../components/forms/AddressButton';
import {
  Resister,
  ResisterItemChangeTypes,
} from './Resister';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import { style } from './Resister.module.scss';
import { ResisterModel } from './Resister.model';
import { Date8 } from '../../../../utils/time';
/**
 * 화면
 * @window w_tb_xclient
 * @category 거래처등록
 */
export const ResisterTemplate: React.FC<TemplateProps<Resister>> = ({
  scope,
  update,
  dataUpdate,
}) => {
  const model = scope.state.data;
  const setData = update!;
  const setData2 = dataUpdate!;

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
      >
        <FlexLayout size={700}>
          <span>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="거래처, 사업자번호, 주민(법인)번호, 대표자명, 전화번호, 핸드폰번호, 주소, 비고로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => setData2({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
          />
        </FlexLayout>
        <FlexLayout size={300}>
          <span>구분</span>
          <ComboBox
            value={scope.state.clttype}
            data={[
              { value: '%', remark: '전체' },
              { value: '1', remark: '매입' },
              { value: '2', remark: '매출' },
              { value: '3', remark: '공통' },
            ].map((y) => new ComboBoxModel(y.value, y.remark))}
            onSelect={(option) => setData2({ clttype: option.value })}
          />
        </FlexLayout>
        <FlexLayout size={165}>
          <span>장기미수거래처</span>
          <div>
            <CheckBox
              value={scope.state.longmis === '1'}
              onChange={(value) => setData2({ longmis: value ? '1' : '0' })}
            />
          </div>
        </FlexLayout>
      </SearchLayout>

      <FlexLayout>
        <GridLayout
          ref={scope.grid}
          header={[
            {
              id: 'cltcd',
              text: '코드',
              width: 20,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.cltcd}
                </FlexLayout>
              ),
            },
            {
              id: 'cltnm',
              text: '거래처명',
              width: 45,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.cltnm}
                </FlexLayout>
              ),
            },
            {
              id: 'saupnum2',
              text: '사업자번호',
              width: 35,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.saupnum2}
                </FlexLayout>
              ),
            },
          ]}
          data={scope.state.resisterData}
          infinityHandler={scope.infinity}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onRowClick={(item, index) => scope.onRowFocusEvent(item, index)}
        />

        <FlexLayout
          isVertical={true}
          weight={2}
        >
          <FlexLayout
            isVertical={true}
            isScroll={true}
            weight={2.7}
          >
            <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
              <LabelBox
                title="거래처코드"
                isNeed={true}
                size={185}
              >
                <TextBox
                  textAlign="center"
                  value={model?.cltcd}
                  onChange={(value) => setData({ cltcd: value })}
                  readonly={!model?.isNew}
                />
              </LabelBox>

              <LabelBox
                title="거래처명칭"
                isNeed={true}
                weight={1.35}
                footer={<FlexLayout>
                  <Button
                    theme={BUTTON_THEMES.BLUE}
                    onClick={() => scope.onAddressButton(model?.cltnm)}
                  >
                    주소찾기
                  </Button>
                </FlexLayout>}
              >
                <TextBox
                  value={model?.cltnm}
                  onChange={(value) => setData({ cltnm: value })}
                  onBlur={() => {
                    if (model?.prtcltnm.trim() === '') {
                      setData({ prtcltnm: model?.cltnm });
                    }
                  }}
                />
              </LabelBox>

              <LabelBox title="인쇄할 거래처명(전자세금계산서 공급받는자명)">
                <TextBox
                  value={model?.prtcltnm}
                  onChange={(value) => setData({ prtcltnm: value })}
                />
              </LabelBox>

              <LabelBox title="거래처타입">
                <ComboBox
                  value={model?.grade}
                  data={scope.state.grades?.map((x) => new ComboBoxModel(x.com_code, x.com_cnam))}
                  onSelect={(option) => setData({ grade: option.value })}
                  isDisabledTrackingStateChange={true}
                />
              </LabelBox>
            </FlexLayout>

            <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
              <LabelBox title="대표자명">
                <TextBox
                  textAlign="center"
                  value={model?.prenm}
                  onChange={(value) => setData({ prenm: value })}
                />
              </LabelBox>

              <LabelBox title="사업자등록번호" isNeed={true}>
                <TextBox
                  textAlign="center"
                  value={model?.saupnum}
                  onChange={(value) => {
                    if (value.length >= 13) {
                      return;
                    }
                    setData({ saupnum: value.trim() });
                  }}
                />
              </LabelBox>

              <LabelBox title="법인(주민)번호">
                <FormatTextBox
                  format="######-#######"
                  textAlign="center"
                  value={model?.prenum}
                  onChange={(value) => setData({ prenum: value })}
                />
              </LabelBox>

              <LabelBox
                title="업태"
                isNeed={true}
              >
                <TextBox
                  value={model?.biztypenm}
                  onChange={(value) => setData({ biztypenm: value })}
                />
              </LabelBox>

              <LabelBox
                title="종목"
                isNeed={true}
              >
                <TextBox
                  value={model?.bizitemnm}
                  onChange={(value) => setData({ bizitemnm: value })}
                />
              </LabelBox>
              <LabelBox title="구분">
                <OptionBox
                  value={model?.clttype}
                  data={[
                    { value: '1', remark: '매입' },
                    { value: '2', remark: '매출' },
                    { value: '3', remark: '공통' },
                  ]}
                  onChange={(item) => setData({ clttype: item.value })}
                />
              </LabelBox>
            </FlexLayout>

            <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
              <LabelBox title="담당자명">
                <TextBox
                  value={model?.agnernm}
                  onChange={(value) => setData({ agnernm: value })}
                />
              </LabelBox>

              <LabelBox title="핸드폰번호">
                <TelnumTextBox
                  textAlign="center"
                  value={model?.hptelnum}
                  onChange={(value) => setData({ hptelnum: value })}
                />
              </LabelBox>

              <LabelBox
                title="전화번호"
                footer={<FlexLayout>
                  <Button
                    onClick={() => scope.openTelnum()}
                    isIcon={true}
                    theme={BUTTON_THEMES.BLUE}
                  >
                    <FiArrowRightCircle size={25}/>
                  </Button>
                </FlexLayout>}
              >
                <TelnumTextBox
                  textAlign="center"
                  value={model?.telnum}
                  onChange={(value) => setData({ telnum: value })}
                />
              </LabelBox>

              <LabelBox title="팩스">
                <TelnumTextBox
                  value={model?.faxnum}
                  onChange={(value) => setData({ faxnum: value })}
                />
              </LabelBox>

              <LabelBox
                title="상태[사업자상태]"
                footer={<FlexLayout>
                  <Button
                    isIcon={true}
                    style={{ fontSize: 20 }}
                    onClick={() => scope.stateButton()}
                  >
                    <FiRefreshCcw />
                  </Button>
                </FlexLayout>}
              >
                <TextBox
                  readonly={true}
                  textAlign="center"
                  value={
                    model?.state === '01' ? '정상'
                      : model?.state === '02' ? '휴업'
                        : model?.state === '03' ? '폐업' : ''
                  }
                  color={ model?.state === '01' ? 'var(--color-blue)'
                    : model?.state === '02' ? 'var(--color-red)'
                      : model?.state === '03' ? 'var(--color-red)' : ''
                  }
                >
                </TextBox>
              </LabelBox>

              <LabelBox weight={1}>
                <FlexLayout isVertical={true}>
                  <OptionBox
                    value={model?.corpperclafi || '0'}
                    data={[
                      { value: '0', remark: '법인' },
                      { value: '1', remark: '개인' },
                    ]}
                    onChange={(item) => setData({ corpperclafi: item.value })}
                  />
                  <OptionBox
                    value={model?.upriceclafi || '1'}
                    data={[
                      { value: '1', remark: '도매' },
                      { value: '2', remark: '소매' },
                    ]}
                    onChange={(item) => setData({ upriceclafi: item.value })}
                  />
                  <OptionBox
                    value={model?.foreyn || '0'}
                    data={[
                      { value: '0', remark: '국내' },
                      { value: '1', remark: '국외' },
                    ]}
                    onChange={(item) => setData({ foreyn: item.value })}
                  />
                </FlexLayout>
              </LabelBox>
            </FlexLayout>

            <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
              <LabelBox
                title="사업장주소"
                style={{ color: 'var(--color-red)' }}
                isNeed={true}
                weight={4}
                footer={<FlexLayout>
                  <AddressButton
                    publicStore={scope.props.publicStore}
                    modalStore={scope.props.modalStore}
                    onChange={(address) => {
                      setData({
                        zipcd: address.zonecode,
                        oldcltadres: address.jibunAddress,
                        cltadres: address.address,
                        lat: address.y,
                        lng: address.x,
                      });
                    }}
                  >
                    주소검색
                  </AddressButton>
                  <Button
                    onClick={() => {
                      setData2({
                        basicAddressToggle: !scope.state.basicAddressToggle,
                      });
                    }}
                  >
                    {!scope.state.basicAddressToggle ? '신' : '구'}주소보기
                  </Button>
                  <DefaultLayout size={150}>
                    <TextBox
                      value={model?.lat !== undefined ? `위도: ${model?.lat}` : '위도:'}
                      readonly={true}
                      transparent={true}
                      size={180}
                    />
                  </DefaultLayout>
                  <DefaultLayout size={150}>
                    <TextBox
                      value={model?.lat !== undefined ? `경도: ${model?.lng}` : '경도:'}
                      readonly={true}
                      transparent={true}
                      size={180}
                    />
                  </DefaultLayout>
                </FlexLayout>}
              >
                <TextBox
                  textAlign="center"
                  value={model?.zipcd}
                  onChange={(value) => setData({ zipcd: value })}
                  readonly={true}
                />

                <TextBox
                  value={scope.state.basicAddressToggle
                    ? model?.cltadres
                    : model?.oldcltadres
                  }
                  onChange={(value) => setData({
                    [scope.state.basicAddressToggle ? 'cltadres' : 'oldcltadres']: value,
                  })}
                  weight={2}
                />

                <TextBox
                  value={model?.cltadres2}
                  onChange={(value) => setData({ cltadres2: value })}
                  weight={2}
                />
              </LabelBox>
              <LabelBox
                title="종 사업장"
                weight={4}
                footer={<FlexLayout style={{ marginRight: 465 }}>
                  <Button
                    theme={BUTTON_THEMES.BLUE}
                    onClick={() => scope.CompanyModal(true)}
                    isIcon={true}
                  >
                    <FaBell />
                  </Button>
                </FlexLayout>}
              >
                <TextBox
                  readonly={true}
                  textAlign="center"
                  value={model?.jspjangcd}
                  onChange={(value) => setData({ jspjangcd: value })}
                />

                <TextBox
                  readonly={true}
                  value={model?.jsaupnum}
                  onChange={(value) => setData({ jsaupnum: value })}
                  weight={2}
                />

                <TextBox
                  readonly={true}
                  value={model?.jspjangnm}
                  onChange={(value) => setData({ jspjangnm: value })}
                  weight={2}
                />
              </LabelBox>
            </FlexLayout>

            <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
              <LabelBox title="CMS 자동이체">
                <OptionBox
                  value={model?.autoflag || '1'}
                  data={[
                    { value: '1', remark: '유' },
                    { value: '0', remark: '무' },
                  ]}
                  onChange={(item) => scope.updateActsAutoflag(item)}
                />
                <FormatTextBox
                  weight={0.5}
                  textAlign="center"
                  format="## 일"
                  value={model?.autodate}
                  onChange={(value) => setData({ autodate: value })}
                >
                </FormatTextBox>
              </LabelBox>
              <LabelBox title="자동이체번호">
                <TextBox
                  value={model?.autonum}
                  onChange={(value) => setData({ autonum: value })}
                />
              </LabelBox>
              <LabelBox
                title="계산서메일 (필수)"
                footer={<FlexLayout>
                  <CheckBox
                    value={model?.taxflag === '1'}
                    onChange={(value) => {
                      setData({ taxflag: value ? '1' : '0' });
                    }}
                  />
                  <Button
                    onClick={() => scope.taxEmailModal(true)}
                    isIcon={true}
                    theme={BUTTON_THEMES.BLUE}
                  >
                    <FiArrowRightCircle size={25}/>
                  </Button>
                </FlexLayout>}
              >
                <TextBox
                  value={model?.taxflag === '0' ? '' : model?.taxmail}
                  readonly={model?.taxflag === '0'}
                  onChange={(value) => setData({ taxmail: value })}
                />
              </LabelBox>
              <LabelBox title="홈페이지">
                <TextBox
                  value={model?.homepage}
                  onChange={(item) => setData({ homepage: item.value })}
                />
              </LabelBox>
            </FlexLayout>
            <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
              <LabelBox title="은행">
                <ComboBox
                  value={model?.bankcd}
                  data={scope.state.bankcds?.map((x) => new ComboBoxModel(x.bankcd, x.banknm))}
                  onSelect={(option) => setData({ bankcd: option.value },
                    () => scope.itemChanged(option.value,
                      ResisterItemChangeTypes.BANKCD))}
                />
              </LabelBox>
              <LabelBox title="예금주">
                <TextBox
                  value={model?.deposit}
                  onChange={(value) => setData({ deposit: value })}
                />
              </LabelBox>
              <LabelBox title="계좌번호">
                <TextBox
                  value={model?.accnum}
                  onChange={(value) => setData({ accnum: value })}
                />
              </LabelBox>
              <LabelBox
                weight={0.5}
                title="현장과 거래처 동일"
                footer={<FlexLayout>
                  <CheckBox
                    value={model?.actflag === '1'}
                    onChange={(value) => {
                      setData({ actflag: value ? '1' : '0' });
                    }}
                  />
                </FlexLayout>}
              >
                <Button
                  theme={BUTTON_THEMES.BLUE}
                  onClick={() => scope.onActButtonEvent()}
                >
                    보수현장
                  </Button>
              </LabelBox>
              <LabelBox
                title="입력일자"
                weight={0.5}
              >
                <FormatTextBox
                  readonly={true}
                  textAlign="center"
                  format="####-##-##"
                  value={model?.inputdate}
                  onChange={(item) => setData({ inputdate: item.value })}
                />
              </LabelBox>
            </FlexLayout>

            <FlexLayout size={100}>
              <LabelBox title="비고">
                <TextBox
                  value={model?.remark}
                  onChange={(value) => setData({ remark: value })}
                  isMultiline={true}
                />
              </LabelBox>
            </FlexLayout>

            <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
              <LabelBox title="장기미수내역(3개월이상)">
                <FormatNumberTextBox
                  textAlign="right"
                  color="var(--color-red)"
                  value={model?.longmisamt}
                  readonly={true}
                />
              </LabelBox>
              <LabelBox title="미수내역(1개월이상)">
                <FormatNumberTextBox
                  textAlign="right"
                  color="var(--color-pink)"
                  value={model?.misamt}
                  readonly={true}
                />
              </LabelBox>
              <LabelBox title="장기미수 문자 미전송(금지)">
                <CheckBox
                  value={model?.missms === '1'}
                  onChange={(value) => setData({ missms: value ? '1' : '0' })}
                />
              </LabelBox>
              <LabelBox
                title="장기미수 문자 미전송(금지)사유"
                weight={3.02}
              >
                <TextBox
                  value={model?.missmsremark}
                  onChange={(item) => setData({ missmsremark: item.value })}
                />
              </LabelBox>

              <LabelBox
                title="폐업일자"
                style={{ color: 'var(--color-red)' }}
              >
                <TextBox
                  readonly={true}
                  value={Date8.withDash(model?.state_enddate)}
                  textAlign="center"
                  color={'var(--color-red)' }
                >
                </TextBox>
              </LabelBox>

              <LabelBox title= {scope.state.dz_flag === '0' ? '' : '더존연동코드(위하고)'}>
                {scope.state.dz_flag === '1' && <FlexLayout>
                  <TextBox
                    value={model?.dz_tr_cd}
                    onChange={(value) => setData({ dz_tr_cd: value })}
                  />
                </FlexLayout>
                }
              </LabelBox>
            </FlexLayout>
          </FlexLayout>

          <FlexLayout>
            <TableLayout
              ref={scope.tableDetail}
              header={[
                {
                  id: 'actcd',
                  text: '코드',
                  width: 20,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.actcd}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'actnm',
                  text: '현장명',
                  width: 45,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.actnm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'qty',
                  text: '관리대수',
                  width: 35,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.qty}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'taxflag',
                  text: '메일구분',
                  width: 35,
                  render: (x, rowUpdate) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      <CheckBox
                        value={x.taxflag === '1'}
                        onChange={(value) => rowUpdate({ taxflag: value ? '1' : '0' })}
                      />
                    </FlexLayout>
                  ),
                },
                {
                  id: 'taxmail',
                  text: '계산서메일',
                  width: 35,
                  render: (x, rowUpdate) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      <TextBox
                        readonly={x.taxflag !== '1'}
                        value={x.taxflag === '1' ? x.taxmail : ''}
                        onChange={(value) => rowUpdate({ taxmail: value })}
                      />
                    </FlexLayout>
                  ),
                },
                {
                  id: 'autoflag',
                  text: 'CMS',
                  width: 35,
                  render: (x, rowUpdate) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      <CheckBox
                        value={x.autoflag === '1'}
                        onChange={(value) => {
                          rowUpdate({ autoflag: value ? '1' : '0' });

                          if (scope.state.data.autoflag === '1' && value) {
                            scope.setState({
                              data: new ResisterModel({
                                ...scope.state.data,
                                autoflag: '0',
                              }, scope.state.data.isNew),
                            });
                          }
                        }}
                      />
                    </FlexLayout>
                  ),
                },
                {
                  id: 'autonum',
                  text: '자동이체번호',
                  width: 35,
                  render: (x, rowUpdate) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      <TextBox
                        readonly={x.autoflag !== '1'}
                        value={x.autonum}
                        onChange={(value) => rowUpdate({ autonum: value })}
                      />
                    </FlexLayout>
                  ),
                },
              ]}
              onChange={(rows, updatedRows) => {
                scope.onUpdatedRows2(rows, updatedRows);
              }}
              data={scope.state.tableDetailData || []}
              infinityHandler={scope.infinity2}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              onRowFocusEvent={(item) => scope.onRowFocusEvent2(item)}
              onRowDoubleClick={(item) => scope.onClickOpenResist(item)}
            />

            <ModalView
              isVisible={scope.state.taxEmailDetailModal}
              onClose={() => scope.taxEmailModal(false)}
              width={1000}
              zIndex={-2}
            >
              <FlexLayout
                isVertical={true}
                style={{ padding: 8 }}
              >
                <SubHeader>
                  <div>
                    히스토리
                  </div>

                  <FlexLayout justify='right'>
                    <Button
                      style={{ height: '25px', width: '80px' }}
                      onClick={() => scope.onNewCheckTaxEvent()}
                    >
                      신규
                    </Button>
                    <Button
                      style={{ height: '25px', width: '80px' }}
                      onClick={() => scope.onCheckEmailSaveEvent()}
                    >
                      저장
                    </Button>
                    <Button
                      style={{ height: '25px', width: '80px' }}
                      theme={BUTTON_THEMES.RED}
                      onClick={() => scope.onCheckTaxDelete()}
                    >
                      삭제
                    </Button>
                    <Button
                      style={{ height: '25px', width: '80px' }}
                      theme={BUTTON_THEMES.RED}
                      onClick={() => scope.taxEmailModal(false)}
                    >
                      닫기
                    </Button>
                  </FlexLayout>
                </SubHeader>
                <FlexLayout weight={14}>
                  <TableLayout
                    header={[
                      {
                        id: 'seq',
                        text: '순번',
                        width: 10,
                        render: (x) => (
                          <FlexLayout
                            justify="center"
                            align="center"
                          >
                            {x.seq}
                          </FlexLayout>
                        ),
                      },
                      {
                        id: 'taxmail',
                        text: '담당자메일',
                        width: 45,
                        render: (x, rowUpdate) => (
                          <TextBox
                            value={x.taxmail}
                            textAlign="left"
                            onChange={(value) => rowUpdate({ taxmail: value })}
                          />
                        ),
                      },
                      {
                        id: 'remark',
                        text: '비고',
                        width: 45,
                        render: (x, rowUpdate) => (
                          <TextBox
                            value={x.remark}
                            textAlign="left"
                            onChange={(value) => rowUpdate({ remark: value })}
                          />
                        ),
                      },
                    ]}
                    data={scope.state.PersonPopUpData || []}
                    rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                    onChange={(rows2, updatedRows2) => {
                      scope.onUpdatedRows2(rows2, updatedRows2);
                    }}
                    onRowFocusEvent={(item) => scope.onRowFocusEvent2(item)}
                    ref={scope.tableCheckTax}
                  />
                </FlexLayout>
              </FlexLayout>
            </ModalView>

            <ModalView
              width={500}
              isVisible={scope.state.searchAddressModal}
              onClose={() => scope.AddressModal(false)}
            >
              <FlexLayout
                style={{ padding: 8 }}
                className={ style }
                isVertical={true}
              >

                <SearchLayout
                  size={Global.LAYOUT_SEARCH_HEIGHT_1}
                  style={{ width: 400 }}
                >
                  <FlexLayout
                    isVertical={true}
                    justify="center"
                    align="left"
                  >
                    <span style={{ color: 'var(--color-red)' }}>주소찾기</span>
                  </FlexLayout>
                </SearchLayout>
                <FlexLayout isVertical={true}>
                    <hr />
                    <hr />
                    <hr />
                    <hr />
                </FlexLayout>


              </FlexLayout>
            </ModalView>

            <ModalView
              width={1000}
              isVisible={scope.state.subCompanyModal}
              onClose={() => scope.CompanyModal(false)}
            >
              <FlexLayout
                style={{ padding: 8 }}
                className={ style }
                isVertical={true}
              >
                <LayoutTitle>
                  종 사업장 등록
                </LayoutTitle>

                <FlexLayout
                  isVertical={true}
                  size={Global.LAYOUT_GRID_HEIGHT_1}
                >
                  <FlexLayout>
                    <LayoutTitle size={100}>
                      거래처
                    </LayoutTitle>

                    <LabelBox>
                      <TextBox
                        textAlign="center"
                        value={model?.cltcd}
                        weight={1}
                      />
                      <TextBox
                        textAlign="left"
                        value={model?.cltnm}
                        weight={3}
                      />
                    </LabelBox>
                    <FlexLayout>
                      <Button
                        theme={BUTTON_THEMES.BLUE}
                        isFull={true}
                        onClick={() => scope.subRetrieveEvent()}
                      >
                        조회
                      </Button>
                      <Button
                        theme={BUTTON_THEMES.BLUE}
                        isFull={true}
                        onClick={() => scope.subNewEvent()}
                      >
                        신규
                      </Button>
                      <Button
                        theme={BUTTON_THEMES.BLUE}
                        isFull={true}
                        onClick={() => scope.subSaveEvent()}
                      >
                        저장
                      </Button>
                      <Button
                        theme={BUTTON_THEMES.BLUE}
                        isFull={true}
                        onClick={() => scope.subDeleteEvent()}
                      >
                        삭제
                      </Button>
                      <Button
                        theme={BUTTON_THEMES.RED}
                        isFull={true}
                        onClick={() => scope.CompanyModal(false)}
                      >
                        닫기
                      </Button>
                    </FlexLayout>
                  </FlexLayout>
                </FlexLayout>

                <FlexLayout>
                  <TableLayout
                    ref={scope.popupTable}
                    header={[
                      {
                        id: 'spjangcd',
                        text: '코드',
                        width: 20,
                        render: (x, rowUpdate, ref) => (
                          <TextBox
                            textAlign="center"
                            ref={ref}
                            value={x.spjangcd}
                            onChange={(value) => rowUpdate({ spjangcd: value })}
                          />
                        ),
                      },
                      {
                        id: 'spjangnm',
                        text: '종사업장명',
                        width: 30,
                        render: (x, rowUpdate, ref) => (
                          <TextBox
                            textAlign="center"
                            ref={ref}
                            value={x.spjangnm}
                            onChange={(value) => rowUpdate({ spjangnm: value })}
                          />
                        ),
                      },
                      {
                        id: 'saupnum',
                        text: '사업자번호',
                        width: 30,
                        render: (x, rowUpdate, ref) => (
                          <TextBox
                            textAlign="center"
                            ref={ref}
                            value={x.saupnum}
                            onChange={(value) => rowUpdate({ saupnum: value })}
                          />
                        ),
                      },
                      {
                        id: 'prenum',
                        text: '법인번호',
                        width: 50,
                        render: (x, rowUpdate, ref) => (
                          <TextBox
                            textAlign="center"
                            ref={ref}
                            value={x.prenum}
                            onChange={(value) => rowUpdate({ prenum: value })}
                          />
                        ),
                      },
                      {
                        id: 'prenm',
                        text: '대표자명',
                        width: 30,
                        render: (x, rowUpdate, ref) => (
                          <TelnumTextBox
                            textAlign="center"
                            ref={ref}
                            value={x.prenm}
                            onChange={(value) => rowUpdate({ prenm: value })}
                          />
                        ),
                      },
                      {
                        id: 'biztypenm',
                        text: '업태',
                        width: 30,
                        render: (x, rowUpdate, ref) => (
                          <TelnumTextBox
                            textAlign="center"
                            ref={ref}
                            value={x.biztypenm}
                            onChange={(value) => rowUpdate({ biztypenm: value })}
                          />
                        ),
                      },
                      {
                        id: 'bizitemnm',
                        text: '종목',
                        width: 30,
                        render: (x, rowUpdate, ref) => (
                          <TextBox
                            textAlign="center"
                            ref={ref}
                            value={x.bizitemnm}
                            onChange={(value) => rowUpdate({ bizitemnm: value })}
                          />
                        ),
                      },
                      {
                        id: 'useyn',
                        text: '대표',
                        width: 30,
                        render: (x, rowUpdate) => (
                          <CheckBox
                            value={x.useyn === '1'}
                            onChange={(value) => rowUpdate({ useyn: value ? '1' : '0' })}
                          />
                        ),
                      },
                    ]}
                    data={scope.state.subCompanyData}
                    infinityHandler={scope.infinity2}
                    rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
                    onChange={(rows3, updatedRows3) => {
                      scope.subUpdatedRows(rows3, updatedRows3);
                    }}
                    onRowFocusEvent={(item) => scope.subRowFocusEvent(item)}
                  />
                </FlexLayout>
              </FlexLayout>
            </ModalView>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};
