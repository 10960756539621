import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { BalanceTemplate } from './Balance.template';
import { InfinityRetrieve } from '../../../../models/common';
import { BalanceModel } from './models/Balance.model';
import { Today } from '../../../../utils/time';
import { TableLayout, GridLayout } from '../../../../components';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';

interface BalanceState {

  // 검색조건
  searchQuery: string; // 검색어
  year: string; // 년도
  store: string; // 창고
  storenm: string; // 창고명
  qtyflag: string; // 재고유무
  idate: string; // 실사일자

  // 통계
  total: string;
  qty_tot: string;
  xqty_tot: string;
  iqty_tot: string;
  cqty_tot: string;
  bqty_tot: string;

  balanceList: Array<BalanceModel>;
  data: BalanceModel;
  focusedBalance?: BalanceModel;

  // 팝업
  loadDetailModal: boolean; // 상세내역 불러오기
  popupList: Array<BalanceModel>;
  popuoPunit: string;
}

/**
 * 컨트롤러
 * @window w_tb_ca602w_01
 * @category 현재고현황
 */
export class Balance extends PageComponent<PageProps, BalanceState>
  implements PageToolEvents {
  table: React.RefObject<TableLayout> = React.createRef();

  popupGrid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  updatedRows?: Array<BalanceModel>;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month: string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date: string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      searchQuery: '',
      year: Today.year(),
      store: '',
      storenm: '',
      qtyflag: '%',
      idate: `${year}${month}${date}`,
      data: new BalanceModel(),
      balanceList: [],
      popupList: [],
      loadDetailModal: false,

      total: '',
      qty_tot: '',
      xqty_tot: '',
      iqty_tot: '',
      cqty_tot: '',
      bqty_tot: '',

      // 팝업
      popuoPunit: '',
    };
  }

  @action
  // eslint-disable-next-line class-methods-use-this
  async onFirstOpenEvent() {
    ConfirmWarning.show('확인', '먼저 창고를 선택해주세요.');
  }

  @action
  async onRetrieveEvent() {
    if (!this.state.store) {
      ConfirmWarning.show('확인', '먼저 창고를 선택해주세요.');
      return;
    }

    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery,
        year: this.state.year,
        store: this.state.store,
        qtyflag: this.state.qtyflag,
        jqtyflag: '%',
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          balanceList: [...this.state.balanceList, ...items],
        }, () => this.table.current?.update(false));
      },
      async () => {
        await this.SS({ balanceList: [] });
        await this.infinity?.retrieveAll();
        this.table.current?.update();
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      balanceList: [],
    }, async () => {
      await this.infinity?.retrieveAll();
      this.setState({
        total: this.infinity?.box?.total || '0',
        qty_tot: this.infinity?.box?.qty_tot || '0',
        xqty_tot: this.infinity?.box?.xqty_tot || '0',
        iqty_tot: this.infinity?.box?.iqty_tot || '0',
        cqty_tot: this.infinity?.box?.cqty_tot || '0',
        bqty_tot: this.infinity?.box?.bqty_tot || '0',
      }, () => this.table.current?.update());
    });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (this.state.balanceList.length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.printWithElmanManager({
      as_nm: this.state.searchQuery,
      year: this.state.year,
      store: this.state.store,
      qtyflag: this.state.qtyflag,
      jqtyflag: '%',
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (this.state.balanceList.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.excel({
      as_nm: this.state.searchQuery,
      year: this.state.year,
      store: this.state.store,
      qtyflag: this.state.qtyflag,
      jqtyflag: '%',
    });
  }

  @action
  onUpdatedRows(rows: Array<BalanceModel>, updatedRows: Array<BalanceModel>) {
    this.updatedRows = updatedRows;
    this.setState({ balanceList: rows });
  }

  @action
  onRowFocusEvent(item:BalanceModel) {
    this.setState({ focusedBalance: item });
  }

  @action
  async loadDetailModal(isOpen: boolean) {
    this.setState({ loadDetailModal: isOpen });
    isOpen && await this.modalRetrieve();
  }

  @action
  async modalRetrieve() {
    const { actionStore: api } = this.props;

    this.infinity2 = new InfinityRetrieve(
      {
        sub: 'w_popup_ca602',
        year: this.state.year,
        store: this.state.store,
        pcode: this.state.focusedBalance?.pcode,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          popupList: [...this.state.popupList, ...items],
        });
      },
      async () => {
        await this.SS({ popupList: [] });
        await this.infinity2?.retrieveAll();
        this.popupGrid.current?.setFocus(0);
      },
    );

    this.setState({
      popupList: [],
    }, async () => {
      await this.infinity2?.retrieveAll();
      this.popupGrid.current?.setFocus(0);
    });
  }

  @action
  async modalRowFocusEvent(item: any) {
    await this.SS({
      popuoPunit: item?.popuoPunit,
    });
  }

  render() {
    return (
      <BalanceTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            data: new BalanceModel({
              ...this.state.data,
              ...change,
            }, this.state.data.isNew),
          }, () => callback && callback());
        }}
      />
    );
  }
}
