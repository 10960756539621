import { computed } from 'mobx';

/**
 * 모델
 * 계약연장 팝업
 * @window w_tb_e601_new
 * @category 보수현장등록
 */
export class EnrollmentContractLongModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(5)
   */
  actnm: string;

  /**
   * 유무상
   *
   * **data type** varchar(3)
   */
  mgubun: string;

  /**
   * 금액
   *
   * **data type** varchar(100)
   */
  amt: string;

  /**
   * 부가세
   *
   * **data type** varchar(1)
   */
  addyn: string;

  /**
   * 계약구분
   *
   * **data type** varchar(100)
   */
  contg: string

  /**
   * 시작일자
   *
   * **data type** varchar(1)
   */
  stdate: string;

  /**
   * 만료일자
   *
   * **data type** varchar(8)
   */
  enddate: string;

  /**
   * 변경계약
   *
   * **data type** varchar(100)
   */
  bcontg: string;

  /**
   * 변경금액
   *
   * **data type** varchar(1)
   */
  bamt: string;

  /**
   * 부가세
   *
   * **data type** varchar(8)
   */
  baddyn: string;

  /**
   * 변경시작일
   *
   * **data type** varchar(8)
   */
  bstdate: string;

  /**
   * 변경만료일
   *
   * **data type** varchar(1)
   */
  benddate: string;

  /**
   * 계약일자
   *
   * **data type** varchar(1)
   */
  bcontdate: string;

  /**
   * 확인
   *
   * **data type** varchar(?)
   */
  chk: string;

  // contg 대체값
  data: string;

  // 저장용
  accyn: string;

  actcd: string;

  billgubun: string;

  cls: string;

  clsdate: string;

  clsnm: string;

  clsremark: string;

  cltcd: string;

  contdate: string;

  contgubun: string;

  contname: string;

  dedate: string;

  delchk: string;

  delflag: string;

  deljugi: string;

  delmon1: string;

  delmon2: string;

  delmon3: string;

  delmon4: string;

  delmon5: string;

  delmon6: string;

  delmon7: string;

  delmon8: string;

  delmon9: string;

  delmon10: string;

  delmon11: string;

  delmon12: string;

  evercd: string;

  evernight: string;

  evju: string;

  evmon: string;

  exclflag: string;

  gubun: string;

  jirochk: string;

  jirodate: null

  jirogubun: string;

  ocontg: string;

  perid: string;

  pubdate: null

  remark: string;

  remflag: string;

  samt: string;

  seq: string;

  subflag: string;

  subsaupnum: null

  tax_actcd: string;

  tax_actnm: string;

  tax_combine: string;

  taxchk: string;

  taxflag: string;

  taxgubun: string;

  ungijun: string;

  bigo: string;

  qty: string;

  taxweek: string;


  /**
   * 신규인지
   *
   * **data type** varchar(1)
   */
  readonly new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.actnm = data.actnm || '';
    this.mgubun = data.mgubun || '';
    this.amt = data.amt || '';
    this.addyn = data.addyn || '';
    this.contg = data.contg || '';
    this.stdate = data.stdate || '';
    this.enddate = data.enddate || '';
    this.bcontg = data.bcontg || '';
    this.bamt = data.bamt || '';
    this.baddyn = data.baddyn || '';
    this.bstdate = data.bstdate || '';
    this.benddate = data.benddate || '';
    this.bcontdate = data.bcontdate || '';
    this.chk = data.chk || '';
    this.data = data.data || '';
    this.accyn = data.accyn || '';
    this.actcd = data.actcd || '';
    this.billgubun = data.billgubun || '';
    this.bstdate = data.bstdate || '';
    this.cls = data.cls || '';
    this.clsdate = data.clsdate || '';
    this.clsnm = data.clsnm || '';
    this.clsremark = data.clsremark || '';
    this.cltcd = data.cltcd || '';
    this.contdate = data.contdate || '';
    this.contg = data.contg || '';
    this.contgubun = data.contgubun || '';
    this.contname = data.contname || '';
    this.custcd = data.custcd || '';
    this.dedate = data.dedate || '';
    this.delchk = data.delchk || '';
    this.delflag = data.delflag || '';
    this.deljugi = data.deljugi || '';
    this.delmon1 = data.delmon1 || '';
    this.delmon2 = data.delmon2 || '';
    this.delmon3 = data.delmon3 || '';
    this.delmon4 = data.delmon4 || '';
    this.delmon5 = data.delmon5 || '';
    this.delmon6 = data.delmon6 || '';
    this.delmon7 = data.delmon7 || '';
    this.delmon8 = data.delmon8 || '';
    this.delmon9 = data.delmon9 || '';
    this.delmon10 = data.delmon10 || '';
    this.delmon11 = data.delmon11 || '';
    this.delmon12 = data.delmon12 || '';
    this.evercd = data.evercd || '';
    this.evernight = data.evernight || '';
    this.evju = data.evju || '';
    this.evmon = data.evmon || '';
    this.exclflag = data.exclflag || '';
    this.gubun = data.gubun || '';
    this.jirochk = data.jirochk || '';
    this.jirodate = data.jirodate || '';
    this.jirogubun = data.jirogubun || '';
    this.ocontg = data.ocontg || '';
    this.perid = data.perid || '';
    this.pubdate = data.pubdate || '';
    this.remark = data.remark || '';
    this.remflag = data.remflag || 'N';
    this.remflag = this.remflag === '0' ? 'N' : this.remflag;
    this.samt = data.samt || '';
    this.seq = data.seq || '';
    this.subflag = data.subflag || '';
    this.subsaupnum = data.subsaupnum || '';
    this.tax_actcd = data.tax_actcd || '';
    this.tax_actnm = data.tax_actnm || '';
    this.tax_combine = data.tax_combine || '';
    this.taxchk = data.taxchk || '';
    this.taxflag = data.taxflag || '';
    this.taxgubun = data.taxgubun || '';
    this.taxweek = data.taxweek || '';
    this.ungijun = data.ungijun || '';
    this.bigo = data.bigo || '';
    this.qty = data.qty || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
