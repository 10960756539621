import { computed } from 'mobx';

/**
 * 모델
 * 계약정보
 * @window w_tb_e601_new
 * @category 보수현장등록
 */
export class EnrollmentContractInformationModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 발령코드
   *
   * **data type** varchar(2)
   */
  ordercd: string;

  /**
   * 발령명
   *
   * **data type** varchar(20)
   */
  ordernm: string;

  /**
   * 발령명
   *
   * **data type** varchar(1)
   */
  useyn: string;

  /**
   * 순번
   *
   * **data type** varchar(1)
   */
  seq: string;

  /**
   * 계약일자
   *
   * **data type** varchar(8)
   */
  contdate: string;

  /**
   * 계약접수일자
   *
   * **data type** varchar(8)
   */
  recedate: string;

  /**
   * 계약분류
   *
   * **data type** varchar(?)
   */
  contg: string;

  /**
   * 계약구분
   *
   * **data type** varchar(?)
   */
  contgubun: string;

  /**
   * 매출구분
   *
   * **data type** varchar(?)
   */
  gubun: string;

  /**
   * 매출구분
   *
   * **data type** varchar(?)
   */
  mgubun: string;

  /**
   * 매출분류
   *
   * **data type** varchar(?)
   */
  billgubun: string;

  /**
   * 세금구분
   *
   * **data type** varchar(?)
   */
  taxgubun: string;

  /**
   * 무상계약진행자
   *
   * **data type** varchar(?)
   */
  muperid: string;

  /**
   * 무상계약진행자 이름
   *
   * **data type** varchar(?)
   */
  mupernm: string;

  /**
   * 계약시작일
   *
   * **data type** varchar(8)
   */
  stdate: string;

  /**
   * 계약종료일
   *
   * **data type** varchar(8)
   */
  enddate: string;

  /**
   * 계약금액
   *
   * **data type** float
   */
  amt: string;

  /**
   * 월대당가
   *
   * **data type** varchar(모름)
   */
  uamt: string;

  /**
   * 계약서금액(협력)
   *
   * **data type** varchar(모름)
   */
  samt: string;

  /**
   * 청구주기
   *
   * **data type** varchar(모름)
   */
  deljugi: string;

  /**
   * 청구주기
   *
   * **data type** varchar(모름)
   */
  delmon1: string;

  /**
   * 청구주기
   *
   * **data type** varchar(모름)
   */
  delmon2: string;

  /**
   * 청구주기
   *
   * **data type** varchar(모름)
   */
  delmon3: string;

  /**
   * 청구주기
   *
   * **data type** varchar(모름)
   */
  delmon4: string;

  /**
   * 청구주기
   *
   * **data type** varchar(모름)
   */
  delmon5: string;

  /**
   * 청구주기
   *
   * **data type** varchar(모름)
   */
  delmon6: string;

  /**
   * 청구주기
   *
   * **data type** varchar(모름)
   */
  delmon7: string;

  /**
   * 청구주기
   *
   * **data type** varchar(모름)
   */
  delmon8: string;

  /**
   * 청구주기
   *
   * **data type** varchar(모름)
   */
  delmon9: string;

  /**
   * 청구주기
   *
   * **data type** varchar(모름)
   */
  delmon10: string;

  /**
   * 청구주기
   *
   * **data type** varchar(모름)
   */
  delmon11: string;

  /**
   * 청구주기
   *
   * **data type** varchar(모름)
   */
  delmon12: string;

  /**
   * 계약서여부
   *
   * **data type** varchar(모름)
   */
  attcnt: string;

  /**
   * 계약자
   *
   * **data type** varchar(모름)
   */
  perid: string;

  /**
   * 계약자이름
   *
   * **data type** varchar(모름)
   */
  pernm: string;

  /**
   * 건물구분이름
   *
   * **data type** varchar(모름)
   */
  contbildnm: string;

  /**
   * 건물
   *
   * **data type** varchar(모름)
   */
  evercd: string;

  /**
   * 표준층수
   *
   * **data type** varchar(모름)
   */
  evernumber: string;

  /**
   * 표준유지관리비
   *
   * **data type** varchar(모름)
   */
  everamt: string;

  /**
   * 표준체증요금
   *
   * **data type** varchar(모름)
   */
  everaddar: string;

  /**
   * 평균층수
   *
   * **data type** varchar(모름)
   */
  avernumber: string;

  /**
   * 금액
   *
   * **data type** varchar(모름)
   */
  addnumber: string;

  /**
   * salenm
   *
   * **data type** varchar(모름)
   */
  salenm: string;

  /**
   * saleamt
   *
   * **data type** varchar(모름)
   */
  saleamt: string;

  /**
   * 총표준유지관리비
   *
   * **data type** varchar(모름)
   */
  toevera: string;

  /**
   * 인수일(협력업체)
   *
   * **data type** varchar(모름)
   */
  insudate: string;

  /**
   * 부가세여부
   *
   * **data type** varchar(모름)
   */
  addyn: string;

  /**
   * 휴일야간적용
   *
   * **data type** varchar(모름)
   */
  evernight: string;

  /**
   * 표준체증요금
   *
   * **data type** varchar(모름)
   */
  everaddamt: string;

  /**
   * 총표준유지관리비 합구하기
   *
   * **data type** varchar(모름)
   */
  toteveraddamt: string;

  /**
   * 총표준유지관리비 합구하기
   *
   * **data type** varchar(모름)
   */
  taxflag: string;

  /**
   * 당월 이월 구분
   *
   * **data type** varchar(모름)
   */
  taxchk: string;

  /**
   * 전자세금계산서발행 일
   *
   * **data type** varchar(모름)
   */
  dedate: string;

  /**
   * 휴일구분
   *
   * **data type** varchar(모름)
   */
  taxweek: string;

  /**
   * 지로발행구분
   *
   * **data type** varchar(모름)
   */
  jirogubun: string;

  /**
   * 지로당월익월 확인
   *
   * **data type** varchar(모름)
   */
  ungijun: string;

  /**
   * 지로 일자
   *
   * **data type** varchar(모름)
   */
  jirodate: string;

  /**
   * 지로체크박스
   *
   * **data type** varchar(모름)
   */
  jirochk: string;

  /**
   * 지로체크박스
   *
   * **data type** varchar(모름)
   */
  cltcd: string;

  /**
   * 지로체크박스
   *
   * **data type** varchar(모름)
   */
  cltnm: string;

  /**
   * 매출통합발행체크박스
   *
   * **data type** varchar(모름)
   */
  tax_combine: string;

  /**
   * 매출통합발행 코드
   *
   * **data type** varchar(모름)
   */
  tax_actcd: string;

  /**
   * 매출통합발행 이름
   *
   * **data type** varchar(모름)
   */
  tax_actnm: string;

  /**
   * 계약해지
   *
   * **data type** varchar(모름)
   */
  clsdate: string;

  /**
   * 계약해지접수일
   *
   * **data type** varchar(모름)
   */
  clsrecedate: string;

  /**
   * 해지구분
   *
   * **data type** varchar(모름)
   */
  cls: string;

  /**
   * 해지구분이름
   *
   * **data type** varchar(모름)
   */
  clsnm: string;

  /**
   * 해지사유상세
   *
   * **data type** varchar(모름)
   */
  clsremark: string;

  /**
   * 보류시작일
   *
   * **data type** varchar(모름)
   */
  boryudate: string;

  /**
   * 보류체크박스
   *
   * **data type** varchar(모름)
   */
  boryu: string;

  /**
   * 보류사유
   *
   * **data type** varchar(모름)
   */
  boryusayoo: string;

  /**
   * 보류만료일
   *
   * **data type** varchar(모름)
   */
  boryudate2: string;

  /**
   * 하도급구분
   *
   * **data type** varchar(모름)
   */
  subflag: string;


  /**
   * 하도급사업자번호
   *
   * **data type** varchar(모름)
   */
  subsaupnum: string;

  /**
   * 선진기법도입(원격감시기능)
   *
   * **data type** varchar(모름)
   */
  remflag: string;


  /**
   * 선진기법도입(보수품질우수업체)
   *
   * **data type** varchar(모름)
   */
  exclflag: string;


  /**
   * 적요(매출품목적요)
   *
   * **data type** varchar(모름)
   */
  remark: string;


  /**
   * 비고(세금계산서)
   *
   * **data type** varchar(모름)
   */
  bigo: string;

  /**
   * 비고
   *
   * **data type** varchar(모름)
   */
  bigo2: string;

  /**
   * 번호
   *
   * **data type** varchar(모름)
   */
  autonum: string;

  /**
   * 자동이체여부
   *
   * **data type** varchar(모름)
   */
  autoflag: string;

  /**
   * 월대당가
   *
   * **data type** varchar(모름)
   */
  uamt_per: string;

  /**
   * 말일체크
   *
   * **data type** varchar(모름)
   */
  delchk: string;

  /**
   * 말일체크
   *
   * **data type** varchar(모름)
   */
  delflag: string;

  /**
   * 말일체크
   *
   * **data type** varchar(모름)
   */
  contd: string;

  /**
   * 말일체크
   *
   * **data type** varchar(모름)
   */
  flag: string;

  /**
   * 말일체크
   *
   * **data type** varchar(모름)
   */
  evmon: string;

  /**
   * 말일체크
   *
   * **data type** varchar(모름)
   */
  evju: string;

  /**
   * 말일체크
   *
   * **data type** varchar(모름)
   */
  pubgubun: string;

  /**
   * 말일체크
   *
   * **data type** varchar(모름)
   */
  accyn: string;

  /**
   * 말일체크
   *
   * **data type** varchar(모름)
   */
  pubchk: string;

  /**
   * 말일체크
   *
   * **data type** varchar(모름)
   */
  becontg: string;

  /**
   * 말일체크
   *
   * **data type** varchar(모름)
   */
  ck: string;

  /**
   * 말일체크
   *
   * **data type** varchar(모름)
   */
  ck2: string;

  /**
   * 현장코드
   *
   * **data type** varchar(모름)
   */
  actcd: string;

  /**
   * 모름
   *
   * **data type** varchar(모름)
   */
  combine: string;

  /**
   * 계약서 이름
   *
   * **data type** varchar(모름)
   */
  sitename: string;

  /**
   * 신규인지
   *
   * **data type** varchar(1)
   */
  readonly new: string;

  /**
   * 데이터가 없는 경우 (계약등록 안됨)
   *
   * **data type** varchar(1)
   */
  readonly isEmpty: boolean;

  constructor(data: any = {}, isNew: boolean = false) {
    this.isEmpty = !data.custcd;
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.ordercd = data.ordercd || '';
    this.ordernm = data.ordernm || '';
    this.useyn = data.useyn || '';
    this.contdate = data.contdate || '';
    this.recedate = data.recedate || '';
    this.muperid = data.muperid || '';
    this.mupernm = data.mupernm || '';
    this.stdate = data.stdate || '';
    this.enddate = data.enddate || '';
    this.amt = data.amt || '';
    this.uamt = data.uamt || '';
    this.attcnt = data.attcnt || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.contbildnm = data.contbildnm || '';
    this.evercd = data.evercd || '01';
    this.evernumber = data.evernumber || '6';
    this.everamt = data.everamt || '';
    this.everaddar = data.everaddar || '';
    this.avernumber = data.avernumber || '';
    this.addnumber = data.addnumber || '';
    this.salenm = data.salenm || '';
    this.saleamt = data.saleamt || '';
    this.toevera = data.toevera || '';
    this.insudate = data.insudate || '';
    this.everaddamt = data.everaddamt || '';
    this.toteveraddamt = data.toteveraddamt || '';
    this.dedate = data.dedate || '';
    this.jirodate = data.jirodate || '10';
    this.cltcd = data.cltcd || '';
    this.cltnm = data.cltnm || '';
    this.tax_combine = data.tax_combine || '';
    this.tax_actcd = data.tax_actcd || '';
    this.tax_actnm = data.tax_actnm || '';
    this.clsdate = data.clsdate || '';
    this.clsrecedate = data.clsrecedate || '';
    this.cls = data.cls || '';
    this.clsnm = data.clsnm || '';
    this.clsremark = data.clsremark || '';
    this.boryudate = data.boryudate || '';
    this.boryudate2 = data.boryudate2 || '';
    this.boryusayoo = data.boryusayoo || '';
    this.subsaupnum = data.subsaupnum || '';
    this.remark = data.remark || '';
    this.bigo = data.bigo || '';
    this.bigo2 = data.bigo2 || '';
    this.autonum = data.autonum || '';
    this.autoflag = data.autoflag || '';
    this.uamt_per = data.uamt_per || '';
    this.seq = data.seq || '';
    this.mgubun = data.mgubun || '1';
    this.boryu = data.boryu || '0';
    this.contg = data.contg || '01';
    this.taxchk = data.taxchk || '1';
    this.taxweek = data.taxweek || '0';
    this.delchk = data.delchk || '1';
    this.delflag = data.delflag || '1';
    this.ungijun = data.ungijun || '1';
    this.samt = data.samt || '0';
    this.contd = data.contd || '0';
    this.deljugi = data.deljugi || '01';
    this.delmon1 = data.delmon1 || '01';
    this.delmon2 = data.delmon2 || '02';
    this.delmon3 = data.delmon3 || '03';
    this.delmon4 = data.delmon4 || '04';
    this.delmon5 = data.delmon5 || '05';
    this.delmon6 = data.delmon6 || '06';
    this.delmon7 = data.delmon7 || '07';
    this.delmon8 = data.delmon8 || '08';
    this.delmon9 = data.delmon9 || '09';
    this.delmon10 = data.delmon10 || '10';
    this.delmon11 = data.delmon11 || '11';
    this.delmon12 = data.delmon12 || '12';
    this.addyn = data.addyn || '1';
    this.flag = data.flag || 'Y';
    this.taxflag = data.taxflag || '1';
    this.taxgubun = data.taxgubun || '01';
    this.evmon = data.evmon || '1';
    this.evju = data.evju || '1';
    this.jirogubun = data.jirogubun || '1';
    this.jirochk = data.jirochk || '1';
    this.pubgubun = data.pubgubun || '2';
    this.accyn = data.accyn || '1';
    this.pubchk = data.pubchk || '0';
    this.billgubun = data.billgubun || '1';
    this.gubun = data.gubun || '11';
    this.contgubun = data.contgubun || '01';
    this.becontg = data.becontg || '01';
    this.subflag = data.subflag || '3';
    this.remflag = data.remflag || 'N';
    this.remflag = this.remflag === '0' ? 'N' : this.remflag;
    this.exclflag = data.exclflag || 'N';
    this.ck = data.ck || '1';
    this.ck2 = data.ck2 || '1';
    this.evernight = data.evernight || '0';
    this.actcd = data.actcd || '';
    this.combine = data.combine || '';
    this.sitename = data.sitename || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }

  @computed
  get iAvernumber(): number {
    return parseInt(this.avernumber, 10) || 0;
  }

  @computed
  get iEvernumber(): number {
    return parseInt(this.evernumber, 10) || 0;
  }

  @computed
  get iSum(): number {
    return parseInt(this.everamt, 10) || 0;
  }

  @computed
  get iSum2(): number {
    return parseInt(this.toteveraddamt, 10) || 0;
  }

  @computed
  get iSum3(): number {
    return parseInt(this.saleamt, 10) || 0;
  }
}
