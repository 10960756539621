import { GoChecklist, RiFolder2Line } from 'react-icons/all';
import { Global, TLHF } from '../../../../../constants';
import {
  Button, CheckBox, ComboBox, ComboBoxModel, DateTextBox, FlexLayout, FormatTextBox, TextBox,
} from '../../../../../components';
import { Result } from '../Result';
import ResultModel from '../models/ResultModel';
import { SearchBinding } from '../../../../../models';
import { Format, Parse } from '../../../../../utils/string';


/**
 * 화면 > 메인 테이블
 * @window w_tb_e035_03
 * @category 점검결과등록
 */
export const ResultTableHeader: TLHF<ResultModel, Result> = (scope) => ([
  {
    id: 'chk',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="end"
          justify="center"
        >
          출력
        </FlexLayout>

        <FlexLayout
          align="start"
          justify="center"
        >
          전송
        </FlexLayout>
      </FlexLayout>
    ),
    width: 50,
    render: (x, rowUpdate) => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="center"
          justify="center"
        >
          <CheckBox
            value={x.chk === '1'}
            noMargin={true}
            onChange={(v) => rowUpdate({ chk: v ? '1' : '0' })}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout
          align="center"
          justify="center"
        >
          <CheckBox
            value={x.gosi === '1'}
            noMargin={true}
            onChange={(v) => rowUpdate({ gosi: v ? '1' : '0' })}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'result',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="end"
          justify="center"
        >
          결과
        </FlexLayout>

        <FlexLayout
          align="start"
          justify="center"
        >
          전송결과
        </FlexLayout>
      </FlexLayout>
    ),
    width: 70,
    render: (x, rowUpdate) => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="center"
          justify="center"
        >
          <ComboBox
            textAlign="center"
            value={x.state}
            style={x.state === '2' ? Global.STYLE_COLOR_BLUE : Global.STYLE_COLOR_RED}
            data={[
              { value: '1', remark: '미처리' },
              { value: '2', remark: '처리' },
            ].map((x) => new ComboBoxModel(x.value, x.remark))}
            onSelect={(option) => scope.tableItemChanged(rowUpdate, 'state', option.value)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>


        <FlexLayout
          align="center"
          justify="center"
          style={
            x.sendflag === '2' ? Global.STYLE_COLOR_BLUE
              : x.sendflag === '9' ? Global.STYLE_COLOR_BLUE : Global.STYLE_COLOR_RED
          }
        >
          {
            x.sendflag === '0' ? '미전송'
              : x.sendflag === '2' ? '전송'
                : x.sendflag === '9' ? '대상아님' : '오류'
          }
        </FlexLayout>
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="end"
          justify="start"
        >
          계획
        </FlexLayout>

        <FlexLayout
          align="start"
          justify="start"
          isVertical={true}
        >
          전송대상 {scope.state.send_cnt_tot}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'plandate',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="end"
          justify="center"
        >
          계획일자
        </FlexLayout>

        <FlexLayout
          align="start"
          justify="center"
        >
          점검일자
        </FlexLayout>
      </FlexLayout>
    ),
    width: 100,
    render: (x, rowUpdate, ref) => (
      <FlexLayout
        isVertical={true}
        align="center"
        justify="center"
      >
        <DateTextBox
          ref={ref}
          format="####-##-##"
          textAlign="center"
          readonly={x.state === '2'}
          value={x.plandate}
          onChange={(v) => scope.tableItemChanged(rowUpdate, 'plandate', v)}
        />

        <DateTextBox
          format="####-##-##"
          textAlign="center"
          value={x.rptdate}
          onChange={(v) => scope.rpdateItemChanged(rowUpdate, v)}
        />
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        isVertical={true}
      >
        <FlexLayout
          align="end"
          justify="center"
        >
          점검내역
        </FlexLayout>

        <FlexLayout
          align="start"
          justify="center"
        >
          전송내역
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'self',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="end"
          justify="center"
        >
          점검
        </FlexLayout>

        <FlexLayout
          align="start"
          justify="center"
        >
          일지
        </FlexLayout>
      </FlexLayout>
    ),
    width: 50,
    render: (x) => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="center"
          justify="center"
          style={{
            fontSize: 16,
            ...(x.kclog === '1' ? Global.STYLE_COLOR_BLUE : Global.STYLE_COLOR_RED),
          }}
        >
          {x.kclog === '1' ? 'O' : 'X'}
        </FlexLayout>

        <FlexLayout
          align="center"
          justify="center"
        >
          <Button
            isIcon={true}
            onClick={() => scope.openDetail()}
          >
            <GoChecklist size={24} />
          </Button>
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'wkactcd',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="end"
          justify="center"
        >
          회사
        </FlexLayout>

        <FlexLayout
          align="start"
          justify="center"
        >
          구분
        </FlexLayout>
      </FlexLayout>
    ),
    width: 70,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
        style={x.wkactcd !== '001' ? { color: 'var(--color-violet)' } : ''}
      >
        {scope.state.wkactcds?.filter((y) => y.wkactcd === x.wkactcd)[0]?.wkactnm || '-'}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        isVertical={true}
      >
        <FlexLayout
          align="end"
          justify="start"
          style={Global.STYLE_COLOR_RED}
        >
          미처리 {scope.state.plan_not_tot}
        </FlexLayout>

        <FlexLayout
          align="start"
          justify="start"
          style={Global.STYLE_COLOR_RED}
        >
          전송실패 {scope.state.sendcnt1_tot}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'actcd',
    text: '현장명',
    width: 250,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="start"
      >
        {x.actnm}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        isVertical={true}
      >
        <FlexLayout
          align="end"
          justify="start"
          style={Global.STYLE_COLOR_BLUE}
        >
          점검처리 {scope.state.plan_cnt_tot}
        </FlexLayout>

        <FlexLayout
          align="start"
          justify="start"
          style={Global.STYLE_COLOR_BLUE}
        >
          전송 {scope.state.sendcnt2_tot}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'equpcd',
    text: '호기명',
    width: 100,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
      >
        {x.equpnm}
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        isVertical={true}
        style={Global.STYLE_COLOR_BLUE}
      >
        (담당점검 {scope.state.plan_comp_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'perid',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="end"
          justify="center"
        >
          담당자
        </FlexLayout>

        <FlexLayout
          align="start"
          justify="center"
        >
          보조담당
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    render: (x) => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="center"
          justify="center"
        >
          {x.pernm}
        </FlexLayout>

        <FlexLayout
          align="center"
          justify="center"
        >
          {x.pernm2}
        </FlexLayout>
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        isVertical={true}
        style={{
          color: 'var(--color-green)',
        }}
      >
        지원받음 {scope.state.plan_rece_tot})
      </FlexLayout>
    ),
  },
  {
    id: 'plantime',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="end"
          justify="center"
        >
          시작시간
        </FlexLayout>

        <FlexLayout
          align="start"
          justify="center"
        >
          완료시간
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    render: (x, rowUpdate, ref) => (
      <FlexLayout
        isVertical={true}
        align="center"
        justify="center"
      >
        <FormatTextBox
          ref={ref}
          format="##:##"
          textAlign="center"
          value={x.plantime}
          onChange={(v) => rowUpdate({ plantime: v })}
        />

        <FormatTextBox
          format="##:##"
          textAlign="center"
          value={x.plantime2}
          onChange={(v) => rowUpdate({ plantime2: v })}
        />
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        isVertical={true}
        style={{
          color: 'var(--color-green)',
        }}
      >
        지원처리 {scope.state.plan_jcomp_tot}
      </FlexLayout>
    ),
  },
  {
    id: 'kcperid',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="end"
          justify="center"
          style={Global.STYLE_COLOR_RED}
        >
          처리자
        </FlexLayout>

        <FlexLayout
          align="start"
          justify="center"
          style={Global.STYLE_COLOR_RED}
        >
          보조자
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    render: (x, rowUpdate, ref) => (
      <FlexLayout
        isVertical={true}
        align="center"
        justify="center"
      >
        <TextBox
          ref={ref}
          value={x.kcperid}
          textAlign="center"
          onChange={(value) => rowUpdate({ kcperid: value })}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_JA001',
            {},
            true,
            (item) => {
              rowUpdate({
                kcperid: item.cd,
                kcpernm: item.cdnm,
              });
            },
          )}
        />

        <TextBox
          value={x.kcperid2}
          textAlign="center"
          onChange={(value) => rowUpdate({ kcperid2: value })}
          bindSearchModal={new SearchBinding(
            scope.props.modalStore,
            'TB_JA001',
            {},
            true,
            (item) => {
              rowUpdate({
                kcperid2: item.cd,
                kcpernm2: item.cdnm,
              });
            },
          )}
        />
      </FlexLayout>
    ),
    trail: () => (
      <FlexLayout
        isVertical={true}
      >
        <FlexLayout
          align="end"
          justify="start"
          style={Global.STYLE_COLOR_BLUE}
        >
          총 점검 {Format.number(Parse.make(scope.state.plan_comp_tot) + Parse.make(scope.state.plan_jcomp_tot))}
        </FlexLayout>

        <FlexLayout
          align="start"
          justify="start"
          style={Global.STYLE_COLOR_RED}
        >
          미전송 {scope.state.send_not_tot}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'kcpernm',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="end"
          justify="center"
        >
          처리자명
        </FlexLayout>

        <FlexLayout
          align="start"
          justify="center"
        >
          보조자명
        </FlexLayout>
      </FlexLayout>
    ),
    width: 80,
    render: (x) => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="center"
          justify="center"
        >
          {x.kcpernm}
        </FlexLayout>

        <FlexLayout
          align="center"
          justify="center"
        >
          {x.kcpernm2}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'sendflag',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="end"
          justify="center"
        >
          전송
        </FlexLayout>

        <FlexLayout
          align="start"
          justify="center"
        >
          대상
        </FlexLayout>
      </FlexLayout>
    ),
    width: 50,
    render: (x, rowUpdate) => (
      <CheckBox
        value={x.kcflag === '1'}
        noMargin={true}
        onChange={(v) => scope.tableItemChanged(rowUpdate, 'kcflag', v ? '1' : '0')}
      />
    ),
  },
  {
    id: 'evcd',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="end"
          justify="center"
        >
          점검기종
        </FlexLayout>

        <FlexLayout
          align="start"
          justify="center"
        >
          승강기번호
        </FlexLayout>
      </FlexLayout>
    ),
    width: 150,
    render: (x, rowUpdate, ref) => (
      <FlexLayout
        isVertical={true}
        align="center"
        justify="center"
      >
        <FlexLayout
          align="center"
          justify="center"
        >
          {scope.state.evcds?.filter((y) => y.evlcd === x.evcd)[0]?.evlnm}
        </FlexLayout>
        <FormatTextBox
          ref={ref}
          value={x.elno}
          textAlign="center"
          format="####-###"
          onChange={(v) => scope.tableItemChanged(rowUpdate, 'elno', v)}
        />
      </FlexLayout>
    ),
  },
  {
    id: 'sign',
    text: '고객싸인',
    width: 70,
    render: (x) => (
      <FlexLayout
        align="center"
        justify="center"
        style={x.s1flag === '1' ? Global.STYLE_COLOR_BLUE : Global.STYLE_COLOR_RED}
      >
        {x.s1flag === '1' ? 'O' : 'X'}
      </FlexLayout>
    ),
  },
  {
    id: 'kcsign',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="end"
          justify="center"
        >
          담당싸인
        </FlexLayout>

        <FlexLayout
          align="start"
          justify="center"
        >
          보조싸인
        </FlexLayout>
      </FlexLayout>
    ),
    width: 70,
    render: (x) => (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="end"
          justify="center"
          style={x.ks1flag === '1' ? Global.STYLE_COLOR_BLUE : Global.STYLE_COLOR_RED}
        >
          {x.ks1flag === '1' ? 'O' : 'X'}
        </FlexLayout>

        <FlexLayout
          align="start"
          justify="center"
          style={x.ks2flag === '1' ? Global.STYLE_COLOR_BLUE : Global.STYLE_COLOR_RED}
        >
          {x.ks2flag === '1' ? 'O' : 'X'}
        </FlexLayout>
      </FlexLayout>
    ),
  },
  {
    id: 'actpernm',
    text: '관리주체성명',
    width: 150,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.actpernm}
        onChange={(v) => scope.tableItemChanged(rowUpdate, 'actpernm', v)}
      />
    ),
  },
  {
    id: 'actperhp',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="end"
          justify="center"
        >
          관리자전화번호
        </FlexLayout>

        <FlexLayout
          align="start"
          justify="center"
        >
          관리자 EMAIL
        </FlexLayout>
      </FlexLayout>
    ),
    width: 150,
    render: (x, rowUpdate, ref) => (
      <FlexLayout isVertical={true}>
        <TextBox
          textAlign="center"
          ref={ref}
          value={x.actperhp}
          onChange={(v) => scope.tableItemChanged(rowUpdate, 'actperhp', v)}
        />

        <TextBox
          value={x.email}
          onChange={(v) => scope.tableItemChanged(rowUpdate, 'email', v)}
        />
      </FlexLayout>
    ),
  },
  {
    id: 'remark',
    text: '특이사항',
    width: 250,
    render: (x, rowUpdate, ref) => (
      <TextBox
        ref={ref}
        value={x.remark}
        onChange={(v) => rowUpdate({ remark: v })}
      />
    ),
  },
  {
    id: 'attcnt',
    text: (
      <FlexLayout isVertical={true}>
        <FlexLayout
          align="end"
          justify="center"
        >
          첨부
        </FlexLayout>

        <FlexLayout
          align="start"
          justify="center"
        >
          (사진)
        </FlexLayout>
      </FlexLayout>
    ),
    width: 60,
    render: (x) => (
      <FlexLayout isVertical={true}>
        <Button
          isIcon={true}
          onClick={() => scope.fileModal(true)}
        >
          <RiFolder2Line size={17}/>
        </Button>

        <TextBox
          size={24}
          value={parseInt(x.attcnt, 10) > 0 ? 'O' : 'X'}
          textAlign="center"
          readonly={true}
          transparent={true}
          style={parseInt(x.attcnt, 10) > 0 ? Global.STYLE_COLOR_BLUE : Global.STYLE_COLOR_RED}
        />
      </FlexLayout>
    ),
  },
  // {
  //   id: 'replace',
  //   text: '교체항목',
  //   width: 750,
  //   render: (x, updateRow) => (
  //     <FlexLayout isVertical={true}>
  //       <FlexLayout
  //         align="center"
  //         justify="start"
  //       >
  //         <FlexLayout>
  //           <CheckBox
  //             value={x.part_nm1 === '1'}
  //             onChange={(v) => updateRow({ part_nm1: v ? '1' : '0' })}
  //           />
  //           &nbsp;조속기
  //         </FlexLayout>
  //
  //         <FlexLayout>
  //           <CheckBox
  //             value={x.part_nm2 === '1'}
  //             onChange={(v) => updateRow({ part_nm2: v ? '1' : '0' })}
  //           />
  //           &nbsp;로프브레이크
  //         </FlexLayout>
  //
  //         <FlexLayout>
  //           <CheckBox
  //             value={x.part_nm3 === '1'}
  //             onChange={(v) => updateRow({ part_nm3: v ? '1' : '0' })}
  //           />
  //           &nbsp;도어잠금장치
  //         </FlexLayout>
  //
  //         <FlexLayout>
  //           <CheckBox
  //             value={x.part_nm4 === '1'}
  //             onChange={(v) => updateRow({ part_nm4: v ? '1' : '0' })}
  //           />
  //           &nbsp;비상정지장치
  //         </FlexLayout>
  //
  //         <FlexLayout>
  //           <CheckBox
  //             value={x.part_nm5 === '1'}
  //             onChange={(v) => updateRow({ part_nm5: v ? '1' : '0' })}
  //           />
  //           &nbsp;완충기
  //         </FlexLayout>
  //
  //         <FlexLayout>
  //           <CheckBox
  //             value={x.part_nm6 === '1'}
  //             onChange={(v) => updateRow({ part_nm6: v ? '1' : '0' })}
  //           />
  //           &nbsp;역구동방지장치
  //         </FlexLayout>
  //
  //         <FlexLayout>
  //           <CheckBox
  //             value={x.part_nm7 === '1'}
  //             onChange={(v) => updateRow({ part_nm7: v ? '1' : '0' })}
  //           />
  //           &nbsp;기판(제어반)
  //         </FlexLayout>
  //       </FlexLayout>
  //       <FlexLayout
  //         align="center"
  //         justify="start"
  //       >
  //         <FlexLayout>
  //           <CheckBox
  //             value={x.part_nm8 === '1'}
  //             onChange={(v) => updateRow({ part_nm8: v ? '1' : '0' })}
  //           />
  //           &nbsp;EL제동기
  //         </FlexLayout>
  //
  //         <FlexLayout>
  //           <CheckBox
  //             value={x.part_nm9 === '1'}
  //             onChange={(v) => updateRow({ part_nm9: v ? '1' : '0' })}
  //           />
  //           &nbsp;와이어로프
  //         </FlexLayout>
  //
  //         <FlexLayout>
  //           <CheckBox
  //             value={x.part_nm10 === '1'}
  //             onChange={(v) => updateRow({ part_nm10: v ? '1' : '0' })}
  //           />
  //           &nbsp;리미트스위치
  //         </FlexLayout>
  //
  //         <FlexLayout>
  //           <CheckBox
  //             value={x.part_nm11 === '1'}
  //             onChange={(v) => updateRow({ part_nm11: v ? '1' : '0' })}
  //           />
  //           &nbsp;럽쳐밸브
  //         </FlexLayout>
  //
  //         <FlexLayout>
  //           <CheckBox
  //             value={x.part_nm12 === '1'}
  //             onChange={(v) => updateRow({ part_nm12: v ? '1' : '0' })}
  //           />
  //           &nbsp;스텝
  //         </FlexLayout>
  //
  //         <FlexLayout>
  //           <CheckBox
  //             value={x.part_nm13 === '1'}
  //             onChange={(v) => updateRow({ part_nm13: v ? '1' : '0' })}
  //           />
  //           &nbsp;스텝체인
  //         </FlexLayout>
  //
  //         <FlexLayout>
  //           <CheckBox
  //             value={x.part_nm14 === '1'}
  //             onChange={(v) => updateRow({ part_nm14: v ? '1' : '0' })}
  //           />
  //           &nbsp;ES제동기
  //         </FlexLayout>
  //       </FlexLayout>
  //     </FlexLayout>
  //   ),
  // },
]);
