import * as React from 'react';
import { action, computed } from 'mobx';
import { inject } from 'mobx-react';
import style from './TelnumTextBox.module.scss';
import { JoinClassName } from '../../../utils/string';
import { FlexLayout } from '../../layout';
import { LayoutProps } from '../../../constants';
import { SearchBinding } from '../../../models/common';
import { PublicStore } from '../../../stores';

interface TelnumTextBoxActions {
  onChange?: (value: any) => any;
  onBlur?: (value: any) => any;
  onEnter?: (value: any) => any;
}

interface TelnumTextBoxProps extends TelnumTextBoxActions, LayoutProps {
  publicStore?: PublicStore;
  refs?: React.RefObject<any>;
  value?: any;
  color?: string;
  bold?: boolean;
  textAlign?: 'left' | 'center' | 'right';
  placeholder?: string;
  className?: any;
  style?: any;
  readonly?: boolean;
  transparent?: boolean;
  isDisabledTrackingStateChange?: boolean;
  bindSearchModal?: SearchBinding;
  head?: React.ReactNode;
  trail?: React.ReactNode;
}

interface FormatNumberTextBoxState {
  value: string;
  isFocused: boolean;
}

@inject('publicStore')
export class TelnumTextBox
  extends React.Component<TelnumTextBoxProps, FormatNumberTextBoxState> {
  refInput: React.RefObject<HTMLInputElement>;

  constructor(props: TelnumTextBoxProps, context: any) {
    super(props, context);
    this.refInput = props.refs || React.createRef();
    this.state = {
      value: this.result(this.props.value ?? ''),
      isFocused: false,
    };
  }

  componentDidUpdate(prevProps: Readonly<TelnumTextBoxProps>) {
    !this.state.isFocused
      && this.props.value !== prevProps.value
      && this.setState({ value: this.result(this.props.value ?? '') });
  }

  @action
  public forceUpdate() {
    this.setState({
      value: this.props.value,
    });
  }

  @computed
  get value(): string {
    return this.state.value.replace(/-/ig, '');
  }

  @computed
  get formatValue(): string {
    return this.result(this.state.value);
  }

  // eslint-disable-next-line class-methods-use-this
  result(value: string): string {
    const v = value.replace(/-/ig, '');

    switch (v.length) {
      case 7:
        return `${v.substr(0, 3)}-${v.substr(3, 4)}`;

      case 8:
        return `${v.substr(0, 4)}-${v.substr(4, 4)}`;

      case 9:
        return `${v.substr(0, 2)}-${v.substr(2, 3)}-${v.substr(5, 4)}`;

      case 10:
        if (v.startsWith('02')) {
          return `${v.substr(0, 2)}-${v.substr(2, 4)}-${v.substr(6, 4)}`;
        }
        return `${v.substr(0, 3)}-${v.substr(3, 3)}-${v.substr(6, 4)}`;

      case 11:
        return `${v.substr(0, 3)}-${v.substr(3, 4)}-${v.substr(7, 4)}`;

      default:
        return v;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  isNumberChar(ch: string): boolean {
    return (ch.charCodeAt(0) > 47
      && ch.charCodeAt(0) < 58);
  }

  // eslint-disable-next-line class-methods-use-this
  isChar(ch: string): boolean {
    return (ch.charCodeAt(0) > 31
      && ch.charCodeAt(0) < 127);
  }

  @action
  callOnChange(value: string) {
    const { publicStore } = this.props;
    this.props.onChange && this.props.onChange(value);

    if (!this.props.isDisabledTrackingStateChange) {
      publicStore?.setStateChanged(true);
    }
  }

  @action
  public focus() {
    requestAnimationFrame(() => this.refInput.current?.focus());
  }

  render() {
    return (
      <FlexLayout
        className={JoinClassName.make([
          style.container,
          this.props.className,
          this.props.transparent ? style.transparent : false,
        ])}
        style={this.props.style}
        weight={this.props.weight}
        size={this.props.size}
        justify="center"
        align="center"
      >
        {this.props.head}
        {!this.props.value && this.props.placeholder && (
          <label>{this.props.placeholder}</label>
        )}
        <input
          ref={this.refInput}
          className={this.props.readonly ? style.readonly : undefined}
          style={{
            textAlign: this.props?.textAlign,
            color: this.props?.color,
            fontWeight: this.props?.bold ? 600 : 'inherit',
          }}
          value={this.state.isFocused ? this.value : this.formatValue}
          readOnly={this.props.readonly}
          onChange={(e) => this.setState({ value: this.result(e.target.value) })}
          onDoubleClick={() => this.props.bindSearchModal && !this.props.readonly && this.props.bindSearchModal.open()}
          onBlur={() => {
            this.setState({ isFocused: false });
            this.callOnChange(this.value);
            setTimeout(() => {
              this.props.onBlur && this.props.onBlur(this.value);
            });
          }}
          onFocus={() => this.setState({ isFocused: true })}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              e.preventDefault();
              if (this.props.bindSearchModal && !this.props.readonly) {
                this.props.bindSearchModal.open({
                  as_nm: this.props.value,
                });
              } else {
                this.props.onEnter && this.props.onEnter(this.props.value);
              }
            }
          }}
        />
        {this.props.trail}
      </FlexLayout>
    );
  }
}
