/**
 * 모델
 * @window w_tb_e101w_01
 * @category 보수현장현황
 */
import { computed } from 'mobx';

export class UnitModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   * 사업장코드
   *
   * **data type** varchar(2)
   */
  spjangnm: string;

  /**
   * No
   *
   * **data type** varchar(?)
   */
  no: number;

  /**
   * 시작일자
   *
   * **data type** varchar(8)
   */
  stdate: string;

  /**
   * 만료일자
   *
   * **data type** varchar(8)
   */
  enddate: string;

  /**
   * 담당자코드
   *
   * **data type** varchar(10)
   */
  perid: string;

  /**
   * 담당자
   *
   * **data type** varchar(30)
   */
  pernm: string;

  /**
   * 회사구분
   *
   * **data type** varchar(3)
   */
  wkactcd: string;

  /**
   * 회사구분
   *
   * **data type** varchar(3)
   */
  wkactnm: string;

  /**
   * 현장코드
   *
   * **data type** varchar(15)
   */
  actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(100)
   */
  actnm: string;

  /**
   * 호기코드
   *
   * **data type** varchar(3)
   */
  equpcd: string;

  /**
   * 호기명
   *
   * **data type** varchar(100)
   */
  equpnm: string;

  /**
   * 최초설치년도
   *
   * **data type** varchar(8)
   */
  yyyymm: string;

  /**
   * 년수
   *
   * **data type** varchar(8)
   */
  gigan: string;

  /**
   * 설치년도
   *
   * **data type** varchar(8)
   */
  yyyymm2: string;

  /**
   * 년수
   *
   * **data type** varchar(8)
   */
  gigan2: string;

  /**
   * 정밀검사(회)
   *
   * **data type** varchar(?)
   */
  gubun002: string;

  /**
   * 계약구분
   *
   * **data type** varchar(2)
   */
  contg: string;

  /**
   * 계약분류
   *
   * **data type** varchar(10)
   */
  contgubun: string;

  /**
   * 대당금액
   *
   * **data type** varchar(?)
   */
  monamt: string;

  /**
   * 운행층수
   *
   * **data type** varchar(?)
   */
  number: string;

  /**
   * 보수업체
   *
   * **data type** varchar(200)
   */
  bosunm: string;

  /**
   * 현장담당자
   *
   * **data type** varchar(?)
   */
  actpernm: string;

  /**
   * 전화번호
   *
   * **data type** varchar(?)
   */
  tel: string;

  /**
   * 주소
   *
   * **data type** varchar(100)
   */
  address: string;

  /**
   * 상세주소
   *
   * **data type** varchar(100)
   */
  address2: string;

  /**
   * 제작
   *
   * **data type** varchar(200)
   */
  jejak: string;

  /**
   * 모델
   *
   * **data type** varchar(20)
   */
  mdnm: string;

  /**
   * 속도
   *
   * **data type** varchar(3)
   */
  spped: string;

  /**
   * 점검기종
   *
   * **data type** varchar(30)
   */
  evnm: string;

  /**
   * 분류
   *
   * **data type** varchar(20)
   */
  classnm: string;

  /**
   * 인화물구분
   *
   * **data type** varchar(1)
   */
  class2: string;

  /**
   * 수주여부
   *
   * **data type** varchar(1)
   */
  ordcnt: number;

  /**
   * 대당금액
   *
   * **data type** varchar(1)
   */
  daeamt: string;

  /**
   * 총계
   *
   * **data type** varchar(1)
   */
  total: string;

  /**
   * 운행종료일
   *
   * **data type** varchar(8)
   */
  kcenddate: string;

  /**
   * 최종검사일
   *
   * **data type** varchar(8)
   */
  totkcdate: string;

  /**
   * 승강기번호
   *
   * **data type** varchar(8)
   */
  elno: string;

  /**
   * 대당가
   *
   * **data type** varchar(8)
   */
  equpamt: string;

  /**
   * 건물용도
   *
   * **data type** varchar(8)
   */
  bildyd: string;

  /**
   * PLC
   *
   * **data type** varchar(8)
   */
  plc: string;

  /**
   * 구분
   *
   * **data type** varchar(8)
   */
  elignm: string;

  /**
   * 형식
   *
   * **data type** varchar(8)
   */
  elifnm: string;

  /**
   * 사양
   *
   * **data type** varchar(8)
   */
  sizecd: string;

  /**
   * 정원
   *
   * **data type** varchar(8)
   */
  nperson: string;

  /**
   * 무게
   *
   * **data type** varchar(8)
   */
  height: string;

  /**
   * 주기
   *
   * **data type** varchar(8)
   */
  jugi: string;

  /**
   * 담당부서
   *
   * **data type** varchar(8)
   */
  divinm: string;

  /**
   * 지역명
   *
   * **data type** varchar(8)
   */
  areanm: string;

  /**
   * 우편번호
   *
   * **data type** varchar(8)
   */
  zipcode: string;

  /**
   * cctv
   *
   * **data type** varchar(8)
   */
  cctv: string;

  /**
   * 신규인지
   *
   * **data type** varchar(1)
   */
  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.spjangnm = data.spjangnm || '';
    this.no = data.no || 0;
    this.stdate = data.stdate || '';
    this.enddate = data.enddate || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.wkactcd = data.wkactcd || '';
    this.wkactnm = data.wkactnm || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.equpcd = data.equpcd || '';
    this.equpnm = data.equpnm || '';
    this.yyyymm = data.yyyymm || '';
    this.gigan = data.gigan || '';
    this.yyyymm2 = data.yyyymm2 || '';
    this.gigan2 = data.gigan2 || '';
    this.gubun002 = data.gubun002 || '';
    this.contg = data.contg || '';
    this.contgubun = data.contgubun || '';
    this.monamt = data.monamt || '';
    this.number = data.number || '';
    this.bosunm = data.bosunm || '';
    this.actpernm = data.actpernm || '';
    this.tel = data.tel || '';
    this.address = data.address || '';
    this.address2 = data.address2 || '';
    this.jejak = data.jejak || '';
    this.mdnm = data.mdnm || '';
    this.spped = data.spped || '';
    this.evnm = data.evnm || '';
    this.classnm = data.classnm || '';
    this.class2 = data.class2 || '';
    this.no = data.no || '';
    this.ordcnt = data.ordcnt || 0;
    this.daeamt = data.daeamt || '';
    this.total = data.total || '';
    this.kcenddate = data.kcenddate || '';
    this.totkcdate = data.totkcdate || '';
    this.elno = data.elno || '';
    this.equpamt = data.equpamt || '';
    this.bildyd = data.bildyd || '';
    this.plc = data.plc || '';
    this.elifnm = data.elifnm || '';
    this.sizecd = data.sizecd || '';
    this.elignm = data.elignm || '';
    this.nperson = data.nperson || '';
    this.height = data.height || '';
    this.jugi = data.jugi || '';
    this.divinm = data.divinm || '';
    this.areanm = data.areanm || '';
    this.zipcode = data.zipcode || '';
    this.cctv = data.cctv || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
