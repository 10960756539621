import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import {
  Button,
  DateTextBox,
  FlexLayout,
  FormatNumberTextBox,
  FormatTextBox,
  GridLayout,
  LayoutTitle,
  ModalView,
  SearchLayout,
  SubHeader,
  TabView,
  TextBox,
} from '../../../../components';
import {
  BankStatement,
  BankStatementTabDataStateNames,
} from './BankStatement';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { Date8 } from '../../../../utils/time';
import { SearchBinding } from '../../../../models/common';
import { Sum } from '../../../../utils/array';
import { Format } from '../../../../utils/string';

const acccds = [
  { value: '1011', remark: '현금' },
  { value: '1012', remark: '현금(외화)' },
  { value: '1013', remark: '당좌예금' },
  { value: '1015', remark: '보통예금' },
  { value: '1016', remark: '보통예금(외화)' },
  { value: '1031', remark: '정기예금' },
  { value: '1032', remark: '정기적금' },
  { value: '1611', remark: '정기예금' },
  { value: '1684', remark: '퇴직보험예치금' },
  { value: '2112', remark: '단기차입금(일반)' },
];

const signflags = [
  { value: '0', remark: '미연동' },
  { value: '1', remark: '연동계좌' },
  { value: '2', remark: '실패' },
  { value: '3', remark: '연동대기' },
];

/**
 * 화면
 * @window w_tb_ca642w_10
 * @category 입출금현황
 */
export const BankStatementTemplate: React.FC<TemplateProps<BankStatement>> = ({
  scope,
}) => {
  // @ts-ignore
  const setData = (data: any) => scope?.setState(data);

  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <SearchLayout>
          <FlexLayout size={200}>
            <span>기간</span>
            <DateTextBox
              value={scope.state.stdate}
              textAlign="center"
              format="####-##-##"
              onChange={(value) => setData({ stdate: value })}
              onEnter={() => scope.onRetrieveEvent()}
              head={(<button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() - 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>)}
              trail={(<button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>)}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={180}>
            <span style={{ marginLeft: 8 }}>~</span>
            <DateTextBox
              value={scope.state.enddate}
              textAlign="center"
              format="####-##-##"
              onChange={(value) => setData({ enddate: value })}
              onEnter={() => scope.onRetrieveEvent()}
              head={(<button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() - 1);
                  scope.setState({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>)}
              trail={(<button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>)}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={400}>
            <span style={{ color: 'var(--color-red)' }}>계좌번호</span>
            <TextBox
              weight={1.5}
              value={scope.state.bankcds}
              textAlign="center"
              onChange={(value) => setData({ bankcds: value })}
              isDisabledTrackingStateChange={true}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_AA040',
                {},
                true,
                (item) => {
                  setData({
                    bankcds: item.cd,
                    banknms: item.uv_arg1,
                    accnums: item.uv_arg3,
                  });
                  scope.onRetrieveEvent();
                },
              )}
            />
            <TextBox
              value={scope.state.banknms}
              weight={1.5}
              readonly={true}
            />
            <TextBox
              value={scope.state.accnums}
              weight={1.7}
              readonly={true}
            />
          </FlexLayout>

          <FlexLayout/>
          <Button onClick={() => scope.textModal(true)}>
            입출금비교
          </Button>
        </SearchLayout>
      </FlexLayout>

      <FlexLayout>
        <FlexLayout weight={3}>
          <GridLayout
            ref={scope.grid}
            header={[
              {
                id: 'banknm',
                text: '은행명',
                width: 13,
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합 계
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.banknm}
                  </FlexLayout>
                ),
              },
              {
                id: 'accname',
                text: '계좌명칭',
                width: 14,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.accname}
                  </FlexLayout>
                ),
              },
              {
                id: 'accnum',
                text: '계좌번호',
                width: 14,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.accnum}
                  </FlexLayout>
                ),
              },
              {
                id: 'acccd',
                text: '계좌계정',
                width: 9,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.acccd === '2112' ? 'var(--color-red)' : 'var(--color-blue)' }}>
                    {acccds.filter((y) => y.value === x.acccd)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'beamt',
                text: '전일잔액',
                width: 9,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.beamt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.beamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'misamt',
                text: '입금금액',
                width: 9,
                sum: (x, data) => {
                  const sumMisamt = Sum.byGroup(data, 'misamt', x, []);
                  return (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(sumMisamt)}
                    </FlexLayout>
                  );
                },
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.misamt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.misamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'mijamt',
                text: '지급금액',
                width: 9,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.mijamt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.mijamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'resuamt',
                text: '잔액',
                width: 9,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.resuamt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.resuamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'signflag',
                text: '연동',
                width: 5,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                    style={{
                      // eslint-disable-next-line no-nested-ternary
                      color: x.signflag === '2'
                        ? 'var(--color-red)'
                        : x.signflag === '1' ? 'var(--color-blue)' : '',
                    }}>
                    {signflags.filter((y) => y.value === x.signflag)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'bank_resuamt',
                text: '은행잔액',
                width: 9,
                render: (x) => (
                  <FormatNumberTextBox
                    char={','}
                    charAt={3}
                    textAlign="right"
                    value={x.bank_resuamt}
                  />
                ),
              },
              {
                id: 'compare',
                text: '비교',
                width: 5,
                render: (x) => (
                  <FlexLayout
                    align='center'
                    justify='center'
                    style={{
                      color: x.compare === '상이' ? 'var(--color-red)' : 'var(--color-blue)',
                    }}
                  >
                    {x.signflag === '1' ? x.compare : ''}
                  </FlexLayout>
                ),
              },
            ]}
             data={scope.state.data}
             infinityHandler={scope.infinity}
             rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
             onRowClick={(item, index) => scope.onRowFocusEvent(item, index)}
             onRowDoubleClick={() => scope.textModal(true)}
          />
        </FlexLayout>
      </FlexLayout>

      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <TabView
          tabs={scope.tabs}
          focused={scope.state.focusedTab}
          onChange={(tab) => scope.onTabChange(tab)}
        />
      </FlexLayout>
      <FlexLayout weight={1.5}>
        <GridLayout
          ref={scope.grid2}
          scope={scope}
          header={scope.tabHeaders[scope.tabIndex]}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          infinityHandler={scope.infinity2}
          // @ts-ignore
          data={scope.state[BankStatementTabDataStateNames[scope.tabIndex]] || [] }
        />
      </FlexLayout>

      <ModalView
        isVisible={scope.state.textDetailModal}
        onClose={() => scope.textModal(false)}
        width={1800}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1} >
            <SearchLayout>
              <FlexLayout size={200}>
                <span>기간</span>
                <DateTextBox
                  value={scope.state.stdate}
                  textAlign="center"
                  format="####-##-##"
                  onChange={(value) => setData({ stdate: value })}
                  onEnter={() => scope.onRetrieveEvent()}
                  head={(<button
                    onClick={() => {
                      const date = new Date(Date8.withDash(scope.state.stdate));
                      date.setDate(date.getDate() - 1);
                      scope.setState({ stdate: Date8.make(date) },
                        () => scope.onRetrieveEvent());
                    }}
                  >
                    <MdKeyboardArrowLeft size={24}/>
                  </button>)}
                  trail={(<button
                    onClick={() => {
                      const date = new Date(Date8.withDash(scope.state.stdate));
                      date.setDate(date.getDate() + 1);
                      scope.setState({ stdate: Date8.make(date) },
                        () => scope.onRetrieveEvent());
                    }}
                  >
                    <MdKeyboardArrowRight size={24}/>
                  </button>)}
                  isDisabledTrackingStateChange={true}
                />
              </FlexLayout>

              <FlexLayout size={180}>
                <span style={{ marginLeft: 8 }}>~</span>
                <DateTextBox
                  value={scope.state.enddate}
                  textAlign="center"
                  format="####-##-##"
                  onChange={(value) => setData({ enddate: value })}
                  onEnter={() => scope.onRetrieveEvent()}
                  head={(<button
                    onClick={() => {
                      const date = new Date(Date8.withDash(scope.state.enddate));
                      date.setDate(date.getDate() - 1);
                      scope.setState({ enddate: Date8.make(date) },
                        () => scope.onRetrieveEvent());
                    }}
                  >
                    <MdKeyboardArrowLeft size={24}/>
                  </button>)}
                  trail={(<button
                    onClick={() => {
                      const date = new Date(Date8.withDash(scope.state.enddate));
                      date.setDate(date.getDate() + 1);
                      scope.setState({ enddate: Date8.make(date) },
                        () => scope.onRetrieveEvent());
                    }}
                  >
                    <MdKeyboardArrowRight size={24}/>
                  </button>)}
                  isDisabledTrackingStateChange={true}
                />
              </FlexLayout>

              <Button onClick={() => scope.textModal(true)}>
                조회
              </Button>
            </SearchLayout>

            <FlexLayout isVertical={true} weight={0.4}>
              <FlexLayout>
                입금액:
                <FlexLayout
                  style={{
                    color: scope.state.popupData?.rcv === '같음' ? 'var(--color-blue)' : 'var(--color-red)',
                  }}
                >
                  {!scope.state.popupData?.rcvamt ? '동일' : scope.state.popupData?.rcv}
                </FlexLayout>
                지급액:
                <FlexLayout
                  style={{
                    color: scope.state.popupData?.snd === '같음' ? 'var(--color-blue)' : 'var(--color-red)',
                  }}
                >
                  {!scope.state.popupData?.sndamt ? '동일' : scope.state.popupData?.snd}
                </FlexLayout>
                잔액:
                <FlexLayout
                  style={{
                    color: scope.state.popupData?.resu === '같음' ? 'var(--color-blue)' : 'var(--color-red)',
                  }}
                >
                  {!scope.state.popupData?.resuamt ? '동일' : scope.state.popupData?.resu}
                </FlexLayout>
              </FlexLayout>

              <FlexLayout>
                <FlexLayout>
                  {scope.state.popupData?.rcvamt
                    ? <FlexLayout>
                      차액:
                      <FlexLayout style={{ color: 'var(--color-red)' }}>
                        {scope.state.popupData?.rcvamt}
                      </FlexLayout>
                    </FlexLayout>
                    : ''}
                </FlexLayout>

                <FlexLayout>
                  {scope.state.popupData?.sndamt
                    ? <FlexLayout>
                      차액:
                      <FlexLayout style={{ color: 'var(--color-red)' }}>
                        {scope.state.popupData?.sndamt}
                      </FlexLayout>
                    </FlexLayout>
                    : ''}
                </FlexLayout>

                <FlexLayout>
                  {scope.state.popupData?.resuamt
                    ? <FlexLayout>
                      차액:
                      <FlexLayout style={{ color: 'var(--color-red)' }}>
                        {scope.state.popupData?.resuamt}
                      </FlexLayout>
                    </FlexLayout>
                    : ''}
                </FlexLayout>
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>

        <FlexLayout weight={12} style={{ padding: 8 }}>
          <FlexLayout isVertical={true}>
            <FlexLayout size={20}>
              <LayoutTitle size={150}>은행내역</LayoutTitle>
            </FlexLayout>

            <FlexLayout>
              <GridLayout
                header={[
                  {
                    id: 'misdate',
                    text: '발생일자',
                    width: 16,
                    trail: () => (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        합 계
                      </FlexLayout>
                    ),
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                        style={{ color: x.flag === '1' ? 'var(--color-red)' : '' }}
                      >
                        {Date8.withDash(x.misdate)}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'cltcd',
                    text: '시간',
                    width: 16,
                    render: (x) => (
                      <FormatTextBox
                        textAlign="center"
                        format="##:##"
                        color={ x.flag === '1' ? 'var(--color-red)' : '' }
                        value={x.cltcd}
                      />
                    ),
                  },
                  {
                    id: 'cltnm',
                    text: '지급처명',
                    width: 17,
                    render: (x) => (
                      <FlexLayout
                        justify="left"
                        align="center"
                        style={{ color: x.flag === '1' ? 'var(--color-red)' : '' }}
                      >
                        {x.cltnm}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'misamt',
                    text: '입금액',
                    width: 17,
                    trail: () => (
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {scope.state.pmisamt_tot}
                      </FlexLayout>
                    ),
                    render: (x) => (
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: x.flag === '1' ? 'var(--color-red)' : '' }}
                      >
                        {Format.number(x.misamt)}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'mijamt',
                    text: '지급액',
                    width: 17,
                    trail: () => (
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {scope.state.pmijamt_tot}
                      </FlexLayout>
                    ),
                    render: (x) => (
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: x.flag === '1' ? 'var(--color-red)' : '' }}
                      >
                        {Format.number(x.mijamt)}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'resuamt',
                    text: '잔액',
                    width: 17,
                    trail: () => (
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {scope.state.presuamt_tot}
                      </FlexLayout>
                    ),
                    render: (x) => (
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: x.flag === '1' ? 'var(--color-red)' : '' }}
                      >
                        {Format.number(x.resuamt)}
                      </FlexLayout>
                    ),
                  },
                ]}
                data={scope.state.popupbank}
                rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              />
            </FlexLayout>

            <SubHeader>
            </SubHeader>
          </FlexLayout>

          <FlexLayout isVertical={true}>
            <FlexLayout size={20}>
              <LayoutTitle size={150}>ERP내역</LayoutTitle>
            </FlexLayout>

            <FlexLayout>
              <GridLayout
                header={[
                  {
                    id: 'misdate',
                    text: '발생일자',
                    width: 12,
                    trail: () => (
                      <FlexLayout
                        justify="center"
                        align="center"
                      >
                        합 계
                      </FlexLayout>
                    ),
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                        style={{ color: x.flag === '1' ? 'var(--color-red)' : '' }}
                      >
                        {Date8.withDash(x.misdate)}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'misnum',
                    text: '번호',
                    width: 10,
                    render: (x) => (
                      <FlexLayout
                        justify="center"
                        align="center"
                        style={{ color: x.flag === '1' ? 'var(--color-red)' : '' }}
                      >
                        {x.misnum}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'remark',
                    text: '적요',
                    width: 21,
                    render: (x) => (
                      <FlexLayout
                        justify="left"
                        align="center"
                        style={{ color: x.flag === '1' ? 'var(--color-red)' : '' }}
                      >
                        {x.remark}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'cltnm',
                    text: '지급처명',
                    width: 20,
                    render: (x) => (
                      <FlexLayout
                        justify="left"
                        align="center"
                        style={{ color: x.flag === '1' ? 'var(--color-red)' : '' }}
                      >
                        {x.cltnm}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'misamt',
                    text: '입금액',
                    width: 12,
                    trail: () => (
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {scope.state.pmisamt_tot2}
                      </FlexLayout>
                    ),
                    render: (x) => (
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: x.flag === '1' ? 'var(--color-red)' : '' }}
                      >
                        {Format.number(x.misamt)}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'mijamt',
                    text: '지급액',
                    width: 12,
                    trail: () => (
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {scope.state.pmijamt_tot2}
                      </FlexLayout>
                    ),
                    render: (x) => (
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: x.flag === '1' ? 'var(--color-red)' : '' }}
                      >
                        {Format.number(x.mijamt)}
                      </FlexLayout>
                    ),
                  },
                  {
                    id: 'resuamt',
                    text: '잔액',
                    width: 13,
                    trail: () => (
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {scope.state.presuamt_tot2}
                      </FlexLayout>
                    ),
                    render: (x) => (
                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: x.flag === '1' ? 'var(--color-red)' : '' }}
                      >
                        {Format.number(x.resuamt)}
                      </FlexLayout>
                    ),
                  },
                ]}
                data={scope.state.popupdetail}
                rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              />
            </FlexLayout>

            <SubHeader>
              <FlexLayout justify="end">
                <Button onClick={() => scope.textModal(false)}>닫기</Button>
              </FlexLayout>
            </SubHeader>
          </FlexLayout>
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
