// eslint-disable-next-line max-classes-per-file
import { computed } from 'mobx';

/**
 * 모델
 * @window w_tb_ca501_cltcd
 * @category 제품거래처별단가등록
 */
export class ProductAccountModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 회사구분
   *
   * **data type** varchar(5)
   */
  wkactcd: string;

  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  phm_cust: string;

  /**
   * 제품코드
   *
   * **data type** varchar(20)
   */
  phm_pcod: string;

  /**
   * 품목명
   *
   * **data type** varchar(60)
   */
  phm_pnam: string;

  /**
   * 품목명(한글명)
   *
   * **data type** varchar(60)
   */
  phm_knam: string;

  /**
   * 품목분류
   *
   * **data type** varchar(20)
   */
  phm_mode: string;

  /**
   * 규격
   *
   * **data type** varchar(60)
   */
  phm_size: string;

  /**
   * 단위
   *
   * **data type** varchar(10)
   */
  phm_unit: string;

  /**
   * 크기/치수
   *
   * **data type** varchar(255)
   */
  psize: string;

  /**
   * 기종
   *
   * **data type** varchar(255)
   */
  pmodel: string;

  /**
   * 품목구분
   *
   * **data type** varchar(2)
   */
  phm_pgun: string;

  /**
   * 품목단위
   *
   * **data type** float
   */
  phm_puni: string;

  /**
   * 품목기본
   *
   * **data type** varchar(1)
   */
  phm_def: string;

  /**
   * 적정재고
   *
   * **data type** float
   */
  phm_jqty: string;

  /**
   * 모름
   *
   * **data type** float
   */
  phm_bqty: string;

  /**
   * 모름
   *
   * **data type** float
   */
  phm_scur: string;

  /**
   * 모름
   *
   * **data type** float
   */
  class: string;

  /**
   * 대분류
   *
   * **data type** varchar(5)
   */
  phm_sgrb: string;

  /**
   * 중분류
   *
   * **data type** varchar(5)
   */
  bgroup: string;

  /**
   * 소분류
   *
   * **data type** varchar(5)
   */
  cgroup: string;

  /**
   * 연결코드
   *
   * **data type** varchar(20)
   */
  lnkcod: string;

  /**
   * 모름
   *
   * **data type** varchar(4)
   */
  phm_mncd: string;

  /**
   * 무게
   *
   * **data type** varchar(50)
   */
  weight: string;

  /**
   * 색깔
   *
   * **data type** varchar(30)
   */
  color: string;

  /**
   * 모름
   *
   * **data type** varchar(10)
   */
  tvtype: string;

  /**
   * 모름
   *
   * **data type** varchar(30)
   */
  lenstype: string;

  /**
   * 구매단가
   *
   * **data type** float
   */
  phm_mamt: string;

  /**
   * 판매단가
   *
   * **data type** float
   */
  phm_samt: string;

  /**
   * 기본단가
   *
   * **data type** float
   */
  phm_uamt: string;

  /**
   * 단가1
   *
   * **data type** float
   */
  uamt1: string;

  /**
   * 단가2
   *
   * **data type** float
   */
  uamt2: string;

  /**
   * 단가3
   *
   * **data type** float
   */
  uamt3: string;

  /**
   * 단가4
   *
   * **data type** float
   */
  uamt4: string;

  /**
   * 단가5
   *
   * **data type** float
   */
  uamt5: string;

  /**
   * 단가6
   *
   * **data type** float
   */
  uamt6: string;

  /**
   * 특이사항
   *
   * **data type** varchar(60)
   */
  remarks: string;

  /**
   * 사용유무
   *
   * **data type** varchar(1)
   */
  useyn: string;

  /**
   * 모름
   *
   * **data type** varchar(8)
   */
  uptdate: string;

  /**
   * 모름
   *
   * **data type** varchar(40)
   */
  vendernm: string;

  /**
   * 모름
   *
   * **data type** varchar(40)
   */
  buyernm: string;

  /**
   * 거래처코드
   *
   * **data type** varchar(13)
   */
  cltcd: string;

  /**
   * 옛날품목코드
   *
   * **data type** varchar(20)
   */
  oldpcod: string;

  /**
   * 모름
   *
   * **data type** float
   */
  pr_qty: string;

  /**
   * 모름
   *
   * **data type** float
   */
  pr_amt: string;

  /**
   * 모름
   *
   * **data type** varchar(30)
   */
  cabletype: string;

  /**
   * 입력자
   *
   * **data type** varchar(10)
   */
  inperid: string;

  /**
   * 사양1
   *
   * **data type** varchar(5)
   */
  spec1: string;

  /**
   * 사양2
   *
   * **data type** varchar(5)
   */
  spec2: string;

  /**
   * 사양3
   *
   * **data type** varchar(5)
   */
  spec3: string;

  /**
   * 사양4
   *
   * **data type** varchar(5)
   */
  spec4: string;

  /**
   * 사양5
   *
   * **data type** varchar(5)
   */
  spec5: string;

  /**
   * 사양6
   *
   * **data type** varchar(5)
   */
  spec6: string;

  /**
   * 사양7
   *
   * **data type** varchar(5)
   */
  spec7: string;

  /**
   * 사양8
   *
   * **data type** varchar(5)
   */
  spec8: string;

  /**
   * 코드버젼
   *
   * **data type** varchar(5)
   */
  pcod_ver: string;

  /**
   * 직업타입
   *
   * **data type** varchar(1)
   */
  jobtype: string;

  /**
   * 재고관리
   *
   * **data type** varchar(1)
   */
  mtyn: string;

  /**
   * 모름
   *
   * **data type** float
   */
  pstc: string;

  /**
   * 모름
   *
   * **data type** varchar(5)
   */
  abcdv: string;

  /**
   * 모름
   *
   * **data type** float
   */
  gamt: string;

  /**
   * 모름
   *
   * **data type** float
   */
  inqty: string;

  /**
   * 모름
   *
   * **data type** float
   */
  cost: string;

  /**
   * 모름
   *
   * **data type** varchar(50)
   */
  size: string;

  /**
   * 모름
   *
   * **data type** varchar(100)
   */
  gijong: string;

  /**
   * 모름
   *
   * **data type** varchar(3)
   */
  power: string;

  /**
   * 제작사
   *
   * **data type** varchar(3)
   */
  manucd: string;

  /**
   * 제조코드
   *
   * **data type** varchar(10)
   */
  jejocd: string;

  /**
   * 제조명
   *
   * **data type** varchar(100)
   */
  jejonm: string;

  /**
   * 사진1
   *
   * **data type** image
   */
  PICTURE: string;

  /**
   * 주요
   *
   * **data type** varchar(1)
   */
  point: string;

  /**
   * 부품수명
   *
   * **data type** float
   */
  age: string;

  /**
   * 보관창고
   *
   * **data type** varchar(3)
   */
  store: string;

  /**
   * 보관창고
   *
   * **data type** varchar(3)
   */
  storenm: string;

  /**
   * 품질검사유형
   *
   * **data type** varchar(3)
   */
  type: string;

  /**
   * 실사주기
   *
   * **data type** varchar(3)
   */
  jugi: string;

  /**
   * 등급
   *
   * **data type** varchar(255)
   */
  quality: string;

  /**
   * 모름
   *
   * **data type** varchar(3)
   */
  csave: string;

  /**
   * 안전검사구분
   *
   * **data type** varchar(3)
   */
  cgubun: string;

  /**
   * ems품목코드
   *
   * **data type** varchar(50)
   */
  emphm_pcod: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  Indate: string;

  /**
   * 사진2
   *
   * **data type** image
   */
  PICTURE2: string;

  /**
   * 사진여부1
   *
   * **data type** varchar(1)
   */
  picflag: string;

  /**
   * 사진여부2
   *
   * **data type** varchar(1)
   */
  picflag2: string;

  /**
   * 사진확장자1
   *
   * **data type** varchar(5)
   */
  fileext: string;

  /**
   * 사진확장자2
   *
   * **data type** varchar(5)
   */
  fileext2: string;

  /**
   * 현재고
   *
   * **data type** float
   */
  jqty: string;

  /**
   * 대분류
   *
   * **data type** varchar(30)
   */
  phm_sgrbnm: string;

  /**
   * 중분류
   *
   * **data type** varchar(30)
   */
  bgroupnm: string;

  /**
   * 소분류
   *
   * **data type** varchar(30)
   */
  cgroupnm: string;

  /**
   * 부품수명(년)
   *
   * **data type** number
   */
  age_year: string;

  /**
   * 부품수명(월)
   *
   * **data type** number
   */
  age_month: string;

  /**
   * 부품수명(월)
   *
   * **data type** varchar(200)
   */
  cltnm: string;

  /**
   * 보관창고(세부)
   *
   * **data type** varchar()
   */
  store_1: string;

  /**
   * 선택
   *
   * **data type** varchar(255)
   */
  choice: string;

  /**
   * 대분류
   *
   * **data type** varchar(20)
   */
  agrb: string;

  /**
   * 중분류
   *
   * **data type** varchar(20)
   */
  bgrb: string;

  /**
   * 소분류
   *
   * **data type** varchar(20)
   */
  cgrb: string;

  /**
   * 품명코드
   *
   * **data type** varchar(20)
   */
  pcode: string;

  /**
   * 매입단가
   *
   * **data type** float
   */
  mamt: string;

  /**
   * 매출단가
   *
   * **data type** float
   */
  samt: string;

  /**
   * 단가
   *
   * **data type** float
   */
  uamt: string;

  /**
   * 비고
   *
   * **data type** text
   */
  remark: string;

  /**
   * 입력일
   *
   * **data type** varchar(8)
   */
  indate: string;

  /**
   * phm_mode 체크메시지
   *
   * **data type** varchar(8)
   */
  phm_modeCheck: string;

  /**
   * agrb 체크메시지
   *
   * **data type** varchar(8)
   */
  agrbCheck: string;

  /**
   * bgrb 체크메시지
   *
   * **data type** varchar(8)
   */
  bgrbCheck: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.wkactcd = data.wkactcd || '';
    this.phm_cust = data.phm_cust || '';
    this.phm_pcod = data.phm_pcod || '';
    this.phm_pnam = data.phm_pnam || '';
    this.phm_knam = data.phm_knam || '';
    this.phm_mode = data.phm_mode || '';
    this.phm_size = data.phm_size || '';
    this.phm_unit = data.phm_unit || '';
    this.psize = data.psize || '';
    this.pmodel = data.pmodel || '';
    this.phm_pgun = data.phm_pgun || '';
    this.phm_puni = data.phm_puni || '';
    this.phm_def = data.phm_def || '';
    this.phm_jqty = data.phm_jqty || '';
    this.phm_bqty = data.phm_bqty || '';
    this.phm_scur = data.phm_scur || '';
    this.class = data.class || '';
    this.phm_sgrb = data.phm_sgrb || '';
    this.bgroup = data.bgroup || '';
    this.cgroup = data.cgroup || '';
    this.lnkcod = data.lnkcod || '';
    this.phm_mncd = data.phm_mncd || '';
    this.weight = data.weight || '';
    this.color = data.color || '';
    this.tvtype = data.tvtype || '';
    this.lenstype = data.lenstype || '';
    this.phm_mamt = data.phm_mamt || '';
    this.phm_samt = data.phm_samt || '';
    this.phm_uamt = data.phm_uamt || '';
    this.uamt1 = data.uamt1 || '';
    this.uamt2 = data.uamt2 || '';
    this.uamt3 = data.uamt3 || '';
    this.uamt4 = data.uamt4 || '';
    this.uamt5 = data.uamt5 || '';
    this.uamt6 = data.uamt6 || '';
    this.remarks = data.remarks || '';
    this.useyn = data.useyn || '';
    this.uptdate = data.uptdate || '';
    this.vendernm = data.vendernm || '';
    this.buyernm = data.buyernm || '';
    this.cltcd = data.cltcd || '';
    this.oldpcod = data.oldpcod || '';
    this.pr_qty = data.pr_qty || '';
    this.pr_amt = data.pr_amt || '';
    this.cabletype = data.cabletype || '';
    this.inperid = data.inperid || '';
    this.spec1 = data.spec1 || '';
    this.spec2 = data.spec2 || '';
    this.spec3 = data.spec3 || '';
    this.spec4 = data.spec4 || '';
    this.spec5 = data.spec5 || '';
    this.spec6 = data.spec6 || '';
    this.spec7 = data.spec7 || '';
    this.spec8 = data.spec8 || '';
    this.pcod_ver = data.pcod_ver || '';
    this.jobtype = data.jobtype || '';
    this.mtyn = data.mtyn || '';
    this.pstc = data.pstc || '';
    this.abcdv = data.abcdv || '';
    this.gamt = data.gamt || '';
    this.inqty = data.inqty || '';
    this.cost = data.cost || '';
    this.size = data.size || '';
    this.gijong = data.gijong || '';
    this.power = data.power || '';
    this.manucd = data.manucd || '';
    this.jejocd = data.jejocd || '';
    this.jejonm = data.jejonm || '';
    this.PICTURE = data.PICTURE || '';
    this.point = data.point || '';
    this.age = data.age || '';
    this.store = data.store || '';
    this.storenm = data.storenm || '';
    this.type = data.type || '';
    this.jugi = data.jugi || '';
    this.quality = data.quality || '';
    this.csave = data.csave || '';
    this.cgubun = data.cgubun || '';
    this.emphm_pcod = data.emphm_pcod || '';
    this.Indate = data.Indate || '';
    this.PICTURE2 = data.PICTURE2 || '';
    this.picflag = data.picflag || '';
    this.picflag2 = data.picflag2 || '';
    this.fileext = data.fileext || '';
    this.fileext2 = data.fileext2 || '';
    this.jqty = data.jqty || '';
    this.phm_sgrbnm = data.phm_sgrbnm || '';
    this.bgroupnm = data.bgroupnm || '';
    this.cgroupnm = data.cgroupnm || '';
    this.age_year = data.age_year || '';
    this.age_month = data.age_month || '';
    this.cltnm = data.cltnm || '';
    this.store_1 = data.store_1 || '';
    this.choice = data.choice || '';
    this.agrb = data.agrb || '';
    this.bgrb = data.bgrb || '';
    this.cgrb = data.cgrb || '';
    this.pcode = data.pcode || '';
    this.mamt = data.mamt || '';
    this.samt = data.samt || '';
    this.uamt = data.uamt || '';
    this.remark = data.remark || '';
    this.indate = data.indate || '';
    this.phm_modeCheck = data.phm_modeCheck || '';
    this.agrbCheck = data.agrbCheck || '';
    this.bgrbCheck = data.bgrbCheck || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}

export class ProductChoiceAllModel {
  readonly chcd: string;

  readonly chnm: string;

  constructor(item: any) {
    this.chcd = (item.chcd || '').trim();
    this.chnm = (item.chnm || '').trim();
  }
}

export class ProductTypeModel {
  readonly phm_mode: string;

  readonly phm_modenm: string;

  constructor(item: any) {
    this.phm_mode = (item.phm_mode || '').trim();
    this.phm_modenm = (item.phm_modenm || '').trim();
  }
}

export class ProductBigModel {
  readonly pgr_hcod: string;

  readonly pgr_hnam: string;

  constructor(item: any) {
    this.pgr_hcod = (item.pgr_hcod || '').trim();
    this.pgr_hnam = (item.pgr_hnam || '').trim();
  }
}

export class ProductMiddleModel {
  readonly bgroup: string;

  readonly bgrpnm: string;

  constructor(item: any) {
    this.bgroup = (item.bgroup || '').trim();
    this.bgrpnm = (item.bgrpnm || '').trim();
  }
}

export class ProductSmallModel {
  readonly cgroup: string;

  readonly cgrpnm: string;

  constructor(item: any) {
    this.cgroup = (item.cgroup || '').trim();
    this.cgrpnm = (item.cgrpnm || '').trim();
  }
}
