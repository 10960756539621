import * as React from 'react';
import { action } from 'mobx';
import {
  Category,
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { SafetyEducationTemplate } from './SafetyEducation.template';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { InfinityRetrieve } from '../../../../models/common';
import { SafetyEducationModel } from './SafetyEducation.model';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';

interface SafetyEducationState {

  // 검색 조건
  stdate: string;
  enddate: string;

  focusIndex: number;
  chk: string;

  // 데이터 객체
  focusedSafetyEducation?: SafetyEducationModel;
  data: SafetyEducationModel;
  safetyEducationList: Array<SafetyEducationModel>;
}

/**
 * 컨트롤러
 * @window w_tb_e521
 * @category 안전교육일지
 */
export class SafetyEducation extends PageComponent<PageProps, SafetyEducationState>
  implements PageToolEvents {
  updatedRows?: Array<SafetyEducationModel>;

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month: string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date: string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      searchQuery: '',
      stdate: `${year}${month}01`,
      enddate: `${year}${month}${date}`,
      data: new SafetyEducationModel({
        safedate: `${year}${month}${date}`,
      }),
      focusIndex: 0,
      chk: '0',
      safetyEducationList: [],
    };
  }

  @action
  async onFirstOpenEvent() {
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          safetyEducationList: [...this.state.safetyEducationList, ...items],
        }, next);
      },
      async () => {
        await this.SS({ safetyEducationList: [] });
        await this.infinity?.retrieveAll();
        await this.table.current?.update(true);
        this.table.current?.setFocus(0, 0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      safetyEducationList: [],
    }, async () => {
      await this.infinity?.retrieveAll();
      this.state.safetyEducationList.length > 0
      && this.onRowFocusEvent(this.state.safetyEducationList[this.state.focusIndex], this.state.focusIndex);
      if (this.state.chk === '1') {
        await this.table.current?.update(true);
        this.table.current?.setFocus(this.state.focusIndex, 0);
        this.setState({ chk: '0' });
      } else {
        await this.table.current?.update(true);
        this.table.current?.setFocus(0, 0);
      }
    });
  }

  @action
  onRowFocusEvent(item: SafetyEducationModel, index: number) {
    if (item?.new === '1') return;
    const { actionStore: api } = this.props;

    this.setState(
      {
        focusedSafetyEducation: item,
        focusIndex: index,
      },
      async () => {
        const data = await api.exec(Category.EMAIL, 'dw_1_RowFocuschanged', this.state.focusedSafetyEducation);
        data && this.setState({ data: new SafetyEducationModel(data) });
      },
    );
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    if (await api.save({
      ...this.state.data,
      items: this.state.safetyEducationList,
    }, this.state.data.new === '1')) {
      this.updatedRows = [];
      await this.setState({ chk: '1' });
      this.onRetrieveEvent();
    }
  }

  @action
  async onPrintEvent() {
    if (!ConfirmWarning.assert(this.state.safetyEducationList
      .filter((x) => x.prtchk === '1').length > 0, '오류', '출력할 내역이 없습니다.')
    ) {
      return;
    }

    const { actionStore: api } = this.props;
    await api.printWithElmanManager({
      stdate: this.state.stdate,
      enddate: this.state.enddate,
      items: this.state.safetyEducationList,
    });
  }

  // /**
  //  * 행 변경 이벤트
  //  * @param rows      전체 행 (변경 행 반영된 상태)
  //  * @param updatedRows 변경 행들만
  //  */

  @action
  // eslint-disable-next-line max-len
  onUpdatedRows(rows: Array<SafetyEducationModel>, updatedRows: Array<SafetyEducationModel>) {
    this.updatedRows = updatedRows;
    this.setState({ safetyEducationList: rows });
  }

  render() {
    return (
      <SafetyEducationTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            data: new SafetyEducationModel({
              ...this.state.data,
              ...change,
            }, this.state.data.isNew),
          }, () => callback && callback());
        }}
      />
    );
  }
}
