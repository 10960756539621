import * as React from 'react';
import {
  FlexLayout,
  TextBox,
  ComboBoxModel,
  ComboBox,
  FormatTextBox,
  TelnumTextBox,
  GridLayout,
  SearchLayout,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { StaffList } from './StaffList';
import { Sum } from '../../../../utils/array';
// 컨플릭트로 인한 주석

/**
 * 화면
 * @window w_p3181
 * @category 사원명부조회
 */
export const StaffListTemplate: React.FC<TemplateProps<StaffList>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <SearchLayout
      size={Global.LAYOUT_SEARCH_HEIGHT_1}
    >
      <FlexLayout size={500}>
        <span>검색어</span>
        <TextBox
          placeholderColor="#CACACA"
          placeholderAlign="left"
          placeholder="성명, 부서, 입사일로 검색하세요"
          value={scope.state.searchQuery}
          onChange={(value) => scope.setState({ searchQuery: value })}
          onEnter={() => scope.onRetrieveEvent()}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
      <FlexLayout size={300}>
        <span>사업장</span>
        <ComboBox
          value={scope.state.spjangcd}
          data={scope.state.spjangcds?.map((y) => new ComboBoxModel(y.spjangcd, y.spjangnm || '전체'))}
          onSelect={(option) => scope.setState({ spjangcd: option.value })}
        />
      </FlexLayout>
      <FlexLayout size={300}>
        <span>구분</span>
        <ComboBox
          value={scope.state.rtclafi}
          style={{
            padding: '0 0 0 6px',
            fontSize: 12,
          }}
          data={[
            { value: '%', remark: '전체' },
            { value: '001', remark: '재직자' },
            { value: '002', remark: '퇴직자' },
          ].map((y) => new ComboBoxModel(y.value, y.remark))}
          onSelect={(option) => scope.setState({ rtclafi: option.value })}
        />
      </FlexLayout>
    </SearchLayout>

    <GridLayout
      ref={scope.grid}
      header={[
        {
          id: 'seq',
          text: '순번',
          width: 50,
        },
        {
          id: 'spjangcd',
          text: '사업장',
          width: 100,
          render: (x) => (
            <FlexLayout
              align="center"
              justify="center"
            >
              {scope.state.spjangcds?.filter((y) => y.spjangcd === x.spjangcd)[0]?.spjangnm}
            </FlexLayout>
          ),
        },
        {
          id: 'perid',
          text: '사원번호',
          width: 100,
          trail: () => (
            <FlexLayout
              justify="center"
              align="center"
            >
              총인원
            </FlexLayout>
          ),
        },
        {
          id: 'pernm',
          text: '사원명',
          width: 100,
        },
        {
          id: 'divinm',
          text: '부서명',
          width: 150,
        },
        {
          id: 'rspnm',
          text: '직급',
          width: 100,
          trail: () => (
            <FlexLayout
              justify="center"
              align="center"
            >
              {scope.state.data.length}명
            </FlexLayout>
          ),
        },
        {
          id: 'rnum',
          text: '주민번호',
          width: 120,
          render: (x) => (
            <FormatTextBox
              format="######-$$$$$$$"
              value={x.rnum}
            />
          ),
        },
        {
          id: 'cardflag',
          text: '명함발매',
          width: 50,
          render: (x) => (
            <FlexLayout
              align="center"
              justify="center"
            >
              {x.cardflag === '1' ? 'O' : 'X'}
            </FlexLayout>
          ),
        },
        {
          id: 'schnm',
          text: '최종학력',
          width: 150,
        },
        {
          id: 'majo',
          text: '전공',
          width: 150,
        },
        {
          id: 'licence',
          text: '자격증',
          width: 250,
        },
        {
          id: 'entdate',
          text: '입사일',
          width: 100,
          render: (x) => (
            <FormatTextBox
              textAlign="center"
              format="####-##-##"
              value={x.entdate}
            />
          ),
        },
        {
          id: 'rtdate',
          text: '퇴사일',
          width: 100,
          render: (x) => (
            <FormatTextBox
              textAlign="center"
              format="####-##-##"
              value={x.rtdate}
            />
          ),
        },
        {
          id: 'dayterm2',
          text: '근속년수',
          width: 100,
          render: (x) => (
            <FlexLayout
              justify="center"
              align="center"
            >
              {x.DAYTERM2}
            </FlexLayout>
          ),
          trail: (data) => {
            const sumDayTerm = Sum.all(data, 'DAYTERM');
            return (
              <FlexLayout
                justify="center"
                align="center"
              >
                {(sumDayTerm)}
              </FlexLayout>
            );
          },
        },
        {
          id: 'promoteterm2',
          text: '진급년수',
          width: 100,
          render: (x) => (
            <FlexLayout
              justify="center"
              align="center"
            >
              {x.promoteterm2}
            </FlexLayout>
          ),
        },
        {
          id: 'tel',
          text: '전화번호',
          width: 100,
          trail: (data) => {
            const sumDayTerm = Sum.all(data, 'DAYTERM');
            return (
              <FlexLayout
                justify="center"
                align="center"
              >
                평균 근속년수 :{Math.round((((scope.state.data.length) / (sumDayTerm)) * 100) / 12)}년
              </FlexLayout>
            );
          },
          render: (x) => (
            <TelnumTextBox
              value={x.tel} />
          ),
        },
        {
          id: 'handphone',
          text: '핸드폰번호',
          width: 100,
          render: (x) => (
            <TelnumTextBox value={x.handphone}/>
          ),
        },
        {
          id: 'banknm',
          text: '은행',
          width: 100,
        },
        {
          id: 'accnum',
          text: '계좌번호',
          width: 100,
        },
        {
          id: 'agegap',
          text: '나이',
          width: 100,
        },
        {
          id: 'rtclafi',
          text: '재/퇴직',
          width: 100,
          render: (x) => (
            <FlexLayout
              justify="center"
              align="center"
            >
              {x.rtclafi === '001' ? '재직' : '퇴직'}
            </FlexLayout>
          ),
        },
        {
          id: 'zipadres',
          text: '주소',
          width: 250,
        },
        {
          id: 'springsize',
          text: '상의(Size)',
          width: 80,
          render: (x) => (
            <FlexLayout
              justify="center"
              align="center"
            >
              {x.springsize}
            </FlexLayout>
          ),
        },
        {
          id: 'footsize',
          text: '신발(Size)',
          width: 80,
          render: (x) => (
            <FlexLayout
              justify="center"
              align="center"
            >
              {x.footsize}
            </FlexLayout>
          ),
        },
      ]}
      rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
      infinityHandler={scope.infinity}
      onRowClick={(item) => scope.onRowFocusEvent(item)}
      data={scope.state.data}
    />
  </FlexLayout>
);
