import * as React from 'react';
import { action } from 'mobx';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { InfinityRetrieve } from '../../../../models/common';
import { CompleteBoxTemplate } from './CompleteBox.template';
import {
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import {
  CompleteBoxModel,
} from './models';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { IncompleteBoxPopupModel } from '../IncompleteBox/models';
import { ConfirmSuccess, ConfirmWarning } from '../../../../utils/confirm';

interface UnpaidBoxState {

  // 검색조건
  stdate: string; // 기간(시작)
  enddate: string; // 기간(끝)
  searchQuery: string; // 검색어
  papercd: string; // 문서구분
  perid: string;
  pernm: string;

  // 데이터 객체
  focusedCompleteBox?: CompleteBoxModel;
  completeBoxList: Array<CompleteBoxModel>;
  data: CompleteBoxModel;
  popupData: IncompleteBoxPopupModel;

  papercds: Array<any>;

  // 팝업
  remark: string;
  attcnt: number;
  isAttcnt: boolean;
  isReportModalVisible: boolean;
  isFileModalVisible: boolean;
  url?: string;
}
//
/*
 컨트롤러
 * @window w_tb_e080_list_02
 * @category 기결함
*/

export class CompleteBox extends React.Component<PageProps, UnpaidBoxState>
  implements PageToolEvents {
  updatedRows?: Array<CompleteBoxModel>;

  table: React.RefObject<TableLayout> = React.createRef();

  detailGrid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);
    const { user } = this.props.publicStore;

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month: string | number = today.getMonth() + 1; // 월

    const lastDate = new Date(2019, month, 0).getDate();

    if (month < 10) {
      month = `0${month}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      stdate: `${year}0101`, // 기간(시작)
      enddate: `${year}${month}${lastDate}`, // 기간(끝)
      papercd: '%', // 문서구분
      searchQuery: '',
      perid: user.perid,
      pernm: user.pernm,
      data: new CompleteBoxModel(),
      popupData: new IncompleteBoxPopupModel(),
      completeBoxList: [],
      remark: '',
      attcnt: 0,
      isAttcnt: false,
      isEduperModalVisible: false,
      isFileModalVisible: false,
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    const data = await api.dropdown('wf_dd_ca510_620_02');

    if (!data) return;

    this.setState({ papercds: data.items });

    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;
    this.updatedRows = [];

    const data = await api.retrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        as_nm: this.state.searchQuery,
        perid: this.state.perid,
        pernm: this.state.pernm,
        papercd: this.state.papercd,
      },
    );
    if (data?.items) {
      this.setState({
        data: new CompleteBoxModel(data),
        completeBoxList: data?.items,
      });
    } else {
      this.setState({
        data: new CompleteBoxModel(data),
        completeBoxList: [],
      });
    }
    this.table.current?.update();
    if (this.state.completeBoxList.length > 0) {
      await this.table.current?.setFocus(0);
    }
  }

  @action
  onRowFocusEvent(item: CompleteBoxModel) {
    this.setState({ focusedCompleteBox: item });
  }

  @action
  onUpdatedRows(rows: Array<CompleteBoxModel>, updatedRows: Array<CompleteBoxModel>) {
    this.updatedRows = updatedRows;
    this.setState({ completeBoxList: rows });
  }

  @action
  async openReportModal() {
    const { user } = this.props.publicStore;
    const paper = this.state.focusedCompleteBox;

    if (!paper) {
      ConfirmWarning.show('오류', '결재할 문서가 없습니다');
      return;
    }

    this.setState({
      isReportModalVisible: true,
      url: `https://api2.elmansoft.com/paper/ND${paper.papercd}/view?custcd=${user.custcd}&appnum=${paper.appnum}`,
    }, () => this.modalRetrieve());
  }

  @action
  async closeReportModal() {
    this.setState({
      isReportModalVisible: false,
    }, () => {
      this.updatedRows = [];
      this.onRetrieveEvent();
    });
  }

  @action
  async modalRetrieve() {
    const { actionStore: api } = this.props;
    const data = await api.retrieve(
      {
        sub: 'w_popup_e080',
        papercd: this.state.focusedCompleteBox?.papercd,
        appnum: this.state.focusedCompleteBox?.appnum,
        perid: this.state.focusedCompleteBox?.repoperid,
      },
    );
    data && this.setState({ popupData: new IncompleteBoxPopupModel(data) });
  }

  @action
  async modalApprove() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('wb_101',
      {
        sub: 'w_popup_e080',
        papercd: this.state.popupData.papercd,
        appnum: this.state.popupData.appnum,
        remark: this.state.popupData.remark,
      });
    if (data) {
      ConfirmSuccess.show(data.titlebox, data.messagebox);
      await this.closeReportModal();
    }
  }

  @action
  async modalReject() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('wb_131',
      {
        sub: 'w_popup_e080',
        papercd: this.state.popupData.papercd,
        appnum: this.state.popupData.appnum,
        remark: this.state.popupData.remark,
      });
    if (data) {
      ConfirmSuccess.show(data.titlebox, data.messagebox);
      await this.closeReportModal();
    }
  }

  @action
  async modalArbitrary() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('wb_301',
      {
        sub: 'w_popup_e080',
        papercd: this.state.popupData.papercd,
        appnum: this.state.popupData.appnum,
        remark: this.state.popupData.remark,
      });
    if (data) {
      ConfirmSuccess.show(data.titlebox, data.messagebox);
      await this.closeReportModal();
    }
  }

  @action
  async modalSubstitute() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('wb_401',
      {
        sub: 'w_popup_e080',
        papercd: this.state.popupData.papercd,
        appnum: this.state.popupData.appnum,
        remark: this.state.popupData.remark,
      });
    if (data) {
      ConfirmSuccess.show(data.titlebox, data.messagebox);
      await this.closeReportModal();
    }
  }

  @action
  openFileModal() {
    this.setState({ isFileModalVisible: true },
      () => {

      });
  }

  @action
  closeFileModal() {
    this.setState({ isFileModalVisible: false },
      () => {

      });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (this.state.completeBoxList.length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.printWithElmanManager({
      sub: 'w_popup_e080',
      papercd: this.state.popupData?.papercd,
      appnum: this.state.popupData?.appnum,
      perid: this.state.popupData?.perid,
    });
  }

  render() {
    return (
      <CompleteBoxTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            popupData: new IncompleteBoxPopupModel({
              ...this.state.popupData,
              ...change,
            }, this.state.popupData.isNew),
          }, () => callback && callback());
        }}
      />

    );
  }
}
