// eslint-disable-next-line max-classes-per-file
import {
  action,
  makeObservable,
  observable,
  toJS,
} from 'mobx';
import React, { RefObject } from 'react';
import Swal from 'sweetalert2';
import printJS from 'print-js';
// @ts-ignore
import ImageEditor from '@toast-ui/react-image-editor';
import { ActionStore, PublicStore } from './index';
import { ActionRepository, FunctionRepository } from '../repositories';
import {
  GridLayout, TableLayout, DragAndDropLayout, DateTextBox, ComboBoxModel, ComboBox, TextBox, AddressButton,
} from '../components';
import {
  InfinityRetrieve,
  ApprovalItem,
  ApprovalReferenceItem,
  SpjangItem,
  PushItem,
  RollbackItem,
  ActionItem,
} from '../models/common';
import {
  AskType,
  ConfirmType,
  ConfirmTypeName,
  PAPERCD,
  TempFileInfo,
} from '../constants';
import {
  Confirm,
  ConfirmFail,
  ConfirmSuccess,
  ConfirmWarning,
} from '../utils/confirm';
import { FileReader, FileSelector } from '../utils/file';
import { ImageResizer } from '../utils/image';
import { ResisterModel } from '../pages/human/account/Resister/Resister.model';
import { ProductCodeModel } from '../pages/general/materials/ProductCode/ProductCode.model';
import {
  ProductBigModel,
  ProductChoiceAllModel,
  ProductMiddleModel,
  ProductSmallModel,
  ProductTypeModel,
} from '../pages/general/materials/ProductAccount/ProductAccount.model';
import {
  Date6,
  Date8,
  Time4,
  Today,
} from '../utils/time';
import { IndividualModel } from '../pages/deadline/management/Goal/Individual.model';
import { ContractModel } from '../pages/deadline/aim/Contract/Contract.model';
import { InstallModel } from '../pages/deadline/management/Goal/Install.model';
import { HistoryPopupModel } from '../pages/maintenance/management/MonthContract/HistoryPopup.model';
import MD5 from '../utils/string/MD5';
import { WriterModel } from '../pages/deadline/management/Goal/Writer.model';
import { BasicModel } from '../pages/human/staff/PersonnelResistration/models';
import {
  ModalCalendar,
  ModalComboBox,
} from '../containers/templates';
import { Fix } from '../utils/string';

export interface CommonSelectorItem {
  cd: string;
  cdnm: string;
  uv_arg1: string;
  uv_arg2: string;
  uv_arg3: string;
  uv_arg4: string;
  uv_arg5: string;
}

export interface ProjectSelectorItem {
  projno: string;
  prodate: string;
  projnm: string;
  gubun: string;
  worknm: string;
  cltcd: string;
  cltnm: string;
  actcd: string;
  actnm: string;
  deldate: string;
  perid: string;
  mcnm: string;
  mcqty: string;
  samt: number;
  tamt: number;
  mamt: number;
  suamt: number;
  endflag: string;
  state: string;
  proseq: string;
  elno: string;
}

export interface CltSelectorItem {
  gubun: string;
  cltcd: string;
  cltnm: string;
  saupnum: string;
  prenm: string;
}

export interface IndividualItem {
  spjangnm: string;
  gubunnm: string;
  cnt: string;
}

class CommonSelectorParamsModel {
  readonly as_nm: string;

  readonly page: string;

  readonly sub: string;

  readonly uv_arg1: string;

  readonly uv_arg2: string;

  readonly rtclafi: string;

  readonly gubun: string;

  constructor(data: any = {}) {
    this.as_nm = data.as_nm || '';
    this.page = `${data.page ?? 0}`;
    this.sub = data.sub || '';
    this.uv_arg1 = data.uv_arg1 || '';
    this.uv_arg2 = data.uv_arg2 || '';
    this.rtclafi = data.rtclafi || '';
    this.gubun = data.gubun || '';
  }
}

class ProjectSelectorParamsModel {
  readonly as_nm: string;

  readonly state: string;

  readonly page: string;

  readonly sub: string;

  readonly gubun: string;

  readonly actcd: string;

  readonly uv_arg1: string;

  readonly uv_arg2: string;

  constructor(data: any = {}) {
    this.as_nm = data.as_nm || '';
    this.state = data.state || '0';
    this.page = `${data.page ?? 0}`;
    this.sub = data.sub || '';
    this.gubun = data.sub || '%';
    this.actcd = data.sub || '%';
    this.uv_arg1 = data.uv_arg1 || '';
    this.uv_arg2 = data.uv_arg2 || '';
  }
}

class CltSelectorParamsModel {
  readonly as_nm: string;

  readonly rtclafi: string;

  readonly page: string;

  readonly sub: string;

  readonly uv_arg1: string;

  readonly uv_arg2: string;

  constructor(data: any = {}) {
    this.as_nm = data.as_nm || '';
    this.rtclafi = data.rtclafi || '%';
    this.page = `${data.page ?? 0}`;
    this.sub = data.sub || '';
    this.uv_arg1 = data.uv_arg1 || '';
    this.uv_arg2 = data.uv_arg2 || '';
  }
}

export const projectGubuns = {
  11: '유지보수',
  12: '수리공사',
  13: '부품교체',
  14: '설치공사',
  15: '리모델링',
  16: '현대엘리베이터(공)',
  17: '전기공사',
  18: '통장거래',
  19: '기타수입',
  20: '기타환불',
  21: '가지급정산',
  22: '개발매출',
};

export const projectStates = [
  new ComboBoxModel('%', '전체'),
  new ComboBoxModel('0', '진행중'),
  new ComboBoxModel('1', '완료'),
];

export const projectStatesForInstall = [
  new ComboBoxModel('%', '전체'),
  new ComboBoxModel('1', '설치'),
  new ComboBoxModel('2', '보수'),
  new ComboBoxModel('3', '설치종료'),
];

export const cltGubuns = {
  1: '거래처',
  2: '사원',
  3: '부서',
  4: '은행',
  5: '카드',
  6: '현장',
  '%': '전체',
};

export const cltRtclafis = [
  new ComboBoxModel('001', '재직자'),
  new ComboBoxModel('002', '퇴사자'),
  new ComboBoxModel('%', '전체'),
];

interface IModalStore {
  publicStore: PublicStore;
  actionStore: ActionStore;

  // 공통 모달
  searchCommonGrid: RefObject<GridLayout>;
  isVisibleCommonSelector: boolean;
  commonSelectorSearchQuery: string;
  commonSelectorFunctionName: string;
  commonSelectorApiParams: any;
  commonSelectorData?: Array<CommonSelectorItem>;
  commonSelectorInfinity?: InfinityRetrieve;
  selectedCommonData?: CommonSelectorItem;
  onCommonSelectorRowFocusEvent?: (item: CommonSelectorItem) => void;
  onCommonSelectorResolve?: (item: CommonSelectorItem) => void;
  onCommonSelectorReject?: (item: CommonSelectorItem) => void;

  // 프로젝트 모달
  isVisibleProjectSelector: boolean;
  isInstallProjectSelector?: boolean;
  projectSelectorSearchQuery: string;
  projectSelectorSearchGubun: string;
  projectSelectorApiParams: any;
  projectSelectorData?: Array<ProjectSelectorItem>;
  selectedProjectData?: ProjectSelectorItem;
  onProjectSelectorRowFocusEvent?: (item: ProjectSelectorItem) => void;
  onProjectSelectorResolve?: (item: ProjectSelectorItem) => void;
  onProjectSelectorReject?: (item: ProjectSelectorItem) => void;

  // 거래처 모달
  isVisibleCltSelector: boolean;
  cltSelectorSearchQuery: string;
  cltSelectorSearchRtclafi: string;
  cltSelectorInfinity?: InfinityRetrieve;
  cltSelectorApiParams: any;
  cltSelectorData?: Array<CltSelectorItem>;
  selectedCltData?: CltSelectorItem;
  onCltSelectorRowFocusEvent?: (item: CltSelectorItem) => void;
  onCltSelectorResolve?: (item: CltSelectorItem) => void;
  onCltSelectorReject?: (item: CltSelectorItem) => void;

  // 주소 검색 모달
  isVisibleSearchAddress: boolean;
  searchAddressSearchQuery: string;
  searchAddressData?: Array<any>;
  selectedSearchAddressData?: any;
  onAddressSelectorResolve?: (item: any) => void;
  onAddressSelectorReject?: (item: any) => void;

  // 팩스 모달
  isVisibleFax: boolean;
  faxTo: string;
  faxRemark: string;
  faxAttachments: TempFileInfo[];
  faxPercentAttachment: number;

  // 이메일 모달
  isVisibleEmail: boolean;
  emailFrom: string;
  emailFromName: string;
  emailFromAutomation: string;
  emailTo: string;
  emailSuggestion: any[];
  emailToName: string;
  emailCC: string;
  emailKakaoTelnum: string;
  emailTitle: string;
  emailRemark: string;
  emailAttachments: TempFileInfo[];
  emailPercentAttachment: number;

  // 카카오톡 모달
  isVisibleKakao: boolean;
  kakaoSearchGubunData: any[];
  kakaoSearchGubun: string;
  kakaoSearchGubunCheck: boolean;
  kakaoTotalCheck: boolean;
  kakaoSearchQuery: string;
  kakaoSearchPosition: number;
  kakaoSearchCheck: boolean;
  kakaoSenderNumber: string;
  kakaoRemark: string;
  kakaoSenderRemark: boolean;
  kakaoData: any[];
  kakaoTempData: any[];
  kakaoPercentAttachment: number;
  kakaoTo: { tel: string }[];
  kakaoInfinity?: InfinityRetrieve;
  kakaoTableUsers: RefObject<TableLayout>;
  kakaoTableTo: RefObject<TableLayout>;

  // SMS 모달
  isVisibleSMS: boolean;
  smsSearchGubunData: any[];
  smsSearchGubun: string;
  smsSearchGubunCheck: boolean;
  smsTotalCheck: boolean;
  smsSearchQuery: string;
  smsSearchPosition: number;
  smsSearchCheck: boolean;
  smsSenderNumber: string;
  smsRemark: string;
  smsSenderRemark: boolean;
  smsData: any[];
  smsTempData: any[];
  smsAttachments: TempFileInfo[];
  smsPercentAttachment: number;
  smsTo: { tel: string }[];
  smsInfinity?: InfinityRetrieve;
  smsTableUsers: RefObject<TableLayout>;
  smsTableTo: RefObject<TableLayout>;

  // 자주쓰는문구 모달
  isVisibleBoilerplate: boolean;
  boilerplateData: any[];
  boilerplateSelected: any;
  boilerplateCallback: Function;
  boilerplateSearchQuery: string;
  boilerplateInfinity?: InfinityRetrieve;
  boilerplateGrid: RefObject<GridLayout>;
  boilerplateSearchPosition: number;

  // 결재 모달
  isVisibleApproval: boolean;
  approvalGrid: RefObject<DragAndDropLayout>;
  approvalPapercd: string;
  approvalData: ApprovalItem[];
  approvalGubunData: any[];
  approvalSelected?: ApprovalItem;
  approvalLinePerid?: string;
  onApprovalResolve?: (isSuccess: boolean) => void;

  // 거래처 빠른 추가
  isVisibleAddClt: boolean;
  addCltGrades: Array<any>;
  addCltData: ResisterModel;
  onAddCltResolve?: (data: ResisterModel) => void;
  onAddCltReject?: () => void;

  // 대기열 모달
  isVisibleWaitQueue: boolean;

  // 결재 참조자 모달
  isVisibleApprovalReference: boolean;
  approvalReferenceSearchPosition: number;
  approvalReferenceTotalCheck: boolean;
  approvalReferenceTable: RefObject<TableLayout>;
  approvalReferenceSearchQuery: string;
  approvalReferencePapercd: string;
  approvalReferenceAppnum: string;
  approvalReferenceData: ApprovalReferenceItem[];
  approvalReferenceSelected?: ApprovalReferenceItem;
  onApprovalReferenceResolve?: (isSuccess: boolean) => void;

  // 모바일 푸시 알림 전송 모달
  isVisiblePush: boolean;
  pushInfinity?: InfinityRetrieve;
  pushTotalCheck: boolean;
  pushTable: RefObject<TableLayout>;
  pushSearchQueryUpdivicd: string;
  pushSearchQuerySpjangcd: string;
  pushSearchQueryDivicd: string;
  pushSearchQueryDivinm: string;
  pushSearchQueryPerid: string;
  pushSearchQueryPernm: string;
  pushSearchQuerySpjangcdData: Array<SpjangItem>;
  pushTitle: string;
  pushMessage: string;
  pushRecedate: string;
  pushRecenum: string;
  pushData: PushItem[];
  pushSelected?: PushItem;

  // 결재권자 기록 뷰 모달
  isVisibleModalApprovalRemark: boolean;
  approvalRemarkAppnum: string;
  approvalRemarkData: Array<any>;
  approvalRemarkAppGubuns: Array<any>;
  approvalRemarkFocused: any;

  // 참조자 기록 뷰 모달
  isVisibleModalApprovalReferenceRemark: boolean;
  approvalReferenceRemarkAppnum: string;
  approvalReferenceRemarkData: Array<any>;

  // 자재 품목 선택 모달
  isVisibleProductSelector: boolean;
  productSelectorGrid: RefObject<GridLayout>;
  productSelected?: ProductCodeModel;
  productSelectorSearchQuery: string;
  productSelectorSearchQuery2: string;
  productSelectorSearchWkactcd: string;
  productSelectorSearchChoice: string;
  productSelectorSearchPhm_mode: string;
  productSelectorSearchAgrb: string;
  productSelectorSearchBgrb: string;
  productSelectorSearchCgrb: string;
  productSelectorData: Array<ProductCodeModel>;
  productSelectorChoices?: Array<any>;
  productSelectorPhm_modes?: Array<any>;
  productSelectorAgrbs?: Array<any>;
  productSelectorBgrbs?: Array<any>;
  productSelectorCgrbs?: Array<any>;
  productSelectorImageA?: ArrayBuffer;
  productSelectorImageB?: ArrayBuffer;
  productSelectorInfinity?: InfinityRetrieve;
  onProductSelectorResolve?: (item: ProductCodeModel) => void;

  // 롤백 모달
  isVisibleRollback: boolean;
  rollbackSearchStdate: string;
  rollbackSearchEnddate: string;
  rollbackSelectorData: Array<RollbackItem>;
  rollbackSelectedIndex?: number;
  rollbackSelectorInfinity?: InfinityRetrieve;

  // 재현 모달
  isVisibleReproduce: boolean;
  reproducePerid: string;
  reproducePernm: string;
  reproduceJson: ActionItem;
  reproduceSelectorData: Array<ActionItem>;
  reproduceSelectedIndex?: number;
  reproduceSelectorInfinity?: InfinityRetrieve;

  // 매니저 안내 모달
  isVisibleModalElmNotice: boolean;

  // 개별알림 모달
  individualData: Array<IndividualModel>;
  individualDetailData: Array<IndividualModel>;
  individualSelected?: IndividualModel;
  individualDetailSelected?: IndividualModel;
  isVisibleIndividualSelector: boolean;
  individualSelectorGrid: RefObject<GridLayout>;
  individualSelectorGrid2: RefObject<GridLayout>;
  onIndividualSelectorResolve?: (item: IndividualModel) => void;
  onIndividualRowFocus?: (item: IndividualItem) => void;

  // 설치현장->보수현장 모달
  isVisibleInstallationSelector: boolean;
  installationData: Array<IndividualModel>;
  installationSelected?: IndividualModel;
  InstallationTable: RefObject<TableLayout>;
  installTotalCheck: boolean;
  onInstallationSelectorResolve?: (item: IndividualModel) => void;
  installationTotal: string;

  // 만료에 대한 답변내용
  isVisibleExpirationSelector: boolean;
  expirationData: Array<IndividualModel>;
  expirationSelectorGrid: RefObject<GridLayout>;
  expirationSelected?: IndividualModel;
  onExpirationSelectorResolve?: (item: IndividualModel) => void;
  expirationTotal: string;
  expirationUser: string;

  // 설치납기만료알림 모달
  contractData: Array<ContractModel>;
  contractTotal: string;
  text1: string;
  monamt_tot: string;
  amt_tot: string;
  contractChk: string;
  contractSelectorInfinity?: InfinityRetrieve;
  contractSelectorGrid: RefObject<GridLayout>;
  isVisibleContractSelector: boolean;
  contractSelected?: ContractModel;
  onContractSelectorResolve?: (item: ContractModel) => void;

  // 계약만료현장알림 모달
  installData: Array<InstallModel>;
  installTotal: string
  installChk: string
  installSelectorInfinity?: InfinityRetrieve;
  installSelectorGrid: RefObject<GridLayout>;
  isVisibleInstallSelector: boolean;
  installSelected?: InstallModel;
  onInstallSelectorResolve?: (item: InstallModel) => void;

  // 계약내용기록 모달
  historyData: Array<HistoryPopupModel>;
  historySelectorInfinity?: InfinityRetrieve;
  historyTableUsers: RefObject<TableLayout>;
  isVisibleHistorySelector: boolean;
  historySelected?: HistoryPopupModel;
  onHistorySelectorResolve?: (item: HistoryPopupModel) => void;
  remark: string
  HistoryUpdatedRows?: Array<HistoryPopupModel>;

  // 작성자 모달
  writerData: Array<WriterModel>;
  writerSearch: string
  writerSelectorInfinity?: InfinityRetrieve;
  writerSelectorGrid: RefObject<GridLayout>;
  isVisibleWriterSelector: boolean;
  writerSelected?: WriterModel;
  onWriterSelectorResolve?: (item: WriterModel) => void;

  // 달력 모달
  isVisibleCalendar: boolean;
  refCalendar?: DateTextBox;
  refCalendarContainer?: RefObject<ModalCalendar>;

  // 이미지 편집 모달
  isVisibleImageEditor: boolean;
  imageSrc: string;
  refImageEditor?: RefObject<ImageEditor>;
  handlerImageUpdate?: (b64: string) => void;

  // 문의확인 모달
  dataQnA: Array<IndividualModel>;
  stdateaQnA: string;
  recedate: string;
  compdate: string;
  remarkQnA: string;
  compremark: string;
  date: any;
  receflag: string;
  endateaQnA: string;
  searchQueryQnA: string;
  dataQnASelected?: IndividualModel;
  isVisibleQnASelector: boolean;
  dataQnASelectorGrid: RefObject<GridLayout>;
  onQnASelectorResolve?: (item: IndividualModel) => void;
  onQnARowFocus?: (item: IndividualItem) => void;

  // 주소검색 모달
  isVisibleAddress: boolean;
  refAddressButton?: AddressButton;

  isVisibleModalAdvice: boolean;
}

export default class ModalStore implements IModalStore {
  readonly publicStore: PublicStore;

  readonly actionStore: ActionStore;

  // Common
  searchCommonGrid: RefObject<GridLayout> = React.createRef();

  @observable isVisibleCommonSelector: boolean;

  @observable isVisibleCommonSelectorGubun: boolean;

  @observable commonSelectorGubuns: Array<ComboBoxModel>;

  @observable commonSelectorGubun?: string;

  @observable commonSelectorGubunColumn?: string;

  @observable commonSelectorGubunTitle?: string;

  @observable commonSelectorSearchQuery: string;

  commonSelectorRefSearchQuery: React.RefObject<TextBox>;

  @observable commonSelectorFunctionName: string;

  @observable commonSelectorApiParams: any;

  @observable commonSelectorData?: Array<CommonSelectorItem>;

  @observable selectedCommonData?: CommonSelectorItem;

  @observable onCommonSelectorResolve?: (item: CommonSelectorItem) => void;

  @observable onCommonSelectorReject?: (item: CommonSelectorItem) => void;

  commonSelectorInfinity?: InfinityRetrieve;

  // Projects
  @observable isVisibleProjectSelector: boolean;

  @observable projectSelectorSearchQuery: string;

  @observable projectSelectorSearchGubun: string;

  @observable projectSelectorApiParams: any;

  @observable projectSelectorData?: Array<ProjectSelectorItem>;

  @observable selectedProjectData?: ProjectSelectorItem;

  @observable onProjectSelectorResolve?: (item: ProjectSelectorItem) => void;

  @observable onProjectSelectorReject?: (item: ProjectSelectorItem) => void;

  projectSelectorInfinity?: InfinityRetrieve

  isInstallProjectSelector?: boolean;

  // Clt
  @observable isVisibleCltSelector: boolean;

  @observable cltSelectorSearchQuery: string;

  @observable cltSelectorSearchRtclafi: string;

  @observable cltSelectorApiParams: any;

  @observable cltSelectorData?: Array<CltSelectorItem>;

  @observable selectedCltData?: CltSelectorItem;

  @observable onCltSelectorResolve?: (item: CltSelectorItem) => void;

  @observable onCltSelectorReject?: (item: CltSelectorItem) => void;

  cltSelectorInfinity?: InfinityRetrieve;

  // Fax
  @observable isVisibleFax: boolean;

  @observable faxTo: string;

  @observable faxRemark: string;

  @observable faxAttachments: TempFileInfo[];

  @observable faxPercentAttachment: number;

  // Email
  @observable isVisibleEmail: boolean;

  @observable emailFrom: string;

  @observable emailFromName: string;

  @observable emailFromAutomation: string;

  @observable emailTo: string;

  @observable emailSuggestion: any[];

  @observable emailToName: string;

  @observable emailCC: string;

  @observable emailKakaoTelnum: string;

  @observable emailTitle: string;

  @observable emailRemark: string;

  @observable emailAttachments: TempFileInfo[];

  @observable emailPercentAttachment: number;

  // Kakao
  @observable isVisibleKakao: boolean;

  @observable kakaoSearchGubunData: any[];

  @observable kakaoSearchGubun: string;

  @observable kakaoSearchGubunCheck: boolean;

  @observable kakaoTotalCheck: boolean;

  @observable kakaoSearchQuery: string;

  @observable kakaoSearchCheck: boolean;

  @observable kakaoSenderNumber: string;

  @observable kakaoRemark: string;

  @observable kakaoSenderRemark: boolean;

  @observable kakaoData: any[];

  @observable kakaoTempData: any[];

  @observable kakaoTo: { tel: string }[];

  @observable kakaoPercentAttachment: number;

  kakaoTableUsers: RefObject<TableLayout>;

  kakaoTableTo: RefObject<TableLayout>;

  kakaoInfinity?: InfinityRetrieve;

  kakaoSearchPosition: number;

  // SMS
  @observable isVisibleSMS: boolean;

  @observable smsSearchGubunData: any[];

  @observable smsSearchGubun: string;

  @observable smsSearchGubunCheck: boolean;

  @observable smsTotalCheck: boolean;

  @observable smsSearchQuery: string;

  @observable smsSearchCheck: boolean;

  @observable smsSenderNumber: string;

  @observable smsRemark: string;

  @observable smsSenderRemark: boolean;

  @observable smsData: any[];

  @observable smsTempData: any[];

  @observable smsAttachments: TempFileInfo[];

  @observable smsTo: { tel: string }[];

  @observable smsPercentAttachment: number;

  smsTableUsers: RefObject<TableLayout>;

  smsTableTo: RefObject<TableLayout>;

  smsInfinity?: InfinityRetrieve;

  smsSearchPosition: number;

  // Boilerplate
  @observable isVisibleBoilerplate: boolean;

  @observable boilerplateData: any[];

  @observable boilerplateSearchQuery: string;

  @observable boilerplateSelected: any;

  boilerplateCallback: Function;

  boilerplateInfinity?: InfinityRetrieve;

  boilerplateGrid: RefObject<GridLayout>;

  boilerplateSearchPosition: number;

  // Approval
  @observable isVisibleApproval: boolean;

  @observable approvalPapercd: string;

  @observable approvalData: ApprovalItem[];

  @observable approvalGubunData: any[];

  @observable approvalSelected?: ApprovalItem;

  @observable approvalLinePerid?: string;

  approvalGrid: RefObject<DragAndDropLayout>;

  onApprovalResolve?: (isSuccess: boolean) => void;

  // Add clt
  @observable isVisibleAddClt: boolean;

  @observable addCltGrades: Array<any>;

  @observable addCltData: ResisterModel;

  onAddCltResolve?: (data: ResisterModel) => void;

  onAddCltReject?: () => void;

  // Wait queue
  @observable isVisibleWaitQueue: boolean;

  gridWaitQueue: RefObject<GridLayout>;

  // Approval reference
  @observable isVisibleApprovalReference: boolean;

  @observable approvalReferenceSearchPosition: number;

  @observable approvalReferenceTotalCheck: boolean;

  @observable approvalReferenceSearchQuery: string;

  @observable approvalReferencePapercd: string;

  @observable approvalReferenceAppnum: string;

  @observable approvalReferenceData: ApprovalReferenceItem[];

  @observable approvalReferenceSelected?: ApprovalReferenceItem;

  approvalReferenceTable: RefObject<TableLayout>;

  onApprovalReferenceResolve?: (isSuccess: boolean) => void;

  // Perid
  @observable isVisiblePerid: boolean;

  @observable peridSearchPosition: number;

  @observable peridTotalCheck: boolean;

  @observable peridTitle: string;

  @observable peridSearchQuery: string;

  @observable peridData: BasicModel[];

  @observable peridSelected?: BasicModel;

  peridTable: RefObject<TableLayout>;

  peridInfinity?: InfinityRetrieve;

  onPeridResolve?: (accounts: Array<{ custcd: string, perid: string, pernm: string }>) => void;

  // Push
  @observable isVisiblePush: boolean;

  @observable pushTotalCheck: boolean;

  @observable pushSearchQueryUpdivicd: string;

  @observable pushSearchQuerySpjangcd: string;

  @observable pushSearchQueryDivicd: string;

  @observable pushSearchQueryDivinm: string;

  @observable pushSearchQueryPerid: string;

  @observable pushSearchQueryPernm: string;

  @observable pushTitle: string;

  @observable pushMessage: string;

  @observable pushRecedate: string;

  @observable pushRecenum: string;

  @observable pushCheckedPerson?: Array<any>;

  @observable pushData: PushItem[];

  @observable pushSelected?: PushItem;

  pushInfinity?: InfinityRetrieve;

  pushTable: RefObject<TableLayout>;

  pushSearchQuerySpjangcdData: Array<SpjangItem>;

  onPushResolve?: (items: Array<PushItem>) => void;

  onPushReject?: Function;

  // Approval remark
  @observable isVisibleModalApprovalRemark: boolean;

  @observable approvalRemarkData: Array<any>;

  @observable approvalRemarkAppGubuns: Array<any>;

  @observable approvalRemarkFocused: any;

  approvalRemarkAppnum: string;

  // Approval reference remark
  @observable isVisibleModalApprovalReferenceRemark: boolean;

  @observable approvalReferenceRemarkData: Array<any>;

  approvalReferenceRemarkAppnum: string;

  // Product selector
  @observable isVisibleProductSelector: boolean;

  @observable productSelected?: ProductCodeModel;

  @observable productSelectorSearchQuery: string;

  @observable productSelectorSearchQuery2: string;

  @observable productSelectorSearchQuery3: string;

  @observable productSelectorSearchWkactcd: string;

  @observable productSelectorSearchChoice: string;

  @observable productSelectorSearchPhm_mode: string;

  @observable productSelectorSearchAgrb: string;

  @observable productSelectorSearchBgrb: string;

  @observable productSelectorSearchCgrb: string;

  @observable productSelectorSearchCltcd: string;

  @observable productSelectorSearchCltnm: string;

  @observable productSelectorSearchMtyn: string;

  @observable productSelectorData: Array<ProductCodeModel>;

  @observable productSelectorChoices?: Array<any>;

  @observable productSelectorPhm_modes?: Array<any>;

  @observable productSelectorAgrbs?: Array<any>;

  @observable productSelectorBgrbs?: Array<any>;

  @observable productSelectorCgrbs?: Array<any>;

  @observable productSelectorImageA?: ArrayBuffer;

  @observable productSelectorImageB?: ArrayBuffer;

  productSelectorGrid: RefObject<GridLayout>;

  productSelectorInfinity?: InfinityRetrieve;

  onProductSelectorResolve?: (item: ProductCodeModel) => void;

  // Rollback selector
  @observable isVisibleRollback: boolean;

  @observable rollbackSelectorData: Array<RollbackItem>;

  @observable rollbackSelectedIndex: number;

  @observable rollbackSearchStdate: string;

  @observable rollbackSearchEnddate: string;

  rollbackSelectorInfinity?: InfinityRetrieve;

  // Reproduce
  @observable isVisibleReproduce: boolean;

  @observable reproducePerid: string;

  @observable reproducePernm: string;

  @observable reproduceJson: ActionItem;

  @observable reproduceSelectorData: Array<ActionItem>;

  reproduceSelectedIndex?: number;

  reproduceSelectorInfinity?: InfinityRetrieve;

  // Elman manager
  @observable isVisibleModalElmNotice: boolean;

  // ModalNotify
  @observable individualData: Array<IndividualModel>;

  @observable individualDetailData: Array<IndividualModel>;

  @observable individualSelected?: IndividualModel;

  @observable individualDetailSelected?: IndividualModel;

  @observable isVisibleIndividualSelector: boolean;

  onIndividualSelectorResolve?: (item: IndividualModel) => void;

  @observable selectedIndividualData?: CommonSelectorItem;

  individualSelectorGrid: RefObject<GridLayout>;

  individualSelectorGrid2: RefObject<GridLayout>;

  // ModalInstall
  @observable isVisibleInstallationSelector: boolean;

  @observable installationData: Array<IndividualModel>;

  @observable installationSelected?: IndividualModel;

  @observable installTotalCheck: boolean;

  InstallationTable: RefObject<TableLayout>;

  onInstallationSelectorResolve?: (item: IndividualModel) => void;

  installationTotal: string;

  // ModalExpiration
  @observable isVisibleExpirationSelector: boolean;

  @observable expirationData: Array<IndividualModel>;

  expirationSelectorGrid: RefObject<GridLayout>;

  @observable expirationSelected?: IndividualModel;

  onExpirationSelectorResolve?: (item: IndividualModel) => void;

  expirationTotal: string;

  expirationUser: string;

  // Contract Modal
  @observable contractData: Array<ContractModel>;

  contflag: string;

  text1: string;

  text2: string;

  amt_tot: string;

  contractChk: string;

  monamt_tot: string;

  contractTotal: string;

  contractSelectorInfinity?: InfinityRetrieve;

  contractSelectorGrid: RefObject<GridLayout>;

  @observable isVisibleContractSelector: boolean;

  @observable contractSelected?: ContractModel;

  onContractSelectorResolve?: (item: ContractModel) => void;

  // Install Modal
  @observable installData: Array<InstallModel>;

  installSelectorInfinity?: InfinityRetrieve;

  installSelectorGrid: RefObject<GridLayout>;

  @observable isVisibleInstallSelector: boolean;

  @observable installSelected?: InstallModel;

  onInstallSelectorResolve?: (item: InstallModel) => void;

  installTotal: string;

  installChk: string;

  // history Modal
  @observable historyData: Array<HistoryPopupModel>;

  historySelectorInfinity?: InfinityRetrieve;

  historyTableUsers: RefObject<TableLayout>;

  @observable isVisibleHistorySelector: boolean;

  @observable historySelected?: HistoryPopupModel;

  onHistorySelectorResolve?: (item: HistoryPopupModel) => void;

  HistoryUpdatedRows?: Array<HistoryPopupModel>;

  @observable remark: string;

  // Writer Modal
  @observable writerData: Array<WriterModel>;

  writerSelectorInfinity?: InfinityRetrieve;

  writerSelectorGrid: RefObject<GridLayout>;

  @observable isVisibleWriterSelector: boolean;

  @observable writerSelected?: WriterModel;

  onWriterSelectorResolve?: (item: WriterModel) => void;

  writerSearch: string;

  // Calendar Modal
  @observable isVisibleCalendar: boolean;

  @observable refCalendar?: DateTextBox;

  refCalendarContainer: RefObject<ModalCalendar>;

  // Combo box Modal
  @observable isVisibleComboBox: boolean;

  @observable refComboBox?: ComboBox;

  @observable comboBoxWidth?: number;

  @observable comboBoxHeight?: number;

  refComboBoxContainer: RefObject<ModalComboBox>;

  refComboBoxInput: RefObject<HTMLInputElement>;

  // Image Editor Modal
  @observable isVisibleImageEditor: boolean;

  imageSrc: string;

  refImageEditor: RefObject<ImageEditor>;

  handlerImageUpdate?: (b64: string) => void;

  // Address Search Modal
  @observable isVisibleSearchAddress: boolean;

  @observable searchAddressSearchQuery: string;

  @observable searchAddressData?: Array<any>;

  searchAddressGrid: RefObject<GridLayout> = React.createRef();

  selectedSearchAddressData?: any;

  onAddressSelectorResolve?: (item: any) => void;

  onAddressSelectorReject?: (item: any) => void;

  // ModalQnA
  @observable dataQnA: Array<IndividualModel>;

  @observable dataQnASelected?: IndividualModel;

  @observable isVisibleQnASelector: boolean;

  @observable stdateaQnA: string;

  @observable recedate: string;

  @observable compdate: string;

  @observable remarkQnA: string;

  @observable compremark: string;

  @observable date: any;

  @observable receflag: string;

  @observable searchQueryQnA: string;

  @observable endateaQnA: string;

  onQnASelectorResolve?: (item: IndividualModel) => void;

  @observable selectedQnAData?: CommonSelectorItem;

  dataQnASelectorGrid: RefObject<GridLayout>;

  // Address
  @observable isVisibleAddress: boolean;

  refAddressButton?: AddressButton;

  // Advice
  @observable isVisibleModalAdvice: boolean;

  // Gosi ask
  @observable isVisibleGosiAsk: boolean;

  @observable modalGosiAskAnswer: number;

  // KS ask
  @observable isVisibleKS: boolean;

  @observable modalKSAnswer: number;


  constructor(publicStore: PublicStore, actionStore: ActionStore) {
    this.publicStore = publicStore;
    this.actionStore = actionStore;

    this.isVisibleCommonSelector = false;
    this.commonSelectorSearchQuery = '';
    this.commonSelectorRefSearchQuery = React.createRef();
    this.commonSelectorFunctionName = '';
    this.isVisibleCommonSelectorGubun = false;
    this.commonSelectorGubuns = [];
    this.commonSelectorGubun = '';
    this.commonSelectorGubunColumn = '';
    this.commonSelectorGubunTitle = '';

    this.isVisibleProjectSelector = false;
    this.isInstallProjectSelector = false;
    this.projectSelectorSearchQuery = '';
    this.projectSelectorSearchGubun = '0';

    this.isVisibleCltSelector = false;
    this.cltSelectorSearchQuery = '';
    this.cltSelectorSearchRtclafi = '001';

    this.isVisibleFax = false;
    this.faxTo = '';
    this.faxRemark = '';
    this.faxAttachments = [];
    this.faxPercentAttachment = 0;

    this.isVisibleEmail = false;
    this.emailFrom = '';
    this.emailFromName = '';
    this.emailFromAutomation = '0';
    this.emailTo = '';
    this.emailSuggestion = [];
    this.emailToName = '';
    this.emailCC = '';
    this.emailKakaoTelnum = '';
    this.emailTitle = '';
    this.emailRemark = '';
    this.emailAttachments = [];
    this.emailPercentAttachment = 0;

    this.isVisibleKakao = false;
    this.kakaoSearchGubunData = [];
    this.kakaoSearchGubun = '2';
    this.kakaoSearchGubunCheck = true;
    this.kakaoTotalCheck = false;
    this.kakaoSearchQuery = '';
    this.kakaoSearchPosition = -1;
    this.kakaoSearchCheck = false;
    this.kakaoSenderNumber = '';
    this.kakaoRemark = '';
    this.kakaoSenderRemark = false;
    this.kakaoData = [];
    this.kakaoTempData = [];
    this.kakaoTo = [];
    this.kakaoPercentAttachment = 0;
    this.kakaoTableUsers = React.createRef();
    this.kakaoTableTo = React.createRef();

    this.isVisibleSMS = false;
    this.smsSearchGubunData = [];
    this.smsSearchGubun = '';
    this.smsSearchGubunCheck = true;
    this.smsTotalCheck = false;
    this.smsSearchQuery = '';
    this.smsSearchPosition = -1;
    this.smsSearchCheck = false;
    this.smsSenderNumber = '';
    this.smsRemark = '';
    this.smsSenderRemark = false;
    this.smsData = [];
    this.smsTempData = [];
    this.smsAttachments = [];
    this.smsTo = [];
    this.smsPercentAttachment = 0;
    this.smsTableUsers = React.createRef();
    this.smsTableTo = React.createRef();

    this.isVisibleBoilerplate = false;
    this.boilerplateData = [];
    this.boilerplateCallback = () => {};
    this.boilerplateSearchQuery = '';
    this.boilerplateGrid = React.createRef();
    this.boilerplateSearchPosition = -1;

    this.isVisibleApproval = false;
    this.approvalGrid = React.createRef();
    this.approvalPapercd = '101';
    this.approvalData = [];
    this.approvalGubunData = [];

    this.isVisibleApprovalReference = false;
    this.approvalReferenceSearchPosition = -1;
    this.approvalReferenceTotalCheck = false;
    this.approvalReferenceTable = React.createRef();
    this.approvalReferenceSearchQuery = '';
    this.approvalReferencePapercd = '101';
    this.approvalReferenceAppnum = '';
    this.approvalReferenceData = [];

    this.isVisiblePerid = false;
    this.peridSearchPosition = -1;
    this.peridTotalCheck = false;
    this.peridTitle = '';
    this.peridSearchQuery = '';
    this.peridData = [];
    this.peridTable = React.createRef();

    this.isVisibleAddClt = false;
    this.addCltData = new ResisterModel();
    this.addCltGrades = [];

    this.isVisibleWaitQueue = false;
    this.gridWaitQueue = React.createRef();

    this.isVisiblePush = false;
    this.pushTotalCheck = false;
    this.pushTable = React.createRef();
    this.pushSearchQueryUpdivicd = '02';
    this.pushSearchQuerySpjangcd = this.publicStore.user.spjangcd;
    this.pushSearchQueryDivicd = '';
    this.pushSearchQueryDivinm = '';
    this.pushSearchQueryPerid = '';
    this.pushSearchQueryPernm = '';
    this.pushSearchQuerySpjangcdData = [];
    this.pushTitle = '';
    this.pushMessage = '';
    this.pushRecedate = '';
    this.pushRecenum = '';
    this.pushData = [];

    this.isVisibleModalApprovalRemark = false;
    this.approvalRemarkAppnum = '';
    this.approvalRemarkData = [];
    this.approvalRemarkAppGubuns = [];
    this.approvalRemarkFocused = '';

    this.isVisibleModalApprovalReferenceRemark = false;
    this.approvalReferenceRemarkAppnum = '';
    this.approvalReferenceRemarkData = [];

    this.isVisibleProductSelector = false;
    this.productSelectorGrid = React.createRef();
    this.productSelectorSearchQuery = '';
    this.productSelectorSearchQuery2 = '';
    this.productSelectorSearchQuery3 = '';
    this.productSelectorSearchWkactcd = '';
    this.productSelectorSearchChoice = '';
    this.productSelectorSearchPhm_mode = '';
    this.productSelectorSearchAgrb = '';
    this.productSelectorSearchBgrb = '';
    this.productSelectorSearchCgrb = '';
    this.productSelectorSearchMtyn = '';
    this.productSelectorSearchCltcd = '';
    this.productSelectorSearchCltnm = '';
    this.productSelectorData = [];

    this.isVisibleRollback = false;
    this.rollbackSelectorData = [];
    this.rollbackSelectedIndex = 0;
    this.rollbackSearchStdate = '';
    this.rollbackSearchEnddate = '';

    this.isVisibleReproduce = false;
    this.reproducePerid = '';
    this.reproducePernm = '';
    this.reproduceJson = new ActionItem();
    this.reproduceSelectorData = [];

    this.isVisibleModalElmNotice = false;

    this.individualData = [];
    this.individualDetailData = [];
    this.isVisibleIndividualSelector = false;
    this.individualSelectorGrid = React.createRef();
    this.individualSelectorGrid2 = React.createRef();

    this.isVisibleInstallationSelector = false;
    this.installationData = [];
    this.InstallationTable = React.createRef();
    this.installTotalCheck = false;
    this.installationTotal = '';

    this.isVisibleExpirationSelector = false;
    this.expirationData = [];
    this.expirationSelectorGrid = React.createRef();
    this.expirationTotal = '';
    this.expirationUser = '';

    this.contractData = [];
    this.contflag = '';
    this.text1 = '';
    this.text2 = '';
    this.amt_tot = '';
    this.contractChk = '1';
    this.monamt_tot = '';
    this.contractTotal = '';
    this.contractSelectorGrid = React.createRef();
    this.isVisibleContractSelector = false;

    this.installData = [];
    this.installTotal = '';
    this.installChk = '1';
    this.installSelectorGrid = React.createRef();
    this.isVisibleInstallSelector = false;

    this.historyData = [];
    this.isVisibleHistorySelector = false;
    this.historyTableUsers = React.createRef();
    this.remark = '';
    this.HistoryUpdatedRows = [];

    this.writerData = [];
    this.writerSearch = '';
    this.writerSelectorGrid = React.createRef();
    this.isVisibleWriterSelector = false;

    this.isVisibleCalendar = false;
    this.refCalendarContainer = React.createRef();

    this.isVisibleComboBox = false;
    this.refComboBoxContainer = React.createRef();
    this.refComboBoxInput = React.createRef();

    this.isVisibleImageEditor = false;
    this.imageSrc = '';
    this.refImageEditor = React.createRef();

    this.isVisibleSearchAddress = false;
    this.searchAddressSearchQuery = '';

    this.dataQnA = [];
    this.date = new Date();
    this.stdateaQnA = `${this.date.getFullYear()}0101`;
    this.endateaQnA = this.date.getMonth() !== 9 && this.date.getMonth() !== 10 && this.date.getMonth() !== 11
      ? `${this.date.getFullYear()}0${this.date.getMonth() + 1}${this.date.getDate()}`
      : `${this.date.getFullYear()}${this.date.getMonth() + 1}${this.date.getDate()}`;
    this.searchQueryQnA = '';
    this.recedate = '';
    this.compdate = '';
    this.remarkQnA = '';
    this.compremark = '';
    this.receflag = '0';
    this.isVisibleQnASelector = false;
    this.dataQnASelectorGrid = React.createRef();

    this.isVisibleAddress = false;

    this.isVisibleModalAdvice = false;

    this.isVisibleGosiAsk = false;
    this.modalGosiAskAnswer = 0;

    this.isVisibleKS = false;
    this.modalKSAnswer = 0;

    makeObservable(this);
  }


  /**
   *****************************************************************
   * Common
   *****************************************************************
   */
  @action
  updateCommonSelectorSearchQuery(search: string): void {
    this.commonSelectorSearchQuery = search;
  }

  @action
  updateCommonSelectorGubun(search: string): void {
    this.commonSelectorGubun = search;
    this.searchCommonSelector();
  }

  @action
  onCommonSelectorRowFocusEvent(item: CommonSelectorItem): void {
    this.selectedCommonData = item;
  }

  @action
  async searchCommonSelector(): Promise<void> {
    this.commonSelectorData = [];
    this.commonSelectorInfinity = new InfinityRetrieve(
      new CommonSelectorParamsModel({
        ...this.commonSelectorApiParams,
        as_nm: this.commonSelectorSearchQuery,
        ...(this.isVisibleCommonSelectorGubun ? {
          // @ts-ignore
          [this.commonSelectorGubunColumn]: this.commonSelectorGubun,
        } : {}),
      }),
      async (params) => (
        this.publicStore.request(async () => FunctionRepository.searchCommon(
          this.commonSelectorFunctionName,
          await this.publicStore.makeParams(params),
        ))
      ),
      (arr) => {
        const items: any[] = arr.map((d: any) => {
          const keys = Object.keys(d);
          const result = {};
          keys.forEach((k: string) => {
            // @ts-ignore
            result[k.toLowerCase()] = d[k];
          });
          return result;
        });

        if (this.commonSelectorData?.length === 0 && items && items.length === 1) {
          this.successCommonSelector(items[0]);
        } else if (items) {
          this.commonSelectorData = [...this.commonSelectorData || [], ...items];
        }
      },
      async () => {
        this.commonSelectorData = [];
        await this.commonSelectorInfinity?.retrieveAll();
        if (this.commonSelectorData?.length) {
          this.searchCommonGrid.current?.setFocus(0);
        }
      },
    );

    await this.commonSelectorInfinity.retrieveAll();
    if (this.commonSelectorData?.length) {
      this.searchCommonGrid.current?.setFocus(0);
    }
  }

  @action
  openCommonSelector(
    functionName: string,
    params?: any,
    immediatlySearch?: boolean,
    gubuns?: Array<ComboBoxModel>,
    gubunColumn?: string,
    gubunTitle?: string,
    gubunDefault?: string,
  ) : Promise<CommonSelectorItem> {
    return new Promise<CommonSelectorItem>((resolve, reject) => {
      this.commonSelectorFunctionName = functionName;
      this.commonSelectorApiParams = params || {};
      this.commonSelectorSearchQuery = params?.as_nm || '';
      this.onCommonSelectorResolve = resolve;
      this.onCommonSelectorReject = reject;
      this.isVisibleCommonSelector = true;
      this.isVisibleCommonSelectorGubun = false;

      if (gubuns?.length) {
        this.isVisibleCommonSelectorGubun = true;
        this.commonSelectorGubuns = gubuns;
        this.commonSelectorGubun = gubunDefault;
        this.commonSelectorGubunColumn = gubunColumn;
        this.commonSelectorGubunTitle = gubunTitle;
      }

      if (immediatlySearch) {
        this.searchCommonSelector();
      }
      setTimeout(() => this.commonSelectorRefSearchQuery.current?.focus(), 1);
    });
  }

  @action
  closeCommonSelector(reset = false): void {
    this.isVisibleCommonSelector = false;
    this.commonSelectorSearchQuery = '';
    this.commonSelectorData = undefined;

    if (reset) {
      this.onCommonSelectorResolve && this.onCommonSelectorResolve({
        cd: '',
        cdnm: '',
        uv_arg1: '',
        uv_arg2: '',
        uv_arg3: '',
        uv_arg4: '',
        uv_arg5: '',
      });
    }
  }

  @action
  successCommonSelector(item: CommonSelectorItem): void {
    this.onCommonSelectorResolve && this.onCommonSelectorResolve(item);
    this.closeCommonSelector();
  }


  /**
   *****************************************************************
   * Projects
   *****************************************************************
   */
  @action
  updateProjectSelectorSearchQuery(search: string): void {
    this.projectSelectorSearchQuery = search;
  }

  @action
  updateProjectSelectorSearchGubun(search: string): void {
    this.projectSelectorSearchGubun = search;
    this.searchProjectSelector();
  }

  @action
  onProjectSelectorRowFocusEvent(item: ProjectSelectorItem): void {
    this.selectedProjectData = item;
  }

  @action
  async searchProjectSelector(): Promise<void> {
    this.projectSelectorData = [];
    this.projectSelectorInfinity = new InfinityRetrieve(
      new ProjectSelectorParamsModel({
        ...this.projectSelectorApiParams,
        as_nm: this.projectSelectorSearchQuery,
        state: this.projectSelectorSearchGubun,
        gubun: '%',
        actcd: '%',
      }),
      async (params) => (
        this.publicStore.request(async () => FunctionRepository.searchProject(
          'retrieve',
          await this.publicStore.makeParams(params),
          this.isInstallProjectSelector || false,
        ))
      ),
      (items) => {
        if (!this.projectSelectorData?.length && items && items.length === 1) {
          this.successProjectSelector(items[0]);
        } else if (items) {
          this.projectSelectorData = [...this.projectSelectorData || [], ...items];
        }
      },
      async () => {
        this.projectSelectorData = [];
        this.projectSelectorInfinity?.retrieveAll();
      },
    );

    this.projectSelectorInfinity.retrieveAll();
  }

  @action
  openProjectSelector(params?: any, immediatlySearch?: boolean, isInstall?: boolean)
    : Promise<ProjectSelectorItem> {
    return new Promise<ProjectSelectorItem>((resolve, reject) => {
      this.projectSelectorApiParams = params || {};
      this.projectSelectorSearchQuery = params?.as_nm || '';
      this.onProjectSelectorResolve = resolve;
      this.onProjectSelectorReject = reject;
      this.isVisibleProjectSelector = true;
      this.isInstallProjectSelector = isInstall;

      if (immediatlySearch) {
        this.searchProjectSelector();
      }
    });
  }

  @action
  closeProjectSelector(): void {
    this.isVisibleProjectSelector = false;
    this.projectSelectorSearchQuery = '';
    this.projectSelectorData = undefined;
  }

  @action
  successProjectSelector(item: any): void {
    this.onProjectSelectorResolve && this.onProjectSelectorResolve(item);
    this.closeProjectSelector();
  }


  /**
   *****************************************************************
   * Clt
   *****************************************************************
   */
  @action
  updateCltSelectorSearchQuery(search: string): void {
    this.cltSelectorSearchQuery = search;
  }

  @action
  updateCltSelectorSearchRtclafi(search: string): void {
    this.cltSelectorSearchRtclafi = search;
    this.searchCltSelector();
  }

  @action
  onCltSelectorRowFocusEvent(item: CltSelectorItem): void {
    this.selectedCltData = item;
  }

  @action
  async searchCltSelector(): Promise<void> {
    this.cltSelectorData = [];

    this.cltSelectorInfinity = new InfinityRetrieve(
      new CltSelectorParamsModel({
        ...this.cltSelectorApiParams,
        as_nm: this.cltSelectorSearchQuery,
        rtclafi: this.cltSelectorSearchRtclafi,
      }),
      async (params) => (
        this.publicStore.request(async () => FunctionRepository.searchClt(
          'retrieve',
          await this.publicStore.makeParams(params),
        ))
      ),
      (items) => {
        if (items && items.length === 1) {
          this.successCltSelector(items[0]);
        } else if (items) {
          this.cltSelectorData = [...this.cltSelectorData || [], ...items];
        }
      },
      async () => {
        this.cltSelectorData = [];
        this.cltSelectorInfinity?.retrieveAll();
      },
    );

    this.cltSelectorInfinity.retrieveAll();
  }

  @action
  openCltSelector(params?: any, immediatlySearch?: boolean)
    : Promise<CltSelectorItem> {
    return new Promise<CltSelectorItem>((resolve, reject) => {
      this.cltSelectorApiParams = params || {};
      this.cltSelectorSearchQuery = params?.as_nm || '';
      this.cltSelectorSearchRtclafi = params?.rtclafi || '001';
      this.onCltSelectorResolve = resolve;
      this.onCltSelectorReject = reject;
      this.isVisibleCltSelector = true;

      if (immediatlySearch) {
        this.searchCltSelector();
      }
    });
  }

  @action
  closeCltSelector(): void {
    this.isVisibleCltSelector = false;
    this.cltSelectorSearchQuery = '';
    this.cltSelectorSearchRtclafi = '%';
    this.cltSelectorData = undefined;
  }

  @action
  successCltSelector(item: CltSelectorItem): void {
    this.onCltSelectorResolve && this.onCltSelectorResolve(item);
    this.closeCltSelector();
  }


  /**
   *****************************************************************
   * Fax
   *****************************************************************
   */
  @action
  openFax(toTelnum?: string, remark?: string, attachments?: TempFileInfo[]): void {
    this.faxTo = toTelnum || '';
    this.faxRemark = remark || '';
    this.faxAttachments = attachments || [];
    this.isVisibleFax = true;
  }

  @action
  closeFax(): void {
    this.isVisibleFax = false;
    this.faxTo = '';
    this.faxRemark = '';
    this.faxAttachments = [];
  }

  @action
  async sendFax(): Promise<boolean> {
    if (!this.faxTo) {
      ConfirmWarning.show('오류', '수신지 번호를 입력해주세요!');
      return false;
    }

    if (!this.faxAttachments.length) {
      ConfirmWarning.show('오류', '전송할 문서가 없습니다!');
      return false;
    }

    try {
      const { actionStore: api } = this;
      const data = await api.sendFax(
        this.faxTo,
        this.faxRemark,
        this.faxAttachments,
      );

      ConfirmSuccess.showPbMessage(data);
      this.closeFax();

      return true;
    } catch {
      return false;
    }
  }

  @action
  async faxAddAttachment() {
    if (ConfirmWarning.assert(this.faxAttachments.length < 1, '오류', '팩스는 한번에 하나씩만 전송이 가능합니다.')) {
      try {
        const files = await FileSelector.single(false);
        for (let i = 0; i < files.length; i += 1) {
          // eslint-disable-next-line no-await-in-loop
          await this.faxUploadAttachment(`${MD5.make(files[i].name)}${files[i].name.substr(files[i].name.lastIndexOf('.'))}`, files[i]);
        }
      } catch {
        ConfirmFail.show('오류', '파일 처리중 알 수 없는 문제가 발생하였습니다.');
      }
    }
  }

  @action
  async faxUploadAttachment(filename: string, file: File) {
    const { actionStore: api } = this;

    this.faxPercentAttachment = 1;

    this.faxAttachments = [
      ...this.faxAttachments,
      {
        ...(await api.tempUpload(file, filename, (e) => {
          const percent = Math.round((e.loaded / e.total) * 100.0) || 1;
          this.faxPercentAttachment = percent;
        })).data,
        extension: file.name.indexOf('.') ? file.name.substr(file.name.lastIndexOf('.') + 1) : '',
      },
    ];

    this.faxPercentAttachment = 0;
  }

  @action
  async faxAttachmentRemove(file: TempFileInfo) {
    this.faxAttachments = this.faxAttachments
      .filter((x) => x.filename !== file.filename && x.size !== file.size);
  }


  /**
   *****************************************************************
   * Email
   *****************************************************************
   */
  @action
  openEmail(
    from?: string,
    fromName?: string,
    to?: string,
    toName?: string,
    toCC?: string,
    toKakaoTelnum?: string,
    title?: string,
    remark?: string,
    attachments?: TempFileInfo[],
  ): void {
    const u = this.publicStore.user;
    this.emailFrom = from || u.emailadres;
    this.emailFromName = fromName || u.spjangnm;
    this.emailFromAutomation = '0';
    this.emailTo = to || '';
    this.emailSuggestion = [];
    this.emailToName = toName || '';
    this.emailCC = toCC || '';
    this.emailKakaoTelnum = toKakaoTelnum || '';
    this.emailTitle = title || '';
    this.emailRemark = remark || '';
    this.emailAttachments = attachments || [];
    this.emailPercentAttachment = 0;
    this.isVisibleEmail = true;
  }

  @action
  closeEmail(): void {
    this.isVisibleEmail = false;
    this.emailFrom = '';
    this.emailFromName = '';
    this.emailFromAutomation = '0';
    this.emailTo = '';
    this.emailSuggestion = [];
    this.emailToName = '';
    this.emailCC = '';
    this.emailKakaoTelnum = '';
    this.emailTitle = '';
    this.emailRemark = '';
    this.emailAttachments = [];
    this.emailPercentAttachment = 0;
  }

  @action
  async emailComboSelected(option: ComboBoxModel) {
    this.emailTo = option.value;
    this.emailToName = option.remark.substr(option.remark.indexOf('>') + 1).trim();

    const { data } = await FunctionRepository.request(
      false,
      'https://api.elmansoft.com/subreq/email_cc_quick_search.php', {
        database: this.publicStore.user.custcd,
        custcd: this.publicStore.user.custcd,
        search: option.value,
      },
    );

    this.emailCC = data?.response || '';
  }

  @action
  async emailQuickSearch(value: string) {
    const { data } = await FunctionRepository.request(
      false,
      'https://api.elmansoft.com/subreq/email_quick_search.php', {
        database: this.publicStore.user.custcd,
        custcd: this.publicStore.user.custcd,
        search: value,
      },
    );

    this.emailSuggestion = data?.response || [];
    this.emailTo = value;
  }

  @action
  selectEmailAutomation(isCompany: boolean) {
    const u = this.publicStore.user;
    this.emailFrom = isCompany ? u.emailadres : '';
    this.emailFromName = isCompany ? u.spjangnm : u.pernm;
    this.emailFromAutomation = isCompany ? '0' : '1';
  }

  @action
  async sendEmail(): Promise<boolean> {
    if (!this.emailFrom) {
      ConfirmWarning.show('오류', '보내는이의 이메일 주소를 입력해주세요!');
      return false;
    }

    if (!this.emailFromName) {
      ConfirmWarning.show('오류', '보내는이의 이름을 입력해주세요!');
      return false;
    }

    if (!this.emailTo) {
      ConfirmWarning.show('오류', '받는이의 이메일 주소를 입력해주세요!');
      return false;
    }

    if (!this.emailToName) {
      ConfirmWarning.show('오류', '받는이의 이름을 입력해주세요!');
      return false;
    }

    if (!this.emailTitle) {
      ConfirmWarning.show('오류', '이메일 제목을 입력해주세요!');
      return false;
    }

    if (!this.emailRemark) {
      ConfirmWarning.show('오류', '본문을 입력해주세요!');
      return false;
    }

    try {
      const { actionStore: api } = this;
      const data = await api.sendEmail(
        this.emailFrom,
        this.emailFromName,
        this.emailFromAutomation,
        this.emailTo,
        this.emailToName,
        this.emailCC,
        this.emailKakaoTelnum,
        this.emailTitle,
        this.emailRemark.replace(/\n/ig, '<BR>'),
        this.emailAttachments,
      );

      ConfirmSuccess.showPbMessage(data);
      this.closeEmail();

      return true;
    } catch {
      return false;
    }
  }

  @action
  async emailAddAttachment() {
    try {
      const files = await FileSelector.multi(false);
      for (let i = 0; i < files.length; i += 1) {
        // eslint-disable-next-line no-await-in-loop
        await this.emailUploadAttachment(files[i].name, files[i]);
      }
    } catch {
      ConfirmFail.show('오류', '파일 처리중 알 수 없는 문제가 발생하였습니다.');
    }
  }

  @action
  async emailUploadAttachment(filename: string, file: File) {
    const { actionStore: api } = this;

    this.emailPercentAttachment = 1;

    this.emailAttachments = [
      ...this.emailAttachments,
      {
        ...(await api.tempUpload(file, filename, (e) => {
          const percent = Math.round((e.loaded / e.total) * 100.0) || 1;
          this.emailPercentAttachment = percent;
        })).data,
        extension: file.name.indexOf('.') ? file.name.substr(file.name.lastIndexOf('.') + 1) : '',
      },
    ];

    this.emailPercentAttachment = 0;
  }

  @action
  async emailAttachmentRemove(file: TempFileInfo) {
    this.emailAttachments = this.emailAttachments
      .filter((x) => x.filename !== file.filename && x.size !== file.size);
  }


  /**
   *****************************************************************
   * Kakao
   *****************************************************************
   */
  @action
  openKakao(toTelnum?: { tel: string }[], remark?: string): void {
    this.kakaoSearchGubunData = [];
    this.kakaoSearchGubun = '2';
    this.kakaoSearchGubunCheck = true;
    this.kakaoSearchQuery = '';
    this.kakaoSearchCheck = false;
    this.kakaoSenderNumber = '';
    this.kakaoRemark = remark || '';
    this.kakaoSenderRemark = false;
    this.kakaoData = [];
    this.kakaoTo = toTelnum || [];
    this.kakaoPercentAttachment = 0;
    this.isVisibleKakao = true;

    this.retrieveKakao();
  }

  @action
  closeKakao(): void {
    this.isVisibleKakao = false;
    this.kakaoSearchGubunData = [];
    this.kakaoSearchGubun = '2';
    this.kakaoSearchGubunCheck = true;
    this.kakaoSearchQuery = '';
    this.kakaoSearchCheck = false;
    this.kakaoSenderNumber = '';
    this.kakaoRemark = '';
    this.kakaoSenderRemark = false;
    this.kakaoData = [];
    this.kakaoTo = [];
    this.kakaoPercentAttachment = 0;
  }

  @action
  async retrieveKakao() {
    const { actionStore: api } = this;

    this.kakaoData = [];
    this.kakaoInfinity = new InfinityRetrieve(
      {
        groupcd: this.kakaoSearchGubun || '2',
        ing: this.kakaoSearchGubunCheck ? '1' : '0',
      },
      (params) => api.kakao('retrieve', params),
      (items) => {
        this.kakaoData = [...this.kakaoData, ...items];
        this.kakaoTableUsers.current?.update(false);
      },
      async () => {
        this.kakaoData = [];
        this.kakaoInfinity?.retrieveAll();
        this.kakaoTableUsers.current?.update(true);
      },
    );

    this.kakaoInfinity.retrieveAll();
    this.kakaoTableUsers.current?.update(true);
  }

  @action
  async kakaoUpdateCheck(item: any, checked: boolean) {
    if (checked
      && this.kakaoTo.filter((y) => y.tel === item.tel).length === 0) {
      this.kakaoTo = [
        ...this.kakaoTo,
        { tel: item.tel },
      ];
      await this.kakaoTableTo.current?.update(false);
    } else if (!checked
      && this.kakaoTo.filter((y) => y.tel === item.tel).length > 0) {
      this.kakaoTo = this
        .kakaoTo.filter((y) => y.tel !== item.tel);
      await this.kakaoTableTo.current?.update(false);
    }
  }

  @action
  async kakaoUpdateCheckAllToggle(value: boolean) {
    this.kakaoTotalCheck = value;

    const updateTo: any[] = [];
    const existTels = this.kakaoTo.map((x) => x.tel); // 받는 사람 목록의 전화번호
    const targetData = this.kakaoData.filter((x) => x.check !== value); // 영향을 끼치는 유저 목록

    if (value) {
      // 받는 사람 목록에 추가
      targetData.forEach((x) => {
        if (existTels.indexOf(x.tel) === -1) {
          updateTo.push({ tel: x.tel });
        }
      });

      this.kakaoTo = [
        ...this.kakaoTo,
        ...updateTo,
      ];
    } else {
      // 받는 사람 목록에서 삭제
      targetData.forEach((x) => {
        if (existTels.indexOf(x.tel) > -1) {
          updateTo.push(x.tel);
        }
      });

      this.kakaoTo = this
        .kakaoTo.filter((y) => updateTo.indexOf(y.tel) === -1);
    }

    // 유저 목록에 선택 갱신
    this.kakaoData = this
      .kakaoData.map((x) => ({ ...x, check: value }));

    this.kakaoTableUsers.current?.update(false);
    this.kakaoTableTo.current?.update(false);
  }

  @action
  async searchKakao() {
    // 검색하기 전 모든 데이터를 불러온다
    await this.kakaoInfinity?.retrieveAll();

    let searched = false;
    for (
      let i = this.kakaoSearchPosition + 1;
      i < this.kakaoData.length;
      i += 1
    ) {
      const y = this.kakaoData[i];
      if ((y.actmail + y.tel).indexOf(this.kakaoSearchQuery) > -1) {
        this.kakaoSearchPosition = i;
        searched = true;
        break;
      }
    }

    if (searched) {
      this.kakaoTableUsers.current?.setFocus(this.kakaoSearchPosition);
    } else {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      });

      // @ts-ignore
      swalWithBootstrapButtons.fire({
        title: '검색',
        html: '조회된 결과가 없습니다.\n처음부터 다시 검색할까요?',
        icon: ConfirmTypeName[ConfirmType.WARNING],
        showCancelButton: true,
        confirmButtonText: '처음부터 검색',
        cancelButtonText: '취소',
        reverseButtons: true,
        // @ts-ignore
      }).then((result: { value: any; dismiss: Swal.DismissReason; }) => {
        if (result.value) {
          this.kakaoSearchPosition = -1;
          this.searchKakao();
        }
      });
    }
  }

  @action
  async sendKakao(): Promise<boolean> {
    if (!this.kakaoRemark) {
      ConfirmWarning.show('오류', '본문을 입력해주세요!');
      return false;
    }

    if (this.kakaoTo.length === 0) {
      ConfirmWarning.show('오류', '받는 사람을 최소 한명 추가해주세요!');
      return false;
    }

    const { user } = this.publicStore;
    const params = {
      custcd: user.custcd,
      perid: user.perid,
      hp: this.kakaoTo.map((x) => x.tel.replace(/-/ig, '')).join(','),
    };

    // Message
    if (this.kakaoRemark) {
      await FunctionRepository.kakaoSend({
        ...params,
        msg: this.kakaoRemark,
      });
    }

    ConfirmSuccess.show('전송 요청됨', '카카오톡 전송 요청이 완료되었습니다.\n순차적으로 전송될 예정입니다.');

    return true;
  }


  /**
   *****************************************************************
   * SMS
   *****************************************************************
   */
  @action
  openSMS(toTelnum?: { tel: string }[], remark?: string, attachments?: TempFileInfo[]): void {
    this.smsSearchGubunData = [];
    this.smsSearchGubun = '2';
    this.smsSearchGubunCheck = true;
    this.smsSearchQuery = '';
    this.smsSearchCheck = false;
    this.smsSenderNumber = '';
    this.smsRemark = remark || '';
    this.smsSenderRemark = false;
    this.smsData = [];
    this.smsAttachments = attachments || [];
    this.smsTo = toTelnum || [];
    this.smsPercentAttachment = 0;
    this.isVisibleSMS = true;

    this.retrieveSMS();
  }

  @action
  closeSMS(): void {
    this.isVisibleSMS = false;
    this.smsSearchGubunData = [];
    this.smsSearchGubun = '2';
    this.smsSearchGubunCheck = true;
    this.smsSearchQuery = '';
    this.smsSearchCheck = false;
    this.smsSenderNumber = '';
    this.smsRemark = '';
    this.smsSenderRemark = false;
    this.smsData = [];
    this.smsAttachments = [];
    this.smsTo = [];
    this.smsPercentAttachment = 0;
  }

  @action
  async retrieveSMS() {
    const { actionStore: api } = this;

    this.smsData = [];
    this.smsInfinity = new InfinityRetrieve(
      {
        groupcd: this.smsSearchGubun || '2',
        ing: this.smsSearchGubunCheck ? '1' : '0',
      },
      (params) => api.kakao('retrieve', params),
      (items) => {
        this.smsData = [...this.smsData, ...items.map((x: any) => ({
          ...x,
          check: this.smsTo.filter((y) => y.tel.replace(/-/ig, '') === x.tel.replace(/-/ig, '')).length > 0,
        }))];
        this.smsTableUsers.current?.update(false);

        this.smsData?.forEach((x, i) => {
          if (x.check === true) {
            this.smsTableUsers.current?.setFocus(i);
          }
        });
      },
      async () => {
        this.smsData = [];
        this.smsInfinity?.retrieveAll();
        this.smsTableUsers.current?.update(true);
      },
    );

    this.smsInfinity.retrieveAll();
    this.smsTableUsers.current?.update(true);
  }

  @action
  async smsUpdateCheck(item: any, checked: boolean) {
    if (checked
      && this.smsTo.filter((y) => y.tel === item.tel).length === 0) {
      this.smsTo = [
        ...this.smsTo,
        { tel: item.tel },
      ];
      await this.smsTableTo.current?.update(false);
    } else if (!checked
      && this.smsTo.filter((y) => y.tel === item.tel).length > 0) {
      this.smsTo = this
        .smsTo.filter((y) => y.tel !== item.tel);
      await this.smsTableTo.current?.update(false);
    }
  }

  @action
  async smsUpdateCheckAllToggle(value: boolean) {
    this.smsTotalCheck = value;

    const updateTo: any[] = [];
    const existTels = this.smsTo.map((x) => x.tel); // 받는 사람 목록의 전화번호
    const targetData = this.smsData.filter((x) => x.check !== value); // 영향을 끼치는 유저 목록

    if (value) {
      // 받는 사람 목록에 추가
      targetData.forEach((x) => {
        if (existTels.indexOf(x.tel) === -1) {
          updateTo.push({ tel: x.tel });
        }
      });

      this.smsTo = [
        ...this.smsTo,
        ...updateTo,
      ];
    } else {
      // 받는 사람 목록에서 삭제
      targetData.forEach((x) => {
        if (existTels.indexOf(x.tel) > -1) {
          updateTo.push(x.tel);
        }
      });

      this.smsTo = this
        .smsTo.filter((y) => updateTo.indexOf(y.tel) === -1);
    }

    // 유저 목록에 선택 갱신
    this.smsData = this
      .smsData.map((x) => ({ ...x, check: value }));

    this.smsTableUsers.current?.update(false);
    this.smsTableTo.current?.update(false);
  }

  @action
  async searchSMS() {
    // 검색하기 전 모든 데이터를 불러온다
    await this.smsInfinity?.retrieveAll();

    let searched = false;
    for (
      let i = this.smsSearchPosition + 1;
      i < this.smsData.length;
      i += 1
    ) {
      const y = this.smsData[i];
      if ((y.actmail + y.tel).indexOf(this.smsSearchQuery) > -1) {
        this.smsSearchPosition = i;
        searched = true;
        break;
      }
    }

    if (searched) {
      this.smsTableUsers.current?.setFocus(this.smsSearchPosition);
    } else {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      });

      // @ts-ignore
      swalWithBootstrapButtons.fire({
        title: '검색',
        html: '조회된 결과가 없습니다.\n처음부터 다시 검색할까요?',
        icon: ConfirmTypeName[ConfirmType.WARNING],
        showCancelButton: true,
        confirmButtonText: '처음부터 검색',
        cancelButtonText: '취소',
        reverseButtons: true,
        // @ts-ignore
      }).then((result: { value: any; dismiss: Swal.DismissReason; }) => {
        if (result.value) {
          this.smsSearchPosition = -1;
          this.searchSMS();
        }
      });
    }
  }

  @action
  async smsAddAttachment() {
    if (ConfirmWarning.assert(this.smsAttachments.length < 1, '오류', '문자메시지는 한번에 하나씩만 전송이 가능합니다.')) {
      try {
        const files = await FileSelector.single(true);
        const base64 = await FileReader.base64(files[0]);
        const resized = await ImageResizer.byRoughSizeToFile(base64, 70000); // 70kb
        await this.smsUploadAttachment(files[0].name, files[0], resized);
      } catch {
        ConfirmFail.show('오류', '파일 처리중 알 수 없는 문제가 발생하였습니다.');
      }
    }
  }

  @action
  async smsUploadAttachment(filename: string, file: File, data: Blob) {
    const { actionStore: api } = this;

    this.smsPercentAttachment = 1;
    const temp = (await api.tempUpload(data, filename, (e) => {
      const percent = Math.round((e.loaded / e.total) * 100.0) || 1;
      this.smsPercentAttachment = percent;
    })).data;

    this.smsAttachments = [
      ...this.smsAttachments,
      {
        ...temp,
        extension: file.name.indexOf('.') ? file.name.substr(file.name.lastIndexOf('.') + 1) : '',
      },
    ];

    this.smsPercentAttachment = 0;
  }

  @action
  async smsAttachmentRemove(file: TempFileInfo) {
    this.smsAttachments = this.smsAttachments
      .filter((x) => x.filename !== file.filename && x.size !== file.size);
  }

  @action
  async sendSMS(): Promise<boolean> {
    if (!this.smsRemark) {
      ConfirmWarning.show('오류', '본문을 입력해주세요!');
      return false;
    }

    if (this.smsTo.length === 0) {
      ConfirmWarning.show('오류', '받는 사람을 최소 한명 추가해주세요!');
      return false;
    }

    if (this.smsRemark) {
      const { user } = this.publicStore;
      const { actionStore: api } = this;
      await api.smsSend({
        items: this.smsTo.map((x) => x.tel.replace(/-/ig, '')),
        caller: user.tel2,
        callcc: user.tel,
        pernm: user.pernm,
        title: '',
        message: this.smsRemark,
        fileflag: this.smsAttachments.length > 0 ? '1' : '0',
        tempfile: this.smsAttachments.length > 0 ? this.smsAttachments[0] : {},
      });
    }

    ConfirmSuccess.show('전송 요청됨', '문자 전송 요청이 완료되었습니다.\n순차적으로 전송될 예정입니다.');

    return true;
  }


  /**
   *****************************************************************
   * Boilerplate
   *****************************************************************
   */
  openBoilerplate(): Promise<any> {
    this.boilerplateSearchQuery = '';
    this.boilerplateSelected = {};
    this.boilerplateRetrieve();
    this.isVisibleBoilerplate = true;
    return new Promise((resolve) => {
      this.boilerplateCallback = resolve;
    });
  }

  async boilerplateRetrieve() {
    const { actionStore: api } = this;

    this.boilerplateData = [];
    this.boilerplateInfinity = new InfinityRetrieve(
      {
        sub: 'w_popup_sms_repeat',
      },
      (params) => api.kakao('retrieve', params),
      (items) => {
        this.boilerplateData = [...this.boilerplateData, ...items.map((x) => ({
          ...x,
          remark: Fix.newline(x.remark) || '',
        }))];
      },
      async () => {
        this.boilerplateData = [];
        await this.boilerplateInfinity?.retrieveAll();
        if (this.boilerplateData.length > 0) {
          this.boilerplateGrid.current?.setFocus(0);
        }
      },
    );

    await this.boilerplateInfinity.retrieveAll();
    if (this.boilerplateData.length > 0) {
      this.boilerplateGrid.current?.setFocus(0);
    }
  }

  async searchBoilerplate() {
    // 검색하기 전 모든 데이터를 불러온다
    await this.boilerplateInfinity?.retrieveAll();

    let searched = false;
    for (
      let i = this.boilerplateSearchPosition + 1;
      i < this.boilerplateData.length;
      i += 1
    ) {
      const y = this.boilerplateData[i];
      if ((y.title + y.remark).indexOf(this.boilerplateSearchQuery) > -1) {
        this.boilerplateSearchPosition = i;
        searched = true;
        break;
      }
    }

    if (searched) {
      this.boilerplateGrid.current?.setFocus(this.boilerplateSearchPosition);
    } else {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      });

      // @ts-ignore
      swalWithBootstrapButtons.fire({
        title: '검색',
        html: '조회된 결과가 없습니다.\n처음부터 다시 검색할까요?',
        icon: ConfirmTypeName[ConfirmType.WARNING],
        showCancelButton: true,
        confirmButtonText: '처음부터 검색',
        cancelButtonText: '취소',
        reverseButtons: true,
        // @ts-ignore
      }).then((result: { value: any; dismiss: Swal.DismissReason; }) => {
        if (result.value) {
          this.boilerplateSearchPosition = -1;
          this.searchBoilerplate();
        }
      });
    }
  }

  closeBoilerplate(data?: any) {
    if (data) {
      this.boilerplateCallback(data);
    } else {
      this.boilerplateCallback(false);
    }
    this.isVisibleBoilerplate = false;
  }


  /**
   *****************************************************************
   * Approval
   *****************************************************************
   */
  openApprovalLine(papercd: PAPERCD | string, perid?: string): Promise<boolean> {
    this.approvalData = [];
    this.approvalPapercd = typeof papercd === 'string' ? papercd : String(papercd);
    this.approvalSelected = undefined;
    this.isVisibleApproval = true;
    this.approvalLinePerid = perid || this.publicStore.user.perid;

    this.approvalLineRetrieve();
    return new Promise((resolve) => {
      this.onApprovalResolve = resolve;
    });
  }

  closeApprovalLine(isSuccess: boolean) {
    if (ConfirmWarning.assert(
      this.approvalData.length,
      '오류',
      '결재라인에 결재자가 최소 한명은 있어야 합니다!',
    )) {
      this.isVisibleApproval = false;
      this.onApprovalResolve!(isSuccess);
      return;
    }

    if (ConfirmWarning.assert(
      this.approvalData.filter((x) => x.gubun === '101').length < 5,
      '오류',
      '결재라인은 4명까지만 가능합니다!',
    )) {
      this.isVisibleApproval = false;
      this.onApprovalResolve!(isSuccess);
    }
  }

  async approvalLineRetrieve() {
    this.approvalSelected = undefined;
    this.approvalData = [];

    const { actionStore: api, publicStore } = this;
    const { user } = publicStore;

    const gubun = await api.dropdown('wf_dd_ca510_611_01', { sub: 'w_popup_e064' });
    this.approvalGubunData = gubun?.items || [];

    const data = await api.fxExec('retrieve', {
      sub: 'w_popup_e064',
      custcd: user.custcd,
      spjangcd: user.spjangcd,
      perid: this.approvalLinePerid,
      papercd: this.approvalPapercd,
    });

    if (data) {
      this.approvalData = data.items.map((x: any) => new ApprovalItem(x));
    }

    await this.approvalGrid.current?.update(true);
    if (this.approvalData.length) {
      this.approvalGrid.current?.setFocus(0, 1);
    }
  }

  async approvalLineNew() {
    const { actionStore: api, publicStore } = this;
    const { user } = publicStore;

    const data = await api.fxExec('new', {
      sub: 'w_popup_e064',
      custcd: user.custcd,
      perid: this.approvalLinePerid,
      papercd: this.approvalPapercd,
      no: String(this.approvalData.length + 1),
      seq: String(this.approvalData.length + 1),
    });

    if (data) {
      this.approvalData = [
        ...this.approvalData,
        new ApprovalItem(data, true),
      ];

      await this.approvalGrid.current?.update(false);
      await this.approvalGrid.current?.setFocus(this.approvalData.length - 1, 1);
    }
  }

  async approvalLineDelete() {
    const { actionStore: api } = this;

    if (this.approvalSelected) {
      if (await api.fxDelete(
        'delete',
        `${this.approvalSelected?.seq}번 ${this.approvalSelected?.kcpernm}`,
        {
          sub: 'w_popup_e064',
          custcd: this.approvalSelected?.custcd,
          perid: this.approvalSelected?.perid,
          papercd: this.approvalPapercd,
          no: String(this.approvalSelected?.no),
        },
      )) {
        this.approvalData = this.approvalData
          .filter((x) => x.seq !== this.approvalSelected?.seq);
        this.approvalSelected = undefined;
        await this.approvalGrid.current?.update(false);
      }
    } else {
      ConfirmWarning.show('삭제', '삭제할 행을 먼저 선택해주세요.');
    }
  }

  async approvalLineSave(): Promise<Boolean> {
    if (!this.approvalData.length) {
      ConfirmWarning.show('오류', '결재라인에 결재자가 최소 한명은 있어야 합니다!');
      return false;
    }

    if (this.approvalData.filter((x) => x.gubun === '101').length > 4) {
      ConfirmWarning.show('오류', '결재라인은 4명까지만 가능합니다!');
      return false;
    }

    if (this.approvalData.filter((x) => !x.kcperid).length) {
      ConfirmWarning.show('오류', '결재자가 지정되지 않은 행이 있습니다!');
      return false;
    }

    const { actionStore: api, publicStore } = this;
    const { user } = publicStore;

    const appUsers = this.approvalData
      .filter((x) => x.gubun === '101')
      .sort((x, y) => (x.perid < y.perid ? -1 : 0));

    const refUsers = this.approvalData
      .filter((x) => x.gubun === '121')
      .sort((x, y) => (x.perid < y.perid ? -1 : 0));

    const results = appUsers.concat(refUsers).map((x, i) => new ApprovalItem({
      ...x,
      no: `${i + 1}`,
      seq: `${i + 1}`,
    }, x.isNew));

    if (await api.fxSave('save', {
      sub: 'w_popup_e064',
      custcd: user.custcd,
      perid: this.approvalLinePerid,
      papercd: this.approvalPapercd,
      items: results,
    }, true)) {
      await this.approvalLineRetrieve();
      return true;
    }
    return false;
  }


  /**
   *****************************************************************
   * AddClt
   *****************************************************************
   */
  async openAddClt(cltnm: string, gubun: string): Promise<ResisterModel> {
    this.addCltData = new ResisterModel({
      cltnm,
      gubun,
      sub: 'w_popup_xclient',
    });

    const { actionStore: api } = this;
    const data = await api.dropdown('wf_dd_ca510_046_01');

    this.addCltGrades = data?.items || [];
    this.isVisibleAddClt = true;

    const response = await api.fxExec('new', this.addCltData);
    this.addCltData = new ResisterModel(response);

    return new Promise((resolve, reject) => {
      this.onAddCltResolve = resolve;
      this.onAddCltReject = reject;
    });
  }

  async closeAddClt(isSave: boolean) {
    if (isSave) {
      const { actionStore: api } = this;
      if (await api.fxSave('save', this.addCltData, true)) {
        this.onAddCltResolve && this.onAddCltResolve(this.addCltData);
      } else {
        return;
      }
    } else {
      this.onAddCltReject && this.onAddCltReject();
    }

    this.isVisibleAddClt = false;
  }


  /**
   *****************************************************************
   * Wait queue
   *****************************************************************
   */
  @action
  openWaitQueue() {
    this.isVisibleWaitQueue = true;
  }

  @action
  closeWaitQueue() {
    this.isVisibleWaitQueue = false;
  }


  /**
   *****************************************************************
   * Approval Reference
   *****************************************************************
   */
  openApprovalReferenceLine(papercd: PAPERCD | string, appnum: string): Promise<boolean> {
    this.approvalReferenceData = [];
    this.approvalReferencePapercd = typeof papercd === 'string' ? papercd : String(papercd);
    this.approvalReferenceAppnum = appnum;
    this.approvalReferenceSelected = undefined;
    this.isVisibleApprovalReference = true;

    this.approvalReferenceLineRetrieve();
    return new Promise((resolve) => {
      this.onApprovalReferenceResolve = resolve;
    });
  }

  closeApprovalReferenceLine(isSuccess: boolean) {
    this.isVisibleApprovalReference = false;
    this.onApprovalReferenceResolve!(isSuccess);
  }

  async approvalReferenceLineRetrieve() {
    this.approvalReferenceSelected = undefined;
    this.approvalReferenceData = [];

    const { actionStore: api, publicStore } = this;
    const { user } = publicStore;

    const data = await api.fxExec('retrieve', {
      sub: 'w_popup_refer',
      custcd: user.custcd,
      spjangcd: user.spjangcd,
      perid: user.perid,
      papercd: this.approvalReferencePapercd,
      appnum: this.approvalReferenceAppnum,
    });

    if (data) {
      this.approvalReferenceData = data.items.map((x: any) => new ApprovalReferenceItem(x));
    }

    await this.approvalReferenceTable.current?.update(true);

    if (this.approvalReferenceData.length) {
      this.approvalReferenceTable.current?.setFocus(0);
    }
  }

  async approvalReferenceLineSave(): Promise<Boolean> {
    const { actionStore: api, publicStore } = this;
    const { user } = publicStore;

    if (await api.fxSave('save', {
      sub: 'w_popup_refer',
      custcd: user.custcd,
      perid: user.perid,
      papercd: this.approvalReferencePapercd,
      appnum: this.approvalReferenceAppnum,
      items: this.approvalReferenceData,
    }, true)) {
      return true;
    }
    return false;
  }

  @action
  async approvalReferenceUpdateCheckAllToggle(checked: boolean) {
    if (checked && this.approvalReferenceData?.length > 40) {
      ConfirmWarning.show('제한', '최대 40명까지만 선택할 수 있습니다');
      return;
    }

    this.approvalReferenceTotalCheck = checked;
    this.approvalReferenceData = this.approvalReferenceData.map((x) => new ApprovalReferenceItem({
      ...x,
      chk: checked ? '1' : '0',
    }));
    this.approvalReferenceTable.current?.update(false);
  }

  @action
  async searchApprovalReference() {
    let searched = false;
    for (
      let i = this.approvalReferenceSearchPosition + 1;
      i < this.approvalReferenceData.length;
      i += 1
    ) {
      const y = this.approvalReferenceData[i];
      if ((y.appperid + y.pernm).indexOf(this.approvalReferenceSearchQuery) > -1) {
        this.approvalReferenceSearchPosition = i;
        searched = true;
        break;
      }
    }

    if (searched) {
      this.approvalReferenceTable.current?.setFocus(this.approvalReferenceSearchPosition);
    } else {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      });

      // @ts-ignore
      swalWithBootstrapButtons.fire({
        title: '검색',
        html: '조회된 결과가 없습니다.\n처음부터 다시 검색할까요?',
        icon: ConfirmTypeName[ConfirmType.WARNING],
        showCancelButton: true,
        confirmButtonText: '처음부터 검색',
        cancelButtonText: '취소',
        reverseButtons: true,
        // @ts-ignore
      }).then((result: { value: any; dismiss: Swal.DismissReason; }) => {
        if (result.value) {
          this.approvalReferenceSearchPosition = -1;
          this.searchApprovalReference();
        }
      });
    }
  }


  /**
   *****************************************************************
   * Perid
   *****************************************************************
   */
  openSelectAccounts(title: string): Promise<Array<{ custcd: string, perid: string, pernm: string }>> {
    this.peridData = [];
    this.peridTitle = title;
    this.peridSearchQuery = '';
    this.peridSearchPosition = -1;
    this.peridTotalCheck = false;
    this.peridSelected = undefined;
    this.isVisiblePerid = true;

    this.peridRetrieve();
    return new Promise((resolve) => {
      this.onPeridResolve = resolve;
    });
  }

  closeAccountSelector(success: boolean) {
    this.isVisiblePerid = false;
    if (!success) return;

    this.onPeridResolve!(this.peridData.filter((x) => x.chk === '1').map((x) => ({
      custcd: x.custcd,
      perid: x.perid,
      pernm: x.pernm,
    })));
  }

  async peridRetrieve() {
    this.peridSelected = undefined;
    this.peridData = [];

    const { actionStore: api } = this;

    this.peridInfinity = new InfinityRetrieve(
      {
        sub: 'w_p2110',
        as_nm: '',
        spjangcd: '%',
        rtclafi: '001',
      },
      (params) => api.retrieve(params),
      (items) => {
        this.peridData = [...this.peridData, ...items];
      },
      async () => {
        this.peridData = [];
        await this.peridInfinity?.retrieveAll();
        await this.peridTable.current?.update(true);
        if (this.peridData.length) {
          this.peridTable.current?.setFocus(0);
        }
      },
    );

    await this.peridInfinity.retrieveAll();
    await this.peridTable.current?.update(true);
    if (this.peridData.length) {
      this.peridTable.current?.setFocus(0);
    }
  }

  @action
  async peridCheckAllToggle(checked: boolean) {
    this.peridTotalCheck = checked;
    this.peridData = this.peridData.map((x) => new BasicModel({
      ...x,
      chk: checked ? '1' : '0',
    }));
    this.peridTable.current?.update(false);
  }

  @action
  async searchPerid() {
    let searched = false;
    for (
      let i = this.peridSearchPosition + 1;
      i < this.peridData.length;
      i += 1
    ) {
      const y = this.peridData[i];
      if ((y.perid + y.pernm).indexOf(this.peridSearchQuery) > -1) {
        this.peridSearchPosition = i;
        searched = true;
        break;
      }
    }

    if (searched) {
      this.peridTable.current?.setFocus(this.peridSearchPosition);
    } else {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      });

      // @ts-ignore
      swalWithBootstrapButtons.fire({
        title: '검색',
        html: '조회된 결과가 없습니다.\n처음부터 다시 검색할까요?',
        icon: ConfirmTypeName[ConfirmType.WARNING],
        showCancelButton: true,
        confirmButtonText: '처음부터 검색',
        cancelButtonText: '취소',
        reverseButtons: true,
        // @ts-ignore
      }).then((result: { value: any; dismiss: Swal.DismissReason; }) => {
        if (result.value) {
          this.peridSearchPosition = -1;
          this.searchPerid();
        }
      });
    }
  }


  /**
   *****************************************************************
   * Push
   *****************************************************************
   */
  async openPush(title: string, message: string, recedate: string = '', recenum: string = '', items: Array<any>) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const { actionStore: api } = this;

      const spjangcds = await api.dropdown('wf_dd_spjangcd_02');
      this.pushSearchQuerySpjangcdData = spjangcds?.items || [];

      this.pushData = [];
      this.pushMessage = message;
      this.pushTitle = title;
      this.pushRecedate = recedate;
      this.pushRecenum = recenum;
      this.pushCheckedPerson = items;
      this.pushSelected = undefined;
      this.pushSearchQuerySpjangcd = this.publicStore.user.spjangcd;
      this.onPushResolve = resolve;
      this.onPushReject = reject;
      this.isVisiblePush = true;

      this.pushRetrieve();
    });
  }

  closePush() {
    this.isVisiblePush = false;
    this.onPushReject && this.onPushReject();
  }

  async pushRetrieve() {
    this.pushSelected = undefined;
    this.pushData = [];

    const { actionStore: api } = this;

    this.pushInfinity = new InfinityRetrieve(
      {
        sub: 'w_popup_push_perid',
        spjangcd: this.pushSearchQuerySpjangcd || '%',
        gdivicd: this.pushSearchQueryUpdivicd || '02',
        divicd: this.pushSearchQueryDivicd || '%',
        perid: this.pushSearchQueryPerid || '%',
      },
      (params) => api.fxExec('retrieve', params),
      (items) => {
        this.pushData = [...this.pushData, ...items.map((x: any) => new PushItem(x))];
        this.pushTable.current?.update(false);
      },
      async () => {
        this.pushData = [];
        await this.pushInfinity?.retrieveAll();

        const checked = toJS(this.pushCheckedPerson)?.map((x) => x.perid).join(' ') || '';
        this.pushData = this.pushData.map((x: any) => new PushItem({
          ...x,
          chk: checked.indexOf(x.perid) > -1 ? '1' : '0',
        }));

        await this.pushTable.current?.update(true);

        if (this.pushData.length) {
          this.pushTable.current?.setFocus(0);
        }
      },
    );

    await this.pushInfinity.retrieveAll();

    const checked = toJS(this.pushCheckedPerson)?.map((x) => x.perid).join(' ') || '';
    this.pushData = this.pushData.map((x: any) => new PushItem({
      ...x,
      chk: checked.indexOf(x.perid) > -1 ? '1' : '0',
    }));

    await this.pushTable.current?.update(true);

    if (this.pushData.length) {
      this.pushTable.current?.setFocus(0);
    }
  }

  @action
  async pushUpdateCheckAllToggle(checked: boolean) {
    this.pushTotalCheck = checked;
    this.pushData = this.pushData.map((x) => new PushItem({
      ...x,
      chk: checked ? '1' : '0',
    }));
    this.pushTable.current?.update(false);
  }

  @action
  async onPushSave() {
    this.isVisiblePush = false;
    this.onPushResolve && this.onPushResolve(this.pushData.filter((x) => x.isChecked));
  }


  /**
   *****************************************************************
   * Approval remark
   *****************************************************************
   */
  @action
  async openApprovalRemark(appnum: string) {
    this.isVisibleModalApprovalRemark = true;
    this.approvalRemarkAppnum = appnum;
    this.approvalRemarkData = [];

    const { actionStore: api, publicStore } = this;
    const { user } = publicStore;

    const gubuns = await api.dropdown('wf_dd_ca510_621_01');
    this.approvalGubunData = gubuns?.items || [];

    const data = await api.fxExec('retrieve', {
      sub: 'w_popup_e080_app_remark',
      custcd: user.custcd,
      spjangcd: user.spjangcd,
      perid: user.perid,
      appnum: this.approvalRemarkAppnum,
    });

    if (data?.items) {
      this.approvalRemarkData = data.items
        .sort((x: any, y: any) => (parseInt(x.seq, 10) < parseInt(y.seq, 10) ? -1 : 1));
      this.approvalRemarkFocused = data.items.length ? data.items[0] : {};
    }
  }

  @action
  closeApprovalRemark() {
    this.isVisibleModalApprovalRemark = false;
  }

  @action
  onApprovalRemarkRowFocusEvent(item: any) {
    this.approvalRemarkFocused = item;
  }

  getGubunnm(gubuncd: string) {
    const f = this.approvalGubunData?.filter((x) => x.com_code === gubuncd);
    return f.length ? f[0].com_cnam : '';
  }


  /**
   *****************************************************************
   * Approval reference remark
   *****************************************************************
   */
  @action
  async openApprovalReferenceRemark(appnum: string) {
    this.isVisibleModalApprovalReferenceRemark = true;
    this.approvalReferenceRemarkAppnum = appnum;
    this.approvalReferenceRemarkData = [];

    const { actionStore: api, publicStore } = this;
    const { user } = publicStore;

    const gubuns = await api.dropdown('wf_dd_ca510_611_01');
    this.approvalGubunData = gubuns?.items || [];

    const data = await api.fxExec('retrieve', {
      sub: 'w_popup_e080_app_state',
      custcd: user.custcd,
      spjangcd: user.spjangcd,
      perid: user.perid,
      appnum: this.approvalReferenceRemarkAppnum,
    });

    if (data?.items) {
      this.approvalReferenceRemarkData = data.items.filter((x: any) => x.appgubun !== '')
        .sort((x: any, y: any) => (parseInt(x.seq, 10) < parseInt(y.seq, 10) ? -1 : 1));
    }
  }

  @action
  closeApprovalReferenceRemark() {
    this.isVisibleModalApprovalReferenceRemark = false;
  }

  /**
   * Product selector
   */
  @action
  async openProductSelector(searchQuery: string = ''): Promise<ProductCodeModel> {
    this.isVisibleProductSelector = true;
    this.productSelectorSearchQuery = searchQuery;
    this.productSelectorSearchQuery2 = '';
    this.productSelectorSearchQuery3 = '%';
    this.productSelectorSearchWkactcd = '%';
    this.productSelectorSearchChoice = '%';
    this.productSelectorSearchPhm_mode = '%';
    this.productSelectorSearchAgrb = '%';
    this.productSelectorSearchBgrb = '%';
    this.productSelectorSearchCgrb = '%';
    this.productSelectorSearchMtyn = '%';
    this.productSelectorSearchCltcd = '';
    this.productSelectorSearchCltnm = '';
    this.productSelectorData = [];
    this.productSelected = undefined;

    // 회사별로 회사구분 default값
    if (this.publicStore?.user.custcd === 'green_u' || this.publicStore?.user.custcd === 'h_p') {
      this.productSelectorSearchWkactcd = '%';
    } else if (this.publicStore?.user.custcd === 'wsung_i') {
      this.productSelectorSearchWkactcd = '004';
    } else {
      this.productSelectorSearchWkactcd = '001';
    }

    await this.productOptionInit();

    return new Promise<ProductCodeModel>((resolve) => {
      this.onProductSelectorResolve = resolve;
    });
  }

  @action
  async productOptionInit() {
    const { actionStore: api } = this;

    [
      async () => {
        // 선택
        const data = await api.dropdown('wf_dd_ca503_03_choice_all', { sub: 'w_tb_ca501_01' });
        this.productSelectorChoices = data?.items.map((x: any) => new ProductChoiceAllModel(x)) || [];
      },
      async () => {
        // 품목분류
        const data = await api.dropdown('wf_dd_ca541_02', { sub: 'w_tb_ca501_01' });
        this.productSelectorPhm_modes = data?.items.map((x: any) => new ProductTypeModel(x)) || [];
      },
      async () => {
        // 대분류
        const data = await api.dropdown('wf_dd_ca503_01_02', { mode: this.productSelectorSearchPhm_mode, sub: 'w_tb_ca501_01' });
        this.productSelectorAgrbs = data?.items.map((x: any) => new ProductBigModel(x)) || [];
      },
      async () => {
        // 중분류
        const data = await api.dropdown('wf_dd_ca503_02_02', { pgr_hcod: this.productSelectorSearchAgrb, sub: 'w_tb_ca501_01' });
        this.productSelectorBgrbs = data?.items.map((x: any) => new ProductMiddleModel(x)) || [];
      },
      async () => {
        const data = await api.dropdown('wf_dd_ca503_03_02',
          {
            pgr_hcod: this.productSelectorSearchAgrb,
            bgroup: this.productSelectorSearchBgrb,
            sub: 'w_tb_ca501_01',
          });
        this.productSelectorCgrbs = data?.items.map((x: any) => new ProductSmallModel(x)) || [];
      },
    ].forEach((x) => x());

    await this.productSelectorRetrieve();
  }

  @action
  async productSelectorRetrieve() {
    const { actionStore: api } = this;

    this.productSelectorData = [];

    // 무한 스크롤바 헬퍼 초기화
    this.productSelectorInfinity = new InfinityRetrieve(
      {
        sub: 'w_tb_ca501_01',
        wkactcd: this.productSelectorSearchWkactcd,
        as_nm: this.productSelectorSearchQuery,
        psize: this.productSelectorSearchQuery2,
        sulchi: this.productSelectorSearchQuery3,
        choice: this.productSelectorSearchChoice,
        phm_mode: this.productSelectorSearchPhm_mode,
        agroup: this.productSelectorSearchAgrb,
        bgroup: this.productSelectorSearchBgrb,
        cgroup: this.productSelectorSearchCgrb,
        cltcd: this.productSelectorSearchCltcd,
        mtyn: this.productSelectorSearchMtyn,
      },
      (params) => api.retrieve(params, true),
      (items) => {
        this.productSelectorData = [...this.productSelectorData, ...items];
      },
      async () => {
        this.productSelectorData = [];
        await this.productSelectorInfinity?.retrieve();
        if (this.productSelectorData.length) this.productSelectorGrid.current?.setFocus(0);
      },
    );

    await this.productSelectorInfinity?.retrieve();
    if (this.productSelectorData.length) {
      this.productSelectorGrid.current?.setFocus(0);
    }
  }

  @action
  closeProductSelector(isSuccess: boolean = false) {
    this.isVisibleProductSelector = false;
    if (isSuccess && this.onProductSelectorResolve) {
      this.onProductSelectorResolve(this.productSelected!);
    }
  }

  @action
  onProductRowFocusEvent(item: ProductCodeModel) {
    this.productSelected = item;
    this.productImageRequest(item);
  }

  async productImageRequest(item: ProductCodeModel) {
    const { publicStore, actionStore: api } = this;

    this.productSelectorImageA = undefined;
    this.productSelectorImageB = undefined;

    // Image A
    let binary = await api.fxBinary(
      'tb_pic_retrieve',
      {
        sub: 'w_tb_ca501_01',
        spjangcd: publicStore.user.spjangcd,
        wkactcd: item.wkactcd,
        phm_pcod: item.phm_pcod,
      },
    );
    this.productSelectorImageA = binary;

    // Image B
    binary = await api.fxBinary(
      'tb_pic2_retrieve',
      {
        sub: 'w_tb_ca501_01',
        spjangcd: publicStore.user.spjangcd,
        wkactcd: item.wkactcd,
        phm_pcod: item.phm_pcod,
      },
    );
    this.productSelectorImageB = binary;
  }


  /**
   *****************************************************************
   * Rollback selector
   *****************************************************************
   */
  @action
  async openRollbackSelector() {
    this.isVisibleRollback = true;
    this.rollbackSelectorData = [];
    this.rollbackSelectedIndex = -1;

    this.rollbackSearchDate(`${Date6.make()}01`, Today.date());
  }

  @action
  closeRollbackSelector() {
    this.isVisibleRollback = false;
  }

  @action
  async rollbackSearchDate(stdate: string, enddate: string) {
    const { publicStore } = this;
    const windowName = publicStore.currentMenu.active.path?.substr(1) || '';

    this.rollbackSearchStdate = stdate;
    this.rollbackSearchEnddate = enddate;

    this.rollbackSelectorInfinity = new InfinityRetrieve(
      {
        stdate: this.rollbackSearchStdate,
        enddate: this.rollbackSearchEnddate,
      },
      (params) => publicStore.request(async () => FunctionRepository.states(
        windowName,
        {
          custcd: publicStore.user.custcd,
          spjangcd: publicStore.user.spjangcd,
          perid: publicStore.user.perid,
          token: publicStore.user.token,
          window: windowName,
          ...params,
        },
      )),
      (items) => {
        this.rollbackSelectorData = [
          ...this.rollbackSelectorData,
          ...items?.map((x: any) => new RollbackItem(x)) || [],
        ];
      },
      async () => {
        this.rollbackSelectorData = [];
        await this.rollbackSelectorInfinity?.retrieve();

        if (this.rollbackSelectorData.length > -1) {
          this.rollbackSelectedIndex = 0;
        }
      },
    );

    this.rollbackSelectorData = [];
    await this.rollbackSelectorInfinity?.retrieve();

    if (this.rollbackSelectorData.length > -1) {
      this.rollbackSelectedIndex = 0;
    }
  }

  @action
  onRollbackSelectorRowFocusEvent(index: number) {
    this.rollbackSelectedIndex = index;
  }

  @action
  async doRollback() {
    if (this.rollbackSelectedIndex === -1) {
      return;
    }

    const item = this.rollbackSelectorData[this.rollbackSelectedIndex];

    if (AskType.YES === await Confirm.ask(
      '롤백',
      `${Date8.withKor(item.indate)} ${Time4.withKor(item.intime)}\n이 시점으로 되돌릴까요?\n\n(되돌린 후에 저장하여야 서버에 반영됩니다)`,
      '예',
      '취소',
    )) {
      const { publicStore } = this;
      const data = await publicStore.request(async () => FunctionRepository.getState(
        item.id,
        await publicStore.makeParams({}),
      ));

      const json = JSON.parse(data.state);
      const state = json || this.publicStore.currentPage?.state;
      this.publicStore.currentPage?.setState(state);
      this.isVisibleRollback = false;
    }
  }


  /**
   *****************************************************************
   * Reproduce
   *****************************************************************
   */
  @action
  async openReproduceSelector() {
    this.isVisibleReproduce = true;
    this.reproduceSelectorData = [];
    this.reproduceSelectedIndex = -1;
    this.reproduceJson = new ActionItem();

    const { user } = this.publicStore;
    this.reproduceUpdate(user.perid, user.pernm);
  }

  @action
  closeReproduceSelector() {
    this.isVisibleReproduce = false;
  }

  @action
  onReproduceSelectorRowFocusEvent(index: number) {
    this.reproduceSelectedIndex = index;
    this.reproduceJson = new ActionItem({
      ...this.reproduceSelectorData[this.reproduceSelectedIndex],
      data: JSON.parse(this.reproduceSelectorData[this.reproduceSelectedIndex].data.toString()),
    });
  }

  @action
  async reproduceUpdatePerid(perid: string) {
    this.reproducePerid = perid;
  }

  @action
  async reproduceUpdate(perid: string, pernm: string) {
    const { publicStore } = this;
    const windowName = publicStore.currentMenu.active.path?.substr(1) || '';

    this.reproducePerid = perid;
    this.reproducePernm = pernm;

    this.reproduceSelectorInfinity = new InfinityRetrieve(
      {},
      (params) => publicStore.request(async () => FunctionRepository.actions(
        publicStore.user.custcd,
        this.reproducePerid,
        windowName,
        params,
      )),
      (items) => {
        this.reproduceSelectorData = [
          ...this.reproduceSelectorData,
          ...items?.map((x: any) => new ActionItem(x)) || [],
        ];
      },
      async () => {
        this.reproduceSelectorData = [];
        await this.reproduceSelectorInfinity?.retrieve();

        if (this.reproduceSelectorData.length > -1) {
          this.reproduceSelectedIndex = 0;
        }
      },
    );

    this.reproduceSelectorData = [];
    await this.reproduceSelectorInfinity?.retrieve();

    if (this.reproduceSelectorData.length > -1) {
      this.reproduceSelectedIndex = 0;
    }
  }

  /**
   *****************************************************************
   * Elman manager
   *****************************************************************
   */
  @action
  openElmanManagerNotice() {
    this.isVisibleModalElmNotice = true;
  }

  @action
  closeElmanManagerNotice() {
    this.isVisibleModalElmNotice = false;
  }

  @action
  reTryElmanManagerNotice() {
    const { managerKey } = this.publicStore;
    window.location.href = `emr://${managerKey}`;
  }

  /**
   *****************************************************************
   * ModalNotify
   *****************************************************************
   */
  @action
  async openIndividualSelector(): Promise<IndividualModel> {
    this.isVisibleIndividualSelector = true;
    this.individualData = [];
    this.individualDetailData = [];
    this.individualSelected = undefined;
    this.individualDetailSelected = undefined;

    return new Promise<IndividualModel>((resolve) => {
      this.onIndividualSelectorResolve = resolve;
      this.individualSelectorRetrieve();
    });
  }

  @action
  closeIndividualSelector(isSuccess: boolean = false) {
    this.isVisibleIndividualSelector = false;
    if (isSuccess && this.onIndividualSelectorResolve) {
      this.onIndividualSelectorResolve(this.individualSelected!);
    }
  }

  @action
  async individualSelectorRetrieve() {
    const { publicStore } = this;
    const data = await publicStore.request(async () => ActionRepository.retrieve(
      'general',
      await publicStore.makeParamsWithWindowName('w_xusers', {
        sub: 'w_popup_wakeup',
      }),
    ));

    this.individualData = data?.items;
    if (this.individualData?.length) this.individualSelectorGrid.current?.setFocus(0);
    this.individualData?.length > 0 && await this.onIndividualRowFocusEvent(this.individualData[0]);
  }

  @action
  async onIndividualRowFocusEvent(item: IndividualModel) {
    this.individualSelected = item;
    const { publicStore } = this;

    if (item.gubun !== '5' && item.gubun !== '8' && item.gubun !== '9'
      && item.gubun !== '10' && item.gubun !== '11' && item.gubun !== '16'
      && item.gubun !== '39') {
      this.individualDetailData = [];
      return;
    }

    const data = await publicStore.request(async () => FunctionRepository.exec(
      'general',
      'dw_list_RowFocuschanged',
      await publicStore.makeParamsWithWindowName('w_xusers', {
        sub: 'w_popup_wakeup',
        gubun: item.gubun,
      }),
    ));

    this.individualDetailData = data?.items || [];
    if (this.individualDetailData?.length) this.individualSelectorGrid2.current?.setFocus(0);
    this.individualData?.length > 0 && await this.onIndividualRowFocusEvent2(this.individualData[0]);
  }

  @action
  onIndividualRowFocusEvent2(item: IndividualModel) {
    this.individualDetailSelected = item;
  }

  @action
  async onSaveEvent() {
    const { user } = this.publicStore;

    // eslint-disable-next-line max-len
    if (this.individualSelected?.gubun !== '1' && this.individualSelected?.gubun !== '2' && this.individualSelected?.spjangcd !== user.spjangcd) {
      // eslint-disable-next-line max-len
      await Confirm.show('확인', `선택한 알림이 ${this.individualSelected?.spjangnm}사업장입니다. 전체 사업장이 ${this.individualSelected?.spjangnm}으로 변경됩니다. ${this.individualSelected?.spjangnm}으로 확인하시겠습니까?`, ConfirmType.QUESTION);
      return;
    }

    const today = new Date();
    const year = today.getFullYear();
    let month: string | number = today.getMonth() + 1; // 월
    let month2: string | number = today.getMonth() + 2; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    if (month2 < 10) {
      month2 = `0${month2}`;
    }

    let date: string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    // 고장다발현장
    let warning: boolean;
    if (this.individualSelected?.flag === '1') {
      warning = await Confirm.show('확인', '다발고장처리보고서로 넘어가시겠습니까? 아니요 하실시 다발고장현황으로 넘어갑니다', ConfirmType.QUESTION);
      if (warning === false) {
        this.publicStore.go(
          '/w_tb_e411w_01',
          {
            flag: '2',
            actnm: this.individualSelected?.actnm,
            equpnm: this.individualSelected?.equpnm,
          },
        );
      } else {
        this.publicStore.go(
          '/w_tb_e411_wad',
          {
            flag: '1',
            actnm: this.individualSelected?.actnm,
            equpnm: this.individualSelected?.equpnm,
          },
        );
      }
    }

    // 고장다발현장
    if (this.individualSelected?.gubun === '5') {
      let alert: boolean;
      // eslint-disable-next-line prefer-const
      alert = await Confirm.show('확인', '다발고장처리보고서로 넘어가시겠습니까? 아니요 하실시 다발고장현황으로 넘어갑니다.', ConfirmType.QUESTION);
      if (alert === true) {
        this.publicStore.go(
          '/w_tb_e411_wad',
          { searchQuery: this.individualDetailSelected?.actnm },
        );
      }
      if (alert === false) {
        this.publicStore.go(
          '/w_tb_e411w_01',
          {
            perid: user.perid,
            searchQuery: this.individualDetailSelected?.actnm,
          },
        );
      }
    }

    switch (this.individualSelected?.gubun) {
      case '1':
        // 문서결재
        this.publicStore.go(
          '/w_tb_e080_list',
        );
        break;

      case '2':
        // 수신참조
        this.publicStore.go(
          '/w_tb_e080w_03',
        );
        break;

      case '3':
        // 기결함-반려
        this.publicStore.go(
          '/w_tb_e080w_01',
          {
            gubun: '131',
          },
        );
        break;

      case '4':
        // 고장처리-미처리
        this.publicStore.go(
          '/w_tb_e411_01',
        );
        break;

      case '6':
        // 민원접수내역 미처리
        this.publicStore.go(
          '/w_tb_e301',
          {
            stdate: '19700101',
            enddate: `${year}${month}${date}`,
            resultck: '0',
          },
        );
        break;

      case '7':
        // 민원미처리내역
        this.publicStore.go(
          '/w_tb_e311',
          {
            stdate: '19700101',
            enddate: `${year}${month}${date}`,
            perid: user.perid,
          },
        );
        break;

      case '8':
        // 자체점검 미계획
        this.publicStore.go(
          '/w_tb_e035',
        );
        break;

      case '9':
        // 자체점검[다음달]
        this.publicStore.go(
          '/w_tb_e035',
          { planmon: month2 },
        );
        break;

      case '10':
        // 자체점검 계획일초과
        this.publicStore.go(
          '/w_tb_e033w',
          { result: '-99' },
        );
        break;

      case '11':
        // 자체점검-정보센터오류
        this.publicStore.go(
          '/w_tb_e035_gosi',
          {
            result: '-99',
            searchQuery: this.individualDetailSelected?.gubun,
          },
        );
        break;

      case '12':
        // 검사-만료일
        this.publicStore.go(
          '/w_tb_e073',
        );
        break;

      case '13':
        // 공사완료보고서
        this.publicStore.go(
          '/w_tb_e471w_02',
        );
        break;

      case '14':
        // 전자세금계산서
        this.publicStore.go(
          '/w_tb_ia090',
        );
        break;

      case '15':
        // 매입세금계산서[미지급]
        this.publicStore.go(
          '/w_tb_ca640w_02',
        );
        break;

      case '16':
        // 해지현장-자동이체중
        this.publicStore.go(
          '/w_tb_xclient',
          { searchQuery: this.individualDetailSelected?.cltnm },
        );
        break;

      case '17':
        // 계약만료답변
        this.openExpirationSelector();
        break;

      case '18':
        // 설치현장->보수
        this.openInstallationSelector();
        break;

      case '19':
        // 장기미수-자사
        this.publicStore.go(
          '/w_input_da023w_04',
        );
        break;

      case '20':
        // 장기미수-현대
        this.publicStore.go(
          '/w_input_da023w_04',
        );
        break;

      case '21':
        // 영업문의
        this.publicStore.go(
          '/w_tb_b502',
          {
            stdate: '19700101',
            enddate: `${year}${month}${date}`,
            perid: user.perid,
          },
        );
        break;

      case '22':
        // 확인된견적서
        this.publicStore.go(
          '/w_tb_e471',
        );
        break;

      case '23':
        // 외주인건비-매입요청
        this.publicStore.go(
          '/w_tb_ca640',
        );
        break;

      case '24':
        // 외주인건비-지급요청
        this.publicStore.go(
          '/w_tb_ca642_01_all',
        );
        break;

      case '25':
        // 사전검사등록
        this.publicStore.go(
          '/w_tb_e601w_sulchi_03',
          { openflag: '102' },
        );
        break;
      case '26':
        // 사전검사등록
        this.publicStore.go(
          '/w_tb_e601w_sulchi_03',
          { openflag: '103' },
        );
        break;
      case '27':
        // 사전검사등록
        this.publicStore.go(
          '/w_tb_e601w_sulchi_03',
          { openflag: '201' },
        );
        break;
      case '28':
        // 사전검사등록
        this.publicStore.go(
          '/w_tb_e601w_sulchi_03',
          { openflag: '203' },
        );
        break;
      case '29':
        // 사전검사등록
        this.publicStore.go(
          '/w_tb_e601w_sulchi_03',
          { openflag: '204' },
        );
        break;
      case '30':
        // 사전검사등록
        this.publicStore.go(
          '/w_tb_e601w_sulchi_03',
          { openflag: '202' },
        );
        break;
      case '31':
        // 사전검사등록
        this.publicStore.go(
          '/w_tb_e601w_sulchi_03',
          { openflag: '301' },
        );
        break;
      case '32':
        // 사전검사등록
        this.publicStore.go(
          '/w_tb_e601w_sulchi_03',
          { openflag: '401' },
        );
        break;
      case '33':
        // 사전검사등록
        this.publicStore.go(
          '/w_tb_e601w_sulchi_03',
          { openflag: '402' },
        );
        break;
      case '34':
        // 사전검사등록
        this.publicStore.go(
          '/w_tb_e601w_sulchi_03',
          { openflag: '404' },
        );
        break;
      case '35':
        // 사전검사등록
        this.publicStore.go(
          '/w_tb_e601w_sulchi_03',
          { openflag: '403' },
        );
        break;

      case '36':
      case '37':
        // 사전검사등록
        this.publicStore.go(
          '/w_tb_e601w_sulchi_03',
        );
        break;

      case '38':
        // 사전검사등록
        this.publicStore.go(
          '/w_tb_e601w_sulchi_03',
          { openflag: '501' },
        );
        break;

      case '39':
        // 설치현장등록
        this.publicStore.go(
          '/w_tb_e601_sulchi',
          { searchQuery: this.individualDetailSelected?.actnm },
        );
        break;
    }
    this.closeIndividualSelector();
  }

  /**
   *****************************************************************
   * ModalInstall
   *****************************************************************
   */
  @action
  async openInstallationSelector(): Promise<IndividualModel> {
    this.isVisibleInstallationSelector = true;
    this.installationData = [];
    this.installationSelected = undefined;

    return new Promise<IndividualModel>((resolve) => {
      this.onInstallationSelectorResolve = resolve;
      this.installSelectorRetrieve();
    });
  }

  @action
  closeInstallationSelector(isSuccess: boolean = false) {
    this.isVisibleInstallationSelector = false;
    if (isSuccess && this.onInstallationSelectorResolve) {
      this.onInstallationSelectorResolve(this.installationSelected!);
    }
  }

  @action
  async installSelectorRetrieve() {
    const { user } = this.publicStore;

    this.installationSelected = undefined;
    const { publicStore } = this;
    const data = await publicStore.request(async () => ActionRepository.retrieve(
      'general',
      await publicStore.makeParamsWithWindowName('w_xusers', {
        sub: 'w_popup_tb_e601_sulchi',
      }),
    ));
    this.installationTotal = data?.total || '';
    this.expirationUser = user.pernm;

    this.installationData = data?.items;
    await this.InstallationTable.current?.update(true);
    if (this.installationData.length) {
      this.InstallationTable.current?.setFocus(0);
    }
    this.installationData?.length > 0 && this.onInstallationRowFocusEvent(this.installationData[0]);
  }

  @action
  onInstallationRowFocusEvent(item: IndividualModel) {
    this.installationSelected = item;
  }

  @action
  async installationUpdateCheckAllToggle(checked: boolean) {
    const { user } = this.publicStore;

    this.installTotalCheck = checked;
    this.installationData = this.installationData.map((x) => new IndividualModel({
      ...x,
      scokflag: checked ? '1' : '0',
      scokpernm: checked ? user.pernm : '',
    }));
    this.InstallationTable.current?.update(false);
  }

  @action
  async installSave() {
    this.installationSelected = undefined;
    const { publicStore } = this;
    await publicStore.request(async () => ActionRepository.save(
      'general',
      await publicStore.makeParamsWithWindowName('w_xusers', {
        sub: 'w_popup_tb_e601_sulchi',
        items: this.installationData,
      }),
    ));
    await this.installSelectorRetrieve();
  }

  /**
   *****************************************************************
   * Open ModalExpiration
   *****************************************************************
   */

  @action
  async openExpirationSelector(): Promise<IndividualModel> {
    this.isVisibleExpirationSelector = true;
    this.expirationData = [];
    this.expirationSelected = undefined;

    return new Promise<IndividualModel>((resolve) => {
      this.onExpirationSelectorResolve = resolve;
      this.expirationSelectorRetrieve();
    });
  }

  @action
  closeExpirationSelector(isSuccess: boolean = false) {
    this.isVisibleExpirationSelector = false;
    if (isSuccess && this.onExpirationSelectorResolve) {
      this.onExpirationSelectorResolve(this.expirationSelected!);
    }
  }

  @action
  async expirationSelectorRetrieve() {
    const { publicStore } = this;
    const data = await publicStore.request(async () => ActionRepository.retrieve(
      'general',
      await publicStore.makeParamsWithWindowName('w_xusers', {
        sub: 'w_popup_tb_e101_history_wakeup',
      }),
    ));
    this.expirationTotal = data?.total || '';

    this.expirationData = data?.items;
    if (this.expirationData?.length) this.expirationSelectorGrid.current?.setFocus(0);
    this.expirationData?.length > 0 && this.onExpirationRowFocusEvent(this.expirationData[0]);
  }

  @action
  onExpirationRowFocusEvent(item: IndividualModel) {
    this.expirationSelected = item;
  }

  @action
  onPageEvent() {
    this.publicStore.go(
      '/w_tb_e601_new',
      {
        searchQuery: this.expirationSelected?.actcd,
      },
    );
  }

  /**
   *****************************************************************
   * Open Contract Modal
   *****************************************************************
   */

  @action
  async openSelectorRetrieve() {
    const { publicStore } = this;
    const data = await publicStore.request(async () => ActionRepository.retrieve(
      'general',
      await publicStore.makeParamsWithWindowName('w_xusers', {
        sub: 'open-popup',
      }),
    ));
    if (data?.openwindow === 'w_popup_e101_wakeup') {
      this.onContractRetrieveEvent();
    } else if (data?.openwindow === 'w_popup_e601_wakeup') {
      this.onInstallRetrieveEvent();
    }
  }

  @action
  async onContractRetrieveEvent() {
    const { publicStore } = this;

    const data = await publicStore.request(async () => ActionRepository.retrieve(
      'general',
      await publicStore.makeParamsWithWindowName('w_xusers', {
        sub: 'w_popup_e101_wakeup',
      }),
    ));

    this.contflag = data?.contflag || '';
    this.contractData = data?.items || '';
    this.contractTotal = data?.total || '';
    this.text1 = data?.text1 || '';
    this.text2 = data?.text2 || '';
    this.monamt_tot = data?.monamt_tot || '';
    this.amt_tot = data?.amt_tot || '';

    if (this.contractData?.length > 0) {
      await this.openContractSelector();
    }
  }

  @action
  async openContractSelector(): Promise<ContractModel> {
    this.isVisibleContractSelector = true;
    this.contractSelected = undefined;
    setTimeout(() => this.contractSelectorGrid.current?.setFocus(0), 300);
    return new Promise<ContractModel>((resolve) => {
      this.onContractSelectorResolve = resolve;
    });
  }

  @action
  closeContractSelector(isSuccess: boolean = false) {
    this.isVisibleContractSelector = false;
    if (isSuccess && this.onContractSelectorResolve) {
      this.onContractSelectorResolve(this.contractSelected!);
    }
    this.saveContractSelector();
  }

  @action
  saveContractSelector() {
    const { publicStore } = this;

    publicStore.request(async () => ActionRepository.save(
      'general',
      await publicStore.makeParamsWithWindowName('w_xusers', {
        sub: 'w_popup_e101_wakeup',
        chk: this.contractChk,
      }),
    ));
  }

  @action
  async onContractPrint() {
    const { publicStore } = this;
    const { waitQueueStore, modalStore } = publicStore;
    const { user } = publicStore;

    const src = MD5.make(`${user.custcd}${user.spjangcd}${user.perid}${new Date().getTime()}`);
    const title = `${publicStore.currentMenu?.active?.text || ''} 인쇄`;
    const key = `PRINT${src}`;

    waitQueueStore.append(title, key, async (response) => {
      const file = await FunctionRepository.tempDownloadRaw(response);
      if (file.size > 0) {
        try {
          printJS({
            printable: URL.createObjectURL(new Blob([file.raw!], {
              type: file.extension === 'pdf' ? 'application/pdf' : 'image/png',
            })),
            type: file.extension === 'pdf' ? 'pdf' : 'image',
            showModal: true,
          });
        } catch {
          ConfirmFail.show('오류', '인쇄에 실패하였습니다.');
        }
      }
    }, () => this.onContractPrint());
    modalStore?.openWaitQueue();

    await publicStore.request(async () => ActionRepository.print(
      'general',
      await publicStore.makeParamsWithWindowName('w_xusers', {
        sub: 'w_popup_e101_wakeup',
        src,
      }),
    ));
  }

  @action
  onContractRowFocusEvent(item: ContractModel) {
    this.contractSelected = item;
  }

  /**
   *****************************************************************
   * Open Install Modal
   *****************************************************************
   */

  @action
  async openInstallSelector(): Promise<InstallModel> {
    this.isVisibleInstallationSelector = true;
    this.installSelected = undefined;

    return new Promise<InstallModel>((resolve) => {
      this.onInstallSelectorResolve = resolve;
    });
  }

  @action
  closeInstallSelector(isSuccess: boolean = false) {
    this.isVisibleInstallationSelector = false;
    if (isSuccess && this.onInstallSelectorResolve) {
      this.onInstallSelectorResolve(this.installSelected!);
    }
    this.saveInstallSelector();
  }

  @action
  async onInstallRetrieveEvent() {
    const { publicStore } = this;

    const data = await publicStore.request(async () => ActionRepository.retrieve(
      'general',
      await publicStore.makeParamsWithWindowName('w_xusers', {
        sub: 'w_popup_e601_wakeup',
      }),
    ));
    this.installData = data?.items || '';
    this.installTotal = data.total || '';

    if (this.installData?.length > 0) {
      this.openInstallSelector();
    }
  }

  @action
  saveInstallSelector() {
    const { publicStore } = this;

    publicStore.request(async () => ActionRepository.save(
      'general',
      await publicStore.makeParamsWithWindowName('w_xusers', {
        sub: 'w_popup_e601_wakeup',
        chk: this.installChk,
      }),
    ));
  }

  @action
  onInstallRowFocusEvent(item: InstallModel) {
    this.installSelected = item;
  }

  /**
   *****************************************************************
   * Open Install Modal
   *****************************************************************
   */

  @action
  async openHistorySelector(remark?: string): Promise<HistoryPopupModel> {
    if (this.contflag === '0') {
      let result;
      // eslint-disable-next-line prefer-const
      result = await Confirm.ask('확인', this.text1, '예', '아니오');
      if (result === AskType.NO) {
        await ConfirmWarning.show('닫기', '취소되었습니다');
      } else if (result === AskType.YES) {
        await this.closeContractSelector();
        await this.publicStore.go(
          '/w_tb_e601_new',
          {
            searchQuery: this.contractSelected?.actnm,
          },
        );
      }
    } else if (this.contflag === '1') {
      this.isVisibleHistorySelector = true;
    }
    this.historySelected = undefined;
    this.remark = remark || '';

    return new Promise<HistoryPopupModel>((resolve) => {
      this.onHistorySelectorResolve = resolve;
      this.onHistoryRetrieveEvent();
    });
  }

  @action
  closeHistorySelector(isSuccess: boolean = false) {
    this.isVisibleHistorySelector = false;
    this.remark = '';
    if (isSuccess && this.onHistorySelectorResolve) {
      this.onHistorySelectorResolve(this.historySelected!);
    }
  }

  @action
  async onHistoryRetrieveEvent() {
    const { publicStore } = this;

    const data = await publicStore.request(async () => ActionRepository.retrieve(
      'general',
      await publicStore.makeParamsWithWindowName('w_xusers', {
        sub: 'w_tb_e101_history',
        actcd: this.contractSelected?.actcd,
      }),
    ));
    this.historyData = data?.items || '';
    this.remark = data?.remarka || '';

    await this.historyTableUsers.current?.update(true);
    await this.historyTableUsers.current?.setFocus(0, 2);
    this.historyData.length > 0 && this.onHistoryRowFocusEvent(this.historyData[0]);
  }

  @action
  async onNewHistoryEvent() {
    const { publicStore } = this;

    const data = await publicStore.request(async () => ActionRepository.new(
      'general',
      await publicStore.makeParamsWithWindowName('w_xusers', {
        sub: 'w_tb_e101_history',
        actcd: this.historySelected?.actcd,
      }),
    ));
    const newModel = new HistoryPopupModel(data, true);
    this.historySelected = newModel;
    this.historyData = [...this.historyData || [], data];
    await this.historyTableUsers.current?.setFocus(this.historyData?.length - 1, 1);
    await this.historyTableUsers.current?.update();
  }

  @action
  async onHistorySaveEvent() {
    const { publicStore } = this;

    const params = [{
      new: this.historySelected?.new,
      actcd: this.historySelected?.actcd,
      actnm: this.historySelected?.actnm,
      seq: this.historySelected?.seq,
      perid: this.historySelected?.perid,
      pernm: this.historySelected?.pernm,
      hdate: this.historySelected?.hdate,
      title: this.historySelected?.title,
      remark: this.remark,
      wakeflag: '',
      wakedate: this.historySelected?.wakedate,
      okflag: this.historySelected?.okflag,
    }];

    const data = await publicStore.request(async () => ActionRepository.save(
      'general',
      await publicStore.makeParamsWithWindowName('w_xusers', {
        sub: 'w_tb_e101_history',
        items: params,
      }),
    ));
    this.historyData = data?.items || '';

    if (data.messagebox) {
      ConfirmWarning.show('확인', data.messagebox);
      this.onHistoryRetrieveEvent();
    }
  }

  @action
  async historyDelete() {
    const { publicStore } = this;

    const warning = await Confirm.show('확인', '선택한 내역을 목록에서 삭제하시겠습니까?', ConfirmType.QUESTION);
    if (warning === false) return;

    const data = await publicStore.request(async () => ActionRepository.delete(
      'general',
      await publicStore.makeParamsWithWindowName('w_xusers', {
        sub: 'w_tb_e101_history',
        actcd: this.historySelected?.actcd,
        seq: this.historySelected?.seq,
      }),
    ));
    if (data.messagebox) {
      ConfirmWarning.show('확인', data.messagebox);
      this.HistoryUpdatedRows = [];
      await this.onHistoryRetrieveEvent();
    }
  }

  @action
  onHistoryRowFocusEvent(item: HistoryPopupModel) {
    this.historySelected = item;
    this.remark = item.remark;
  }

  @action
  onHistoryUpdatedRows(rows: Array<HistoryPopupModel>, updatedRows: Array<HistoryPopupModel>) {
    this.HistoryUpdatedRows = updatedRows;
    this.historyData = rows;
    this.onHistoryRowFocusEvent(updatedRows[0]);
  }

  /**
   *****************************************************************
   * ModalWriter Modal
   *****************************************************************
   */

  @action
  async openWriterSelector(writerSearch?: string): Promise<WriterModel> {
    this.isVisibleWriterSelector = true;
    this.writerSelected = undefined;
    this.writerSearch = writerSearch || '';

    return new Promise<WriterModel>((resolve) => {
      this.onWriterSelectorResolve = resolve;
      this.onWriterRetrieveEvent();
    });
  }

  @action
  closeWriterSelector(isSuccess: boolean = false) {
    this.isVisibleWriterSelector = false;
    this.writerSearch = '';
    if (isSuccess && this.onWriterSelectorResolve) {
      this.onWriterSelectorResolve(this.writerSelected!);
    }
  }

  @action
  async onWriterRetrieveEvent() {
    const { publicStore } = this;

    const data = await publicStore.request(async () => ActionRepository.retrieve(
      'general',
      await publicStore.makeParamsWithWindowName('w_xusers', {
        sub: 'TB_JA001',
        as_nm: this.writerSearch,
      }),
    ));
    this.writerData = data?.items || '';
  }

  @action
  onWriterDoubleClick() {
    const checkData: any = [];
    this.historyData.forEach((x: any) => {
      // @ts-ignore
      if (x.seq === this.historySelected.seq) {
        checkData.push({
          ...x,
          // @ts-ignore
          perid: this.writerSelected.perid,
          // @ts-ignore
          pernm: this.writerSelected.pernm,
        });
      } else {
        checkData.push({
          ...x,
        });
      }
    });
    this.historyData = checkData;
    this.historyTableUsers.current?.update();
    this.openWriterSelector();
  }

  @action
  onWriterRowFocusEvent(item: WriterModel) {
    this.writerSelected = item;
  }

  /**
   *****************************************************************
   * Calendar Modal
   *****************************************************************
   */

  @action
  openCalendar(ref: DateTextBox) {
    this.refCalendar = ref;
    this.isVisibleCalendar = true;
    this.refCalendarContainer.current?.update();
  }

  @action
  closeCalendar() {
    this.isVisibleCalendar = false;
    this.refCalendar = undefined;
  }

  /**
   *****************************************************************
   * Combo box Modal
   *****************************************************************
   */

  @action
  openComboBox(ref: ComboBox, width: number, height: number) {
    this.refComboBox = ref;
    this.isVisibleComboBox = true;
    this.comboBoxWidth = width;
    this.comboBoxHeight = height;
    this.refComboBoxContainer.current?.update();
    this.refComboBoxContainer.current?.reset();
  }

  @action
  closeComboBox() {
    this.isVisibleComboBox = false;
    this.refComboBox = undefined;
    this.comboBoxWidth = 0;
    this.comboBoxHeight = 0;
    this.refComboBoxContainer.current?.update();
    this.refComboBoxContainer.current?.reset();
  }

  /**
   *****************************************************************
   * Image Editor Modal
   *****************************************************************
   */

  @action
  openImageEditor(src: string | ArrayBuffer | undefined, handlerImageUpdate: (b64: string) => void) {
    if (!src) {
      ConfirmWarning.show('오류', '비정상적인 이미지입니다');
      return;
    }

    this.handlerImageUpdate = handlerImageUpdate;

    if (typeof src === 'object') {
      const blob = new Blob([src]);
      this.imageSrc = URL.createObjectURL(blob);
    } else {
      this.imageSrc = src;
    }

    this.isVisibleImageEditor = true;
  }

  @action
  closeImageEditor() {
    this.isVisibleImageEditor = false;
  }

  @action
  onImageEditorSave() {
    const data = this.refImageEditor.current?.imageEditorInst.toDataURL();
    this.handlerImageUpdate && this.handlerImageUpdate(data);
  }

  /**
   *****************************************************************
   * Address Search Modal
   *****************************************************************
   */

  @action
  openSearchAddress(as_nm?: any, immediatlySearch?: boolean)
    : Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.searchAddressSearchQuery = as_nm || '';
      this.onAddressSelectorResolve = resolve;
      this.onAddressSelectorReject = reject;
      this.isVisibleSearchAddress = true;

      if (immediatlySearch) {
        this.searchAddress();
      }
    });
  }

  @action
  updateSearchAddressSearchQuery(as_nm: string) {
    this.searchAddressSearchQuery = as_nm;
  }

  @action
  closeSearchAddress() {
    this.isVisibleSearchAddress = false;
    this.searchAddressSearchQuery = '';
    this.searchAddressData = [];
    this.onAddressSelectorResolve = undefined;
    this.onAddressSelectorReject = undefined;
  }

  @action
  onSearchAddressRowFocusEvent(item: any) {
    this.selectedSearchAddressData = item;
  }

  @action
  async successSearchAddress(item: any) {
    let result = item;
    const { data } = await FunctionRepository.request(
      false,
      'https://api.elmansoft.com/subreq/cms/addressZipcode.php', {
        q: item.roadAddress,
      },
    );

    if (data.length) {
      result = {
        ...item,
        zipcode: data[0].data?.searchZipCodeByQuery.zipCode,
      };
    }

    this.onAddressSelectorResolve && this.onAddressSelectorResolve(result);
    this.closeSearchAddress();
  }

  @action
  async searchAddress() {
    const { user } = this.publicStore;
    const { data } = await FunctionRepository.request(
      false,
      'https://api.elmansoft.com/subreq/addressRoughSearch.php', {
        q: this.searchAddressSearchQuery,
        coords: `${user.spjangcd_lng};${user.lat}`,
      },
    );

    this.searchAddressData = data?.sites || [];
    if (this.searchAddressData?.length) {
      this.searchAddressGrid.current?.setFocus(0);
    }
  }

  /**
   *****************************************************************
   * ModalQnA
   *****************************************************************
   */
  @action
  async openQnASelector(): Promise<any> {
    this.isVisibleQnASelector = true;
    this.dataQnA = [];
    this.dataQnASelected = undefined;

    return new Promise<any>((resolve) => {
      this.onQnASelectorResolve = resolve;
      this.dataQnASelectorRetrieve();
    });
  }

  @action
  closeQnASelector(isSuccess: boolean = false) {
    this.isVisibleQnASelector = false;
    if (isSuccess && this.onQnASelectorResolve) {
      this.onQnASelectorResolve(this.dataQnASelected!);
    }
    this.publicStore.updateCS();
  }

  @action
  async dataQnASelectorRetrieve() {
    const { publicStore } = this;
    const data = await publicStore.request(async () => ActionRepository.retrieve(
      'general',
      await publicStore.makeParamsWithWindowName('w_xusers', {
        sub: 'w_popup_bug',
        stdate: this.stdateaQnA,
        enddate: this.endateaQnA,
        as_nm: this.searchQueryQnA,
        receflag: this.receflag,
      }),
    ));

    this.dataQnA = data?.items;
    if (this.dataQnA?.length) this.dataQnASelectorGrid.current?.setFocus(0);
    this.dataQnA?.length > 0 && this.onQnARowFocusEvent(this.dataQnA[0]);
  }

  @action
  async onQnARowFocusEvent(item: any) {
    this.dataQnASelected = item;

    const { publicStore } = this;
    const data = await publicStore.request(async () => FunctionRepository.exec(
      'general',
      'dw_list_RowFocuschanged',
      await publicStore.makeParamsWithWindowName('w_xusers', {
        sub: 'w_popup_bug',
        recedate: item.recedate,
        recenum: item.recenum,
        spjangcd: item.spjangcd,
      }),
    ));

    this.recedate = data?.recedate;
    this.compdate = data?.compdate;
    this.remarkQnA = data?.remark.replace(/\\n/ig, '\n').replace(/\\/g, '');
    this.compremark = data?.compremark.replace(/\\n/ig, '\n').replace(/\\/g, '');
  }


  /**
   *****************************************************************
   * ModalAddress
   *****************************************************************
   */
  @action
  openAddressModal(ref: AddressButton) {
    this.refAddressButton = ref;
    this.isVisibleAddress = true;
  }

  @action
  closeAddress() {
    this.isVisibleAddress = false;
  }


  /**
   *****************************************************************
   * ModalAdvice
   *****************************************************************
   */
  @action
  openAdviceModal() {
    this.isVisibleModalAdvice = true;
  }

  @action
  closeAdvice() {
    this.isVisibleModalAdvice = false;
  }


  /**
   *****************************************************************
   * ModalAdvice
   *****************************************************************
   */
  @action
  openGosiAsk() {
    this.modalGosiAskAnswer = 0;
    this.isVisibleGosiAsk = true;
  }

  @action
  async closeGosiAsk(isOK: boolean = false) {
    this.isVisibleGosiAsk = false;

    if (isOK) {
      switch (this.modalGosiAskAnswer) {
        case 1:
          if (AskType.YES === await Confirm.ask('점검 연동', '정보센터 점검을 연동(동기화)하시겠습니까?', '예', '아니오')) {
            this.publicStore.withManager(() => {
              this.publicStore.publish({
                command: 'sync-self-inspect',
              });
            });
          }
          break;

        case 2:
          if (AskType.YES === await Confirm.ask('검사 연동', '정보센터 검사를 연동(동기화)하시겠습니까?', '예', '아니오')) {
            this.publicStore.withManager(() => {
              this.publicStore.publish({
                command: 'sync-inspect',
              });
            });
          }
          break;

        default:
          window.open('https://www.elevator.go.kr/main/Login.do?menuId=00000000');
      }
    }
  }


  /**
   *****************************************************************
   * ModalAdvice
   *****************************************************************
   */
  @action
  openKS() {
    this.modalKSAnswer = 0;
    this.isVisibleKS = true;
  }

  @action
  async closeKS(isOK: boolean = false) {
    this.isVisibleKS = false;
    if (isOK) {
      switch (this.modalKSAnswer) {
        case 0:
          window.open('https://www.ksbc.center');
          break;

        case 1:
          window.open('https://kscenter.co.kr/');
          break;

        default:
          window.open('https://api.elmansoft.com/ask/');
      }
    }
  }
}
